import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useCohortEndingSoonStore } from '../../store/CohortEndingSoonStore';
import { useEffect } from 'react';
import { Banner } from '.';

export const CohortEndingBanner = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
  }: {
    maxWidth?: string;
    padding?: string;
  }) => {
    const cohortEndingStore = useCohortEndingSoonStore();

    useEffect(() => {
      cohortEndingStore.loadCohortEndingSoon();
    }, [cohortEndingStore]);

    const cohortEndingSoon = cohortEndingStore.cohortOnboardingEndingSoon;
    if (
      !cohortEndingSoon ||
      cohortEndingSoon.cohortOnboardingEndingSoon === false
    )
      return;

    const firstCohortSpecialDate = DateTime.fromJSDate(
      cohortEndingSoon.onboardingEndDate,
    );
    const cohortSpecialDateIsInThePast =
      firstCohortSpecialDate <= DateTime.now();
    if (cohortSpecialDateIsInThePast) return;

    const cohortStartDateFormatted = DateTime.fromJSDate(
      cohortEndingSoon.cohortStartDate,
    ).toFormat('dd.MM');
    const onboardingEndDateFormatted = DateTime.fromJSDate(
      cohortEndingSoon.onboardingEndDate,
    ).toFormat('dd.MM');

    const title = `Du möchtest Teil der nächsten Kohorte werden, um schon zum ${cohortStartDateFormatted} mit dem legalen Kauf von Cannabis-Produkten zu beginnen?`;
    const description = `Dann registriere Dich so schnell wie möglich und vereinbare vor dem ${onboardingEndDateFormatted} einen Termin für das Aufnahmegespräch.`;

    return (
      <Banner
        padding={padding}
        maxWidth={maxWidth}
        title={title}
        description={description}
      />
    );
  },
);
