import { action, makeObservable, observable } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import ReactGA from 'react-ga4';

export const gaId = 'G-3RP3K5HYMJ';

export enum GoogleAnalyticsEvent {
  Survey_Complete = 'SURVEY_COMPLETE', // category: registration
  Register_Page = 'REGISTER_PAGE', // category: registration
  Not_Eligible_Page = 'USER_NOT_ELIGIBLE', // category: registration
  User_registerd = 'USER_REGISTERED', // category: registration
  Wants_to_book_appointment = 'APPOINTMENT_BOOKING', // category: registration,
  Enroll_Register = 'ENROLLED', // category: registration
  Enroll_Appointment = 'ENROLL_APPOINTMENT',
  Enroll_Club = 'ENROLL_CLUB',
}

export class GoogleAnalyticsStore {
  @observable public initialized = false;

  constructor() {
    makeObservable(this);
  }

  @action public init() {
    const isProd = process.env['NX_ENVIRONMENT'] === 'Production';
    const isAllowedToTrack = !(
      window.localStorage.getItem('ga_tracking_active') === 'false'
    );

    if (isProd && isAllowedToTrack && !this.initialized) {
      ReactGA.initialize(gaId);
      this.initialized = true;

      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: window.location.hostname,
      });
    }
  }

  @action public track(event: GoogleAnalyticsEvent, category = 'registration') {
    const isAllowedToTrack = !(
      window.localStorage.getItem('ga_tracking_active') === 'false'
    );

    if (isAllowedToTrack)
      ReactGA.event({
        category: category,
        action: event,
      });
  }
}

const { provider, useStore } = setupMobX<GoogleAnalyticsStore>();
export const GoogleAnalyticsProvider = provider;
export const useGoogleAnalyticsStore = useStore;
