import { MobileNavItem } from './MobileNavBar';
import { NavItem } from './NavItem';

export const DynamicNavItem = ({
  iconName,
  text,
  route,
  onClick,
  isMobile,
}: {
  iconName: string;
  text: string | JSX.Element;
  route: string;
  onClick?: () => void;
  isMobile?: boolean;
}) => {
  return isMobile ? (
    <MobileNavItem
      iconName={iconName}
      text={text}
      route={route}
      onClick={onClick}
    />
  ) : (
    <NavItem iconName={iconName} text={text} route={route} onClick={onClick} />
  );
};
