import { ErrorCode } from '@web/common';
import { RequestErrorType } from './RequestErrorType';
import { RequestError } from './RequestError';
import { ServerError } from './ServerError';
import { showErrorNotification } from '../component/notification';
import * as Sentry from '@sentry/react';

export function handleRequestError<
  K extends RequestErrorType | ErrorCode,
  T extends [K, () => void],
>(error: unknown, ...errorHandler: T[]) {
  let handled = false;
  if (error instanceof RequestError || error instanceof ServerError) {
    for (const [key, handler] of errorHandler) {
      if (key === error.errorCode) {
        handled = true;
        handler();
      }
    }

    if (!handled) {
      console.error(
        `Couldn't handle error. Error code ${error.errorCode} not handled. ${JSON.stringify(error)} ${error.message}`,
      );
      Sentry.captureEvent({
        message: `Error: ${error.errorCode} `,
        level: 'error',
        extra: {
          error,
          message: error.message,
        },
      });
      showErrorNotification();
    }
  } else {
    Sentry.captureEvent({
      message: `Error: handleRequestError`,
      level: 'error',
      extra: { error },
    });
    showErrorNotification();
    console.error(
      `Couldn't handle error. Was not of type Request error or server error.`,
    );
  }
}
