import { z } from 'zod';

const cohortEndingSoonSchema = z.object({
  cohortOnboardingEndingSoon: z.literal(true),
  cohortId: z.string(),
  cohortStartDate: z.coerce.date(),
  onboardingEndDate: z.coerce.date(),
});
const cohortNotEndingSoon = z.object({
  cohortOnboardingEndingSoon: z.literal(false),
});

export const cohortEndingSoonResDto = z.discriminatedUnion(
  'cohortOnboardingEndingSoon',
  [cohortEndingSoonSchema, cohortNotEndingSoon],
);
export type CohortEndingSoonResDto = z.infer<typeof cohortEndingSoonResDto>;
