import { Flex, Progress } from '@mantine/core';

export type SnakeBubbleProgressIndicatorProps = {
  active: number;
  max: number;
};
export const SnakeBubbleProgressIndicator = ({
  active,
  max,
}: SnakeBubbleProgressIndicatorProps) => {
  const range = [...Array(max).keys()].map((v) => v + 1);

  return (
    <Flex gap="5px" w="100%" justify="center">
      {range.map((step, index) => {
        const progress = index <= active ? 100 : 0;
        return (
          <Progress
            key={index}
            value={progress}
            size="md"
            color="#3D7F53"
            bg="#D0D0D0"
            w={index === active ? '20px' : '9px'}
          />
        );
      })}
    </Flex>
  );
};
