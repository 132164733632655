import { useOnboardingChecklistStore, useUserMeStore } from '../../../../store';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Accordion, Flex, Space, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import classes from './EmailVerification.module.css';
import { showSuccessNotification } from '../../../../component/notification';
import { CheckListId } from '@web/common';
import { useTranslation } from 'react-i18next';

export const EmailVerificationItemView = observer(
  ({ children }: PropsWithChildren) => {
    const checklistStore = useOnboardingChecklistStore();
    const myInfoStore = useUserMeStore();
    const { t } = useTranslation('onboarding-checklist');

    const emailVerified = !!myInfoStore.myInfo?.emailVerified;

    const [requestedEmail, setRequestedEmail] = useState(false);
    const shouldBeAbleToRequestEmail = !requestedEmail && !emailVerified;

    const sendEmail = () => {
      if (!shouldBeAbleToRequestEmail) return;
      myInfoStore.requestEmailVerificationMail();
      setRequestedEmail(true);
      showSuccessNotification(
        'Bitte überprüfe dein Postfach um deine E-Mail-Adresse zu verifizieren.',
        'E-Mail wurde versendet!',
      );
    };

    useEffect(() => {
      if (checklistStore.checklist) {
        const isMailVerifiedOnChecklist =
          checklistStore.checklist?.[CheckListId.EmailVerified]?.done;

        if (!isMailVerifiedOnChecklist && emailVerified) {
          checklistStore.checkItem(CheckListId.EmailVerified, true);
          showSuccessNotification(
            'Glückwunsch! Deine E-Mail Adresse ist jetzt verifiziert',
            'E-Mail verifiziert!',
          );
        }
      }
    }, [checklistStore, emailVerified]);

    return (
      <Accordion.Item
        key={CheckListId.EmailVerified}
        value={CheckListId.EmailVerified}
      >
        {children}

        <Accordion.Panel key={'accordion-panel'}>
          <Flex direction="column" align="flex-start" m="0px 34px">
            <Text ta="left" span>
              {t('MailVerification.Description1')}{' '}
              <Text fw={700} span>
                {myInfoStore.myInfo?.email}
              </Text>{' '}
              {t('MailVerification.Description2')}
            </Text>
            <Text ta="left">{t('MailVerification.Description3')}</Text>
            <Space h="md" />
            <Text ta="left">
              {t('MailVerification.Description4')}{' '}
              <Text span onClick={sendEmail} className={classes.text}>
                {t('MailVerification.Description5')}
              </Text>
            </Text>
            <Space h="md" />
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    );
  },
);
