import { z } from 'zod';
import { languageZodEnum } from '../../enum';

export const requestEnrollMobileReqDto = z.object({
  phoneNumber: z.string(),
  language: languageZodEnum(),
  source: z.string().optional(),
});
export type RequestEnrollMobileReqDto = z.infer<
  typeof requestEnrollMobileReqDto
>;
