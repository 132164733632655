import { CheckListId, CheckListItem } from '@web/common';
import { Routes } from '../../../../route/Routes';
import { Accordion, Checkbox, Flex, Text } from '@mantine/core';
import { ChecklistItemWrapper } from './ChecklistItemWrapper';
import { EmailVerificationItemView } from './EmailVerificationItemView';
import { BaseItem } from './BaseItem';
import { checklistStaticData } from './CheckListStaticData';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props {
  id: CheckListId;
  item: CheckListItem;
  handleTick: () => void;
  index: number;
  hideFormular?: boolean;
  t: WithTranslation['t'];
}
const ChecklistItemView = ({
  id,
  item,
  handleTick,
  index,
  hideFormular,
  t,
}: Props) => {
  const content = checklistStaticData(t);

  if (hideFormular) {
    switch (id) {
      case CheckListId.CannabisConsumptionQuestionnaire:
      case CheckListId.SF12Questionnaire:
        return;
      default:
        break;
    }
  }

  // this will hide the elements in the checklist
  switch (id) {
    case CheckListId.Einwilligungserklaerung:
    case CheckListId.Wohnsitznachweis:
    case CheckListId.AHVNummerNachweis:
      return;

    default:
      break;
  }

  return <div id={id}>{getContent(id)}</div>;

  function getContent(id: CheckListId) {
    switch (id) {
      case CheckListId.Einwilligungserklaerung: {
        return (
          <ChecklistItemWrapper
            key={id}
            id={id}
            title={() => (
              <BaseItem
                index={index}
                title={content[id].title}
                handleTick={handleTick}
                item={item}
              />
            )}
            item={item}
            description={
              <Flex gap="15px" direction="column">
                <Text component="div">{content[id].description}</Text>
                <Checkbox
                  label="Ich bestätige, die Einwilligungserklärung gelesen und verstanden zu haben."
                  checked={item.done}
                  onChange={handleTick}
                />
              </Flex>
            }
            type={CheckListId.Einwilligungserklaerung}
            route="/assets/Beilage Studienbedingungen.pdf"
            containsDownload
            btnText="Datei herunterladen"
          />
        );
      }
      case CheckListId.StammdatenQuestionnaire: {
        return (
          <ChecklistItemWrapper
            key={id}
            id={id}
            title={() => (
              <BaseItem
                index={index}
                item={item}
                title={content[id].title}
                handleTick={() => {}}
                changeableWhenDone={false}
              />
            )}
            item={item}
            description={content[id].description}
            type={CheckListId.StammdatenQuestionnaire}
            route={Routes.StammdatenQuestionnaire}
            routeState={{ id }}
            btnText={
              item.done ? t('Buttons.AlreadyFilledOut') : t('Buttons.Fillout')
            }
          />
        );
      }
      case CheckListId.CannabisConsumptionQuestionnaire: {
        return (
          <ChecklistItemWrapper
            key={id}
            id={id}
            title={() => (
              <BaseItem
                index={index}
                item={item}
                title={content[id].title}
                handleTick={() => {}}
                changeableWhenDone={false}
              />
            )}
            item={item}
            description={content[id].description}
            type={CheckListId.CannabisConsumptionQuestionnaire}
            route={Routes.CannabisConsumptionQuestionnaire}
            routeState={{ id }}
            btnText={
              item.done ? t('Buttons.AlreadyFilledOut') : t('Buttons.Fillout')
            }
          />
        );
      }
      case CheckListId.SF12Questionnaire: {
        return (
          <ChecklistItemWrapper
            key={id}
            id={id}
            title={() => (
              <BaseItem
                index={index}
                item={item}
                title={content[id].title}
                handleTick={() => {}}
                changeableWhenDone={false}
              />
            )}
            item={item}
            description={content[id].description}
            type={CheckListId.SF12Questionnaire}
            route={Routes.SF12Questionnaire}
            routeState={{ id }}
            btnText={
              item.done ? t('Buttons.AlreadyFilledOut') : t('Buttons.Fillout')
            }
          />
        );
      }
      case CheckListId.IdentitaetsNachweis: {
        return (
          <Accordion.Item key={id} value={id}>
            <BaseItem
              index={index}
              item={item}
              title={content[id].title}
              handleTick={handleTick}
              changeableWhenDone={true}
            />
            <Accordion.Panel>
              <Flex
                direction="column"
                align="flex-start"
                m="0px 34px"
                gap="15px"
              >
                <Text>{content[id].description}</Text>
                <Checkbox
                  label={content[id].checkboxLabel}
                  checked={item.done}
                  onChange={handleTick}
                />
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        );
      }
      case CheckListId.EmailVerified: {
        return (
          <EmailVerificationItemView>
            <BaseItem
              index={index}
              item={item}
              title={content[id].title}
              handleTick={() => {}}
              changeableWhenDone={false}
            />
          </EmailVerificationItemView>
        );
      }
      default: {
        return <div>{t('Text.UnkownEntry')}</div>;
      }
    }
  }
};

export default withTranslation('onboarding-checklist')(ChecklistItemView);
