import { observer } from 'mobx-react';
import { Card, Divider, Flex } from '@mantine/core';
import { PersonalData } from './PersonalData';
import { ParticipantStateInformation } from './ParticipantStateInformation';
import { useParticipantDataStore } from '../../../store';
import { UserStatus } from '@prisma/client';

export const ParticipantIdentification = observer(() => {
  const participantDataStore = useParticipantDataStore();
  const status = participantDataStore.data?.status;

  return (
    <Card bg="#F7F6F1" p="24px 16px">
      <Flex gap="40px">
        <PersonalData />
        {status !== UserStatus.OPEN && <Divider orientation="vertical" />}
        <ParticipantStateInformation />
      </Flex>
    </Card>
  );
});
