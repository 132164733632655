export interface TranslationObject {
  [key: string]: string;
}
export const translateLabels = (
  list: { label: string; value: string }[],
  translation: TranslationObject,
) => {
  return list.map((item) => {
    const translatedLabel = translation[item.label];
    return {
      ...item,
      label: translatedLabel ? translatedLabel : item.label,
    };
  });
};

export const translateValue = (
  value: string,
  translation: TranslationObject,
) => {
  const translatedValue = translation[value];
  return translatedValue ? translatedValue : value;
};
