import {
  SF12QuestionnaireQuestionKey,
  SF12QuestionnaireResult,
} from '../dto/sf12-questionnaire/sf12-questionnaire.req.dto';
import {
  contactToFriendsAndFamily,
  dailyActivitiesIssuesBcOfPainQuestion,
  generalHealthQuestion,
  last4WeeksFeelingQuestion,
  last4WeeksMentalIssuesQuestion,
  last4WeeksWorkPhysicalIssuesQuestion,
  mentalBaselineScore,
  normalDayActivitiesMatrixQuestion,
  physicalBaselineScore,
  shouldGoToPhysicianMental,
  shouldGoToPhysicianPhysical,
} from './sf12-survey-questions';

function customRound(number: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

function choiceValue(
  obj: {
    choices: { value: number; physicalValue: number; mentalValue: number }[];
  },
  value: number,
): { physicalValue: number; mentalValue: number } {
  const choice = obj.choices.find((choice) => choice.value === value);
  if (!choice) {
    throw new Error(`Could not find choice with value ${value}`);
  }

  return {
    physicalValue: choice.physicalValue,
    mentalValue: choice.mentalValue,
  };
}

function matrixExtraction(
  key: SF12QuestionnaireQuestionKey,
  matrix: Record<string, number>,
  obj: {
    columns: {
      value: number;
      values: { physicalValue: number; mentalValue: number }[];
    }[];
    rows: { value: string }[];
  },
): { physicalValue: number; mentalValue: number } {
  const rowIndex = obj.rows.findIndex((row) => row.value === key);
  if (rowIndex === -1) {
    throw new Error(`Could not find row with value ${key}`);
  }
  const column = obj.columns.find((column) => column.value === matrix[key]);
  if (!column) {
    throw new Error(`Could not find column with value ${matrix[key]}`);
  }

  return {
    physicalValue: column.values[rowIndex].physicalValue,
    mentalValue: column.values[rowIndex].mentalValue,
  };
}

const plusOperator = (a: number, b: number) => a + b;

function t(key: string) {
  return key;
}

export function sf12Scoring(result: SF12QuestionnaireResult): {
  physicalScore: number;
  mentalScore: number;
} {
  const points = [
    {
      result: choiceValue(
        generalHealthQuestion(t),
        result[SF12QuestionnaireQuestionKey.GENERAL_HEALTH],
      ),
      operator: plusOperator,
    },
    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.MIDDLE_HEAVY_WORK,
        result[SF12QuestionnaireQuestionKey.NORMAL_DAY_ACTIVITIES_MATRIX],
        normalDayActivitiesMatrixQuestion(t),
      ),
      operator: plusOperator,
    },
    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.MULTIPLE_STAIRS,
        result[SF12QuestionnaireQuestionKey.NORMAL_DAY_ACTIVITIES_MATRIX],
        normalDayActivitiesMatrixQuestion(t),
      ),
      operator: plusOperator,
    },

    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_PHYSICAL,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEKS_WORK_PHYSICAL_ISSUES],
        last4WeeksWorkPhysicalIssuesQuestion(t),
      ),
      operator: plusOperator,
    },

    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.I_COULD_ONLY_DO_CERTAIN_WORK_PHYSICAL,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEKS_WORK_PHYSICAL_ISSUES],
        last4WeeksWorkPhysicalIssuesQuestion(t),
      ),
      operator: plusOperator,
    },

    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_MENTAL,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEKS_MENTAL_ISSUES],
        last4WeeksMentalIssuesQuestion(t),
      ),
      operator: plusOperator,
    },
    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.I_COULDNT_WORK_AS_CAREFULLY_MENTAL,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEKS_MENTAL_ISSUES],
        last4WeeksMentalIssuesQuestion(t),
      ),
      operator: plusOperator,
    },

    {
      result: choiceValue(
        dailyActivitiesIssuesBcOfPainQuestion(t),
        result[SF12QuestionnaireQuestionKey.DAILY_ACTIVITIES_ISSUES_BC_OF_PAIN],
      ),
      operator: plusOperator,
    },

    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.RELAXED_AND_CALM,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING],
        last4WeeksFeelingQuestion(t),
      ),
      operator: plusOperator,
    },
    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.FULL_OF_ENERGY,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING],
        last4WeeksFeelingQuestion(t),
      ),
      operator: plusOperator,
    },
    {
      result: matrixExtraction(
        SF12QuestionnaireQuestionKey.DISCOURAGED_AND_SAD,
        result[SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING],
        last4WeeksFeelingQuestion(t),
      ),
      operator: plusOperator,
    },

    {
      result: choiceValue(
        contactToFriendsAndFamily(t),
        result[SF12QuestionnaireQuestionKey.CONTACT_TO_FRIENDS_AND_FAMILY],
      ),
      operator: plusOperator,
    },
  ];

  const accumulatedResult = points.reduce(
    (acc, obj) => {
      // console.debug(`${index + 1} obj: ${JSON.stringify(obj)}`);

      const result = {
        physicalScore: obj.operator(
          acc.physicalScore,
          obj.result.physicalValue,
        ),
        mentalScore: obj.operator(acc.mentalScore, obj.result.mentalValue),
      };
      // console.debug(`${index + 1} result: ${result}`);

      return result;
    },
    {
      physicalScore: physicalBaselineScore(),
      mentalScore: mentalBaselineScore(),
    },
  );

  return {
    physicalScore: customRound(accumulatedResult.physicalScore, 5),
    mentalScore: customRound(accumulatedResult.mentalScore, 5),
  };
}

export function sf12Evaluation(result: SF12QuestionnaireResult): {
  physical: boolean;
  mental: boolean;
} {
  const scores = sf12Scoring(result);

  return {
    physical: shouldGoToPhysicianPhysical(scores.physicalScore),
    mental: shouldGoToPhysicianMental(scores.mentalScore),
  };
}
