import {
  type OpenOrdersForUserResDto,
  type OrderDto,
  type ProductsResDto,
} from '@web/common';
import { observable, makeObservable, action, runInAction } from 'mobx';
import {
  getProducts,
  getOrders,
  getOrderDetails,
} from '../request/authenticated-requests/shopify';
import { showErrorNotification } from '../component/notification';
import { setupMobX } from '../util/setupMobX';

export class ShopStore {
  @observable public products: ProductsResDto = [];
  @observable public orders: OpenOrdersForUserResDto = [];
  @observable public orderDetails: OrderDto | null = null;
  @observable public showCartDrawer: boolean = false;
  @observable public loadingCart: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public setLoadingCart = (loading: boolean) => {
    this.loadingCart = loading;
  };

  @action public toggleCartDrawer = () => {
    this.showCartDrawer = !this.showCartDrawer;
  };

  @action public setCartDrawerOpen = (open: boolean) => {
    this.showCartDrawer = open;
  };

  @action public loadProducts = () => {
    getProducts()
      .then((products) => runInAction(() => (this.products = products)))
      .catch(() => showErrorNotification());
  };

  @action public loadOpenOrders = () => {
    getOrders()
      .then((orders) => runInAction(() => (this.orders = orders)))
      .catch(() => showErrorNotification());
  };

  @action public loadOrderDetails = (orderId: string) => {
    getOrderDetails(orderId)
      .then((order) => runInAction(() => (this.orderDetails = order)))
      .catch(() => showErrorNotification());
  };

  @action public clearOrderDetails = () => {
    this.orderDetails = null;
  };
}

const { provider, useStore } = setupMobX<ShopStore>();
export const useShopStore = useStore;
export const ShopStoreProvider = provider;
