import { observer } from 'mobx-react';
import { useCohortStore, useSearchUserStore } from '../../../../store';
import { DateTime } from 'luxon';
import { Select, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { UserStatus } from '@prisma/client';
import {
  ChecklistComplete,
  COHORT_NONE,
  UF_WITHOUT_TERMIN_VALUE,
} from '@web/common';
import { userSearchRemoveInvalidCharacters } from '../../../../util/userSearchRemoveInvalidCharacters';

const UNTIL_TODAY = 'UNTIL_TODAY';
const IN_THE_FUTURE = 'IN_THE_FUTURE';

export const Filter = observer(() => {
  const { t } = useTranslation('organizer-user-search-list');
  const [emailValue, setEmailValue] = useState<null | string>(null);
  const [phoneNumberValue, setPhoneNumberValue] = useState<null | string>(null);
  const searchUserStore = useSearchUserStore();
  const cohortStore = useCohortStore();

  const dateData = useMemo(
    () => [
      {
        label: t('Select.Date.Choice.WithoutTermin'),
        value: UF_WITHOUT_TERMIN_VALUE,
      },
      {
        label: t('Select.Date.Choice.UntilToday'),
        value: UNTIL_TODAY,
      },
      {
        label: t('Select.Date.Choice.InTheFuture'),
        value: IN_THE_FUTURE,
      },
    ],
    [t],
  );

  const statusData = useMemo(
    () =>
      Object.values(UserStatus).map((status) => ({
        label: t(`Select.Status.Choice.${status}`),
        value: status,
      })),
    [t],
  );

  const cohortData = useMemo(
    () => [
      {
        label: t('Select.Cohort.Choice.NoCohort'),
        value: COHORT_NONE,
      },
      ...cohortStore.cohorts.map((cohort) => ({
        label: `${cohort.id} - ${DateTime.fromJSDate(cohort.startDate)}`,
        value: cohort.id,
      })),
    ],
    [cohortStore.cohorts, t],
  );

  const checklistCompleteData = useMemo(
    () =>
      [
        {
          label: t('Select.ChecklistComplete.Choice.Complete'),
          value: ChecklistComplete.TRUE,
        },
        {
          label: t('Select.ChecklistComplete.Choice.Incomplete'),
          value: ChecklistComplete.FALSE,
        },
      ] as const,
    [t],
  );

  useEffect(() => {
    cohortStore.loadCohorts();
  }, [cohortStore]);

  useEffect(() => {
    searchUserStore.reset();
  }, [searchUserStore]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchUserStore.filter({
        email: emailValue ?? undefined,
        phoneNumber: phoneNumberValue
          ? userSearchRemoveInvalidCharacters(phoneNumberValue)
          : undefined,
      });
    }, 400);

    return () => clearTimeout(timeoutId);
  }, [emailValue, phoneNumberValue, searchUserStore]);

  const handleDateFilter = (value: string | null) => {
    if (value === null || value === '') {
      return searchUserStore.filter({
        termin: undefined,
      });
    }
    if (value === UF_WITHOUT_TERMIN_VALUE) {
      searchUserStore.filter({
        termin: UF_WITHOUT_TERMIN_VALUE,
      });
      return;
    } else if (value === UNTIL_TODAY) {
      searchUserStore.filter({
        termin: {
          qualifier: 'lte',
          value: DateTime.local().toJSDate(),
        },
      });
      return;
    } else if (value === IN_THE_FUTURE) {
      searchUserStore.filter({
        termin: {
          qualifier: 'gte',
          value: DateTime.local().toJSDate(),
        },
      });
      return;
    }
    return;
  };

  const handleStatusFilter = (value: string | null) => {
    if (value === null) {
      searchUserStore.filter({
        status: undefined,
      });
      return;
    }
    searchUserStore.filter({
      status: value as UserStatus,
    });
  };

  const handleCohortFilter = (value: string | null) => {
    if (value === null) {
      searchUserStore.filter({
        cohort: undefined,
      });
      return;
    }
    searchUserStore.filter({
      cohort: value,
    });
  };

  const handleChecklistCompleteFilter = (value: string | null) => {
    if (value === null) {
      searchUserStore.filter({
        checklistComplete: undefined,
      });
      return;
    }
    searchUserStore.filter({
      checklistComplete: value as ChecklistComplete,
    });
    return;
  };

  return (
    <>
      <h2>{t('FilterTitle')}</h2>
      <div>
        <TextInput
          style={{ flex: 1 }}
          placeholder={t('TextSearch.Placeholder')}
          value={emailValue ?? undefined}
          onChange={(event) => {
            const value = event.currentTarget.value;
            setEmailValue(value ? value : null);
          }}
          label={t('TextSearch.Label')}
          maw="500px"
        />
        <TextInput
          style={{ flex: 1 }}
          placeholder={t('PhoneNumberSearch.Placeholder')}
          value={phoneNumberValue ?? undefined}
          onChange={(event) => {
            const value = event.currentTarget.value;
            setPhoneNumberValue(value ? value : null);
          }}
          label={t('PhoneNumberSearch.Label')}
          maw="500px"
        />
        <Select
          placeholder={t('Select.Date.Placeholder')}
          label={t('Select.Date.Label')}
          w="350px"
          data={dateData}
          onChange={handleDateFilter}
        />
        <Select
          placeholder={t('Select.Status.Placeholder')}
          label={t('Select.Status.Label')}
          w="350px"
          data={statusData}
          onChange={handleStatusFilter}
        />
        <Select
          placeholder={t('Select.Cohort.Placeholder')}
          label={t('Select.Cohort.Label')}
          w="350px"
          data={cohortData}
          onChange={handleCohortFilter}
        />
        <Select
          placeholder={t('Select.ChecklistComplete.Placeholder')}
          label={t('Select.ChecklistComplete.Label')}
          w="350px"
          data={checklistCompleteData}
          onChange={handleChecklistCompleteFilter}
        />
      </div>
    </>
  );
});
