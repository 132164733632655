import {
  type OpenOrdersForUserResDto,
  type OpenOrdersResDto,
} from '@web/common';
import { showErrorNotification } from '../../../../component/notification';
import {
  getAllOpenOrders,
  getAllOpenOrdersForUser,
} from '../../../../request/authenticated-requests/shopify';
import { setupMobX } from '../../../../util/setupMobX';
import { observable, makeObservable, action, runInAction } from 'mobx';

export class OpenOrderStore {
  @observable public isLoading: boolean = false;
  @observable public openOrders: OpenOrdersResDto = [];
  @observable public openOrdersForUser: OpenOrdersForUserResDto = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadAllOpenOrders = () => {
    this.isLoading = true;
    getAllOpenOrders()
      .then((orders) => runInAction(() => (this.openOrders = orders)))
      .catch(() => showErrorNotification())
      .finally(() => (this.isLoading = false));
  };

  @action public getAllOpenOrdersForUser = (userId: string) => {
    this.isLoading = true;
    getAllOpenOrdersForUser(userId)
      .then((orders) => runInAction(() => (this.openOrdersForUser = orders)))
      .catch(() => showErrorNotification())
      .finally(() => (this.isLoading = false));
  };
}

const { provider, useStore } = setupMobX<OpenOrderStore>();
export const useOpenOrderStore = useStore;
export const OpenOrderStoreProvider = provider;
