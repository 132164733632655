import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Model } from 'survey-core';
import survey_theme from '../../assets/survey_theme.json';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.i18n';
import { Title } from '../title';
import { Survey } from 'survey-react-ui';
import { ZodError, ZodSchema } from 'zod';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { StammdatenQuestionnaireQuestionKey as Key } from '@web/common';

interface Props<QuestionnaireSchema> {
  title: string;
  questionnaire: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    json: any;
    schema: ZodSchema<QuestionnaireSchema>;
    initial?: Record<string, unknown>;
  };
  onComplete: (surveyResult: QuestionnaireSchema) => void;
  onError: (parseError: ZodError<QuestionnaireSchema>) => void;
  readonly?: boolean;
}

export const Questionnaire = <T,>({
  title,
  questionnaire: { json, initial, schema },
  readonly = false,
  onComplete,
  onError,
}: PropsWithChildren & Props<T>) => {
  const isSmallScreen = useWindowSize(615);
  const { t, i18n } = useTranslation();

  const questionnaire = useMemo(() => {
    const model = new Model(json);
    model.locale = i18n.language;
    model.applyTheme({ cssVariables: survey_theme.cssVariables });
    model.completeText = t('Buttons.Finish');
    model.onComplete.add((survey: Model) => {
      console.debug(`raw survey result (before parsing)`, survey.data);
      const parsed = schema.safeParse(survey.data);
      if (!parsed.success) {
        onError(parsed.error);
      } else {
        onComplete(parsed.data);
      }
    });

    if (readonly) {
      model.mode = 'display';
    }

    return model;
  }, [json, readonly, onComplete, onError, schema, i18n, t]);

  useEffect(() => {
    if (!initial) return;

    Object.keys(initial).forEach((key) => {
      // other nationality question contains a other field which is populated like this
      if (key === Key.OtherNationalityComment) {
        questionnaire.getQuestionByName(Key.OtherNationality).comment = initial[
          key
        ] as string;
        return;
      }

      questionnaire.getQuestionByName(key).value = initial[key];
    });
  }, [questionnaire, initial]);

  return (
    <div>
      <Title
        text={title}
        margin="0"
        boxPadding={isSmallScreen ? '0px 20px 0px 20px' : '0px 40px 0px 40px'}
      />
      <Survey model={questionnaire} />
    </div>
  );
};
