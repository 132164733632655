import { z } from 'zod';
import { userIdDto } from './user-id.dto';
import { phoneNumberPrefixRegex, phoneNumberRegex } from '.';

const combine = new RegExp(`^${phoneNumberPrefixRegex}${phoneNumberRegex}$`);

export const phoneNumberSchemaWithPrefix = z
  .string()
  .regex(combine, 'Invalid phone number format');

export const userContactInfoReqDto = z.object({
  id: userIdDto,
  email: z.string().email(),
  phoneNumber: phoneNumberSchemaWithPrefix,
});
export type UserContactInfoReqDto = z.infer<typeof userContactInfoReqDto>;
