import { z } from 'zod';
import { authenticatedAPI } from '../AuthenticatedAPI';
import {
  CancelOrderReqDto,
  openOrdersForUserResDto,
  openOrdersResDto,
  orderResDto,
  productsResDto,
} from '@web/common';

// --- participant endpoints ---
export const getProducts = async () => {
  return authenticatedAPI.get('api/shopify/products', productsResDto);
};

export const getOrders = async () => {
  return authenticatedAPI.get('api/shopify/orders', openOrdersForUserResDto);
};
// --- shop employee endpoints ---
export const getAllOpenOrders = async () => {
  return authenticatedAPI.get('api/shopify/orders/open', openOrdersResDto);
};

export const getAllOpenOrdersForUser = async (userId: string) => {
  return authenticatedAPI.get(
    `api/shopify/orders/${userId}/open`,
    openOrdersForUserResDto,
  );
};

export const getOrderDetails = async (orderId: string) => {
  return authenticatedAPI.get(`api/shopify/orders/${orderId}`, orderResDto);
};

export const cancelOrder = async (cancelOrder: CancelOrderReqDto) => {
  return authenticatedAPI.post(
    'api/shopify/orders/cancel',
    cancelOrder,
    z.string(),
  );
};

export const markOrderAsPickedUp = async (orderId: string) => {
  return authenticatedAPI.post(
    'api/shopify/orders/mark-as-picked-up',
    { orderId },
    z.string(),
  );
};
