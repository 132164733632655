import {
  AusweisTypEnum,
  CivilStatusEnum,
  CurrentLivingSituationEnum,
  devRandomCharString,
  OtherNationalityEnum,
  PassportTypeEnum,
  QuestionnaireOutput,
  SexEnum,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';

export function devRandomize(): QuestionnaireOutput {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  let ausweisData: any = {
    [StammdatenQuestionnaireQuestionKey.SwissNationality]: [true, false][
      Math.floor(Math.random() * 2)
    ],
  };
  if (ausweisData[StammdatenQuestionnaireQuestionKey.SwissNationality]) {
    ausweisData = {
      ...ausweisData,
      [StammdatenQuestionnaireQuestionKey.HeimatOrt]: devRandomCharString(10),
      [StammdatenQuestionnaireQuestionKey.AusweisType]: [
        AusweisTypEnum.Ausweis,
        AusweisTypEnum.Reisepass,
      ][Math.floor(Math.random() * 2)],
      [StammdatenQuestionnaireQuestionKey.AusweisNr]: devRandomCharString(20),
    };
  } else {
    ausweisData = {
      ...ausweisData,
      [StammdatenQuestionnaireQuestionKey.GeburtsOrt]: devRandomCharString(36),
      [StammdatenQuestionnaireQuestionKey.AusweisNr]: devRandomCharString(20),
    };
  }

  return {
    [StammdatenQuestionnaireQuestionKey.Name]: devRandomCharString(10),
    [StammdatenQuestionnaireQuestionKey.Surname]: devRandomCharString(10),
    [StammdatenQuestionnaireQuestionKey.Address]: devRandomCharString(20),
    [StammdatenQuestionnaireQuestionKey.PLZ]: 1234,
    [StammdatenQuestionnaireQuestionKey.Ort]: devRandomCharString(10),
    [StammdatenQuestionnaireQuestionKey.BirthDate]: `1999-11-11`,
    [StammdatenQuestionnaireQuestionKey.AHVNumber]: `1234567890123`,

    // socio-economic
    [StammdatenQuestionnaireQuestionKey.Sex]:
      Object.values(SexEnum)[Math.floor(Math.random() * 3)],
    [StammdatenQuestionnaireQuestionKey.BirthPlaceAbroad]: [true, false][
      Math.floor(Math.random() * 2)
    ],
    [StammdatenQuestionnaireQuestionKey.BirthPlace]: {
      [StammdatenQuestionnaireQuestionKey.BirthPlaceMunicipality]:
        devRandomCharString(20),
      [StammdatenQuestionnaireQuestionKey.BirthPlaceCanton]:
        devRandomCharString(20),
    },
    ...ausweisData,
    [StammdatenQuestionnaireQuestionKey.OtherNationality]:
      Object.values(OtherNationalityEnum)[Math.floor(Math.random() * 8)],
    [StammdatenQuestionnaireQuestionKey.PassportType]:
      Object.values(PassportTypeEnum)[Math.floor(Math.random() * 4)],
    [StammdatenQuestionnaireQuestionKey.CurrentLivingSituation]: Object.values(
      CurrentLivingSituationEnum,
    )[Math.floor(Math.random() * 5)],
    [StammdatenQuestionnaireQuestionKey.CivilStatus]:
      Object.values(CivilStatusEnum)[Math.floor(Math.random() * 6)],
    [StammdatenQuestionnaireQuestionKey.Clustering]: [
      ...Array(Math.floor(Math.random() * 5)).keys(),
    ].map(() => ({
      [StammdatenQuestionnaireQuestionKey.ClusteringName]:
        devRandomCharString(10),
      [StammdatenQuestionnaireQuestionKey.ClusteringSurname]:
        devRandomCharString(10),
    })),
  };
}
