import { Center, Flex } from '@mantine/core';
import { Header } from '../../view/public/navigation';
import { useParticipantDataStore } from '../../store';
import { Breadcrumbs } from '../../view/authenticated/navigation/Breadcrumbs';
import classes from './Participant.module.css';

export const QuestionnaireWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const participantData = useParticipantDataStore();

  return (
    <>
      <Header />
      <Center p="20px">
        <Flex className={classes.questionnaireWrapper}>
          <Center>
            <Breadcrumbs
              style={{ width: '1020px' }}
              path={
                '/ Studienteilnehmer prüfen / ' +
                participantData.getParticipantName()
              }
            />
          </Center>
          {children}
        </Flex>
      </Center>
    </>
  );
};
