import { observer } from 'mobx-react';
import { ActionIcon, Popover, Select } from '@mantine/core';
import { MyIcon } from '../../../../../component';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const AudioControlsSettings = observer(
  ({ setPlaybackRate }: { setPlaybackRate: (speed: number) => void }) => {
    const { t } = useTranslation('podcast');
    const [speed, setSpeed] = useState(1);

    return (
      <Popover width={'10em'} position="bottom" withArrow shadow="md">
        <Popover.Target>
          <ActionIcon size="lg" variant="transparent" radius="xl">
            <MyIcon name="settings" style={{ fill: '#202020' }} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Select
            label={t('Settings.Speed')}
            defaultValue="1"
            clearable={false}
            allowDeselect={false}
            data={[
              { value: '0.5', label: '0.5x' },
              { value: '1', label: '1x' },
              { value: '1.5', label: '1.5x' },
              { value: '2', label: '2x' },
            ]}
            value={speed.toString()}
            onChange={(value) => {
              setSpeed(Number(value));
              setPlaybackRate(Number(value));
            }}
            style={{ width: '8em' }}
          />
        </Popover.Dropdown>
      </Popover>
    );
  },
);
