import { observer } from 'mobx-react';
import { Flex, Text } from '@mantine/core';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { BaseLayout } from '../../navigation';
import { useTranslation } from 'react-i18next';
import { AllOpenOrders } from './AllOpenOrders';

export const OpenShopOrders = observer(() => {
  const { t } = useTranslation('shop');

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1500}
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('OpenShopOrders')}
        </Text>

        <AllOpenOrders />
      </Flex>
    </BaseLayout>
  );
});
