import { Button } from '@mantine/core';
import { MyIcon } from '../icon';

export const BaseExportButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => (
  <Button
    w="300px"
    variant="outline"
    onClick={onClick}
    rightSection={<MyIcon name="export" style={{ fill: '#3D7F53' }} />}
  >
    {label}
  </Button>
);
