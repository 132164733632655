import { useTranslation } from 'react-i18next';
import { Button, Flex, Stack, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useConsumptionStore } from '../../stores';

export const SubmitChanges = observer(
  ({ prevStep, onSubmit }: { prevStep: () => void; onSubmit: () => void }) => {
    const store = useConsumptionStore();
    const newLimit = store.newLimit;

    const { t } = useTranslation('consumption-dialog');
    return (
      <Flex direction="column" gap="10px">
        <Text>{t('SubmitChanges.Description')}</Text>
        <Text size="28px" lh="33px" lts="-0.5px" fw="600" ta="center" py="30px">
          {newLimit}g THC
        </Text>
        <Text size="14px" lh="21px" c="#4A4A48">
          {t('SubmitChanges.PleaseApprove')}
        </Text>
        <Stack p="30px 0px 0px 0px">
          <Button onClick={prevStep} variant="outline">
            {t('SubmitChanges.Buttons.Change')}
          </Button>
          <Button onClick={onSubmit}>
            {t('SubmitChanges.Buttons.Confirm')}
          </Button>
        </Stack>
      </Flex>
    );
  },
);
