import 'reflect-metadata';
import * as ReactDOM from 'react-dom/client';
import { App } from './App';
import 'survey-core/survey.i18n';
import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import '@mantine/carousel/styles.css';
import '@mantine/dates/styles.css';
import { setupSentry } from './config/setupSentry';
import './config/setupTranslations';
import './config/setupPolyfills';
import { GlobalStores } from './GlobalStores';

setupSentry();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <GlobalStores>
    <App />
  </GlobalStores>,
);
