import { Container, Space, Text, Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import contents from './contents';
import classes from './Faq.module.css';
import { ContactDetails } from '../landing-page/sections/ContactDetails';
import { useLayoutEffect } from 'react';
import { LandingPageHeader } from '../navigation';
import { useLocation } from 'react-router-dom';
import { AccordionSection } from './AccordionSection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export const FAQPage = observer(() => {
  const { t } = useTranslation('landingpage');
  const location = useLocation();
  const navigateId = location.state?.navigateId ?? null;
  const hash = location.hash.replace('#', '');
  const sessionNavigateId = localStorage.getItem('navigateId');
  const translatedContent = contents.getFullFaq(t);

  useLayoutEffect(() => {
    if (!navigateId && !sessionNavigateId && !hash) {
      window.scrollTo(0, 0);
      console.log('scrolling to top', sessionNavigateId);
    }
  });

  return (
    <>
      <Helmet>
        <title>Cannabis Studie Zürich: Häufig gestellte Fragen</title>
      </Helmet>
      <Container
        fluid
        className={classes.container}
        p={0}
        style={{ overflow: 'clip' }}
      >
        <LandingPageHeader />
        <Flex direction="column">
          <Flex className={classes.wrapper}>
            <Text className={classes.topTitle}>{t('faq:TitleSmall')}</Text>
            <Text className={classes.title}>{t('faq:TitleBig')}</Text>
            <Text ta="center">
              {t('faq:Description1')}
              <br />
              {t('faq:Description2')}
            </Text>
            <Space h="0px" />
          </Flex>
          <Flex className={classes.accordionContainer}>
            {translatedContent.map(
              (item: {
                title: string;
                content: { value: string; description: string | JSX.Element }[];
              }) => {
                return (
                  <AccordionSection
                    key={item.title}
                    title={item.title}
                    content={item.content}
                  />
                );
              },
            )}
          </Flex>
          <Flex className={classes.bottomSection}>
            <Text size="21px" fw="600" lh="24px">
              {t('faq:NoGoodAnswer')}
            </Text>

            <Text size="16px" lh="24px" maw="500px">
              {t('faq:NoGoodAnswerDescription1')}
              <br />
              {t('faq:NoGoodAnswerDescription2')} <br />
              {t('faq:NoGoodAnswerDescription3')}
            </Text>
            <ContactDetails />
          </Flex>
        </Flex>
      </Container>
    </>
  );
});
