import { z } from 'zod';
import { entryQuestionnaireReqDto } from '../entry-questionnaire';
import { languageZodEnum } from '../../enum';

export const phoneNumberPrefixRegex = '\\+[0-9]{1,3}';
export const phoneNumberRegex = '[0-9]{9,12}';

export const phoneNumberPrefixSchema = z
  .string()
  .regex(new RegExp(`^${phoneNumberPrefixRegex}$`));
export const phoneNumberSchema = z
  .string()
  /* eslint-disable-next-line no-useless-escape */
  .regex(
    new RegExp(`^${phoneNumberRegex}$`),
    'Nur Zahlen erlaubt (9-12 Ziffern).',
  );

export const registerReqDto = z.object({
  phoneNumberPrefix: phoneNumberPrefixSchema,
  phoneNumber: phoneNumberSchema,
  email: z.string().email({
    message: 'E-Mail ist nicht gültig.',
  }),
  password: z.string(),
  entryQuestionnaireData: entryQuestionnaireReqDto,
  language: languageZodEnum(),
  affiliateCode: z.string().optional(),
});

export type RegisterReqDto = z.infer<typeof registerReqDto>;
