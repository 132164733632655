import { z } from 'zod';
import { appointmentTypeZodEnum } from '../../enum/appointment-type.zod-enum';

export const BookingTriggerEvent = {
  BOOKING_CREATED: 'BOOKING_CREATED',
  BOOKING_RESCHEDULED: 'BOOKING_RESCHEDULED',
  BOOKING_CANCELLED: 'BOOKING_CANCELLED',
};

export const acceptedState = z.literal('ACCEPTED');
export const cancelledState = z.literal('CANCELLED');

const dateTime = z.coerce.date();

const payloadCommon = z.object({
  uid: z.string(),
  userFieldsResponses: z.object({
    // we want to call the booking link in the app with ?userid=... so that this is prefilled with the userid, see CAL.COM.md
    userid: z.object({
      label: z.string(),
      value: z.string().optional(),
    }),
    // we want to call the booking link in the app with ?eventtype=... so that this is prefilled with the eventtype, see CAL.COM.md
    eventtype: z.object({
      label: z.string(),
      value: appointmentTypeZodEnum().optional(),
    }),
  }),
  responses: z.object({
    location: z.object({
      value: z.object({
        value: z.string(),
      }),
    }),
    email: z.object({
      label: z.string(),
      value: z.string(),
    }),
    name: z.object({
      label: z.string(),
      value: z.string(),
    }),
  }),
});

export const bookingCreatedDto = z.object({
  triggerEvent: z.literal(BookingTriggerEvent.BOOKING_CREATED),
  payload: z
    .object({
      bookingId: z.number(),
      organizer: z.object({
        id: z.number(),
      }),
      startTime: dateTime,
      endTime: dateTime,
      status: z.union([acceptedState, z.string()]),
      team: z.object({
        id: z.number(),
      }),
      eventTypeId: z.number(),
    })
    .merge(payloadCommon),
});
export type BookingCreatedDto = z.infer<typeof bookingCreatedDto>;

export const bookingRescheduledDto = z.object({
  triggerEvent: z.literal(BookingTriggerEvent.BOOKING_RESCHEDULED),
  payload: z
    .object({
      organizer: z.object({
        id: z.number(),
      }),
      rescheduleUid: z.string(),
      startTime: dateTime,
      endTime: dateTime,
      status: z.union([acceptedState, z.string()]),
    })
    .merge(payloadCommon),
});
export type BookingRescheduledDto = z.infer<typeof bookingRescheduledDto>;

export const bookingCancelledDto = z.object({
  triggerEvent: z.literal(BookingTriggerEvent.BOOKING_CANCELLED),
  payload: z
    .object({
      status: z.union([cancelledState, z.string()]),
      cancellationReason: z.string().optional(),
    })
    .merge(payloadCommon),
});
export type BookingCancelledDto = z.infer<typeof bookingCancelledDto>;

export const receiveBookingTriggerReqDto = z.discriminatedUnion(
  'triggerEvent',
  [bookingCreatedDto, bookingRescheduledDto, bookingCancelledDto],
);

export type ReceiveBookingTriggerReqDto = z.infer<
  typeof receiveBookingTriggerReqDto
>;
