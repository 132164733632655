import { Button, Flex, Loader, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '../../navigation';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { List } from './List';
import {
  InventoryStore,
  InventoryStoreProvider,
  useInventoryStore,
} from './InventoryStore';
import { Filters } from './filter/Filters';
import { useEffect } from 'react';
import { DispensaryStore, DispensaryStoreProvider } from '../../../../store';
import { CSVExport } from '../../../../util/CSVExport';
import { mapData } from './mapForExport';

const Inventory = observer(() => {
  const store = useInventoryStore();
  const items = store.inventoryData?.length ?? 0;

  const isLoading = store.isLoading;
  const { t } = useTranslation('inventory');

  useEffect(() => {
    store.loadInventory();
  }, [store]);

  return (
    <BaseLayout>
      <Flex
        direction="column"
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Flex justify="space-between">
          <Text fw="500" size="28px" lh="24px">
            {t('Title')}
          </Text>
          <Flex gap="10px" justify="end">
            <Button variant="transparent" onClick={store.resetValues}>
              {t('Buttons.Reset')}
            </Button>

            <CSVExport
              csvData={mapData(store.inventoryData)}
              disabled={items === 0}
            />
          </Flex>
        </Flex>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Filters />
            <List />
          </>
        )}
      </Flex>
    </BaseLayout>
  );
});

export const InventoryView = () => {
  return (
    <InventoryStoreProvider store={new InventoryStore()}>
      <DispensaryStoreProvider store={new DispensaryStore()}>
        <Inventory />
      </DispensaryStoreProvider>
    </InventoryStoreProvider>
  );
};
