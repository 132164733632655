import { z } from 'zod';

export const shopifyOrderWithFulfillmentOrdersResDto = z.object({
  node: z.object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    fulfillmentOrders: z.object({
      nodes: z.array(
        z.object({
          id: z.string(),
          deliveryMethod: z.object({
            id: z.string(),
            methodType: z.string(),
          }),
          orderName: z.string(),
          requestStatus: z.string(),
          status: z.string(),
        }),
      ),
    }),
  }),
});
