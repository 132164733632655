import { Flex, Text } from '@mantine/core';
import { OrderItemDto } from '@web/common';
import placeholder from '../../../../../../assets/placeholder.svg';
import { getTitleAndDescriptionFromProduct } from '../../utils/getTitleAndDescriptionFromProduct';
import { Card } from '../../../../../../component';

export const OrderedProduct = ({ item }: { item: OrderItemDto }) => {
  const product = item.product;

  const { title, description } = getTitleAndDescriptionFromProduct(
    product.title,
  );

  return (
    <Card style={{ padding: 20, height: '100%', width: '100%' }}>
      <Flex direction="row" gap="20px" h="100%" align="end">
        <img
          src={product.imageUrl ?? placeholder}
          alt="product"
          height={50}
          style={{ alignSelf: 'center' }}
        />
        <Flex
          direction="column"
          gap="5px"
          w="100%"
          h="100%"
          justify="space-around"
        >
          <Text fw="600" size="16px" lh="24px">
            {title}
          </Text>
          <Text size="12px" lh="18px" c="#4A4A48" w="100%">
            {item.currentQuantity} x {description}
          </Text>
        </Flex>

        <Text w="140" ta="end">
          {item.currentQuantity * product.price} {product.currencyCode}
        </Text>
      </Flex>
    </Card>
  );
};
