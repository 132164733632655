import { Outlet } from 'react-router-dom';
import { Loading } from '../../component';
import { useKeycloakStore, useUserMeStore } from '../../store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import i18n from 'i18next';
import { RealmRole } from '@web/common';

export const AuthenticatedRoot = observer(() => {
  const kcStore = useKeycloakStore();
  const userMeStore = useUserMeStore();

  if (!kcStore.initialized) {
    kcStore.initKeycloak();
    return <Loading />;
  }

  if (!kcStore.authenticated) {
    void kcStore.login();
    return;
  }

  useEffect(() => {
    if (!userMeStore.myInfo) return;

    void i18n.changeLanguage(userMeStore.myInfo.language);
  }, [userMeStore.myInfo]);

  useEffect(() => {
    if (
      kcStore.authenticated &&
      kcStore.realmRoleList?.includes(RealmRole.Participant) &&
      !userMeStore.myInfo
    ) {
      userMeStore.loadMyData();
    }
  }, [
    userMeStore,
    kcStore.authenticated,
    userMeStore.myInfo,
    kcStore.realmRoleList,
  ]);

  return <Outlet />;
});
