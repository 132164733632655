import { observer } from 'mobx-react';
import { ActionIcon, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import classes from '../../../sharedStyles/Layout.module.css';
import { useShopStore } from '../../../store';
import { useCart } from '@shopify/hydrogen-react';
import mobileClasses from '../../../view/authenticated/navigation/NavBar/MobileNavBar/MobileNavBar.module.css';
import { ShoppingCartIcon } from './ShoppingCartIcon';
import { ShoppingCartDrawer } from '../../../view/authenticated/participant';

export const ShoppingCartButton = observer(
  ({ isMobile = false }: { isMobile?: boolean }) => {
    const { t } = useTranslation();
    const store = useShopStore();
    const { lines } = useCart();
    const isDisabled = !lines || lines?.length === 0;
    const Btn = isMobile ? ActionIcon : Button;
    const Icon = <ShoppingCartIcon isMobile={isMobile} />;

    return (
      <>
        <ShoppingCartDrawer
          opened={store.showCartDrawer}
          toggle={store.toggleCartDrawer}
        />

        <div
          style={{
            visibility: isDisabled && isMobile ? 'hidden' : 'visible',
          }}
          className={isMobile ? mobileClasses.mobileSticky : undefined}
        >
          <Btn
            className={isMobile ? mobileClasses.shoppingButton : classes.button}
            variant="white"
            justify="flex-start"
            size="lg"
            disabled={isDisabled}
            bg={isMobile ? '#202020' : 'transparent'}
            leftSection={isMobile ? null : Icon}
            onClick={store.toggleCartDrawer}
            radius={isMobile ? 'xl' : '5px'}
            p={isMobile ? '28px' : '0px'}
          >
            {isMobile && Icon}
            {!isMobile && (
              <Text
                c={isDisabled ? '#bbb' : '#000'}
                className={classes.navBtnText}
              >
                {t('Buttons.ShoppingCart')}
              </Text>
            )}
          </Btn>
        </div>
      </>
    );
  },
);
