import { useEffect } from 'react';
import { useUserMeStore } from '../../../../store';
import { reschedule } from '../book-in-person/cal-dot-com.links';
import { observer } from 'mobx-react';
import { Loading } from '../../../../component';
import { UserStatus } from '@prisma/client';
import { NoAccess } from '../../../no-access';

// this is only used as a permalink for changing the appointment
export const RescheduleAppointment = observer(() => {
  const myInfoStore = useUserMeStore();
  sessionStorage.removeItem('redirectLocation');

  useEffect(() => {
    myInfoStore.loadMyData();
    myInfoStore.loadMyLatestAppointment();
  }, [myInfoStore]);

  if (myInfoStore.loadingInfo) return <Loading />;

  // this page is only accessible for users with status OPEN
  if (
    myInfoStore.myInfo?.status !== UserStatus.OPEN ||
    !myInfoStore.appointment
  )
    return <NoAccess />;

  if (myInfoStore.appointment) {
    const link = reschedule(myInfoStore.appointment.id);
    window.location.href = link;
  }

  return <Loading />;
});
