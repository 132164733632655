import { DiaryEntry } from '@web/common';
import { DateTime } from 'luxon';
import type { i18n, TFunction } from 'i18next';

export function getFormattedDate(
  entry: DiaryEntry,
  t: TFunction,
  i18n: i18n,
): string {
  const { entryDate, entryTime } = entry;
  const formattedDate = DateTime.fromJSDate(entryDate)
    .setLocale(i18n.language)
    .toFormat('cccc, dd.MM.yy');

  const formattedTime = t(`EntryTime.${entryTime}`, {
    ns: 'diary-dialog',
  });

  return `${formattedDate} - ${formattedTime}`;
}
