import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  getMe,
  requestEmailVerificationMail,
  updateMyData,
} from '../request/authenticated-requests/user';
import {
  ErrorCode,
  type AppointmentResDto,
  type UserDataResDto,
  MyDataReqDto,
} from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { getMyLatestAppointment } from '../request/authenticated-requests/appointment';
import { showErrorNotification } from '../component/notification';
import { handleRequestError, RequestErrorType } from '../request';

export class UserMeStore {
  @observable public myInfo: UserDataResDto | null = null;
  @observable public appointment: AppointmentResDto | null = null;
  @observable public loadingInfo: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action public loadMyData = () => {
    this.loadingInfo = true;
    getMe()
      .then((myInfo) => {
        runInAction(() => {
          this.myInfo = myInfo;
        });
      })
      .catch((error) =>
        handleRequestError(error, [
          RequestErrorType.ParseIssue,
          () => {
            runInAction(() => {
              showErrorNotification(RequestErrorType.ParseIssue);
              this.myInfo = null;
            });
          },
        ]),
      )
      .finally(() => runInAction(() => (this.loadingInfo = false)));
  };

  @action public loadMyLatestAppointment = () => {
    getMyLatestAppointment()
      .then((appointment) => {
        runInAction(() => {
          this.appointment = appointment ?? null;
        });
      })
      .catch(() => showErrorNotification());
  };

  @action public requestEmailVerificationMail = () => {
    requestEmailVerificationMail()
      .then(({ success }) => {
        if (!success) {
          showErrorNotification(ErrorCode.EmailVerificationError);
        }
      })
      .catch(() => showErrorNotification());
  };

  @action public setMyInfo = (myInfo: MyDataReqDto) => {
    updateMyData(myInfo)
      .then(() => {
        runInAction(() => {
          this.loadMyData();
        });
      })
      .catch((e) => {
        console.error(e);
        showErrorNotification();
      });
  };
}

const { provider, useStore } = setupMobX<UserMeStore>();
export const useUserMeStore = useStore;
export const UserMeStoreProvider = provider;
