import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import {
  clusteringData,
  exportOnboardingData,
  studyData,
} from '../request/authenticated-requests/export';

export enum ExportType {
  Onboarding = 'onboarding',
  Clustering = 'clustering',
  AllStudyData = 'allStudyData',
}

export class ExportStore {
  @observable public open = false;

  @observable public exportType: null | ExportType = null;
  @observable public downloadSettings:
    | null
    | {
        type: ExportType.Onboarding;
        cohortId: string;
      }
    | { type: ExportType.Clustering; cohortId: string } = null;
  @observable public loading = false;
  @observable public downloadData: null | { filename: string; data: string } =
    null;

  constructor() {
    makeObservable(this);
  }

  @action public download = async () => {
    switch (this.exportType) {
      case ExportType.Onboarding: {
        const cohortId = this.downloadSettings?.cohortId;
        if (!cohortId) {
          throw new Error('No cohort id set');
        }

        const data = await exportOnboardingData(cohortId);
        runInAction(() => {
          this.downloadData = {
            data: data,
            filename: `onboarding-cohort-${cohortId}.csv`,
          };
        });
        break;
      }
      case ExportType.Clustering: {
        const cohortId = this.downloadSettings?.cohortId;
        if (!cohortId) {
          throw new Error('No cohort id set');
        }

        const data = await clusteringData(cohortId);
        runInAction(() => {
          this.downloadData = {
            data: data,
            filename: `clustering-cohort-${cohortId}.csv`,
          };
        });
        break;
      }
      case ExportType.AllStudyData: {
        const cohortId = this.downloadSettings?.cohortId;
        if (!cohortId) {
          throw new Error('No cohort id set');
        }
        const data = await studyData(cohortId);
        runInAction(() => {
          this.downloadData = {
            data: data,
            filename: `study-data-cohort-${cohortId}.csv`,
          };
        });
        break;
      }
      default: {
        console.log(`Unknown export type ${this.exportType}`);
        break;
      }
    }
  };

  @action public resetModal = () => {
    this.exportType = null;
    this.downloadSettings = null;
    this.open = false;
  };
}

const { provider, useStore } = setupMobX<ExportStore>();
export const useExportStore = useStore;
export const ExportStoreProvider = provider;
