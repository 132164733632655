import { Flex, Text, Button } from '@mantine/core';
import { useWindowSize } from '../../../../hooks';
import { InfoItem, MyIcon } from '../../../../component';
import { useOnboardingChecklistStore } from '../../../../store';
import { useTranslation } from 'react-i18next';

const smallScreenWidth = 760;

export const ChecklistShortcut = () => {
  const { t } = useTranslation('onboarding-checklist');
  const checklistStore = useOnboardingChecklistStore();
  const isSmallScreen = useWindowSize(smallScreenWidth);

  return (
    <Flex
      justify={isSmallScreen ? 'flex-start' : 'center'}
      p="20px 20px 10px 20px"
      gap="30px"
      wrap={isSmallScreen ? 'wrap' : 'nowrap'}
      maw="910px"
      m="auto"
    >
      <Flex
        direction="column"
        p="20px"
        gap="20px"
        style={{ borderRadius: 8, border: 'solid 2px #202020' }}
      >
        <div>
          <Text fw="600">{t('ChecklistShortcut.Title')}</Text>
          <Text>{t('ChecklistShortcut.Description')}</Text>
        </div>

        <Button
          w="fit-content"
          variant="outline"
          onClick={() => checklistStore.toggleSimpleChecklist()}
        >
          {t('ChecklistShortcut.MakeAppointment')}
        </Button>
        <InfoItem
          icon={<MyIcon name="phone" />}
          customText={
            <a style={{ color: '#000' }} href="tel:0444401121">
              044 440 11 21
            </a>
          }
        />
      </Flex>
    </Flex>
  );
};
