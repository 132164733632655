import {
  DeactivateUserReqDto,
  emailVerificationApiUrlPathParts,
  emailVerificationParam,
  RegisterReqDto,
  registerResDto,
  selfDeactivationApiUrlPathParts,
  selfDeactivationParam,
} from '@web/common';
import { publicAPI } from '../PublicAPI';
import { z } from 'zod';
import { Language } from '@prisma/client';

export const registerUser = async (data: RegisterReqDto) => {
  return await publicAPI.post(`/api/users/register`, data, registerResDto);
};

export const verifyEmail = async (token: string) => {
  const urlParams = new URLSearchParams({
    [emailVerificationParam]: token,
  });
  return await publicAPI.get(
    ['api', ...emailVerificationApiUrlPathParts].join('/') +
      '?' +
      urlParams.toString(),
    z.object({ success: z.boolean() }),
  );
};

export const requestEnrollMail = async (email: string, language?: Language) => {
  return await publicAPI.post(
    `/api/users/request-enroll-mail`,
    { email, language },
    z.object({ success: z.boolean() }),
  );
};

export const requestEnrollSMS = async (
  phoneNumber: string,
  language?: Language,
) => {
  return await publicAPI.post(
    `/api/users/request-enroll-mobile`,
    { phoneNumber, language },
    z.object({ success: z.boolean() }),
  );
};

export const deactivateUser = async (data: DeactivateUserReqDto) => {
  return await publicAPI.post(
    `/api/users/deactivate`,
    data,
    z.object({ success: z.boolean() }),
  );
};

export const verifyDeactivation = async (token: string) => {
  const urlParams = new URLSearchParams({
    [selfDeactivationParam]: token,
  });
  return await publicAPI.get(
    ['api', ...selfDeactivationApiUrlPathParts].join('/') +
      '?' +
      urlParams.toString(),
    z.object({ success: z.boolean() }),
  );
};
