import { Button, Flex, Text } from '@mantine/core';
import classes from '../../sharedStyles/InfoCard.module.css';
import { Link } from 'react-router-dom';
import reject_img from '../../assets/reject.svg';
import { useWindowSize } from '../../hooks';
import { Header } from './navigation';
import { useEffect } from 'react';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useGoogleAnalyticsStore,
  useSnapPixelStore,
} from '../../store';

const sorryMessage = `Tut uns leid. Die Auswertung des Fragebogens hat ergeben, 
  dass Du leider nicht zur Teilnahme an der Studie berechtigt bist.`;
const signToClubMessage = `Auch wenn es leider nicht mit der Studie geklappt hat, 
  kannst Du dich heute für den Bi!rdy Club anmelden und exklusive 
  Rabatte und Angebote erhalten.`;

export const NotEligible = ({
  customSorry,
  canLogout = false,
}: {
  customSorry?: string;
  canLogout?: boolean;
}) => {
  const isSmallScreen = useWindowSize(960);
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();

  useEffect(() => {
    googleAnalyticsStore.track(GoogleAnalyticsEvent.Not_Eligible_Page);
  }, [googleAnalyticsStore]);

  useEffect(() => {
    snapPixelStore.track(SnapEvent.Not_Eligible_Page);
  }, [snapPixelStore]);

  return (
    <Flex direction="column" mih="calc(100vh - 3.5em)" justify="center">
      <Header canLogout={canLogout} />

      <Flex style={{ flex: 1 }} align="center">
        {!isSmallScreen && (
          <Flex className={classes.rejectMessage}>
            <img
              src={reject_img}
              alt="register"
              style={{ minWidth: '200px', maxWidth: '250px' }}
            />
            <Text size="28px" ta="center">
              Tut uns leid!
            </Text>
            <Text maw="370px" ta="center">
              {customSorry ?? sorryMessage}
            </Text>
          </Flex>
        )}

        <Flex
          className={isSmallScreen ? classes.clubBoxSmall : classes.clubBox}
        >
          {isSmallScreen && (
            <img
              src={reject_img}
              alt="register"
              className={classes.rejectImage}
            />
          )}
          <Text size="28px" fw="700" lh="38px">
            Für den Bi!rdy Club anmelden
          </Text>

          {isSmallScreen && <Text m="30px 0px 0px 0px">{sorryMessage}</Text>}
          <Text my="30px">{signToClubMessage}</Text>
          <Link
            children={<Button>Zum Bi!rdy Club gehen</Button>}
            to="https://biirdy.club/"
            style={{ alignSelf: isSmallScreen ? 'center' : 'flex-start' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
