import { z } from 'zod';
import { UserStatus } from '@prisma/client';

export const simpleStatusChanges = [UserStatus.OPEN];

const simpleStatusChange = (status: string) =>
  z.object({
    status: z.literal(status).transform(() => status as 'OPEN'),
  });

const activeStatusChange = z.object({
  status: z.literal(UserStatus.ACTIVE),
  cohortId: z.string(),
  cannavigiaData: z.object({
    ignoreHashCheck: z.boolean(),
    hash: z.string(),
    idType: z.enum(['SP', 'SI', 'RP']), // SP Schweizer Pass, SI Schweizer ID, RP Ausländerausweis
    idNumber: z.string(), // nr des ausweises
    placeOfBirthOrResidence: z.string(),
    dispensaries: z.array(z.string()),
  }),
});

const pausedStatusChange = z.object({
  status: z.literal(UserStatus.PAUSED),
  pauseReason: z.string().max(2048),
});

const deactivatedStatusChange = z.object({
  status: z.literal(UserStatus.DEACTIVATED),
  contraIndication: z.string().max(2048),
});

export const userStatusChangeReqDto = z.discriminatedUnion('status', [
  activeStatusChange,
  pausedStatusChange,
  deactivatedStatusChange,
  ...simpleStatusChanges.map(simpleStatusChange),
]);

export const changeDispensaryReqDto = z.object({
  dispensaryId: z.string(),
});
export type ChangeDispensaryReqDto = z.infer<typeof changeDispensaryReqDto>;

export type UserStatusChangeReqDto = z.infer<typeof userStatusChangeReqDto>;

export const deactivateUserReqDto = z.object({
  email: z.string().max(2048),
});
export type DeactivateUserReqDto = z.infer<typeof deactivateUserReqDto>;
