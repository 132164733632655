import { action, makeObservable, observable, runInAction } from 'mobx';
import type { CohortEndingSoonResDto } from '@web/common';
import { getCohortsEndingSoon } from '../request';
import { showErrorNotification } from '../component/notification';
import { setupMobX } from '../util/setupMobX';

export class CohortEndingSoonStore {
  @observable public cohortOnboardingEndingSoon: null | CohortEndingSoonResDto =
    null;

  constructor() {
    makeObservable(this);
  }

  @action public loadCohortEndingSoon = () => {
    getCohortsEndingSoon()
      .then((cohortEndingSoon) => {
        runInAction(() => {
          this.cohortOnboardingEndingSoon = cohortEndingSoon;
        });
      })
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<CohortEndingSoonStore>();
export const useCohortEndingSoonStore = useStore;
export const CohortEndingSoonStoreProvider = provider;
