import { Flex, Grid, Text } from '@mantine/core';
import { OrderDto, PaidStatus } from '@web/common';
import { MyIcon } from '../../../../../../component';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const OrderDetailHeader = ({ order }: { order: OrderDto }) => {
  const { t } = useTranslation('shop');

  const span = { base: 12, md: 4, lg: 4 };
  const orderDate = DateTime.fromJSDate(order.orderPlacedDate);
  const formattedOrderDate = orderDate.toFormat('ccc, dd.MM.yy');
  const iconName =
    order.paidStatus === PaidStatus.Unpaid ? 'payments' : 'store';

  return (
    <Grid align="stretch">
      <Grid.Col span={span} style={{ borderBottom: '1px solid #F1F1F1' }}>
        <Flex justify="space-between" gap="20px">
          <Text fw="600" size="12px" lh="18px">
            {t('OrderDetailHeader.OrderDate')}
          </Text>
          <Text size="12px" lh="18px">
            {formattedOrderDate}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={span} style={{ borderBottom: '1px solid #F1F1F1' }}>
        <Flex justify="space-between" gap="20px">
          <Text fw="600" size="12px" lh="18px">
            {t('OrderCard.OrderNumber')}:
          </Text>
          <Text size="12px" lh="18px">
            {order.name}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={span} style={{ borderBottom: '1px solid #F1F1F1' }}>
        <Flex justify="space-between" gap="20px">
          <Text fw="600" size="12px" lh="18px">
            {t('OrderDetailHeader.State')}
          </Text>
          <Flex>
            <MyIcon name={iconName} style={{ fill: '#3D7F53', height: 18 }} />
            <Text size="12px" c="#4A4A48" lh="18px">
              {t('PaidStatus.' + order.paidStatus)}
            </Text>
          </Flex>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
