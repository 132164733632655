import { Flex, Button, Text } from '@mantine/core';
import { CartCheckoutButton } from '@shopify/hydrogen-react';
import classes from './shoppingCart.module.css';
import { useTranslation } from 'react-i18next';
import { maxWeight } from '../utils/calculateTHCLimit';

type CheckoutSectionProps = {
  orderAmount: number;
  toggle: () => void;
  totalCost: number;
  totalThc: number;
  totalWeight: number;
  availableAmount: number;
  currentThcUnit: string;
  canCheckout: boolean;
};

export const CheckoutSection = (props: CheckoutSectionProps) => {
  const { t } = useTranslation('shop');
  const {
    orderAmount,
    toggle,
    totalCost,
    totalThc,
    totalWeight,
    availableAmount,
    currentThcUnit,
    canCheckout,
  } = props;

  return (
    <div>
      <Flex className={classes.textContainer}>
        <Text size="16px" lh="24px">
          {t('ShoppingCart.Total')}
        </Text>
        <Text fw="600" size="16px" lh="24px">
          {totalCost} CHF
        </Text>
      </Flex>
      <Flex className={classes.textContainer}>
        <Text className={classes.subText}>
          {t('ShoppingCart.TotalThcAmount')}
        </Text>
        <Text className={classes.subText}>{`${totalThc.toFixed(2)}g`}</Text>
      </Flex>

      <Flex className={classes.textContainer}>
        <Text
          className={classes.subText}
          c={canCheckout || orderAmount === 0 ? '#4A4A48' : '#D12300'}
        >
          {t('ShoppingCart.AvailableThcAmount')}
        </Text>
        <Text
          className={classes.subText}
          c={canCheckout || orderAmount === 0 ? '#4A4A48' : '#D12300'}
        >{`${availableAmount}${currentThcUnit}`}</Text>
      </Flex>
      <Flex className={classes.textContainer}>
        <Text
          className={classes.subText}
          c={!canCheckout && totalWeight > maxWeight ? '#D12300' : '#4A4A48'}
        >
          {t('ShoppingCart.Weight')}
        </Text>
        <Text
          className={classes.subText}
          c={!canCheckout && totalWeight > maxWeight ? '#D12300' : '#4A4A48'}
        >{`${totalWeight}g / ${maxWeight}g`}</Text>
      </Flex>

      <Flex direction="column" p="20px">
        <CartCheckoutButton
          className={classes.checkoutBtn}
          style={{ background: canCheckout ? '#3d7f53' : '#B1B1B1' }}
          disabled={!canCheckout}
        >
          {t('ShoppingCart.Checkout')}
        </CartCheckoutButton>
        <Button variant="white" onClick={toggle}>
          {t('ShoppingCart.ContinueShopping')}
        </Button>
      </Flex>
    </div>
  );
};
