import { observer } from 'mobx-react';
import { useOnboardingChecklistStore } from '../../../../store';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import {
  CheckListId,
  CheckListItemType,
  ErrorCode,
  QuestionnaireOutput,
  questionnaireOutputSchema,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';

import { Button, Container, Flex } from '@mantine/core';
import { Questionnaire } from '../../../../component/questionnaire';
import classes from '../../../../sharedStyles/Containers.module.css';
import { DateTime } from 'luxon';
import { Header } from '../../../public/navigation';
import { getStammdatenQuestionnaireSurveyJSON } from './stammdaten-questionnaire-specification';
import { DevContent } from '../../../../util/DevContent';
import { devRandomize } from './devRandomize';
import { useState } from 'react';
import { showErrorNotification } from '../../../../component/notification';
import { useTranslation } from 'react-i18next';

export const StammdatenQuestionnaire = observer(() => {
  const onboardingChecklistStore = useOnboardingChecklistStore();
  const { t } = useTranslation('stammdaten-questionnaire');
  const { state } = useLocation();
  const [initialData, setInitialData] = useState({});
  const navigate = useNavigate();
  const checkListItemId =
    state?.id as CheckListId.StammdatenQuestionnaire | null;

  if (!checkListItemId) {
    return <Navigate to={Routes.Study} />;
  }

  const surveyComplete = (surveyResult: QuestionnaireOutput) => {
    const {
      [StammdatenQuestionnaireQuestionKey.BirthDate]: birthdate,
      [StammdatenQuestionnaireQuestionKey.Clustering]: clustering,
      ...rest
    } = surveyResult;
    const questionnaireData = {
      ...rest,
      [StammdatenQuestionnaireQuestionKey.BirthDate]: DateTime.fromFormat(
        birthdate,
        'yyyy-MM-dd',
      ).toFormat('dd-MM-yyyy'),
      [StammdatenQuestionnaireQuestionKey.Clustering]: clustering?.flatMap(
        (clusterObj) => {
          if (
            !clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringName] &&
            !clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringSurname]
          ) {
            return [];
          }
          return [
            {
              [StammdatenQuestionnaireQuestionKey.ClusteringName]:
                clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringName] ??
                '',
              [StammdatenQuestionnaireQuestionKey.ClusteringSurname]:
                clusterObj[
                  StammdatenQuestionnaireQuestionKey.ClusteringSurname
                ] ?? '',
            },
          ];
        },
      ),
    };
    console.debug(`questionnaireData`, questionnaireData);

    onboardingChecklistStore
      .storeQuestionnaireResults({
        id: checkListItemId,
        result: questionnaireData,
      })
      .then(() => {
        navigate(Routes.Study);
      });
  };

  const checkListItem = onboardingChecklistStore.checklist
    ? onboardingChecklistStore.checklist[checkListItemId]
    : null;

  const alreadyAnswered =
    checkListItem?.type === CheckListItemType.QuestionnaireStammdaten &&
    !!checkListItem?.questionnaireResult;

  if (alreadyAnswered) {
    return <Navigate to={Routes.Study} />;
  }

  return (
    <>
      <Header canLogout />
      <Container fluid className={classes.container} maw="1200px">
        <Questionnaire
          title={t('Title')}
          questionnaire={{
            json: getStammdatenQuestionnaireSurveyJSON(t),
            schema: questionnaireOutputSchema,
            initial: initialData,
          }}
          onComplete={surveyComplete}
          onError={() =>
            showErrorNotification(ErrorCode.QuestionnaireParsingError)
          }
        />
      </Container>
      <DevContent>
        <Flex>
          <Button onClick={() => setInitialData(devRandomize())}>
            Randomize
          </Button>
        </Flex>
      </DevContent>
    </>
  );
});
