import { CheckListId } from '@web/common';
import {
  useUserMeStore,
  useOnboardingChecklistStore,
} from '../../../../../store';
import { useEffect } from 'react';

export const useInitChecks = (hiddenChecklistItems: CheckListId[]) => {
  const userStore = useUserMeStore();
  const checklistStore = useOnboardingChecklistStore();

  useEffect(() => {
    // we can only check items when the checklist is loaded into the container
    if (checklistStore.loadingChecklist) return;

    if (
      userStore.myInfo?.emailVerified &&
      checklistStore.checklist?.[CheckListId.EmailVerified]?.done === false
    ) {
      checklistStore.checkItem(CheckListId.EmailVerified, true);
    }

    // we don't show certain item anymore, so we need to check it here to done

    hiddenChecklistItems.forEach((itemId) => {
      if (checklistStore.checklist?.[itemId]?.done === false) {
        checklistStore.checkItem(itemId, true);
      }
    });
  }, [userStore.myInfo?.emailVerified, checklistStore, hiddenChecklistItems]);
};
