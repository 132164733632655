import { Flex, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { TierList } from '@web/common';
import {
  getRewardsColor,
  ObtainablePointsStatus,
} from '../../ObtainablePoints';
import { MyIcon } from '../../icon';

export const NewRankNotification = observer(
  ({ rank }: { rank: keyof typeof TierList }) => {
    const { t } = useTranslation('notification');
    const color = getRewardsColor(ObtainablePointsStatus.Available, true);

    const rankTranslation = t(`homescreen:TierList.${rank}`);
    return (
      <Flex direction={'column'}>
        <Flex gap="10px" align="center">
          <MyIcon
            name="local_activity"
            style={{ fill: color, width: '24px', marginBottom: 2 }}
          />
          <Text
            c={'#fff'}
            style={{ fontWeight: 700, lineHeight: '24px' }}
            size={'16px'}
          >
            {t('Points.NewRankTitle') + ' ' + rankTranslation}
          </Text>
        </Flex>

        <Text
          c={'#fff'}
          style={{ fontWeight: 400, lineHeight: '16px' }}
          size={'14px'}
        >
          {t('Points.Reason.NewRank', { rank: rankTranslation })}
        </Text>
      </Flex>
    );
  },
);
