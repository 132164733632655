import { List } from './List';
import { BaseLayout } from '../../navigation';
import { Flex, Text } from '@mantine/core';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { Filter } from './Filter';
import { useTranslation } from 'react-i18next';

export const UserFilterList = () => {
  const { t } = useTranslation('organizer-user-search-list');
  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1500}
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('Header')}
        </Text>
        <Filter />
        <List showExtendedFields={true} allowExport={true} />
      </Flex>
    </BaseLayout>
  );
};
