import { z } from 'zod';
import {
  consumptionConcentrationZodEnum,
  entryTimeZodEnum,
  consumptionTypeZodEnum,
} from '../../enum';
import { feelingTypeZodEnum } from './diary-feeling.zod-enum';
import { loyalityPointsRewardResDto } from '../loyality-point';

export const diaryEntrySchema = z.object({
  id: z.number(),
  didConsume: z.boolean(),
  entryDate: z.coerce.date(),
  entryTime: entryTimeZodEnum(),
  consumptionType: consumptionTypeZodEnum().nullable(),
  consumptionAmount: z.number().nullable(),
  consumptionConcentration: consumptionConcentrationZodEnum().nullable(),
  feelings: z.array(feelingTypeZodEnum()),
  reasons: z.array(z.string().max(256)),
  notes: z.string().max(2048).nullable(),
  createdAt: z.coerce.date(),
});

export type DiaryEntry = z.infer<typeof diaryEntrySchema>;

export const createDiaryEntryResDto = z.object({
  diaryEntry: diaryEntrySchema,
  loyalityPoints: z.array(loyalityPointsRewardResDto),
});

export type CreateDiaryEntryResDto = z.infer<typeof createDiaryEntryResDto>;
