import { action, makeObservable, observable, runInAction } from 'mobx';
import { type DispensaryResDto } from '@web/common';
import { getUserDispensary } from '../../../../../../request/authenticated-requests/dispensary';
import { showErrorNotification } from '../../../../../../component/notification';
import { setupMobX } from '../../../../../../util/setupMobX';

export class PickUpStore {
  @observable public loading: boolean = false;
  @observable public dispensary: null | DispensaryResDto = null;

  constructor() {
    makeObservable(this);
  }

  @action public getUserDispensary(userId: string) {
    this.loading = true;

    getUserDispensary(userId)
      .then((dispensary) => runInAction(() => (this.dispensary = dispensary)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loading = false)));
  }
}

const { provider, useStore } = setupMobX<PickUpStore>();
export const usePickUpStore = useStore;
export const PickUpStoreProvider = provider;
