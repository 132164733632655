import { z } from 'zod';
import { livingPlaces } from '../../constants';

export enum EntryQuestionnaireQuestionKey {
  HOW_OLD_ARE_YOU = 'how-old-are-you',
  WHERE_DO_YOU_LIVE = 'where-do-you-live',
  HOW_OFTEN_DO_YOU_CONSUME_CANNABIS = 'how-often-do-you-consume-cannabis',
  ARE_YOU_PREGNANT = 'are-you-pregnant',
  ARE_YOU_UNDER_GUARDIANSHIP = 'are-you-under-guardianship',
  ARE_YOU_PROFESSIONAL_DRIVER = 'are-you-professional-driver',
  SUFFERING_FROM_MEDICAL_CONDITION = 'suffering-from-medical-condition',
  ARE_YOU_PLANNING_RELOCATION = 'are-you-planning-relocation',
  ARE_YOU_READY_TO_FILL_OUT_QUESTIONNAIRES = 'are-you-ready-to-fill-out-questionnaires',
  ARE_YOU_MEMBER_OF_ZURICAN = 'are-you-member-of-zurican',
}

export enum EntryQuestionnaireHowOldAreYouAnswer {
  UNDER_18 = 0,
  BETWEEN_18_AND_65 = 18,
  OVER_65 = 65,
}

export const whereDoYouLiveChoices = [
  ...livingPlaces.sort((a, b) => a.localeCompare(b)),
];

export enum EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer {
  NEVER = 0,
  EVERY_FEW_MONTHS = 6,
  MONTHLY = 12,
  WEEKLY = 12 * 4,
  DAILY = 365,
}

export const entryQuestionnaireReqDto = z.object({
  [EntryQuestionnaireQuestionKey.HOW_OLD_ARE_YOU]: z.nativeEnum(
    EntryQuestionnaireHowOldAreYouAnswer,
  ),
  [EntryQuestionnaireQuestionKey.WHERE_DO_YOU_LIVE]: z.string(),
  [EntryQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS]:
    z.nativeEnum(EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer),
  [EntryQuestionnaireQuestionKey.ARE_YOU_PREGNANT]: z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.ARE_YOU_UNDER_GUARDIANSHIP]:
    z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.ARE_YOU_PROFESSIONAL_DRIVER]:
    z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.SUFFERING_FROM_MEDICAL_CONDITION]:
    z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.ARE_YOU_PLANNING_RELOCATION]:
    z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.ARE_YOU_READY_TO_FILL_OUT_QUESTIONNAIRES]:
    z.coerce.boolean(),
  [EntryQuestionnaireQuestionKey.ARE_YOU_MEMBER_OF_ZURICAN]: z.coerce
    .boolean()
    .optional(),
});

export type EntryQuestionnaireReqDtoType = z.infer<
  typeof entryQuestionnaireReqDto
>;
