/* eslint-disable @typescript-eslint/no-explicit-any */
export function DebugPrettyJSON({
  header,
  data,
}: {
  header: string;
  data: any;
}) {
  return (
    <>
      <i>Debug: {header}</i>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
}
