import { useEffect } from 'react';
import { useParticipantDataStore } from '../store';
import { useConsumptionStore } from '../view/authenticated/participant/hrplus';

export const useAvailableThcAmount = () => {
  const participantDataStore = useParticipantDataStore();
  const consumptionStore = useConsumptionStore();

  const currentLimit = consumptionStore.currentLimit;
  const currentThcAmount = consumptionStore.currentThcAmount;
  const currentThcUnit = consumptionStore.currentThcUnit;
  const availableAmount = currentLimit - currentThcAmount;

  useEffect(() => {
    consumptionStore.getCurrentThcAmount();
    consumptionStore.getCurrentLimit();
  }, [consumptionStore, participantDataStore]);

  return {
    availableAmount,
    currentThcAmount,
    currentThcUnit,
    thcLimit: currentLimit,
  };
};
