import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ChecklistItemView from '../onboarding-checklist/ChecklistItemView';
import { Accordion, Center, Container, Flex, Text } from '@mantine/core';
import classes from './BookedAppointment.module.css';
import { Title } from '../../../../component';
import { AppointmentDetails } from './AppointmentDetails';
import { StudyCode } from '../study-code/StudyCode';
import { useOnboardingChecklistStore } from '../../../../store';
import { CheckListId } from '@web/common';
import { Header } from '../../../public/navigation';
import { useTranslation } from 'react-i18next';

export const BookedChecklist = observer(
  ({ isSmallScreen }: { isSmallScreen: boolean }) => {
    const store = useOnboardingChecklistStore();
    const { t } = useTranslation('appointment-booking');

    useEffect(() => {
      store.loadOnboardingChecklist();
    }, [store]);

    return (
      <>
        <Header canLogout />
        <Flex p="40px 20px 0px 20px" gap="30px" justify="center" align="center">
          <Flex w="850px" justify="space-between" gap="20px" wrap="wrap">
            <Title
              text={t('AppointmentDetails.Title')}
              boxPadding="0px"
              textPadding="0px"
            />
            <StudyCode />
          </Flex>
        </Flex>

        <Container fluid className={classes.container} p={0}>
          <Center p={isSmallScreen ? '20px 0px' : '20px'}>
            <Flex
              direction="column"
              bg="#fff"
              className={isSmallScreen ? undefined : classes.contentWrapper}
            >
              <AppointmentDetails />
              <Flex m={15} direction="column" gap={20} p="10px">
                <Text fw="700" size="21px">
                  {t('AppointmentDetails.SubTitle')}
                </Text>
                <Text>{t('AppointmentDetails.Description')}</Text>
              </Flex>

              <Accordion
                radius="xs"
                multiple
                p={isSmallScreen ? '0px' : '0px 20px '}
                classNames={{ item: classes.accordionItem }}
              >
                {store.checklist &&
                  Object.entries(store.checklist).map(([id, item], idx) => (
                    <ChecklistItemView
                      key={id}
                      id={id as CheckListId}
                      item={item}
                      handleTick={() =>
                        store.checkItem(id as CheckListId, !item.done)
                      }
                      index={idx}
                      hideFormular
                    />
                  ))}
              </Accordion>
            </Flex>
          </Center>
        </Container>
      </>
    );
  },
);
