import { observer } from 'mobx-react';
import { Text } from '@mantine/core';
import { useResponsibleUserStore } from './ResponsibleUserStore';
import { useEffect } from 'react';
import { useParticipantDataStore } from '../../../../store';
import { useTranslation } from 'react-i18next';

export const ResponsibleUserUI = observer(() => {
  const participantDataStore = useParticipantDataStore();
  const store = useResponsibleUserStore();
  const { t } = useTranslation('common');

  const participantId = participantDataStore.data?.Participant?.participantId;

  useEffect(() => {
    if (!participantId) return;
    store.getResponsibleUser(participantId);
  }, [participantId, store]);

  if (!participantId) {
    return <Text>Component needs participant ID</Text>;
  }

  if (store.isLoading) {
    return <Text>Loading...</Text>;
  }

  if (store.noResponsibleUserFound) {
    return <Text c="#4A4A48">{t('NoResponsibleUserFound')}</Text>;
  }

  const email = store.responsibleUser?.email ?? '-';
  const fullName = `${store.responsibleUser?.firstName ?? '-'} ${store.responsibleUser?.lastName ?? '-'}`;

  return (
    <Text c="#4A4A48">
      geonboarded von Mitarbeiter {fullName} <br /> mit E-Mail: {email}
    </Text>
  );
});
