import { Flex, Space, Text } from '@mantine/core';
import { StepDataType } from './stepsData';
import { useTranslation } from 'react-i18next';

export const StepItem = (step: StepDataType) => (
  <Flex key={step.id} direction="column" w="23%" gap="16px">
    <Text size="21px" fw="500" lh="31px">
      {step.title}
    </Text>
    <Text size="16px" lh="24px">
      {step.description}
    </Text>
  </Flex>
);

export const StepItemDetail = (
  title_long: string,
  description: JSX.Element | string,
  stepNumber: number,
  description_long?: JSX.Element | string,
  bottomAction?: JSX.Element,
) => {
  const { t } = useTranslation('waiting-screen');
  return (
    <Flex direction="column" my="10px" miw="300px" style={{ flex: 1 }}>
      <Text>
        {t('Step')} {stepNumber + 1}{' '}
      </Text>
      <Text size="21px" fw="700" lh="31px">
        {title_long}
      </Text>
      <Space h="sm" />

      {typeof description === 'string' ? (
        <Text size="16px" lh="24px">
          {description_long ?? description}
        </Text>
      ) : (
        (description_long ?? description)
      )}
      {bottomAction}
    </Flex>
  );
};
