import { observer } from 'mobx-react';
import {
  PausedUsersStore,
  PausedUsersStoreProvider,
  usePausedUsersStore,
} from '../../../store';
import { ActionIcon, Flex, Table, Text } from '@mantine/core';
import {
  CheckListId,
  CheckListItemType,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../route/Routes';
import { BaseLayout } from '../navigation';
import classes from '../../../sharedStyles/InfoCard.module.css';
import { DateTime } from 'luxon';
import { MyIcon } from '../../../component';
import { useTranslation } from 'react-i18next';

const PausedUsers = observer(() => {
  const pausedUsersStore = usePausedUsersStore();
  const navigate = useNavigate();
  const { t } = useTranslation('organizer-dashboard');
  const foundUsers = pausedUsersStore.pausedUsers.length;

  const route = (userId: string) => {
    navigate(`${Routes.Study}/${Routes.User}/${userId}`, {
      state: { userId },
    });
  };

  useEffect(() => {
    pausedUsersStore.loadPausedUsers();
  }, [pausedUsersStore]);

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('PausedParticipants.Title')}
        </Text>

        <Text fw="600" lh="24px" size="16px">
          {foundUsers}{' '}
          {foundUsers > 1 || foundUsers === 0 ? t('Results') : t('Result')}
        </Text>
        <Table>
          <Table.Thead bg="#F1F0E89999" fw="500" lh="24px">
            <Table.Tr>
              <Table.Td>{t('Table.Columns.Name')}</Table.Td>
              <Table.Td>{t('Table.Columns.Code')}</Table.Td>
              <Table.Td>{t('Table.Columns.PLZ')}</Table.Td>
              <Table.Td>{t('Table.Columns.Birthday')}</Table.Td>
              <Table.Td>{t('Table.Columns.Reason')}</Table.Td>
              <Table.Td>{t('Table.Columns.PauseDate')}</Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {pausedUsersStore.pausedUsers.map((user) => {
              const checkList = user?.CheckList?.data;
              const stammdaten =
                checkList?.[CheckListId.StammdatenQuestionnaire];
              if (
                stammdaten &&
                stammdaten.type === CheckListItemType.QuestionnaireStammdaten &&
                !!stammdaten.questionnaireResult
              ) {
                return (
                  <Table.Tr key={user.id} onClick={() => route(user.id)}>
                    <Table.Td>
                      {stammdaten.questionnaireResult[
                        StammdatenQuestionnaireQuestionKey.Name
                      ] +
                        ' ' +
                        stammdaten.questionnaireResult[
                          StammdatenQuestionnaireQuestionKey.Surname
                        ]}
                    </Table.Td>
                    <Table.Td>{user.userSearchID}</Table.Td>
                    <Table.Td>
                      {
                        stammdaten.questionnaireResult[
                          StammdatenQuestionnaireQuestionKey.PLZ
                        ]
                      }
                    </Table.Td>
                    <Table.Td>
                      {
                        stammdaten.questionnaireResult[
                          StammdatenQuestionnaireQuestionKey.BirthDate
                        ]
                      }
                    </Table.Td>
                    <Table.Td>{user.pauseReason}</Table.Td>
                    <Table.Td>
                      {user.pauseDate
                        ? DateTime.fromJSDate(user.pauseDate).toFormat(
                            'dd.MM.yyyy HH:mm',
                          ) + ' Uhr'
                        : '-'}
                    </Table.Td>
                    <Table.Td>
                      <Flex w="100%" justify="flex-end">
                        <ActionIcon variant="white">
                          <MyIcon name="chevron_right" />
                        </ActionIcon>
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                );
              }

              const noInformation = 'Keine Informationen';
              return (
                <Table.Tr key={user.id} onClick={() => route(user.id)}>
                  <Table.Td>{user.userSearchID}</Table.Td>
                  <Table.Td>{noInformation}</Table.Td>
                  <Table.Td>{noInformation}</Table.Td>
                  <Table.Td>{noInformation}</Table.Td>
                  <Table.Td>{user.pauseReason}</Table.Td>
                  <Table.Td>
                    {user.pauseDate
                      ? DateTime.fromJSDate(user.pauseDate).toFormat(
                          'dd.MM.yyyy HH:mm',
                        ) + ' Uhr'
                      : '-'}
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Flex>
    </BaseLayout>
  );
});

export const PausedUsersView = () => {
  return (
    <PausedUsersStoreProvider store={new PausedUsersStore()}>
      <PausedUsers />
    </PausedUsersStoreProvider>
  );
};
