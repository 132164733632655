import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { AnonymousQuestionnaireEvaluationRecordType } from '@prisma/client';

export const anonymousQuestionnaireEvaluationRecordTypeZodEnum = () =>
  z
    .enum(
      arrayToNonEmptyArray(
        Object.values(AnonymousQuestionnaireEvaluationRecordType),
      ),
    )
    .transform(
      (value) =>
        AnonymousQuestionnaireEvaluationRecordType[
          value as keyof typeof AnonymousQuestionnaireEvaluationRecordType
        ] as AnonymousQuestionnaireEvaluationRecordType,
    );
