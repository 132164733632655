import { observer } from 'mobx-react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import {
  useOnboardingChecklistStore,
  useQuestionnaireEvaluationStore,
} from '../../../../store';
// https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.i18n';
import {
  CannabisConsumptionQuestionnaireResult,
  cannabisConsumptionQuestionnaireReqDto,
  CheckListId,
  CheckListItemType,
  ErrorCode,
} from '@web/common';
import classes from '../../../../sharedStyles/Containers.module.css';
import { Button, Container, Flex } from '@mantine/core';
import { Questionnaire } from '../../../../component/questionnaire';
import { Header } from '../../../public/navigation';
import { DevContent } from '../../../../util/DevContent';
import { useState } from 'react';
import { devRandomize } from './dev-randomize';
import { getCannabisConsumptionQuestionnaireSurveyJSON } from './cannabis-consumption-survey-specification';
import { showErrorNotification } from '../../../../component/notification';
import { useTranslation } from 'react-i18next';

export const CannabisConsumptionQuestionnaire = observer(() => {
  const onboardingChecklistStore = useOnboardingChecklistStore();
  const questionnaireEvaluationStore = useQuestionnaireEvaluationStore();
  const { t } = useTranslation('consumption-questionnaire');
  const [initialData, setInitialData] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();
  const checkListItemId =
    state?.id as CheckListId.CannabisConsumptionQuestionnaire | null;

  if (
    !checkListItemId ||
    checkListItemId !== CheckListId.CannabisConsumptionQuestionnaire
  ) {
    return <Navigate to={Routes.Study} />;
  }

  const surveyComplete = (
    surveyResult: CannabisConsumptionQuestionnaireResult,
  ) => {
    Promise.all([
      onboardingChecklistStore.storeQuestionnaireResults({
        id: checkListItemId,
        result: surveyResult,
      }),
      questionnaireEvaluationStore.evaluateCuditQuestionnaire(surveyResult),
    ])
      .then(() => {
        navigate(Routes.Study, {
          state: {
            id: checkListItemId,
            result: surveyResult,
            reoccurrence: 0,
          },
          replace: true,
        });
      })
      .catch(() => showErrorNotification());
  };

  const checkListItem = onboardingChecklistStore.checklist
    ? onboardingChecklistStore.checklist[checkListItemId]
    : null;
  const alreadyAnswered =
    checkListItem?.type ===
      CheckListItemType.QuestionnaireCannabisConsumption &&
    !!checkListItem?.questionnaireResult;

  if (alreadyAnswered) {
    return <Navigate to={Routes.Study} />;
  }

  return (
    <div>
      <Header canLogout />
      <Container fluid className={classes.container} maw="1200px">
        <Questionnaire
          title={t('Title')}
          questionnaire={{
            json: getCannabisConsumptionQuestionnaireSurveyJSON(t),
            schema: cannabisConsumptionQuestionnaireReqDto,
            initial: initialData,
          }}
          onComplete={surveyComplete}
          onError={() =>
            showErrorNotification(ErrorCode.QuestionnaireParsingError)
          }
        />
      </Container>
      <DevContent>
        <Flex direction="row" gap="10px">
          <Button onClick={() => setInitialData(devRandomize(t))}>
            Randomize
          </Button>
        </Flex>
      </DevContent>
    </div>
  );
});
