import { ThcConsumptionPerMonth } from '@web/common';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  CartesianGrid,
  YAxis,
  Bar,
  Rectangle,
  ReferenceLine,
  LabelProps,
  ResponsiveContainer,
} from 'recharts';
import { CartesianViewBox } from 'recharts/types/util/types';

const BAG_LIMIT = 10;

export const ConsumptionBarChart = ({
  data,
  yourLimit,
}: {
  data: ThcConsumptionPerMonth;
  yourLimit: number;
}) => {
  const dataAverage =
    data.reduce((acc, val) => {
      return acc + val.thcConsumptionInGrams;
    }, 0) / data.length;

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        margin={{ top: 30, right: 10, left: -20, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <YAxis unit="g" domain={[0, 10]} />

        <Bar
          dataKey="thcConsumptionInGrams"
          fill="#3D7F5360"
          activeBar={<Rectangle fill="#3D7F53" />}
          radius={[5, 5, 0, 0]}
        />
        <ReferenceLine
          y={BAG_LIMIT}
          label={
            <CustomLabel
              color="#EB0037"
              translationKey="ChangeTHCLimit.BAGLimit"
            />
          }
          stroke="#EB0037"
          strokeDasharray="5 5"
        />
        <ReferenceLine
          y={yourLimit}
          label={
            <CustomLabel
              color="#0048D2"
              translationKey="ChangeTHCLimit.YourLimit"
            />
          }
          stroke="#0048D2"
          strokeDasharray="5 5"
        />
        <ReferenceLine
          y={dataAverage}
          label={
            <CustomLabel color="#3D7F53" text="ø" offset={15} bold={true} />
          }
          stroke="#3D7F53"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomLabel = ({
  viewBox,
  color,
  translationKey,
  text,
  bold,
}: LabelProps & {
  color: string;
  translationKey?: string;
  text?: string;
  bold?: boolean;
}) => {
  const { t } = useTranslation('consumption-dialog');
  const { x, y, width } = viewBox as CartesianViewBox;

  return (
    <text
      x={x}
      y={y}
      fill={color}
      dy={-5}
      dx={(width ?? 0) - (translationKey ? 80 : 15)}
      fontWeight={bold ? 500 : 0}
    >
      {translationKey ? t(translationKey) : text}
    </text>
  );
};
