import * as Scrypt from 'scrypt-js';
import { Buffer } from 'buffer';

function toHexString(byteArray: Uint8Array) {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('');
}

export class CdsScrypt {
  /**
   * The salt
   *
   * @var string
   * Must always be: bded01e8ad47d523aa2d508d3bd4ccc5c0c98c588a513e5c35ed19dc42032dc7
   */
  private static readonly SALT: string =
    'bded01e8ad47d523aa2d508d3bd4ccc5c0c98c588a513e5c35ed19dc42032dc7';

  /**
   * The cost
   *
   * @var number
   * Must always be: 16384
   */
  private static readonly COST: number = 16384;

  /**
   * The block size
   *
   * @var number
   * Must always be: 8
   */
  private static readonly BLOCK_SIZE: number = 8;

  /**
   * The parallelization
   *
   * @var number
   * Must always be: 1
   */
  private static readonly PARALLELIZATION: number = 1;

  /**
   * The desired key length
   *
   * @var number
   * Must always be: 32
   */
  private static readonly DESIRED_KEY_LENGTH: number = 32;

  /**
   * Calculates the hash and returns it in hexadecimal representation.
   *
   * @param password string
   * @returns string
   */
  public static async calculateHash(password: string): Promise<string> {
    const key = await Scrypt.scrypt(
      Buffer.from(password),
      Buffer.from(this.SALT),
      this.COST,
      this.BLOCK_SIZE,
      this.PARALLELIZATION,
      this.DESIRED_KEY_LENGTH,
    );

    return toHexString(key);
  }

  /**
   * Normalizes input data and generates the password.
   *
   * @param firstName string
   * @param lastName string
   * @param sex string (D, F, M)
   * @param dateOfBirth string (dd.mm.yyyy)
   * @returns string
   * @throws Error
   */
  public static generatePassword(
    firstName: string,
    lastName: string,
    sex: string,
    dateOfBirth: string,
  ): string {
    // check for valid sex
    if (!['D', 'F', 'M'].includes(sex)) {
      throw new Error('INVALID SEX: ' + sex);
    }

    // check for valid date of birth
    if (!/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/.test(dateOfBirth)) {
      throw new Error('INVALID DATE OF BIRTH: ' + dateOfBirth);
    }

    // normalize first name and add to password
    let password: string = firstName.replace(/\s/g, '').toUpperCase();

    // normalize last name and add to password
    password += lastName.replace(/\s/g, '').toUpperCase();

    // add sex to password
    password += sex.toUpperCase();

    // normalize date of birth and add to password
    const dateOfBirthArray: string[] = dateOfBirth.split('.');
    password += dateOfBirthArray[2] + dateOfBirthArray[1] + dateOfBirthArray[0];

    return password;
  }
}
