import { z } from 'zod';
import { priceSchemaDto } from './price-schema.dto';
import { createContestResDto } from './create-contest.res.dto';

const myWinSchemaDto = priceSchemaDto.extend({
  ContestPriceWinner: z
    .object({
      contestPriceId: z.number(),
      userId: z.string(),
      createdAt: z.coerce.date(),
    })
    .nullable(),
  Contest: createContestResDto,
});
export const myWinsResDto = z.array(myWinSchemaDto);
export type MyWinsResDto = z.infer<typeof myWinsResDto>;
