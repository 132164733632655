import { z } from 'zod';

export const eventTypesSchema = z.object({
  id: z.number(),
  title: z.string(),
  slug: z.string(),
  description: z.string(),
  locations: z.array(
    z.object({
      address: z.string(),
      type: z.string(),
    }),
  ),
});
export const getEventTypesReqDto = z.object({
  event_types: z.array(eventTypesSchema),
});
export type GetEventTypesReqDto = z.infer<typeof getEventTypesReqDto>;

/* keeping this for easy reference
example

{
  "event_types": [
    {
      "id": 469101,
      "title": "Onboarding",
      "slug": "onboarding",
      "length": 20,
      "hidden": false,
      "position": 0,
      "userId": null,
      "teamId": 12953,
      "scheduleId": 140367,
      "eventName": "",
      "timeZone": null,
      "periodType": "UNLIMITED",
      "periodStartDate": "2023-10-25T09:13:06.000Z",
      "periodEndDate": "2023-10-25T09:13:06.000Z",
      "periodDays": null,
      "periodCountCalendarDays": false,
      "requiresConfirmation": false,
      "recurringEvent": null,
      "disableGuests": false,
      "hideCalendarNotes": false,
      "minimumBookingNotice": 120,
      "beforeEventBuffer": 0,
      "afterEventBuffer": 0,
      "schedulingType": "ROUND_ROBIN",
      "price": 0,
      "currency": "usd",
      "slotInterval": null,
      "parentId": null,
      "successRedirectUrl": "https://swiss-cannabis-center.ch/study",
      "description": "General onboarding meeting",
      "locations": [
        {
          "address": "Here is the location",
          "type": "inPerson"
        }
      ],
      "metadata": {
        "apps": {
          "giphy": {
            "enabled": false,
            "thankYouPage": ""
          }
        },
        "config": {
          "useHostSchedulesForTeamEvent": false
        }
      },
      "seatsPerTimeSlot": null,
      "seatsShowAttendees": false,
      "seatsShowAvailabilityCount": true,
      "bookingFields": [
        {
          "name": "name",
          "type": "name",
          "label": "",
          "defaultLabel": "your_name",
          "placeholder": "",
          "required": true,
          "variantsConfig": {
            "variants": {
              "fullName": {
                "fields": [
                  {
                    "name": "fullName",
                    "type": "text",
                    "label": "Deine Identifikation",
                    "placeholder": "",
                    "required": true
                  }
                ]
              },
              "firstAndLastName": {
                "fields": [
                  {
                    "name": "firstName",
                    "type": "text",
                    "required": true
                  },
                  {
                    "name": "lastName",
                    "type": "text",
                    "required": false
                  }
                ]
              }
            }
          },
          "editable": "system",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "email",
          "type": "email",
          "label": "Deine Email Adresse",
          "defaultLabel": "email_address",
          "placeholder": "",
          "required": true,
          "editable": "system",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "location",
          "type": "radioInput",
          "defaultLabel": "location",
          "required": false,
          "getOptionsAt": "locations",
          "optionsInputs": {
            "phone": {
              "type": "phone",
              "required": true,
              "placeholder": ""
            },
            "attendeeInPerson": {
              "type": "address",
              "required": true,
              "placeholder": ""
            }
          },
          "hideWhenJustOneOption": true,
          "editable": "system",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "title",
          "type": "text",
          "defaultLabel": "what_is_this_meeting_about",
          "defaultPlaceholder": "",
          "required": true,
          "hidden": true,
          "editable": "system-but-optional",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "notes",
          "type": "textarea",
          "defaultLabel": "additional_notes",
          "defaultPlaceholder": "share_additional_notes",
          "required": false,
          "hidden": true,
          "editable": "system-but-optional",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "userid",
          "type": "text",
          "label": "userid",
          "placeholder": "",
          "required": true,
          "hidden": true,
          "editable": "user",
          "sources": [
            {
              "id": "user",
              "type": "user",
              "label": "User",
              "fieldRequired": true
            }
          ]
        },
        {
          "name": "eventtype",
          "type": "text",
          "label": "eventtype",
          "placeholder": "",
          "required": true,
          "hidden": true,
          "editable": "user",
          "sources": [
            {
              "id": "user",
              "type": "user",
              "label": "User",
              "fieldRequired": true
            }
          ]
        },
        {
          "name": "teamId",
          "type": "text",
          "label": "TeamId",
          "placeholder": "",
          "required": true,
          "hidden": true,
          "editable": "user",
          "sources": [
            {
              "id": "user",
              "type": "user",
              "label": "User",
              "fieldRequired": true
            }
          ]
        },
        {
          "name": "guests",
          "type": "multiemail",
          "defaultLabel": "additional_guests",
          "defaultPlaceholder": "email",
          "required": false,
          "hidden": true,
          "editable": "system-but-optional",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        },
        {
          "name": "rescheduleReason",
          "type": "textarea",
          "defaultLabel": "reason_for_reschedule",
          "defaultPlaceholder": "reschedule_placeholder",
          "required": false,
          "views": [
            {
              "label": "Reschedule View",
              "id": "reschedule"
            }
          ],
          "hidden": true,
          "editable": "system-but-optional",
          "sources": [
            {
              "id": "default",
              "type": "default",
              "label": "Default"
            }
          ]
        }
      ],
      "bookingLimits": null,
      "onlyShowFirstAvailableSlot": false,
      "durationLimits": null,
      "children": [],
      "hosts": [],
      "customInputs": []
    }
  ]
}

 */
