import { Flex, Text, Image } from '@mantine/core';
import team from '../../../../assets/team.webp';
import classes from '../LandingPage.module.css';
import { useTranslation } from 'react-i18next';

export const AboutUs = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');

  return (
    <Flex
      id={id}
      className={classes.baseSection}
      style={{ background: '#F1F0E899' }}
      gap="40px"
      wrap="wrap"
      direction="row"
      justify="space-between"
      align="flex-start"
      ta="left"
      h="100%"
    >
      <Flex direction="column" gap="20px" style={{ flex: '1 0 44%' }}>
        <Text className={classes.howItWorksTitle} c="#202020">
          {t('AboutUs.Title')}
        </Text>
        <Text>
          {t('AboutUs.Description1')}
          <br /> {t('AboutUs.Description2')}
        </Text>
      </Flex>
      <Image
        src={team}
        alt="Cannabis Research Logo"
        style={{ objectFit: 'contain', flex: '1 0 20%', borderRadius: 8 }}
        w="50%"
      />
    </Flex>
  );
};
