import { z } from 'zod';
import { MissionType } from '@prisma/client';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';

export const missionTypeZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(MissionType)))
    .transform(
      (value) => MissionType[value as keyof typeof MissionType] as MissionType,
    );
