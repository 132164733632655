import { BaseLayout } from '../../view/authenticated/navigation';
import { Divider, Flex } from '@mantine/core';
import classes from '../../sharedStyles/InfoCard.module.css';
import { Breadcrumbs } from '../../view/authenticated/navigation/Breadcrumbs';
import { observer } from 'mobx-react';
import { useWindowSize } from '../../hooks';

export const StudyDashboardDetailPage = observer(
  ({
    breadcrumbText,
    children,
  }: {
    breadcrumbText: string;
    children: JSX.Element | JSX.Element[];
  }) => {
    const isSmallScreen = useWindowSize(500);

    return (
      <BaseLayout>
        <Flex
          direction="column"
          maw={1100}
          bg="#fff"
          className={classes.borderRadius}
        >
          <Breadcrumbs
            style={{ borderColor: 'transparent', borderWidth: 0 }}
            path={breadcrumbText}
          />

          <Divider size="xs" />
          <Flex
            direction="column"
            gap={20}
            align="flex-start"
            p={isSmallScreen ? 20 : 40}
          >
            {children}
          </Flex>
        </Flex>
      </BaseLayout>
    );
  },
);
