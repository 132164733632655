import { action, makeObservable, observable, runInAction } from 'mobx';
import type { EventsSchemaDto } from '@web/common';
import { getEvents } from '../request/authenticated-requests/events';
import { setupMobX } from '../util/setupMobX';
import { showErrorNotification } from '../component/notification';

export class EventStore {
  @observable public events: EventsSchemaDto = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadEvents = () => {
    getEvents()
      .then((events) => {
        runInAction(() => {
          this.events = events.sort((a, b) => a.title.localeCompare(b.title));
        });
      })
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<EventStore>();
export const useEventStore = useStore;
export const EventStoreProvider = provider;
