import { z } from 'zod';

// for simplicity, we don't go deeper than 1 level
// in theory, there is no limit for nesting here
const subGroupRep = z.object({
  id: z.string().uuid(),
  name: z.string(),
});
export const groupRepResDto = z.object({
  id: z.string().uuid(),
  name: z.string(),
  subGroups: z.array(subGroupRep),
});
