import { observer } from 'mobx-react';
import { Flex, Text } from '@mantine/core';
import classes from '../../../sharedStyles/InfoCard.module.css';
import { UserSearchList } from './user-search-list/UserSearchList';
import { BaseLayout } from '../navigation/BaseLayout';
import useRedirect from '../../../hooks/useRedirect';
import { useTranslation } from 'react-i18next';

export const OrganizerDashboard = observer(() => {
  useRedirect();
  const { t } = useTranslation('organizer-dashboard');

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1500}
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('CheckParticipants.Title')}
        </Text>
        <Text>{t('CheckParticipants.Description')}</Text>
        <UserSearchList />
      </Flex>
    </BaseLayout>
  );
});
