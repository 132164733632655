import { CheckListId, CheckListItemType } from '@web/common';
import { List, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

export const checklistStaticData = (t: TranslationFunction) => {
  return {
    [CheckListId.EmailVerified]: {
      type: CheckListItemType.SimpleCheck,
      title: t('MailVerification.Title'),
      description: null,
    },
    [CheckListId.Einwilligungserklaerung]: {
      type: CheckListItemType.DocumentDownloaded,
      title: t('Einwilligungserklaerung.Title'),
      description: t('Einwilligungserklaerung.Description'),
    },
    [CheckListId.StammdatenQuestionnaire]: {
      type: CheckListItemType.QuestionnaireStammdaten,
      title: t('Stammdaten.Title'),
      description: (
        <>
          <Text>{t('Stammdaten.Description1')}</Text>
          <br />
          <Text>{t('Stammdaten.Description2')}</Text>
        </>
      ),
    },
    [CheckListId.CannabisConsumptionQuestionnaire]: {
      type: CheckListItemType.QuestionnaireCannabisConsumption,
      title: t('CannabisConsumptionQuestionnaire.Title'),
      description: (
        <>
          <Text>{t('CannabisConsumptionQuestionnaire.Description1')}</Text>
          <br />
          <Text>{t('CannabisConsumptionQuestionnaire.Description2')}</Text>
          <br />
          <Text>{t('CannabisConsumptionQuestionnaire.Description3')}</Text>
        </>
      ),
    },
    [CheckListId.SF12Questionnaire]: {
      type: CheckListItemType.Questionnaire12SF,
      title: t('SF12Questionnaire.Title'),
      description: (
        <>
          <Text>
            {t('SF12Questionnaire.Description1')}{' '}
            <strong> {t('SF12Questionnaire.BoldText1')}</strong>{' '}
            {t('SF12Questionnaire.Description2')}
          </Text>
          <br />
          <Text>{t('SF12Questionnaire.Description3')}</Text>
        </>
      ),
    },
    [CheckListId.IdentitaetsNachweis]: {
      type: CheckListItemType.SimpleCheck,
      title: t('IdentitaetsNachweis.Title'),
      description: (
        <List>
          <List.Item p="10px">
            <Text fw="500">{t('IdentitaetsNachweis.Point1.Title')}</Text>
            <Text>
              {t('IdentitaetsNachweis.Point1.Description1')}{' '}
              <Link
                to="/faq"
                target="_blank"
                onClick={() =>
                  localStorage.setItem('navigateId', 'Teilnehmende Gemeinden')
                }
              >
                {t('IdentitaetsNachweis.Point1.Description2')}
              </Link>
              .<br />
              <i>{t('IdentitaetsNachweis.Point1.Description3')}</i>
            </Text>
          </List.Item>

          <List.Item p="10px">
            <Text fw="500">{t('IdentitaetsNachweis.Point2.Title')}</Text>
            <Text>{t('IdentitaetsNachweis.Point2.Description1')}</Text>
          </List.Item>
          <List.Item p="10px">
            <Text fw="500">{t('IdentitaetsNachweis.Point3.Title')}</Text>
            <Text>
              {t('IdentitaetsNachweis.Point3.Description1')}
              <br /> {t('IdentitaetsNachweis.Point3.Description2')}
              <br />
              <i>{t('IdentitaetsNachweis.Point3.Description3')}</i>
            </Text>
          </List.Item>
        </List>
      ),
      checkboxLabel: t('CheckboxLabel'),
    },
  };
};

export const simpleChecklistStaticData = (t: TranslationFunction) => {
  return {
    [CheckListId.Wohnsitznachweis]: {
      type: CheckListItemType.SimpleCheck,
      title: t('SimpleChecklist.Wohnsitznachweis.Title'),
      description: (
        <>
          <Text>
            {t('IdentitaetsNachweis.Point1.Description1')}{' '}
            <Link
              to="/faq"
              target="_blank"
              onClick={() =>
                localStorage.setItem('navigateId', 'Teilnehmende Gemeinden')
              }
            >
              {t('IdentitaetsNachweis.Point1.Description2')}
            </Link>
          </Text>
          <br />
          <Text>{t('IdentitaetsNachweis.Point1.Description3')}</Text>
        </>
      ),
      checkboxLabel: t('SimpleChecklist.Wohnsitznachweis.CheckboxLabel'),
    },
    [CheckListId.AHVNummerNachweis]: {
      type: CheckListItemType.SimpleCheck,
      title: t('SimpleChecklist.AHVNummerNavhweis.Title'),
      description: t('IdentitaetsNachweis.Point2.Description1'),
      checkboxLabel: t('SimpleChecklist.AHVNummerNavhweis.CheckboxLabel'),
    },
    [CheckListId.IdentitaetsNachweis]: {
      type: CheckListItemType.SimpleCheck,
      title: t('SimpleChecklist.IdentitaetsNachweis.Title'),
      description: (
        <Text>
          {t('IdentitaetsNachweis.Point3.Description1')}
          <br /> {t('IdentitaetsNachweis.Point3.Description2')}
          <br />
          <i>{t('IdentitaetsNachweis.Point3.Description3')}</i>
        </Text>
      ),
      checkboxLabel: t('SimpleChecklist.AHVNummerNavhweis.CheckboxLabel'),
    },
  };
};
