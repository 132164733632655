import { dispensariesWithCountResDto, dispensaryResDto } from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';

export const getDispensaries = async () => {
  return authenticatedAPI.get(
    `/api/cannavigia-api/dispensaries`,
    dispensariesWithCountResDto,
  );
};

export const getUserDispensary = async (userId: string) => {
  return authenticatedAPI.get(
    `/api/users/${userId}/dispensary`,
    dispensaryResDto,
  );
};

export const updateUserDispensary = async (
  userId: string,
  dispensaryId: string,
) => {
  return authenticatedAPI.put(
    `/api/users/${userId}/dispensary`,
    { dispensaryId: dispensaryId },
    dispensaryResDto,
  );
};
