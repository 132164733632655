import classes from '../Deactivation.module.css';
import { Button, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const Success = ({ close }: { close: () => void }) => {
  const { t } = useTranslation('deactivation');

  return (
    <div className={classes.stepContainer}>
      <Text size="21px" lh="24px" fw="600">
        {t('Success.Title')}
      </Text>
      <Text size="16px" lh="24px" c="#4A4A48">
        {t('Success.Description')}
      </Text>
      <Space h="30px" />
      <Button w="100%" onClick={close}>
        {t('Close')}
      </Button>
    </div>
  );
};
