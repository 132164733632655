import { z } from 'zod';
import { userIdDto } from './user-id.dto';
import {
  cohortTypeZodEnum,
  languageZodEnum,
  userStatusZodEnum,
} from '../../enum';

export const userDataResDto = z.object({
  id: userIdDto,
  email: z.string().email().nullable(),
  status: userStatusZodEnum(),
  userSearchID: z.string(),
  Participant: z
    .object({
      userId: z.string(),
      participantId: z.string(),
      cohortId: z.string(),
      cohortType: cohortTypeZodEnum(),
      createdAt: z.coerce.date(),
      cannavigiaCode: z.string(),
      dispensaries: z.array(z.string()),
    })
    .nullable(),
  emailVerified: z.boolean(),
  language: languageZodEnum(),
});
export type UserDataResDto = z.infer<typeof userDataResDto>;
