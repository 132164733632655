import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import { statisticData } from '../request/authenticated-requests/statistic';
import type { StatisticResDto } from '@web/common';
import { showErrorNotification } from '../component/notification';

export class StatisticStore {
  @observable public statisticData: StatisticResDto | null = null;
  @observable public isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action public loadStatisticData = () => {
    this.isLoading = true;
    statisticData()
      .then((data) => {
        runInAction(() => {
          this.statisticData = data;
          this.isLoading = false;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.isLoading = false;
        });
        showErrorNotification();
      });
  };
}

const { provider, useStore } = setupMobX<StatisticStore>();
export const useStatisticStore = useStore;
export const StatisticStoreProvider = provider;
