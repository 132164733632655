import {
  SF12QuestionnaireQuestionKey,
  SF12QuestionnaireResult,
  dailyActivitiesIssuesBcOfPainQuestion,
  generalHealthQuestion,
  last4WeeksFeelingQuestion,
  last4WeeksMentalIssuesQuestion,
  last4WeeksWorkPhysicalIssuesQuestion,
  normalDayActivitiesMatrixQuestion,
  contactToFriendsAndFamily,
} from '@web/common';
import {
  randomChoiceValue,
  randomColumnValue,
} from '../../../../util/randomChoice';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

export function devRandomize(t: TranslationFunction): SF12QuestionnaireResult {
  return {
    [SF12QuestionnaireQuestionKey.GENERAL_HEALTH]: randomChoiceValue(
      generalHealthQuestion(t),
    ),
    [SF12QuestionnaireQuestionKey.NORMAL_DAY_ACTIVITIES_MATRIX]: {
      [SF12QuestionnaireQuestionKey.MIDDLE_HEAVY_WORK]: randomColumnValue(
        normalDayActivitiesMatrixQuestion(t),
      ),
      [SF12QuestionnaireQuestionKey.MULTIPLE_STAIRS]: randomColumnValue(
        normalDayActivitiesMatrixQuestion(t),
      ),
    },
    [SF12QuestionnaireQuestionKey.LAST_4_WEEKS_WORK_PHYSICAL_ISSUES]: {
      [SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_PHYSICAL]:
        randomColumnValue(last4WeeksWorkPhysicalIssuesQuestion(t)),
      [SF12QuestionnaireQuestionKey.I_COULD_ONLY_DO_CERTAIN_WORK_PHYSICAL]:
        randomColumnValue(last4WeeksWorkPhysicalIssuesQuestion(t)),
    },
    [SF12QuestionnaireQuestionKey.LAST_4_WEEKS_MENTAL_ISSUES]: {
      [SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_MENTAL]:
        randomColumnValue(last4WeeksMentalIssuesQuestion(t)),
      [SF12QuestionnaireQuestionKey.I_COULDNT_WORK_AS_CAREFULLY_MENTAL]:
        randomColumnValue(last4WeeksMentalIssuesQuestion(t)),
    },
    [SF12QuestionnaireQuestionKey.DAILY_ACTIVITIES_ISSUES_BC_OF_PAIN]:
      randomChoiceValue(dailyActivitiesIssuesBcOfPainQuestion(t)),
    [SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING]: {
      [SF12QuestionnaireQuestionKey.RELAXED_AND_CALM]: randomColumnValue(
        last4WeeksFeelingQuestion(t),
      ),
      [SF12QuestionnaireQuestionKey.FULL_OF_ENERGY]: randomColumnValue(
        last4WeeksFeelingQuestion(t),
      ),
      [SF12QuestionnaireQuestionKey.DISCOURAGED_AND_SAD]: randomColumnValue(
        last4WeeksFeelingQuestion(t),
      ),
    },
    [SF12QuestionnaireQuestionKey.CONTACT_TO_FRIENDS_AND_FAMILY]:
      randomChoiceValue(contactToFriendsAndFamily(t)),
  };
}
