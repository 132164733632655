import React, { forwardRef } from 'react';
import * as icons from '../../assets/icons';

export const MyIcon = forwardRef<
  SVGSVGElement,
  {
    name: string;
    style?: React.CSSProperties;
    className?: string;
  }
>(({ name, style, className }, ref) => {
  const IconComponent = icons[name as keyof typeof icons];
  return <IconComponent ref={ref} style={style} className={className} />;
});
