import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

// this is the visible box for the drawn label
const height = 100;
const width = 100;

// this is the position for the labels in the Pie Area
const labelY = 155;
const leftLabelX = 5;
const rightLabelX = 170;
const innerLabelX = 85;
const innerLabelY = 75;

export const InnerLabel = ({
  availableAmount,
  unit,
}: {
  availableAmount: number;
  unit: string;
}) => {
  const { t } = useTranslation('homescreen');
  return (
    <g>
      <foreignObject
        x={innerLabelX}
        y={innerLabelY}
        width={width}
        height={height}
      >
        <Text fw="500" size="28px" lh="38px" lts={-0.5}>
          {availableAmount.toLocaleString()}
          {unit}
        </Text>
        <Text size="16px" lh="24px">
          {t('THC.Available')}
        </Text>
      </foreignObject>
    </g>
  );
};

export const LeftLabel = () => {
  return (
    <g>
      <foreignObject x={leftLabelX} y={labelY} width={width} height={height}>
        <Text size="16px">0g</Text>
      </foreignObject>
    </g>
  );
};

export const RightLabel = ({ thcLimit }: { thcLimit: number }) => {
  return (
    <g>
      <foreignObject x={rightLabelX} y={labelY} width={width} height={height}>
        <Text size="16px">{thcLimit}g</Text>
      </foreignObject>
    </g>
  );
};
