import { setupMobX } from '../../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { type GivePodcastFeedbackReqDto } from '@web/common';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../component/notification';
import { giveFeedback } from '../../request/authenticated-requests/podcasts';

export class PodcastFeedbackStore {
  @observable public modalOpen: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public giveFeedback(
    podcastId: number,
    feedback: GivePodcastFeedbackReqDto,
    afterAction?: () => void,
  ) {
    giveFeedback(podcastId, feedback)
      .then(() => {
        showSuccessNotification(
          'Vielen Dank für Ihr Feedback!',
          'Feedback gespeichert',
        );
        runInAction(() => {
          this.modalOpen = false;
          afterAction?.();
        });
      })
      .catch((error) => {
        console.error(error);
        showErrorNotification();
        runInAction(() => {
          this.modalOpen = false;
          afterAction?.();
        });
      });
  }
}

const { provider, useStore } = setupMobX<PodcastFeedbackStore>();
export const usePodcastFeedbackStore = useStore;
export const PodcastFeedbackStoreProvider = provider;
