import { z } from 'zod';
import { diaryEntrySchema } from './create-diary-entry.res.dto';

const array = z.array(diaryEntrySchema);
export type DiaryEntryArraySchema = z.infer<typeof array>;

export const getDiaryEntriesResDto = z.object({
  meta: z.object({
    earliestEntryDate: z.coerce.date().nullable(),
  }),
  entries: array,
});
export type GetDiaryEntriesResDto = z.infer<typeof getDiaryEntriesResDto>;
