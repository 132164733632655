import { Button, Flex, TextInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { BaseModal } from '../../../userStatus/dialogs';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { useForm, zodResolver } from '@mantine/form';
import { useParticipantDataStore } from '../../../../store';
import { ErrorCode, userContactInfoReqDto } from '@web/common';
import { handleRequestError, RequestErrorType } from '../../../../request';
import { showSuccessNotification } from '../../../notification';
import { useParams } from 'react-router-dom';
import { updateContactInfo } from '../../../../request/authenticated-requests/participant';

export const UpdateParticipantInfoModal = observer(() => {
  const [opened, { open, close }] = useDisclosure(false);
  const participantDataStore = useParticipantDataStore();
  const { t } = useTranslation('update-participant-info');
  const email = participantDataStore.data?.email ?? null;
  const phoneNumber = participantDataStore.data?.phoneNumber ?? null;
  const { userId } = useParams();

  function setFormError(error: RequestErrorType | ErrorCode) {
    if (error === ErrorCode.AlreadyRegisteredEmailOnce) {
      Form.setErrors({
        email: t('register:Errors.UserAlreadyRegisteredEmailOnce'),
      });
    } else if (error === ErrorCode.AlreadyRegisteredPhoneNumberOnce) {
      Form.setErrors({
        phoneNumber: t('register:Errors.UserAlreadyRegisteredPhoneNumberOnce'),
      });
    } else if (error === ErrorCode.KeycloakFailedToUpdateUserMail) {
      Form.setErrors({
        email: t('register:Errors.KeycloakFailedToUpdateUserMail'),
      });
    }
  }

  function handleSubmit() {
    const { id, email, phoneNumber } = Form.values;
    if (!id || !email || !phoneNumber) return;

    const data = { id, email, phoneNumber };

    updateContactInfo(data)
      .then(() => {
        showSuccessNotification(t('Success'));
        participantDataStore.retrieveUserInformation(id);
        close();
      })
      .catch((error) => {
        handleRequestError(
          error,
          [
            ErrorCode.AlreadyRegisteredEmailOnce,
            () => setFormError(ErrorCode.AlreadyRegisteredEmailOnce),
          ],
          [
            ErrorCode.AlreadyRegisteredPhoneNumberOnce,
            () => setFormError(ErrorCode.AlreadyRegisteredPhoneNumberOnce),
          ],
          [
            ErrorCode.KeycloakFailedToUpdateUserMail,
            () => setFormError(ErrorCode.KeycloakFailedToUpdateUserMail),
          ],
        );
      });
  }
  const Form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(userContactInfoReqDto),
    initialValues: {
      id: userId,
      email: email,
      phoneNumber: phoneNumber,
    },
  });

  return (
    <div>
      <Button variant="outline" onClick={open}>
        {t('Title')}
      </Button>
      <BaseModal
        opened={opened}
        onClose={close}
        title={t('Title')}
        description=""
        size="500px"
        buttons={[
          <Button
            key="cancel"
            variant="outline"
            onClick={handleSubmit}
            w="100%"
          >
            {t('Action')}
          </Button>,
        ]}
        children={
          <Flex direction="column" gap="10px" w="100%">
            {t('Description')}
            <TextInput
              required
              type="email"
              label={t('Inputs.Email')}
              placeholder={t('Inputs.EmailPlaceholder')}
              data-cy="register-email"
              {...Form.getInputProps('email')}
            />

            <TextInput
              style={{ flex: 1 }}
              required
              label={t('Inputs.Number')}
              placeholder={t('Inputs.NumberPlaceholder')}
              data-cy="register-phone"
              {...Form.getInputProps('phoneNumber')}
            />
          </Flex>
        }
      />
    </div>
  );
});
