import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import apiUrl from '../config/apiUrl';
import { z, ZodError } from 'zod';
import { RequestError } from './RequestError';
import { RequestErrorType } from './RequestErrorType';
import { ErrorCode } from '@web/common';
import { ServerError } from './ServerError';
import * as Sentry from '@sentry/react';

class PublicAPI {
  private readonly client: AxiosInstance;

  constructor(requestTimeout: number = 10000) {
    this.client = axios.create({
      timeout: requestTimeout,
      headers: { 'Content-Type': 'application/json' },
      baseURL: apiUrl,
    });
  }

  public async get<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
    queryParams?: object,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.get<ReturnType>(resourceUrl, {
          params: queryParams,
        }),
      schema,
    );
  }

  public async post<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
    config?: AxiosRequestConfig,
  ): Promise<ReturnType> {
    return this.request(
      () =>
        this.client.post<ReturnType>(resourceUrl, data, {
          ...config,
        }),
      schema,
    );
  }

  public async patch<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () => this.client.patch<ReturnType>(resourceUrl, data),
      schema,
    );
  }

  public async put<Type, ReturnType>(
    resourceUrl: string,
    data: Type,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () => this.client.put<ReturnType>(resourceUrl, data),
      schema,
    );
  }

  public async noBodyPut<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(() => this.client.put<ReturnType>(resourceUrl), schema);
  }

  public async delete<ReturnType>(
    resourceUrl: string,
    schema: z.Schema<ReturnType>,
  ): Promise<ReturnType> {
    return this.request(
      () => this.client.delete<ReturnType>(resourceUrl),
      schema,
    );
  }

  private async request<RequestData, ResponseData>(
    block: () => Promise<AxiosResponse<unknown, RequestData>>,
    schema: z.Schema<ResponseData>,
  ): Promise<ResponseData> {
    const response = await block().catch(this.handleAPIError);

    const parseResult = schema.safeParse(response.data);
    if (parseResult.success) {
      return parseResult.data;
    }

    throw this.handleParseError(parseResult.error, response.config);
  }

  private handleAPIError = (error: AxiosError): never => {
    const apiResponseData = error?.response?.data as
      | undefined
      | { errorMessage?: string; error?: string; errorCode?: string };

    const statusCode = error.response?.status;
    const serverErrorCode = apiResponseData?.errorCode as undefined | ErrorCode;

    console.error(
      JSON.stringify({
        statusCode: statusCode,
        error: error.message,
        body: apiResponseData,
      }),
    );

    if (serverErrorCode) {
      throw new ServerError(serverErrorCode);
    } else {
      throw new RequestError(RequestErrorType.Generic);
    }
  };

  private handleParseError<T>(error: ZodError<T>, config: AxiosRequestConfig) {
    Sentry.captureEvent({
      message: `Error: PublicAPI ${error} `,
      level: 'error',
      extra: { error },
    });

    throw new RequestError(RequestErrorType.ParseIssue, JSON.stringify(error));
  }
}

export const publicAPI = new PublicAPI();
