import { Box, Divider, Flex, Image } from '@mantine/core';
import logo from '../../../../assets/scc_text_logo.svg';
import { useNavigate } from 'react-router-dom';
import { useKeycloakStore } from '../../../../store';
import { Routes } from '../../../../route/Routes';
import classes from './Header.module.css';
import { useWindowSize } from '../../../../hooks';
import BurgerMenu from './BurgerMenu';

export const Header = ({
  canLogout = true,
  hideLogout = false,
}: {
  canLogout?: boolean;
  hideLogout?: boolean;
}) => {
  const navigate = useNavigate();
  const isSmallScreen = useWindowSize(500);
  const keycloakStore = useKeycloakStore();

  function handleLogoClick() {
    if (keycloakStore.authenticated) {
      navigate(Routes.Study);
    } else {
      navigate(Routes.Root);
    }
  }

  return (
    <Box w="100%">
      <Flex bg="#fff" justify="space-between" align="center" p="5px 15px">
        <Image
          radius="md"
          h={60}
          w="auto"
          fit="contain"
          p={5}
          src={logo}
          className={classes.logo}
          onClick={handleLogoClick}
        />

        <BurgerMenu
          username={'Username'}
          canLogout={canLogout}
          hideLogout={hideLogout}
          showItems
        />
      </Flex>

      {isSmallScreen && <Divider />}
    </Box>
  );
};
