import { Button, Flex, Text } from '@mantine/core';
import classes from '../LandingPage.module.css';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import { useWindowSize } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

export const JoinAndProfit = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');
  const isSmallScreen = useWindowSize(1000);
  const points = t('JoinAndProfit.Points', { returnObjects: true }) as string[];
  return (
    <Flex
      id={id}
      className={classes.baseSection}
      style={{ background: '#F1F0E899' }}
      justify="space-between"
      gap={isSmallScreen ? '15px' : '40px'}
      wrap="wrap"
      direction="row"
      align="start"
    >
      <Flex className={classes.leftContainer}>
        <Text className={classes.howItWorksTitle} c="#202020">
          {t('JoinAndProfit.Title')}
        </Text>
        {!isSmallScreen && (
          <Link
            children={<Button>{t('Buttons.RegisterNow')}</Button>}
            to={Routes.EntryQuestionnaire}
          />
        )}
      </Flex>
      <Flex
        flex="1"
        direction="column"
        ta="start"
        miw={isSmallScreen ? '100%' : '420px'}
      >
        <Text>{t('JoinAndProfit.Description1')}</Text>
        <ul>{points?.map((point: string) => <li key={point}>{point}</li>)}</ul>
      </Flex>
      {isSmallScreen && (
        <Link
          children={<Button>{t('Buttons.RegisterNow')}</Button>}
          to={Routes.EntryQuestionnaire}
        />
      )}
    </Flex>
  );
};
