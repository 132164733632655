import { action, makeObservable, observable, runInAction } from 'mobx';
import type { PausedUsersResDto } from '@web/common';
import { pausedUsers } from '../request/authenticated-requests/user';
import { setupMobX } from '../util/setupMobX';
import { showErrorNotification } from '../component/notification';

export class PausedUsersStore {
  @observable public pausedUsers: PausedUsersResDto = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadPausedUsers = () => {
    pausedUsers()
      .then((pausedUsers) => {
        runInAction(() => {
          this.pausedUsers = pausedUsers;
        });
      })
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<PausedUsersStore>();
export const usePausedUsersStore = useStore;
export const PausedUsersStoreProvider = provider;
