import { Box, Flex, Space, Text } from '@mantine/core';
import { useMissionStore } from '../../../../../../store/MissionStore';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { MissionItem } from './MissionItem';
import { useTranslation } from 'react-i18next';

export const MissionWrapper = observer(() => {
  const missionStore = useMissionStore();
  const missions = missionStore.missions;
  const { t } = useTranslation('homescreen');

  useEffect(() => {
    missionStore.loadMissions();
  }, [missionStore]);

  return (
    <Box flex="1">
      <Text fw="600" size="21px" lh="24px">
        {t('Missions')}
      </Text>
      <Space h="20px" />
      <Flex gap="16px" direction="column">
        {missions.map((mission) => (
          <MissionItem key={mission.type} mission={mission} />
        ))}
      </Flex>
    </Box>
  );
});
