import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { ConsumptionConcentration } from '@prisma/client';

export const consumptionConcentrationZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(ConsumptionConcentration)))
    .transform(
      (value) =>
        ConsumptionConcentration[
          value as keyof typeof ConsumptionConcentration
        ] as ConsumptionConcentration,
    );
