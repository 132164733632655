import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  allowedItems,
  GoogleAnalyticsEvent,
  SnapEvent,
  useGoogleAnalyticsStore,
  useOnboardingChecklistStore,
  useSnapPixelStore,
  useUserMeStore,
} from '../../../../store';
import ChecklistItemView from './ChecklistItemView';
import { Routes } from '../../../../route/Routes';
import {
  Accordion,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Progress,
  Space,
  Text,
} from '@mantine/core';
import classes from './OnboardingChecklist.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { DevContent } from '../../../../util/DevContent';
import { ChecklistHeader } from './ChecklistHeader';
import { CheckListId, ErrorCode, FeatureFlag } from '@web/common';
import { BookingSoonPossible } from './BookingSoonPossible';
import { Header } from '../../../public/navigation';
import { QuestionnaireEvaluationModal } from '../questionnaire-evaluation/QuestionnaireEvaluationModal';
import { ChecklistShortcut } from './ChecklistShortcut';
import useScrollToCheckItem from './hooks/useScrollToCheckItem';
import { useInitChecks } from './hooks/useInitChecks';
import useAutoTaskOpener from './hooks/useAutoTaskOpener';
import { CohortEndingBanner, Loading } from '../../../../component';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { NoData } from '../deactivated';
import { isFeatureEnabled } from '../../../../util/ff/isFeatureEnabled';

const hiddenChecklistItems = [
  CheckListId.Einwilligungserklaerung,
  CheckListId.Wohnsitznachweis,
  CheckListId.AHVNummerNachweis,
];

export const OnboardingChecklist = observer(() => {
  const checklistStore = useOnboardingChecklistStore();
  const userStore = useUserMeStore();
  const isLoading = checklistStore.loadingChecklist;
  const navigate = useNavigate();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const { t } = useTranslation('onboarding-checklist');
  const [openItem, setOpenItem] = useState<string | null>(null);

  useEffect(() => {
    checklistStore.loadOnboardingChecklist();
  }, [checklistStore, userStore]);

  const bookingCurrentlyNotPossible = !isFeatureEnabled(
    FeatureFlag.BookingAllowed,
  );

  useInitChecks(hiddenChecklistItems);
  useScrollToCheckItem(openItem);
  useAutoTaskOpener(setOpenItem, hiddenChecklistItems);

  if (isLoading) return <Loading />;
  if (!checklistStore.checklist) {
    Sentry.captureEvent({
      message: `Error: ${ErrorCode.CannotLoadChecklist} - The Onboarding Checklist could not be loaded for the user: ${userStore.myInfo?.id}`,
      level: 'warning',
    });

    return <NoData errorCode={ErrorCode.CannotLoadChecklist} />;
  }

  const checklist = Object.entries(checklistStore.checklist ?? []).filter(
    ([id]) => allowedItems.includes(id as CheckListId),
  );
  const taskCount = checklist.length;
  const doneItems = checklist.filter(([id, item]) => item.done).length;
  const isAppointmentDisabled =
    !checklistStore.allChecked || bookingCurrentlyNotPossible;

  return (
    <>
      <QuestionnaireEvaluationModal />
      <Header canLogout />
      <ChecklistHeader />

      <CohortEndingBanner maxWidth="910px" />
      <Container fluid className={classes.container} p={0}>
        <Flex direction="column">
          <Center className={classes.centerContainer}>
            <Flex direction="column" w="870px">
              <Flex className={classes.checklistHeader}>
                <Text fw="700" size="21px" lh="24px">
                  {t('Checklist.Title')}
                </Text>
                <Space h={10} />
                <Text fw="700" size="16px" lh="24px" data-cy="done-item-count">
                  {doneItems}/{taskCount} {t('Checklist.FinishedTasks')}
                </Text>
                <Flex gap="10px">
                  {checklist.map((item, index) => {
                    const progress =
                      index < doneItems ? 100 : index === doneItems ? 50 : 0;
                    return (
                      <Progress
                        key={index}
                        value={progress}
                        size="sm"
                        color="#3D7F53"
                        bg="#D0D0D0"
                        w="32px"
                      />
                    );
                  })}
                </Flex>
              </Flex>
              <Flex className={classes.checklistSection}>
                <Divider />
                <Accordion
                  radius="xs"
                  value={openItem}
                  onChange={setOpenItem}
                  classNames={{ item: classes.accordionItem }}
                >
                  {checklist.map(([id, item], index) => (
                    <ChecklistItemView
                      key={id}
                      id={id as CheckListId}
                      item={item}
                      handleTick={() =>
                        checklistStore.checkItem(id as CheckListId, !item.done)
                      }
                      index={index}
                      hideFormular={false}
                    />
                  ))}
                </Accordion>
                <div className={classes.appointmentContainer}>
                  {checklistStore.allChecked && bookingCurrentlyNotPossible && (
                    <BookingSoonPossible />
                  )}

                  <Button
                    className={classes.appointmentItem}
                    disabled={isAppointmentDisabled}
                    onClick={() => {
                      snapPixelStore.track(SnapEvent.Wants_to_book_appointment);
                      googleAnalyticsStore.track(
                        GoogleAnalyticsEvent.Wants_to_book_appointment,
                      );
                      navigate(Routes.BookInPerson);
                    }}
                    maw="300px"
                  >
                    {t('Buttons.MakeAppointment')}
                  </Button>
                </div>
              </Flex>
            </Flex>
          </Center>
        </Flex>
      </Container>
      <ChecklistShortcut />
      <DevContent>
        <Link to={Routes.BookInPerson}>{t('Buttons.MakeAppointment')}</Link>
      </DevContent>
    </>
  );
});
