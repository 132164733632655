import { z } from 'zod';
import { productSchemaDto } from './products.res.dto';
import { billingAddressSchemaDto } from './shopify-order.res.dto';

export enum PaidStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Expired = 'Expired',
}

const product = z.object({
  variantId: z.string(),
  currentQuantity: z.number(),
  product: productSchemaDto.omit({ inventory: true }),
});
export type OrderItemDto = z.infer<typeof product>;

export const orderResDto = z.object({
  id: z.string(),
  name: z.string(),
  paidStatus: z.nativeEnum(PaidStatus),
  cancelled: z.boolean(),
  closed: z.boolean(),
  orderPlacedDate: z.coerce.date(),
  totalPrice: z.coerce.number(),
  currencyCode: z.string(),
  billingAddress: billingAddressSchemaDto,
  orderItems: z.array(product),
  userId: z.string().nullable(), // this is null, if the user input a wrong email when ordering
  studyCode: z.string().nullable(), // this is null, if the user input a wrong email when ordering
});
export type OrderDto = z.infer<typeof orderResDto>;

export const ordersResDto = z.array(orderResDto);
export type OrdersResDto = z.infer<typeof ordersResDto>;

export const openOrders = orderResDto.extend({
  orderItems: z.number(),
});
export type OpenOrders = z.infer<typeof openOrders>;
export const openOrdersResDto = z.array(openOrders);
export type OpenOrdersResDto = z.infer<typeof openOrdersResDto>;

export const openOrdersForUser = orderResDto.extend({
  orderItems: z.array(product),
});
export type OpenOrdersForUser = z.infer<typeof openOrdersForUser>;
export const openOrdersForUserResDto = z.array(openOrdersForUser);
export type OpenOrdersForUserResDto = z.infer<typeof openOrdersForUserResDto>;
