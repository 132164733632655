import { FeatureFlag } from '@web/common';
import { FeatureAccess } from '../../../../../util/ff/FeatureAccess';
import { observer } from 'mobx-react';
import { ShoppingCartButton } from '../../../../../component/button';

export const ParticipantSection = observer(() => (
  <FeatureAccess flag={FeatureFlag.Shop_Checkout}>
    <ShoppingCartButton />
  </FeatureAccess>
));
