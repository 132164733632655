import { Button, Text } from '@mantine/core';
import {
  MyIcon,
  ExportModal,
  LogoutButtonWithIcon,
} from '../../../../../component';
import { useExportStore } from '../../../../../store/ExportStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classes from '../../../../../sharedStyles/Layout.module.css';

export const StudyOrganizerSection = observer(
  ({ navBarOpen }: { navBarOpen: boolean }) => {
    const exportStore = useExportStore();
    const { t } = useTranslation('navbar');

    return (
      <>
        <Button
          className={classes.button}
          variant={navBarOpen ? 'outline' : 'white'}
          justify="flex-start"
          w="100%"
          leftSection={<MyIcon name="export" className={classes.icon} />}
          radius="5px"
          size="lg"
          onClick={() => (exportStore.open = true)}
        >
          <Text className={classes.navBtnText}>{t('ExportData')}</Text>
        </Button>
        <ExportModal />
        <LogoutButtonWithIcon smallIcon={navBarOpen} />
      </>
    );
  },
);
