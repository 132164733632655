import { z } from 'zod';

export const sendgridEmailSendingResDto = z.tuple([
  z.object({
    statusCode: z.number(),
    headers: z.object({
      'x-message-id': z.string(),
    }),
  }),
  z.literal(''),
]);
