import {
  CannabisConsumptionQuestionnaireQuestionKey,
  CannabisConsumptionQuestionnaireResult,
} from '../dto/cannabis-consumption-questionnaire/cannabis-consumption-questionnaire.req.dto';
import {
  consumptionInDangerousSituationQuestion,
  howManyHoursWhereYouHighQuestion,
  howOftenCouldYouNotStopSmokingQuestion,
  howOftenDoYouConsumeCannabisQuestion,
  memoryOrConcentrationProblemsQuestion,
  missedExpectationsQuestion,
  organizingCannabisConsumptionQuestion,
  thoughtAboutStoppingConsumption,
} from './cannabis-consumption-survey-questions';

const cutOff = 13;

const keysForEval = [
  CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS,
  CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_HOURS_WERE_YOU_HIGH,
  CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_COULD_YOU_NOT_STOP_SMOKING,
  CannabisConsumptionQuestionnaireQuestionKey.FAILED_TO_MEET_EXPECTATIONS,
  CannabisConsumptionQuestionnaireQuestionKey.ORGANIZING_CANNABIS_CONSUMPTION,
  CannabisConsumptionQuestionnaireQuestionKey.MEMORY_OR_CONCENTRATION_PROBLEMS,
  CannabisConsumptionQuestionnaireQuestionKey.CONSUMPTION_IN_DANGEROUS_SITUATIONS,
  CannabisConsumptionQuestionnaireQuestionKey.THOUGHT_ABOUT_STOPPING_CONSUMPTION,
];
const questionSpecs = [
  howOftenDoYouConsumeCannabisQuestion(t),
  howManyHoursWhereYouHighQuestion(t),
  howOftenCouldYouNotStopSmokingQuestion(t),
  missedExpectationsQuestion(t),
  organizingCannabisConsumptionQuestion(t),
  memoryOrConcentrationProblemsQuestion(t),
  consumptionInDangerousSituationQuestion(t),
  thoughtAboutStoppingConsumption(t),
];

function t(key: string) {
  return key;
}

function calcValueForKey(
  key: CannabisConsumptionQuestionnaireQuestionKey,
  result: CannabisConsumptionQuestionnaireResult,
) {
  const question = questionSpecs.find((question) => question.name === key);
  if (!question) {
    throw new Error(`Question with key ${key} not found`);
  }

  const choice = question.choices.find(
    (choice) => choice.value === result[key],
  );
  if (!choice) {
    throw new Error(`Choice with value ${result[key]} not found`);
  }

  return choice.calcValue;
}

export function cuditEvaluation(
  result: CannabisConsumptionQuestionnaireResult,
): { shouldVisitDoctor: boolean } {
  const sum = keysForEval
    .map((key) => calcValueForKey(key, result))
    .reduce((sum, value) => sum + value, 0);

  return {
    shouldVisitDoctor: sum >= cutOff,
  };
}
