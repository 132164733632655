import { z } from 'zod';
import { createContestResDto } from './create-contest.res.dto';
import { priceSchemaDto } from './price-schema.dto';

const contestSchemaDto = createContestResDto.extend({
  ContestPrice: z.array(priceSchemaDto),
});
export const getAvailableContestsResDto = z.array(contestSchemaDto);
export type GetAvailableContestsResDto = z.infer<
  typeof getAvailableContestsResDto
>;
