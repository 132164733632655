import { observer } from 'mobx-react';
import { Flex, Skeleton, Space, Text } from '@mantine/core';
import { usePickUpStore } from './PickUpStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PickUpDetails = observer(({ userId }: { userId: string }) => {
  const store = usePickUpStore();
  const { t } = useTranslation('shop');
  const dispensary = store.dispensary;
  const showData = dispensary && !store.loading;

  useEffect(() => {
    store.getUserDispensary(userId);
  }, [store, userId]);

  return (
    <Flex direction="column">
      <Text size="16px" lh="24px" c="#202020">
        {t('OrderOverview.PickUp')}
      </Text>
      {showData ? (
        <>
          <Space h="10px" />
          <Text size="14px" lh="18px" c="#3D7F53">
            {dispensary.name}
          </Text>
          <Text size="14px" lh="18px">
            {dispensary.street}
          </Text>
          <Text size="14px" lh="18px">
            {dispensary.zip_code}
          </Text>
          <Text size="14px" lh="18px">
            {dispensary.city}
          </Text>
        </>
      ) : (
        <Skeleton height={100} />
      )}
    </Flex>
  );
});
