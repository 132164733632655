import { ActionIcon, Stack, Text } from '@mantine/core';
import { MyIcon } from '../../../../../../component';
import classes from './ActionButton.module.css';

export const ActionButton = ({
  iconName,
  iconColor,
  variant,
  size,
  text,
  disabled,
  onClick,
}: {
  iconName: string;
  iconColor?: string;
  variant?: 'greyed' | 'filled';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}) => {
  const dynamicIconColor = variant === 'greyed' ? '#7F898F' : '#3D7F53';
  return (
    <Stack align="center" gap="5px">
      <ActionIcon
        size={size ?? 'xl'}
        variant="outline"
        color="gray"
        className={classes[variant ?? 'filled']}
        disabled={disabled}
        onClick={onClick}
      >
        <MyIcon
          name={iconName}
          style={{ fill: iconColor ?? dynamicIconColor }}
        />
      </ActionIcon>
      {text && (
        <Text size="12px" lh="18px" c="#4A4A48">
          {text}
        </Text>
      )}
    </Stack>
  );
};
