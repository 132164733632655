import { Box, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { BannerContainer } from './BannerContainer';

type BannerProps = {
  maxWidth?: string;
  padding?: string;
  margin?: string;
  link?: string | null;
  title: string;
  limit: string;
  description: string;
};

export const THCLimitBanner = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
    margin = 'auto',
    title,
    limit,
    description,
    link,
  }: BannerProps) => {
    return (
      <BannerContainer margin={margin} padding={padding} maxWidth={maxWidth}>
        <Box p="20px" style={{ borderRadius: 8, border: 'solid 2px red' }}>
          <Text fw="600" size="16px" lh="24px">
            {title}
          </Text>
          <Text fw="600" size="28px" lh="38px" lts="-0.5px" py="5px">
            {limit}
          </Text>
          <Text c="#4A4A48">{description}</Text>
          {link && (
            <a style={{ wordBreak: 'break-word' }} href={link}>
              {link}
            </a>
          )}
        </Box>
      </BannerContainer>
    );
  },
);
