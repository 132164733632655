import {
  AusweisTypEnum,
  CdsScrypt,
  SexEnum,
  StammDaten,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';

type CannavigiaData = {
  ignoreHashCheck: boolean;
  hash: string;
  idType: 'SP' | 'SI' | 'RP';
  idNumber: string;
  placeOfBirthOrResidence: string;
  dispensaries: string[];
};

export function getCannavigiaSourceData(stammdaten: StammDaten): {
  vorname: string;
  nachname: string;
  sex: SexEnum;
  birthdate: string;
  formattedBirthdate: string;
  idNumber: string;
  isSwiss: boolean;
  birthPlace: string;
  hasRP: boolean;
  idType: 'SP' | 'SI' | 'RP';
  idTypeReadable: string;
} {
  const {
    AusweisNr,
    AusweisType,
    SwissNationality,
    HeimatOrt,
    GeburtsOrt,
    Sex,
    BirthDate,
    Name,
    Surname,
  } = StammdatenQuestionnaireQuestionKey;

  const vorname = stammdaten[Name];
  const nachname = stammdaten[Surname];
  const sex = stammdaten[Sex];
  const birthdate = stammdaten[BirthDate];
  const formattedBirthdate = birthdate.replaceAll('-', '.');
  const idNumber = stammdaten[AusweisNr];
  const isSwiss = stammdaten[SwissNationality];
  const birthPlace = stammdaten[isSwiss ? HeimatOrt : GeburtsOrt]!;
  const hasRP = stammdaten[AusweisType] === AusweisTypEnum.Reisepass;
  const idType = isSwiss ? (hasRP ? 'SP' : 'SI') : 'RP';
  const idTypeReadable = isSwiss
    ? hasRP
      ? 'Schweizer Pass'
      : 'Schweizer Id'
    : 'Reisepass';

  return {
    vorname,
    nachname,
    sex,
    birthdate,
    formattedBirthdate,
    idNumber,
    hasRP,
    isSwiss,
    birthPlace,
    idType,
    idTypeReadable,
  };
}

export async function getCannavigiaData(
  stammdaten: StammDaten | null,
  dispensaries: string,
  ignoreHash = false,
): Promise<CannavigiaData | undefined> {
  if (!stammdaten) return;

  const {
    vorname,
    nachname,
    sex,
    formattedBirthdate,
    idNumber,
    birthPlace,
    idType,
  } = getCannavigiaSourceData(stammdaten);

  const password = CdsScrypt.generatePassword(
    vorname,
    nachname,
    sex[0].toLocaleUpperCase(),
    formattedBirthdate,
  );

  const hash = await CdsScrypt.calculateHash(password);

  return {
    ignoreHashCheck: ignoreHash,
    hash,
    idType,
    idNumber,
    placeOfBirthOrResidence: birthPlace,
    dispensaries: [dispensaries],
  };
}
