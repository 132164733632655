import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { EntryTime } from '@prisma/client';

export const entryTimeZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(EntryTime)))
    .transform(
      (value) => EntryTime[value as keyof typeof EntryTime] as EntryTime,
    );
