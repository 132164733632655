import { Button, Flex, Loader } from '@mantine/core';
import { BaseModal } from '../../../../../../component/userStatus/dialogs';
import { observer } from 'mobx-react';
import { QRCodeSVG } from 'qrcode.react';
import { ThcLimitWarning } from '..';

export const QrCodeModal = observer(
  ({
    modalOpen,
    setModalClose,
    qrCodeContent,
  }: {
    modalOpen: boolean;
    setModalClose: (open: boolean) => void;
    qrCodeContent: string | null;
  }) => {
    const onOnboardModalClose = () => {
      setModalClose(false);
    };

    const content = qrCodeContent ? (
      <QRCodeSVG size={260} value={qrCodeContent} />
    ) : (
      <Loader />
    );

    return (
      <BaseModal
        opened={modalOpen}
        onClose={onOnboardModalClose}
        title="Studiencode"
        description=""
        iconName="qr_code"
        iconColor="#3D7F53"
        size="sm"
        buttons={[
          <Button
            key="cancel"
            variant="outline"
            onClick={onOnboardModalClose}
            w="100%"
          >
            Schließen
          </Button>,
        ]}
        children={
          <Flex direction="column" gap="30px" align="center">
            <ThcLimitWarning />
            {content}
          </Flex>
        }
      />
    );
  },
);
