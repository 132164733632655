import { Flex, ActionIcon, CSSProperties, Text } from '@mantine/core';
import { MyIcon } from '../../../../component';
import { useNavigate } from 'react-router-dom';
import classes from './Breadcrumbs.module.css';

export const Breadcrumbs = ({
  path,
  style,
}: {
  path: string;
  style?: CSSProperties;
}) => {
  const navigate = useNavigate();
  return (
    <Flex className={classes.container} style={style}>
      <ActionIcon variant="white" onClick={() => navigate(-1)}>
        <MyIcon name="arrow_back" />
      </ActionIcon>
      <MyIcon name="home" style={{ fill: '#000', marginBottom: 2 }} />
      <Text lh="20px">{path}</Text>
    </Flex>
  );
};
