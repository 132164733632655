import { z } from 'zod';

const team = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorMap: (issue, _ctx) => ({
      message: 'The slug cannot be queried with this api token',
    }),
  }),
});
export const getTeamsReqDto = z.object({
  teams: z.array(team),
});
