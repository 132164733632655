import { z } from 'zod';

const inventoryItemSchema = z.object({
  productId: z.string(),
  batchId: z.string(),
  productName: z.string(),
  amount: z.number(),
  thcPerUnit: z.number().nullable(),
  thcTotalInGrams: z.number().nullable(),
  expiryDate: z.coerce.date().nullable(),
});
export type InventoryItemResDto = z.infer<typeof inventoryItemSchema>;

export const inventoryResDto = z.array(inventoryItemSchema);
export type InventoryResDto = z.infer<typeof inventoryResDto>;
