import { z } from 'zod';

const getPodcastSchema = z.object({
  id: z.number(),
  order: z.number(),
  loyalityPoints: z.number(),
  loyalityPointsAvailable: z.boolean(),
  unlocked: z.boolean(),
  prompt: z.boolean(), // prompt user to do it
});
export type GetPodcastSchema = z.infer<typeof getPodcastSchema>;

export const getPodcastsResDto = z.array(getPodcastSchema);
export type GetPodcastsResDto = z.infer<typeof getPodcastsResDto>;
