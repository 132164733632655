import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Routes } from '../../../../route/Routes';
import { useUserMeStore, useEventStore } from '../../../../store';
import { Container, Flex, Space, Divider, ScrollArea } from '@mantine/core';
import classes from './BookInPerson.module.css';
import { Title } from '../../../../component';
import { useWindowSize } from '../../../../hooks';
import { BookingHeader } from './BookingHeader';
import { MapWrapper } from './MapWrapper';
import { BookingItemList } from './BookingItem';
import { splitLocationString } from '../../../../util/appointment/splitLocationString';
import { Header } from '../../../public/navigation';
import { useTranslation } from 'react-i18next';

export const BookInPerson = observer(() => {
  const userMeStore = useUserMeStore();
  const eventStore = useEventStore();
  const isSmallScreen = useWindowSize(650);
  const [selectedTeamSlug, setSelectedTeamSlug] = useState<null | string>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<null | number>(null);
  const [selectedLocation, setSelectedLocation] = useState<null | string>(null);
  const { t } = useTranslation('appointment-booking');

  useEffect(() => {
    userMeStore.loadMyData();
  }, [userMeStore]);

  useEffect(() => {
    eventStore.loadEvents();
  }, [eventStore]);

  useEffect(() => {
    if (eventStore.events[0]?.team?.slug && eventStore.events[0]?.team?.id) {
      setSelectedTeamSlug(eventStore.events[0]?.team?.slug);
      setSelectedTeamId(eventStore.events[0]?.id);
      // is needed to update map location
      setSelectedLocation(
        splitLocationString(eventStore.events[0]?.locations).link,
      );
    }
  }, [eventStore.events]);

  if (!userMeStore.myInfo && !userMeStore.loadingInfo) {
    return <Navigate to={Routes.Study} />;
  }

  const bookingList = (
    <BookingItemList
      // we only want to list teams, that we have in our mapping
      data={eventStore.events}
      selectedTeamSlug={selectedTeamSlug}
      setSelectedTeamSlug={setSelectedTeamSlug}
      selectedEventSlug={selectedTeamId}
      setSelectedEventSlug={setSelectedTeamId}
      selectedLocation={selectedLocation}
      setSelectedLocation={setSelectedLocation}
    />
  );

  return (
    <>
      <Header canLogout />
      <Title width={900} text={t('Title')} />
      {!isSmallScreen && <Space h="xl" />}
      <Container fluid className={classes.container}>
        <Flex className={classes.contentWrapper}>
          <Flex className={classes.leftSide}>
            <BookingHeader count={eventStore.events.length} />
            {isSmallScreen && <MapWrapper location={selectedLocation} />}
            <Divider color="#B1B1B1" />

            {isSmallScreen ? (
              bookingList
            ) : (
              <ScrollArea
                mah="500px"
                scrollbarSize={8}
                type="always"
                offsetScrollbars
              >
                {bookingList}
              </ScrollArea>
            )}
          </Flex>

          {!isSmallScreen && <MapWrapper location={selectedLocation} />}
        </Flex>
      </Container>
    </>
  );
});
