import { observer } from 'mobx-react';
import { Button, Flex, TextInput } from '@mantine/core';
import { useSearchUserStore } from '../../../../store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { simpleSearchRemoveInvalidCharacters } from '../../../../util/userSearchRemoveInvalidCharacters';

export const Search = observer(() => {
  const searchUserStore = useSearchUserStore();
  const { t } = useTranslation('organizer-dashboard');
  const [value, setValue] = useState<string>('');

  const search = () => {
    searchUserStore.search(simpleSearchRemoveInvalidCharacters(value));
  };

  return (
    <Flex gap={10} align="flex-end">
      <TextInput
        style={{ flex: 1 }}
        placeholder={t('CheckParticipants.Search.Placeholder')}
        onChange={(event) => setValue(event.currentTarget.value)}
        onKeyUp={(event) => {
          if (event.key === 'Enter') search();
        }}
        label={t('CheckParticipants.Search.Label')}
        maw="500px"
      />
      <Button disabled={value.length === 0} variant="outline" onClick={search}>
        {t('CheckParticipants.Search.Button')}
      </Button>
    </Flex>
  );
});
