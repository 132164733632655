import classes from '../Deactivation.module.css';
import { Button, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const EnterReason = ({
  makeStep,
  cancel,
  mail,
}: {
  makeStep: () => void;
  cancel: () => void;
  mail: string;
}) => {
  const { t } = useTranslation('deactivation');

  return (
    <div className={classes.stepContainer}>
      <Text size="21px" lh="24px" fw="600">
        {t('EnterReason.Title')}
      </Text>
      <Text size="16px" lh="24px" c="#4A4A48">
        {t('EnterReason.Description')}
      </Text>
      <Space h="10px" />
      <Text c="#4A4A48">{t('EnterReason.YourMailDescription')}</Text>
      <Text bg="#F1F0E899" p="5px" ta="center" style={{ borderRadius: 5 }}>
        {mail}
      </Text>
      <Space h="30px" />
      <Button w="100%" onClick={cancel}>
        {t('Cancel')}
      </Button>
      <Button w="100%" variant="outline" onClick={makeStep}>
        {t('Deactivate')}
      </Button>
    </div>
  );
};
