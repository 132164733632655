import classes from '../../../public/entry-questionnaire/IntroBox.module.css';
import stammdaten from '../../../../assets/stammdaten.svg';
import {
  consumptionInDangerousSituationQuestion,
  howDoYouConsumeCannabisQuestion,
  howManyHoursWhereYouHighQuestion,
  howManyTimesDidYouConsumeWhatQuestion,
  howOftenCouldYouNotStopSmokingQuestion,
  howOftenDoYouConsumeCannabisQuestion,
  memoryOrConcentrationProblemsQuestion,
  missedExpectationsQuestion,
  organizingCannabisConsumptionQuestion,
  otherDrugsQuestion,
  thoughtAboutStoppingConsumption,
  whatCannabisProductsDidYouConsumeQuestion,
  whenDidYouStartSmokingQuestion,
} from '@web/common';
import { renderToString } from 'react-dom/server';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

const introComponent = (t: TranslationFunction) => {
  return (
    <div className={classes.flexBox}>
      <img
        src={stammdaten}
        alt="Stammdaten"
        style={{ flex: '0 10%', justifySelf: 'center' }}
      />
      <div style={{ flex: '1', minWidth: 300 }}>
        <span className={classes.header}>{t('Header.Title')}</span>
        <p className={classes.firstParagraph}>{t('Header.Description')}</p>
      </div>
    </div>
  );
};
const getIntro = (t: TranslationFunction) => {
  return {
    name: 'intro',
    type: 'html',
    html: renderToString(introComponent(t)),
  };
};

export const getCannabisConsumptionQuestionnaireSurveyJSON = (
  t: TranslationFunction,
) => {
  return {
    pages: [
      {
        elements: [
          getIntro(t),
          howOftenDoYouConsumeCannabisQuestion(t),
          howManyHoursWhereYouHighQuestion(t),
          howOftenCouldYouNotStopSmokingQuestion(t),
          missedExpectationsQuestion(t),
          organizingCannabisConsumptionQuestion(t),
          memoryOrConcentrationProblemsQuestion(t),
          consumptionInDangerousSituationQuestion(t),
          thoughtAboutStoppingConsumption(t),
        ],
      },
      {
        elements: [
          whenDidYouStartSmokingQuestion(t),
          howManyTimesDidYouConsumeWhatQuestion(t),
          whatCannabisProductsDidYouConsumeQuestion(t),
          howDoYouConsumeCannabisQuestion(t),
          otherDrugsQuestion(t),
        ],
      },
    ],
  };
};
