import { observer } from 'mobx-react';
import { Button } from '@mantine/core';
import { useKeycloakStore } from '../../store';
import { useTranslation } from 'react-i18next';

export const LoginButton = observer(() => {
  const kcStore = useKeycloakStore();
  const { t } = useTranslation();
  return (
    <Button variant="outline" w="fit-content" onClick={() => kcStore.login()}>
      {t('Buttons.Login')}
    </Button>
  );
});
