import { z } from 'zod';

const cohortUserScheme = z.object({
  cohortId: z.string(),
  amount: z.number(),
});

const statisticScheme = z.object({
  registeredUsers: z.number(),
  usersWithAppointment: z.number(),
  usersForEveryCohort: z.array(cohortUserScheme),
  deactivatedUsers: z.number(),
  usersWithPassedHealthQuestionnaire: z.number(),
  usersWithFilledOutHealthQuestionnaire: z.number(),
  usersWithoutProblematicConsumption: z.number(),
  usersWithFilledOutCuditQuestionnaire: z.number(),
  usersWithFilledOutBaselineSurvey: z.number(),
  usersWithAppointmentBeforeCohortStart: z.number(),
});
export const statisticResDto = statisticScheme;
export type StatisticResDto = z.infer<typeof statisticResDto>;
