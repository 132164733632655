import { observer } from 'mobx-react';
import { useParticipantDataStore } from '../../store';
import { UserStatus as UserStatusEnum } from '@prisma/client';
import { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Text,
  Flex,
  Space,
  Progress,
  Divider,
} from '@mantine/core';
import { CheckOffItem } from './CheckOffItem';
import { Routes } from '../../route/Routes';
import { useNavigate } from 'react-router-dom';
import { BaseItem } from '../../view/authenticated/participant/onboarding-checklist/BaseItem';
import {
  CheckOffId,
  useViewParticipantOnboardingStore,
} from '../../store/ViewParticipantOnboardingStore';
import classes from '../../view/authenticated/participant/onboarding-checklist/OnboardingChecklist.module.css';
import { ContraIndicationRadioGroup } from './radio-groups/ContraIndicationRadioGroup';
import { NotSmokingRadioGroup } from './radio-groups/NotSmokingRadioGroup';

export const ViewParticipantOnboarding = observer(() => {
  const participantDataStore = useParticipantDataStore();
  const stateStore = useViewParticipantOnboardingStore();
  const status = participantDataStore.data?.status;
  const isDeactivated = status === UserStatusEnum.DEACTIVATED;
  const navigate = useNavigate();

  const [openItem, setOpenItem] = useState<string | null>(
    stateStore.checkpoints[0].id,
  );

  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    if (status === UserStatusEnum.PAUSED) {
      setIsPaused(true);
    }
  }, [status]);

  const additionalChecks = (checkoffId: CheckOffId) => {
    switch (checkoffId) {
      case CheckOffId.StammdatenQuestionnaire: {
        return (
          <>
            <Button
              onClick={() => {
                navigate(Routes.UserDetailStammdatenQuestionnaire, {
                  relative: 'route',
                });
              }}
            >
              Antworten öffnen
            </Button>
            <Space h="xl" />
            <Checkbox
              checked={stateStore.getCheckpointState(checkoffId)}
              label={'Die Daten stimmen überein'}
              onChange={(event) => {
                stateStore.setCurrentCheckpointState(
                  checkoffId,
                  event.currentTarget.checked,
                );
                if (event.currentTarget.checked) {
                  setOpenItem(CheckOffId.EinwilligungserklaerungErhalten);
                }
              }}
            />
          </>
        );
      }
      case CheckOffId.EinwilligungserklaerungErhalten: {
        return (
          <Checkbox
            checked={stateStore.getCheckpointState(checkoffId)}
            label={'Einwilligungserklärung erhalten'}
            onChange={(event) => {
              stateStore.setCurrentCheckpointState(
                checkoffId,
                event.currentTarget.checked,
              );
              if (event.currentTarget.checked) {
                setOpenItem(CheckOffId.Wohnsitznachweis);
              }
            }}
          />
        );
      }
      case CheckOffId.Wohnsitznachweis: {
        return (
          <Checkbox
            checked={stateStore.getCheckpointState(checkoffId)}
            label={'Wohnsitzbestätigung korrekt'}
            onChange={(event) => {
              stateStore.setCurrentCheckpointState(
                checkoffId,
                event.currentTarget.checked,
              );
              if (event.currentTarget.checked) {
                setOpenItem(CheckOffId.EntryQuestionnaire);
              }
            }}
          />
        );
      }
      case CheckOffId.EntryQuestionnaire: {
        return (
          <Flex direction="column" gap="10px">
            <Button
              onClick={() => {
                navigate(Routes.UserDetailEntryQuestionnaire, {
                  relative: 'route',
                });
              }}
              w="fit-content"
            >
              Antworten öffnen
            </Button>
            <Checkbox
              checked={stateStore.getCheckpointState(checkoffId)}
              label={'Die Daten stimmen überein '}
              onChange={(event) => {
                stateStore.setCurrentCheckpointState(
                  checkoffId,
                  event.currentTarget.checked,
                );
                if (event.currentTarget.checked) {
                  setOpenItem(CheckOffId.CannabisConsumptionQuestionnaire);
                }
              }}
            />
          </Flex>
        );
      }
      case CheckOffId.CannabisConsumptionQuestionnaire: {
        return (
          <Flex direction="column" gap="10px">
            <Button
              w="fit-content"
              onClick={() => {
                navigate(Routes.UserDetailCannabisConsumptionQuestionnaire, {
                  relative: 'route',
                });
              }}
            >
              Antworten öffnen
            </Button>
            <NotSmokingRadioGroup
              setOpenItem={setOpenItem}
              isDone={stateStore.getCheckpointState(checkoffId)}
              isPaused={isPaused}
            />
          </Flex>
        );
      }
      case CheckOffId.SF12Questionnaire: {
        return (
          <Flex direction="column" gap="10px">
            <Button
              w="fit-content"
              onClick={() => {
                navigate(Routes.UserDetailSF12Questionnaire, {
                  relative: 'route',
                });
              }}
            >
              Antworten öffnen
            </Button>
            <Checkbox
              checked={stateStore.getCheckpointState(checkoffId)}
              label={'Die Daten stimmen überein '}
              onChange={(event) => {
                stateStore.setCurrentCheckpointState(
                  checkoffId,
                  event.currentTarget.checked,
                );
                if (event.currentTarget.checked) {
                  setOpenItem(CheckOffId.KontraIndikationen);
                }
              }}
            />
          </Flex>
        );
      }
      case CheckOffId.KontraIndikationen:
        return <ContraIndicationRadioGroup />;
      default:
        return null;
    }
  };

  const taskCount = stateStore.checkpoints.length;
  const doneItems = stateStore.checkpoints.filter((item) => item.done).length;

  return (
    <Flex direction="column">
      <Flex className={classes.checklistHeader}>
        <Text fw="700" size="21px" lh="24px">
          Zu prüfende Punkte
        </Text>
        <Space h={10} />
        <Text fw="700" size="16px" lh="24px">
          {doneItems}/{taskCount}
          {' Aufgaben erledigt'}
        </Text>
        <Flex gap="10px">
          {stateStore.checkpoints.map((item, index) => {
            const progress =
              index < doneItems ? 100 : index === doneItems ? 50 : 0;
            return (
              <Progress
                key={index}
                value={progress}
                size="sm"
                color={isDeactivated ? '#7F898F' : '#3D7F53'}
                bg="#D0D0D0"
                w="32px"
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex className={classes.checklistSection}>
        <Divider />
        <Accordion
          radius="xs"
          value={isDeactivated ? null : openItem}
          onChange={setOpenItem}
        >
          {stateStore.checkpoints.map((checkpoint, idx) => (
            <CheckOffItem
              key={checkpoint.id}
              title={() => (
                <BaseItem
                  deactivated={isDeactivated}
                  index={idx}
                  title={checkpoint.title}
                  item={checkpoint}
                  handleTick={() => {
                    console.debug('tick', idx);
                  }}
                />
              )}
              checkpoint={checkpoint}
            >
              {additionalChecks(checkpoint.id as CheckOffId)}
            </CheckOffItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  );
});
