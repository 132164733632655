import { Container, Flex, Text } from '@mantine/core';

import classes from './Nutzungsbedingungen.module.css';
import { Header } from './navigation';

const headerText = (text: string) => (
  <Text className={classes.headerText}>{text}</Text>
);

const text = [
  [headerText('1.'), headerText('Geltungsbereich & Geltungsumfang')],
  [
    <Text key={'1.1'}>1.1.</Text>,
    <Text key={'1.1.d'}>
      Gegenstand dieser Allgemeinen Nutzungsbedingungen (NB) sind die
      Beziehungen der Rivamo AG in ihrer Funktion als Betreiberin der «Cannabis
      Consumer APP» und den Nutzer:innen dieser APP (nachfolgend «Betreiberin»
      resp. «Nutzer:in»).
    </Text>,
  ],
  [
    <Text key={'1.2'}>1.2.</Text>,
    <Text key={'1.2.d'}>
      Diese Nutzungsbedingungen sind für die Nutzer:innen der digitalen Angebote
      (vgl. 2.2) verbindlich. Mit der Nutzung der digitalen Angebote akzeptieren
      die Nutzer:innen die vorliegenden Nutzungsbedingungen.
    </Text>,
  ],
  [
    <Text key={'1.3'}>1.3.</Text>,
    <Text key={'1.3.d'}>
      Die Datenschutzerklärung (DSE) ist, soweit anwendbar, integraler
      Bestandteil dieser NB.
    </Text>,
  ],
  [
    <Text key={'1.4'}>1.4.</Text>,
    <Text key={'1.4.d'}>
      Diese NB sind anwendbar auf alle Nutzer:innen unbeachtlich ihrer sonstigen
      vertraglichen Beziehung zur Betreiberin.
    </Text>,
  ],
  [headerText('2.'), headerText('Begriffe')],
  [
    <Text key={'2'}>2.1.</Text>,
    <Text key={'2.d'}>
      Die Betreiberin ist die Rivamo AG, Neue Winterthurerstrasse 99, 8304
      Wallisellen.
    </Text>,
  ],
  [
    <Text key={'2.2'}>2.2.</Text>,
    <Text key={'2.2.d'}>
      «Digitale Angebote» umfasst die gesamte technische Infrastruktur der
      Cannabis Consumer APP so insbesondere die Angebote auf
      https://www.pilotversuche.ch inkl. Subdomains, sowie zugehörige Webseiten
      und sonstige digitale Services.
    </Text>,
  ],
  [headerText('3.'), headerText('Rechte und Pflichten')],
  [
    <Text key={'3.1'}>3.1.</Text>,
    <Text key={'3.1.d'}>
      Die Benutzung der digitalen Angebote der Betreiberin ist lediglich im
      Rahmen der Teilnahme an einem Pilotversuch der Betreiberin gestattet.
    </Text>,
  ],
  [
    <Text key={'3.2'}>3.2.</Text>,
    <Text key={'3.2.d'}>
      Die Nutzer:innen stellen keine nicht zielgerichteten oder unnötigen Daten
      an die Digitalen Angebote oder sonstige Server der Betreiberin zu.
      Nutzer:innen betreiben insbesondere kein Spamming oder sonstige
      Aktivitäten, welche eine unzumutbare oder übermässige Belastung der
      digitalen Angebote zur Folge haben könnten. Die Nutzer:innen schleusen
      keine Computerviren oder andere Schadprogramme in die Infrastruktur der
      Betreiberin ein. Handlungen oder die Verwendung von Mechanismen, Software
      oder Scripts, die den ordnungsgemässen Betrieb der digitalen Angebote
      stören könnten, ist untersagt.
    </Text>,
  ],
  [
    <Text key={'3.3'}>3.3.</Text>,
    <Text key={'3.3.d'}>
      Nutzer:innen können selbständig ein Nutzer:innenkonto eröffnen. Über das
      Nutzer:innenkonto können die angebotenen Services bezogen werden. Username
      und Passwort sind von den Nutzer:innen vertraulich zu behandeln und haben
      den gängigen Mindeststandards zu entsprechen. Die Betreiberin ist nicht
      verantwortlich, wenn Usernamen oder Passwörter unbefugten Dritten bekannt
      und von diesen missbraucht werden.
    </Text>,
  ],
  [headerText('4.'), headerText('Urheber:innenrecht')],
  [
    <Text key={'4'}></Text>,
    <Text key={'4.d'}>
      Nutzer:innen ist es grundsätzlich untersagt, digitale Inhalte der
      Betreiberin zu kopieren, zu veröffentlichen, zu ändern, vorzutragen,
      anzubieten, zu vermieten, zu veräussern, zu lizenzieren,
      weiterzuverarbeiten oder auf Dritte zu übertragen. Ausgenommen sind
      diejenigen Inhalte, welche ausdrücklich eine Weiterverbreitung von
      Inhalten erlauben und entsprechend gekennzeichnet sind (z.B. im Sinne
      einer Creative Commons Lizenz).
    </Text>,
  ],
  [headerText('5.'), headerText('Gewährleistung und Haftung')],
  [
    <Text key={'5.1'}>5.1.</Text>,
    <Text key={'5.1.d'}>
      Die Zugänglichkeit und Verfügbarkeit der digitalen Angebote sind nicht
      garantiert.
    </Text>,
  ],
  [
    <Text key={'5.2'}>5.2.</Text>,
    <Text key={'5.2.d'}>
      Die Betreiberin macht keine Zusicherungen in Bezug auf die Zugänglichkeit
      und Verfügbarkeit der digitalen Angebote, insbesondere im Fall von
      technischen Störungen. Die Sicherung oder Archivierung von übermittelten
      Daten werden nicht garantiert.
    </Text>,
  ],
  [
    <Text key={'5.3'}>5.3.</Text>,
    <Text key={'5.3.d'}>
      Die Betreiberin schliesst, soweit gesetzlich zulässig, die Haftung für
      jegliche Schäden von Nutzer:innen oder Dritten aus, die insbesondere durch
      die Nutzung, den Besuch oder das Durchsuchen oder durch fehlende oder
      eingeschränkte Verfügbarkeit der Digitale Angebote entstehen. Dies umfasst
      insbesondere auch Schäden, welche entstehen durch das unsachgemässes
      Vorgehen und die Missachtung der Risiken seitens der Nutzer:innen oder
      Dritter im online-Verkehr, übermässige Beanspruchung, ungeeignete
      Betriebsmittel der Nutzer:innen oder Dritter, extreme Umgebungseinflüsse,
      Eingriffe der Nutzer:innen oder Störungen durch Dritte (Schadsoftware).
    </Text>,
  ],
  [
    <Text key={'5.4'}>5.4.</Text>,
    <Text key={'5.4.d'}>
      Bei Schäden infolge unzulässiger Nutzung der Digitale Angebote behält sich
      die Betreiberin das Recht vor, Schadenersatzforderungen geltend zu machen.
    </Text>,
  ],
  [headerText('6.'), headerText('Datenschutz')],
  [
    <Text key={'6'}></Text>,
    <Text key={'6.d'}>
      Nutzer:innen haben das Recht Auskunft darüber zu verlangen, ob und welche
      personenbezogenen Daten über sie gespeichert sind. Für die Details
      konsultieren Sie bitte die Datenschutzerklärung.
    </Text>,
  ],
  [headerText('7.'), headerText('Anwendbares Recht und Gerichtsstand')],
  [
    <Text key={'7.1'}>7.1.</Text>,
    <Text key={'7.1.d'}>
      Auf alle Streitigkeiten, welche sich aus der Nutzung der Digitalen
      Angebote ergeben, ist, soweit gesetzlich zulässig und soweit keine
      vertraglichen Abreden vorgehen, ausschliesslich Schweizer Recht anwendbar,
      unter Ausschluss der kollisionsrechtlichen Bestimmungen.
    </Text>,
  ],
  [
    <Text key={'7.2'}>7.2.</Text>,
    <Text key={'7.2.d'}>
      Ausschliesslicher Gerichtsstand ist, soweit gesetzlich zulässig, am Sitz
      der Betreiberin.
    </Text>,
  ],
  [headerText('8.'), headerText('Schlussbestimmungen')],
  [
    <Text key={'8.1'}>8.1.</Text>,
    <Text key={'8.1.d'}>
      Salvatorische Klausel: Sollten einzelne oder mehrere Bestimmungen dieser
      NB ganz oder teilweise unwirksam oder nicht durchsetzbar sein oder werden,
      wird die Wirksamkeit oder Durchsetzbarkeit der übrigen Bestimmungen dieser
      NB davon nicht berührt. Eine mangelhafte Bestimmung ist durch eine
      wirksame und durchsetzbare Bestimmung zu ersetzen, die dem Zweck der zu
      ersetzenden Bestimmung am nächsten kommt. Dasselbe gilt sinngemäss für den
      Fall, dass diese NB eine Regelungslücke aufweisen sollten.
    </Text>,
  ],
  [
    <Text key={'8.2'}>8.2.</Text>,
    <Text key={'8.2.d'}>
      Diese NB gelten in der zum Zeitpunkt der Angebotsnutzung jeweils aktuellen
      Fassung. Die Betreiberin kann die NB jederzeit ändern. Die Änderungen
      treten mit ihrer Aufschaltung in Kraft.
    </Text>,
  ],
  [
    <Text key={'8.3'}>8.3.</Text>,
    <Text key={'8.3.d'}>
      Disclaimer: Die digitalen Angebote verweisen unter Umständen über
      Verlinkungen auf die Websites Dritter. Auf die Richtigkeit und
      Rechtmässigkeit von deren Inhalten hat die Betreiberin keinen Einfluss und
      lehnt deshalb jede Verantwortung dafür ab.
    </Text>,
  ],
];

export function Nutzungsbedingungen() {
  return (
    <>
      <Header canLogout={false} />
      <Container fluid maw={1000}>
        <Flex
          direction="column"
          gap={20}
          py={80}
          style={{ overflowWrap: 'anywhere' }}
        >
          <Text size="50px" fw="500">
            Nutzungsbedingungen (NB)
          </Text>
          <table>
            <thead>
              <tr>
                <td></td>
                <td></td>
              </tr>
              {text.map(([enumeration, paragraph], index) => (
                <tr key={index}>
                  <td className={classes.tableColumn1}>{enumeration}</td>
                  <td>{paragraph}</td>
                </tr>
              ))}
            </thead>
          </table>
          <Text>NB-Version 1.1</Text>
        </Flex>
      </Container>
    </>
  );
}
