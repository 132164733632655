import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../../util/setupMobX';
import type {
  CannabisConsumptionQuestionnaireResult,
  SF12QuestionnaireResult,
} from '@web/common';
import { cuditEvaluation, sf12Evaluation } from '@web/common';
import { recordAnonymousQuestionnaireResult } from '../../request/authenticated-requests/questionnaire';
import { DateTime } from 'luxon';
import { AnonymousQuestionnaireEvaluationRecordType } from '@prisma/client';
import { showErrorNotification } from '../../component/notification';

export enum DialogContentType {
  CUDIT,
  SF12Physical,
  SF12Mental,
}
export class QuestionnaireEvaluationStore {
  @observable public showDialog: boolean = false;
  @observable public dialogContentType: DialogContentType | null = null;

  constructor() {
    makeObservable(this);
  }

  @action public closeDialog() {
    runInAction(() => {
      this.showDialog = false;
    });
  }

  @action public evaluateCuditQuestionnaire(
    result: CannabisConsumptionQuestionnaireResult,
  ) {
    const evaluation = cuditEvaluation(result);
    recordAnonymousQuestionnaireResult({
      date: DateTime.now().toJSDate(),
      evaluation: {
        type: AnonymousQuestionnaireEvaluationRecordType.CUDIT,
        cuditEvaluation: evaluation.shouldVisitDoctor,
      },
      reoccurrence: 0,
    })
      .then((result) => {
        runInAction(() => {
          const showDialog = result.cuditEvaluation ?? false;
          if (showDialog) {
            this.dialogContentType = DialogContentType.CUDIT;
            this.showDialog = showDialog;
          }
        });
      })
      .catch((error) => {
        showErrorNotification();
        runInAction(() => {
          this.showDialog = false;
        });
      });
  }

  @action public evaluateSf12Questionnaire(result: SF12QuestionnaireResult) {
    const evaluation = sf12Evaluation(result);
    recordAnonymousQuestionnaireResult({
      date: DateTime.now().toJSDate(),
      evaluation: {
        type: AnonymousQuestionnaireEvaluationRecordType.SF12,
        sf12PhysicalEvaluation: evaluation.physical,
        sf12MentalEvaluation: evaluation.mental,
      },
      reoccurrence: 0,
    })
      .then((result) => {
        runInAction(() => {
          const showDialog =
            (result.sf12PhysicalEvaluation || result.sf12MentalEvaluation) ??
            false;
          if (showDialog) {
            this.dialogContentType = result.sf12PhysicalEvaluation
              ? DialogContentType.SF12Physical
              : DialogContentType.SF12Mental;
            this.showDialog = showDialog;
          }
        });
      })
      .catch(() => {
        showErrorNotification();
        runInAction(() => {
          this.showDialog = false;
        });
      });
  }
}

const { provider, useStore } = setupMobX<QuestionnaireEvaluationStore>();
export const useQuestionnaireEvaluationStore = useStore;
export const QuestionnaireEvaluationStoreProvider = provider;
