import { Card } from '../../../../../../component';
import { Flex, Text } from '@mantine/core';
import { AvailableMission } from '@web/common';
import { useTranslation } from 'react-i18next';
import { ObtainablePoints } from '../../../../../../component/ObtainablePoints';

export const MissionItem = ({ mission }: { mission: AvailableMission }) => {
  const { t } = useTranslation('homescreen');
  return (
    <Card size="small">
      <Flex gap="30px" justify="space-between">
        <Text td={mission.completed ? 'line-through' : undefined}>
          {t(mission.type)}
        </Text>
        <ObtainablePoints points={mission.points} />
      </Flex>
    </Card>
  );
};
