import { CannabisConsumptionQuestionnaireQuestionKey } from '../dto/cannabis-consumption-questionnaire/cannabis-consumption-questionnaire.req.dto';
import { requiredAnswer } from './requiredAnswer';

type TranslationFunction = {
  (key: string): string;
};

export const howOftenDoYouConsumeCannabisQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS,
    title: t('HowOftenDoYouConsume.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('HowOftenDoYouConsume.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('HowOftenDoYouConsume.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'two-to-four-times-a-month',
        text: t('HowOftenDoYouConsume.TwoToFourTimesAMonth'),
      },
      {
        calcValue: 3,
        value: 'two-to-three-times-a-week',
        text: t('HowOftenDoYouConsume.TwoToThreeTimesAWeek'),
      },
      {
        calcValue: 4,
        value: 'four-or-more-times-a-week',
        text: t('HowOftenDoYouConsume.FourOrMoreTimesAWeek'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const howManyHoursWhereYouHighQuestion = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_HOURS_WERE_YOU_HIGH,
    title: t('HowManyHoursWhereYouHigh.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'less-than-once',
        text: t('HowManyHoursWhereYouHigh.LessThanOneHour'),
      },
      {
        calcValue: 1,
        value: 'one-to-two-times',
        text: t('HowManyHoursWhereYouHigh.OneToTwoHours'),
      },
      {
        calcValue: 2,
        value: 'three-to-four-times',
        text: t('HowManyHoursWhereYouHigh.TwoToThreeHours'),
      },
      {
        calcValue: 3,
        value: 'five-to-six-times',
        text: t('HowManyHoursWhereYouHigh.ThreeToFourHours'),
      },
      {
        calcValue: 4,
        value: 'seven-times-or-more',
        text: t('HowManyHoursWhereYouHigh.FourOrMoreHours'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const howOftenCouldYouNotStopSmokingQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_COULD_YOU_NOT_STOP_SMOKING,
    title: t('HowOftenCouldYouNotStopSmoking.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('HowOftenCouldYouNotStopSmoking.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('HowOftenCouldYouNotStopSmoking.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'monthly',
        text: t('HowOftenCouldYouNotStopSmoking.Monthly'),
      },
      {
        calcValue: 3,
        value: 'weekly',
        text: t('HowOftenCouldYouNotStopSmoking.Weekly'),
      },
      {
        calcValue: 4,
        value: 'daily-or-almost-daily',
        text: t('HowOftenCouldYouNotStopSmoking.DailyOrAlmostDaily'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const missedExpectationsQuestion = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.FAILED_TO_MEET_EXPECTATIONS,
    title: t('MissedExpectations.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('MissedExpectations.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('MissedExpectations.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'monthly',
        text: t('MissedExpectations.Monthly'),
      },
      {
        calcValue: 3,
        value: 'weekly',
        text: t('MissedExpectations.Weekly'),
      },
      {
        calcValue: 4,
        value: 'daily-or-almost-daily',
        text: t('MissedExpectations.DailyOrAlmostDaily'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const organizingCannabisConsumptionQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.ORGANIZING_CANNABIS_CONSUMPTION,
    title: t('OrganizingCannabisConsumption.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('OrganizingCannabisConsumption.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('OrganizingCannabisConsumption.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'monthly',
        text: t('OrganizingCannabisConsumption.Monthly'),
      },
      {
        calcValue: 3,
        value: 'weekly',
        text: t('OrganizingCannabisConsumption.Weekly'),
      },
      {
        calcValue: 4,
        value: 'daily-or-almost-daily',
        text: t('OrganizingCannabisConsumption.DailyOrAlmostDaily'),
      },
    ],
    ...requiredAnswer(t),
  };
};
export const memoryOrConcentrationProblemsQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.MEMORY_OR_CONCENTRATION_PROBLEMS,
    title: t('MemoryOrConcentrationProblems.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('MemoryOrConcentrationProblems.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('MemoryOrConcentrationProblems.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'monthly',
        text: t('MemoryOrConcentrationProblems.Monthly'),
      },
      {
        calcValue: 3,
        value: 'weekly',
        text: t('MemoryOrConcentrationProblems.Weekly'),
      },
      {
        calcValue: 4,
        value: 'daily-or-almost-daily',
        text: t('MemoryOrConcentrationProblems.DailyOrAlmostDaily'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const consumptionInDangerousSituationQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.CONSUMPTION_IN_DANGEROUS_SITUATIONS,
    title: t('ConsumptionInDangerousSituations.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('ConsumptionInDangerousSituations.Never'),
      },
      {
        calcValue: 1,
        value: 'less-than-monthly',
        text: t('ConsumptionInDangerousSituations.LessThanMonthly'),
      },
      {
        calcValue: 2,
        value: 'monthly',
        text: t('ConsumptionInDangerousSituations.Monthly'),
      },
      {
        calcValue: 3,
        value: 'weekly',
        text: t('ConsumptionInDangerousSituations.Weekly'),
      },
      {
        calcValue: 4,
        value: 'daily-or-almost-daily',
        text: t('ConsumptionInDangerousSituations.DailyOrAlmostDaily'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const thoughtAboutStoppingConsumption = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.THOUGHT_ABOUT_STOPPING_CONSUMPTION,
    title: t('ThoughtAboutStoppingConsumption.Title'),
    type: 'radiogroup',
    choices: [
      {
        calcValue: 0,
        value: 'never',
        text: t('ThoughtAboutStoppingConsumption.Never'),
      },
      {
        calcValue: 2,
        value: 'yes-but-not-in-the-last-six-months',
        text: t('ThoughtAboutStoppingConsumption.YesButNotInTheLastSixMonths'),
      },
      {
        calcValue: 4,
        value: 'yes-in-the-last-six-months',
        text: t('ThoughtAboutStoppingConsumption.YesInTheLastSixMonths'),
      },
    ],
    ...requiredAnswer(t),
  };
};

// consumption biography
export const whenDidYouStartSmokingQuestion = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.WHEN_DID_YOU_START_CONSUMING_CANNABIS,
    title: t('WhenDidYouStartSmoking.Title'),
    type: 'comment',
  };
};

export const howManyTimesDidYouConsumeWhatQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_TIMES_DID_YOU_CONSUME_WHAT,
    title: t('HowManyTimesDidYouConsumeWhat.Title'),
    type: 'comment',
  };
};

export const whatCannabisProductsDidYouConsumeQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.WHAT_CANNABIS_PRODUCTS_DID_YOU_CONSUME,
    title: t('WhatCannabisProductsDidYouConsume.Title'),
    type: 'comment',
  };
};

export const howDoYouConsumeCannabisQuestion = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.HOW_DO_YOU_CONSUME_CANNABIS,
    title: t('HowDoYouConsumeCannabis.Title'),
    type: 'comment',
  };
};
export const otherDrugsQuestion = (t: TranslationFunction) => {
  return {
    name: CannabisConsumptionQuestionnaireQuestionKey.OTHER_DRUGS,
    title: t('OtherDrugs.Title'),
    type: 'comment',
  };
};
