// the website path to the self deactivation page
export const selfDeactivationSitePath = 'self-deactivation';

// the website path to the self deactivation verification page
export const selfDeactivationVerificationSitePath = 'verify-self-deactivation';

// the api path to the email verification page
export const selfDeactivationApiUrlPathParts = ['users', 'self-deactivation'];

export const selfDeactivationParam = 'token';
