import { Button, Select } from '@mantine/core';
import { useDispensaryStore } from '../../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from '../BaseModal';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { usePickUpStore } from '../../../../view/authenticated/participant/shop/components/PickUp/PickUpStore';
import { useChangeDispensaryStore } from './ChangeDispensaryStore';

export const ChangeDispensaryModal = observer(
  ({ userId }: { userId: string }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const dispensaryStore = useDispensaryStore();
    const pickupStore = usePickUpStore();
    const changeDispensaryStore = useChangeDispensaryStore();
    const { t } = useTranslation('check-participant');

    useEffect(() => {
      dispensaryStore.loadDispensaries();
    }, [dispensaryStore]);

    useEffect(() => {
      if (pickupStore.dispensary)
        changeDispensaryStore.setSelectedDispensary(pickupStore.dispensary.gln);
    }, [changeDispensaryStore, pickupStore.dispensary]);

    useEffect(() => {
      pickupStore.getUserDispensary(userId);
    }, [pickupStore, userId]);

    function onSubmit() {
      close();
      pickupStore.getUserDispensary(userId);
    }

    return (
      <>
        <Button
          id={'button-change-dispensary'}
          variant="outline"
          onClick={open}
        >
          {t('Buttons.ChangeDispensary')}
        </Button>
        <BaseModal
          opened={opened}
          onClose={close}
          title="Ausgabestelle wechseln"
          description="Wenn du die Ausgabestelle für den Teilnehmer änderst, kann er nur noch von dieser Ausgabestelle Cannabis beziehen."
          children={
            <Select
              key="dispensarieSelection"
              placeholder="Ausgabestelle auswählen"
              label="Ausgabestelle"
              required
              data={dispensaryStore.dispensaries?.map((dispensary) => ({
                label: `${dispensary.name} (${dispensary.associatedUserCount})`,
                value: dispensary.gln,
              }))}
              onChange={(value) =>
                changeDispensaryStore.setSelectedDispensary(value)
              }
              value={changeDispensaryStore.selectedDispensary}
            />
          }
          buttons={[
            <Button key="cancel" variant="outline" onClick={close}>
              {t('Buttons.Cancel')}
            </Button>,
            <Button
              key="submit"
              disabled={changeDispensaryStore.selectedDispensary === null}
              onClick={() =>
                changeDispensaryStore.updateDispensary(userId, onSubmit)
              }
            >
              {t('Buttons.ChangeDispensary')}
            </Button>,
          ]}
        />
      </>
    );
  },
);
