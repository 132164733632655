import { ActionIcon, Flex } from '@mantine/core';
import { MyIcon } from '../../../../../component';
import { observer } from 'mobx-react';
import { AudioControlsSettings } from './AudioControlsSettings';

export const AudioControls = observer(
  ({
    toggleAudio,
    forward,
    replay,
    currentTime,
    duration,
    paused,
    setPlaybackRate,
  }: {
    toggleAudio: () => void;
    forward: () => void;
    replay: () => void;
    setPlaybackRate: (speed: number) => void;
    currentTime: number;
    duration: number;
    paused: boolean;
  }) => {
    return (
      <Flex p="20px 0px 0px 0px" justify="space-between" align="center">
        <Flex gap="20px" justify="space-between" align="center">
          <ActionIcon
            variant="transparent"
            radius="xl"
            onClick={replay}
            disabled={currentTime === 0}
          >
            <MyIcon name="replay" style={{ fill: '#3D7F53' }} />
          </ActionIcon>
          <ActionIcon size="xl" radius="xl" onClick={toggleAudio}>
            <MyIcon name={paused ? 'play' : 'pause'} style={{ fill: '#fff' }} />
          </ActionIcon>
          <ActionIcon
            variant="transparent"
            radius="xl"
            onClick={forward}
            disabled={currentTime >= duration}
          >
            <MyIcon name="forward" style={{ fill: '#3D7F53' }} />
          </ActionIcon>
        </Flex>
        <AudioControlsSettings setPlaybackRate={setPlaybackRate} />
      </Flex>
    );
  },
);
