import { dateTimeTransformer } from '../../lib/util/date-time-transformer';
import { RefinementCtx, z } from 'zod';

// "6,25" → 6.25
// "6.25" → 6.25
// "1.000,50" → 1000.50
// "1,000.50" → 1000.50
export const customNumber = z
  .string()
  .transform((data: string, ctx: RefinementCtx) => {
    // If only dot is present, keep it as is
    let cleaned = data;

    if (data.includes(',')) {
      // If comma is present, remove the dot (assumed as thousands separator) and convert comma to dot
      cleaned = data.replace(/\./g, '').replace(/,/g, '.');
    }

    const number = parseFloat(cleaned);
    if (isNaN(number) || !isFinite(number)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid number ${data}`,
      });
    }
    return number;
  });

const data = z.object({
  key: z.string(),
  handovers_count: z.number(),
  left_current_month_thc: customNumber,
  status: z.string(),
  created_at: z.string().transform(dateTimeTransformer(`dd.MM.yyyy HH:mm`)),
  status_change_reason_codes: z.nullable(z.unknown()),
  survey_date: z.nullable(z.unknown()),
});
export type CannavigiaUserData = z.infer<typeof data>;

const itemData = z.object({
  participant_id: z.string(),
  handover_id: z.number(),
  created_at: z.string().transform(dateTimeTransformer(`yyyy-MM-dd, HH:mm`)),
  dispensary_name: z.string(),
  dispensary_type: z.string(),
  gln: z.string(),
  product_name: z.string(),
  gtin: z.string(),
  batch_code: z.string(),
  sales_unit: z.string(),
  thc: customNumber,
  labeled_thc: customNumber,
  packaging_number: z.string(),
});
export type HandoverItemSchema = z.infer<typeof itemData>;

const meta = z.object({
  current_page: z.number(),
  first_page_url: z.string().url(),
  from: z.number().nullable(),
  last_page: z.number(),
  last_page_url: z.string().url(),
  links: z.array(
    z.object({
      url: z.string().nullable(),
      label: z.string(),
      active: z.boolean(),
    }),
  ),
  next_page_url: z.string().url().nullable(),
  per_page: z.number(),
  prev_page_url: z.string().url().nullable(),
  to: z.number().nullable(),
  total: z.number(),
});
export type CannavigiaDataPagedMeta = z.infer<typeof meta>;

export const participantHandoverResDto = z.object({
  data: data,
  items: z.object({
    data: z.array(itemData),
    meta: meta,
  }),
});

export type ParticipantHandoverResDto = z.infer<
  typeof participantHandoverResDto
>;
