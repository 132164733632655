import bluete from '../../../assets/faq/bluete.jpg';
import extrakt from '../../../assets/faq/extrakt.jpg';
import vape from '../../../assets/faq/vape.jpg';
import harz from '../../../assets/faq/harz.jpg';
import { Flex } from '@mantine/core';

interface StudyContent {
  value: string;
  description: string | JSX.Element;
}

interface StudySection {
  title: string;
  content: StudyContent[];
}

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

const ns = 'FaqContent.';

const getFullFaq = (t: TranslationFunction): StudySection[] => {
  return [
    {
      title: t(`${ns}Section1.Title`),
      content: [
        {
          value: t(`${ns}Section1.Contents1.Title`),
          description: (
            <span>
              {t(`${ns}Section1.Contents1.Content.Description`)}
              <ul>
                {(
                  t(`${ns}Section1.Contents1.Content.Points`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </span>
          ),
        },
        {
          value: t(`${ns}Section1.Contents2.Title`),
          description: (
            <span>
              {t(`${ns}Section1.Contents2.Content.Description1`)}
              <ul>
                {(
                  t(`${ns}Section1.Contents2.Content.Points`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
              {t(`${ns}Section1.Contents2.Content.Description2`)}
              <br /> <br />
              {t(`${ns}Section1.Contents2.Content.Description3`)}
            </span>
          ),
        },
        {
          value: t(`${ns}Section1.Contents3.Title`),
          description: (
            <span>
              {t(`${ns}Section1.Contents3.Content.Description1`)}
              <br />
              <br />
              {t(`${ns}Section1.Contents3.Content.Description2`)}
            </span>
          ),
        },
        {
          value: t(`${ns}Section1.Contents4.Title`),
          description: (
            <span>
              {t(`${ns}Section1.Contents4.Content.Description1`)}
              <strong>
                {' '}
                {t(`${ns}Section1.Contents4.Content.Description2`)}{' '}
              </strong>
              {t(`${ns}Section1.Contents4.Content.Description3`)}
              <br /> <br />
              {t(`${ns}Section1.Contents4.Content.Description4`)}
              <br /> <br />
              {t(`${ns}Section1.Contents4.Content.Description5`)}
              <strong>
                {' '}
                {t(`${ns}Section1.Contents4.Content.Description6`)}
              </strong>
              .
            </span>
          ),
        },
        {
          value: t(`${ns}Section1.Contents5.Title`),
          description: (
            <div>
              <p>{t(`${ns}Section1.Contents5.Content.Description1`)}</p>
              <ul>
                {(
                  t(`${ns}Section1.Contents5.Content.Points1`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
              <p>{t(`${ns}Section1.Contents5.Content.Description2`)}</p>
              <ul>
                {(
                  t(`${ns}Section1.Contents5.Content.Points2`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents6.Title`),
          description: (
            <div id="teilnehmende gemeinden">
              {/* // id has to stay the same! */}
              <p>{t(`${ns}Section1.Contents6.Content.Description`)}</p>
              <ul>
                {(
                  t(`${ns}Section1.Contents6.Content.Points`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents7.Title`),
          description: (
            <div id="teilnahme an mehreren pilotversuchen">
              {t(`${ns}Section1.Contents7.Content.Description`)}
            </div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents8.Title`),
          description: (
            <div>
              {t(`${ns}Section1.Contents8.Content.Description1`)}{' '}
              <strong>{t(`${ns}Section1.Contents8.Content.BoldText1`)}</strong>{' '}
              {t(`${ns}Section1.Contents8.Content.Description2`)}
              <strong> {t(`${ns}Section1.Contents8.Content.BoldText2`)}</strong>
              {t(`${ns}Section1.Contents8.Content.Description3`)}
            </div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents9.Title`),
          description: (
            <div>
              {t(`${ns}Section1.Contents9.Content.Description1`)}{' '}
              <a href="https://www.swisscannabis-research.ch">
                www.swisscannabis-research.ch
              </a>
              <br /> {t(`${ns}Section1.Contents9.Content.Description2`)}{' '}
              <a href="mailto:paul.good@cannabis-research.ch">
                paul.good@cannabis-research.ch
              </a>
            </div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents10.Title`),
          description: (
            <div>{t(`${ns}Section1.Contents10.Content.Description`)}</div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents11.Title`),
          description: (
            <div>{t(`${ns}Section1.Contents11.Content.Description`)}</div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents12.Title`),
          description: (
            <div>{t(`${ns}Section1.Contents12.Content.Description`)}</div>
          ),
        },
        {
          value: t(`${ns}Section1.Contents13.Title`),
          description: (
            <div>{t(`${ns}Section1.Contents13.Content.Description`)}</div>
          ),
        },
      ],
    },
    {
      title: t(`${ns}Section2.Title`),
      content: [
        {
          value: t(`${ns}Section2.Contents1.Title`),
          description: (
            <div id="studienanmeldung">
              {t(`${ns}Section2.Contents1.Content.Description`)}
            </div>
          ),
        },
        {
          value: t(`${ns}Section2.Contents2.Title`),
          description: (
            <span id="aufnahmegespräch">
              {t(`${ns}Section2.Contents2.Content.Description`)}
            </span>
          ),
        },
        {
          value: t(`${ns}Section2.Contents3.Title`),
          description: (
            <span id="aufnahmefragebogen">
              {t(`${ns}Section2.Contents3.Content.Description`)}
            </span>
          ),
        },
        {
          value: t(`${ns}Section2.Contents4.Title`),
          description: (
            <div id="zufällige gruppenzuteilung">
              {t(`${ns}Section2.Contents4.Content.Description1`)}
              <br />
              <br />
              {t(`${ns}Section2.Contents4.Content.Description2`)}
              <br />
              <br />
              {t(`${ns}Section2.Contents4.Content.Description3`)}
              <br />
              <br />
              {t(`${ns}Section2.Contents4.Content.Description4`)}
            </div>
          ),
        },
        {
          value: t(`${ns}Section2.Contents5.Title`),
          description: (
            <span id="verkaufsstart">
              {t(`${ns}Section2.Contents5.Content.Description`)}
            </span>
          ),
        },
        {
          value: t(`${ns}Section2.Contents6.Title`),
          description: (
            <span id="online-umfragen">
              {t(`${ns}Section2.Contents6.Content.Description1`)}
              <br /> <br />
              {t(`${ns}Section2.Contents6.Content.Description2`)}
            </span>
          ),
        },
      ],
    },
    {
      title: t(`${ns}Section3.Title`),
      content: [
        {
          value: t(`${ns}Section3.Contents1.Title`),
          description: (
            <span>
              {t(`${ns}Section3.Contents1.Content.Description`)}
              <br />
              <ul>
                {(
                  t(`${ns}Section3.Contents1.Content.Points`, {
                    returnObjects: true,
                  }) as string[]
                ).map((point: string, index: number) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </span>
          ),
        },
        {
          value: t(`${ns}Section3.Contents2.Title`),
          description: (
            <div id="produkte">
              <p>{t(`${ns}Section3.Contents2.Content.Description1`)}</p>

              <strong>{t(`${ns}Section3.Contents2.Content.Flowers`)}</strong>
              <Flex align="center" justify="center" wrap="wrap">
                <img src={bluete} alt="Blüte" style={{ width: 350 }} />

                <ul style={{ flex: 1, minWidth: 400, wordBreak: 'break-word' }}>
                  <li>
                    Wedding Cake | 20% THC |&lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 12 / Gramm
                  </li>
                  <li>
                    Amnesia Haze | 20% THC | &lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Alpina
                    Pharma AG, CHF 12 / Gramm
                  </li>
                  <li>
                    Royal Feast | 15% THC |&lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 11 / Gramm
                  </li>
                  <li>
                    Bubba Kush| 15% THC |&lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Alpina
                    Pharma AG, CHF 11 / Gramm
                  </li>
                  <li>
                    Apricot Mimosa | 9% THC | 8% CBD|{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 8 / Gramm
                  </li>
                  <li>
                    Ciskei | 5% THC | 7.5% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 7 / Gramm
                  </li>
                </ul>
              </Flex>

              <br />

              <strong>{t(`${ns}Section3.Contents2.Content.Hash`)}</strong>
              <Flex align="center" justify="center" wrap="wrap">
                <img src={harz} alt="Harz" style={{ width: 350 }} />
                <ul style={{ flex: 1, minWidth: 400, wordBreak: 'break-word' }}>
                  <li>
                    Jura Gold | 20% THC | &lt;5% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 12 / Gramm
                  </li>
                  <li>
                    Grand Marais | 15% THC | 10% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 10 / Gramm
                  </li>
                </ul>
              </Flex>
              <br />

              <strong>{t(`${ns}Section3.Contents2.Content.Vape`)}</strong>
              <Flex align="center" justify="center">
                <img src={vape} alt="Vape" style={{ width: 350 }} />
                <ul style={{ flex: 1, minWidth: 400, wordBreak: 'break-word' }}>
                  <li>
                    Fumo d’Oro | 20% THC |&lt;2% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 40 / ml
                  </li>
                  <li>
                    Fumo d’Oro | 20% THC | 20% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 40 / ml
                  </li>
                  <li>
                    Gelato | 20% THC |&lt;2% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Charlie’s
                    Lab GmbH, CHF 40 / ml
                  </li>
                  <li>
                    Zkittlez | 20% THC | 20% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Charlie’s
                    Lab GmbH, CHF 40 / ml
                  </li>
                </ul>
              </Flex>
              <br />

              <strong>{t(`${ns}Section3.Contents2.Content.Oil`)}</strong>
              <Flex align="center" justify="center" wrap="wrap">
                <img src={extrakt} alt="Extrakt" style={{ width: 350 }} />
                <ul style={{ flex: 1, minWidth: 400, wordBreak: 'break-word' }}>
                  <li>
                    Jail Bird |10% THC | 5% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 149.90 / 10 ml
                  </li>
                  <li>
                    Jail Bird | 5% THC | &lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)}{' '}
                    Swissextract AG, CHF 75 / 10 ml
                  </li>
                  <li>
                    Greenbird |10% THC | 5% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Alpina
                    Pharma AG, CHF 149.90 / 10 ml
                  </li>
                  <li>
                    Greenbird | 5% THC | &lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Alpina
                    Pharma AG, CHF 75 / 10 ml
                  </li>
                  <li>
                    Cannabis Blend |10% THC | 5% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Charlie’s
                    Lab GmbH, CHF 149.90 / 10 ml
                  </li>
                  <li>
                    Cannabis Blend | 5% THC | &lt;1% CBD |{' '}
                    {t(`${ns}Section3.Contents2.Content.ProducedBy`)} Charlie’s
                    Lab GmbH, CHF 75 / 10 ml
                  </li>
                </ul>
              </Flex>
            </div>
          ),
        },
      ],
    },
  ];
};

export default { getFullFaq };
