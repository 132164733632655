import { useTranslation } from 'react-i18next';
import { Banner } from '../../../../../component';

export const LimitBanner = () => {
  const { t } = useTranslation('shop');
  return (
    <Banner
      padding="15px 15px 0px 15px"
      maxWidth="auto"
      title={t('LimitBanner.Title')}
      description={t('LimitBanner.Description')}
    />
  );
};
