import {
  AusweisTypEnum,
  CivilStatusEnum,
  CurrentLivingSituationEnum,
  OtherNationalityEnum,
  PassportTypeEnum,
  requiredAnswer,
  SexEnum,
  stammdatenFieldLength1,
  stammdatenFieldLength2,
  stammdatenFieldLength3,
  stammdatenFieldLength4,
  stammdatenPLSMinMax,
  StammdatenQuestionnaireQuestionKey,
} from '@web/common';
import classes from '../../../public/entry-questionnaire/IntroBox.module.css';
import { renderToString } from 'react-dom/server';
import stammdaten from '../../../../assets/stammdaten.svg';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

const introComponent = (t: TranslationFunction) => {
  return (
    <div className={classes.flexBox}>
      <img
        src={stammdaten}
        alt="Stammdaten"
        style={{ flex: '0 10%', justifySelf: 'center' }}
      />
      <div style={{ flex: '1', minWidth: 300 }}>
        <span className={classes.header}>{t('Header.Title')}</span>
        <p className={classes.firstParagraph}>{t('Header.Description1')}</p>
        <p className={classes.secondParagraph}>{t('Header.Description2')}</p>
      </div>
    </div>
  );
};

const getIntro = (t: TranslationFunction) => {
  return {
    name: 'intro',
    type: 'html',
    html: renderToString(introComponent(t)),
  };
};

const getSurnameQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Surname,
    title: t('Surname'),
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
    ],
    ...requiredAnswer(t),
  };
};

const getNameQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Name,
    title: t('Name.Label'),
    description: t('Name.Description'),
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
    ],
    ...requiredAnswer(t),
  };
};

const getAddressQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Address,
    title: t('Adress'),
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength4 },
    ],
    ...requiredAnswer(t),
  };
};

const getPlzQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.PLZ,
    title: t('PLZ'),
    type: 'text',
    validators: [
      {
        type: 'numeric',
        minValue: stammdatenPLSMinMax[0],
        maxValue: stammdatenPLSMinMax[1],
      },
    ],
    ...requiredAnswer(t),
  };
};

const getOrtQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Ort,
    title: t('Location'),
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength3 },
    ],
    ...requiredAnswer(t),
  };
};

const getBirthdateQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.BirthDate,
    title: t('Birthday'),
    type: 'text',
    inputType: 'date',
    ...requiredAnswer(t),
  };
};

const getAhvNumberQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.AHVNumber,
    title: t('AHV-Number.Label'),
    description: t('AHV-Number.Description'),
    type: 'text',
    validators: [
      {
        type: 'regex',
        regex: /^\d{13}$/,
        text: t('AHV-Number.ValidatorText'),
      },
    ],
    maxLength: 13,
    ...requiredAnswer(t),
  };
};

export const getSexQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Sex,
    title: t('Gender.Label'),
    type: 'radiogroup',
    choices: [
      {
        value: SexEnum.Male,
        text: t('Gender.Male'),
      },
      {
        value: SexEnum.Female,
        text: t('Gender.Female'),
      },
      {
        value: SexEnum.Divers,
        text: t('Gender.Diverse'),
      },
    ],
    ...requiredAnswer(t),
  };
};

const getBirtPlaceInitialQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.BirthPlaceAbroad,
    title: t('BirthPlaceAbroad.Label'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getBirthPlaceSpecificQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.BirthPlace,
    title: t('BirthPlace.Label'),
    type: 'multipletext',
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.BirthPlaceAbroad}} = false`,
    items: [
      {
        name: StammdatenQuestionnaireQuestionKey.BirthPlaceMunicipality,
        title: t('BirthPlace.Municipality'),
        inputType: 'text',
        validators: [
          { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
        ],
        ...requiredAnswer(t),
      },
      {
        name: StammdatenQuestionnaireQuestionKey.BirthPlaceCanton,
        title: t('BirthPlace.Canton'),
        inputType: 'text',
        validators: [
          { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
        ],
        ...requiredAnswer(t),
      },
    ],
  };
};

const getSwissNationalityQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.SwissNationality,
    title: t('SwissNationality.Label'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getOtherNationalityQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.OtherNationality,
    title: t('OtherNationality.Label'),
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.SwissNationality}} = false`,
    type: 'radiogroup',
    choices: [
      {
        value: OtherNationalityEnum.Italy,
        text: t('OtherNationality.Italy'),
      },
      {
        value: OtherNationalityEnum.Germany,
        text: t('OtherNationality.Germany'),
      },
      {
        value: OtherNationalityEnum.Portugal,
        text: t('OtherNationality.Portugal'),
      },
      {
        value: OtherNationalityEnum.France,
        text: t('OtherNationality.France'),
      },
      {
        value: OtherNationalityEnum.Kosovo,
        text: t('OtherNationality.Kosovo'),
      },
      {
        value: OtherNationalityEnum.Spain,
        text: t('OtherNationality.Spain'),
      },
      {
        value: OtherNationalityEnum.Turkey,
        text: t('OtherNationality.Turkey'),
      },
      {
        value: OtherNationalityEnum.Serbia,
        text: t('OtherNationality.Serbia'),
      },
    ],
    hasOther: true,
    ...requiredAnswer(t),
  };
};

const getGeburtsOrtQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.GeburtsOrt,
    title: t('BirthPlaceID.Title'),
    description: t('BirthPlaceID.Description'),
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.SwissNationality}} = false`,
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength3 },
    ],
    ...requiredAnswer(t),
  };
};

const getPassportTypeQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.PassportType,
    title: t('PassportType.Title'),
    description: t('PassportType.Description'),
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.SwissNationality}} = false`,
    type: 'radiogroup',
    choices: [
      {
        value: PassportTypeEnum.PassportC,
        text: t('PassportType.PassportC'),
      },
      {
        value: PassportTypeEnum.PassportB,
        text: t('PassportType.PassportB'),
      },
      {
        value: PassportTypeEnum.PassportA,
        text: t('PassportType.PassportA'),
      },
      {
        value: PassportTypeEnum.OtherPassport,
        text: t('PassportType.otherPassport'),
      },
    ],
    ...requiredAnswer(t),
  };
};

const getHeimatOrtQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.HeimatOrt,
    title: t('HeimatOrt.Title'),
    description: t('HeimatOrt.Description'),
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.SwissNationality}} = true`,
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength1 },
    ],
    ...requiredAnswer(t),
  };
};

const getAusweisTypQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.AusweisType,
    title: t('AusweisType.Title'),
    description: t('AusweisType.Description'),
    visibleIf: `{${StammdatenQuestionnaireQuestionKey.SwissNationality}} = true`,
    type: 'radiogroup',
    choices: [
      {
        value: AusweisTypEnum.Ausweis,
        text: t('AusweisType.Ausweis'),
      },
      {
        value: AusweisTypEnum.Reisepass,
        text: t('AusweisType.Reisepass'),
      },
    ],
    ...requiredAnswer(t),
  };
};

const getAusweisNrQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.AusweisNr,
    title: t('AusweisNr.Title'),
    description: t('AusweisNr.Description'),
    type: 'text',
    validators: [
      { type: 'text', minLength: 1, maxLength: stammdatenFieldLength1 },
    ],
    ...requiredAnswer(t),
  };
};

const getCurrentLivingSituationQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.CurrentLivingSituation,
    title: t('CurrentLivingSituation.Title'),
    type: 'radiogroup',
    choices: [
      {
        value: CurrentLivingSituationEnum.OnePersonHousehold,
        text: t('CurrentLivingSituation.OnePersonHousehold'),
      },
      {
        value: CurrentLivingSituationEnum.PairWithoutChildren,
        text: t('CurrentLivingSituation.PairWithoutChildren'),
      },
      {
        value: CurrentLivingSituationEnum.PairWithChildren,
        text: t('CurrentLivingSituation.PairWithChildren'),
      },
      {
        value: CurrentLivingSituationEnum.OneParentWithChildrenBelow18,
        text: t('CurrentLivingSituation.OneParentWithChildrenBelow18'),
      },
      {
        value: CurrentLivingSituationEnum.OtherHouseholdWithMorePersons,
        text: t('CurrentLivingSituation.OtherHouseholdWithMorePersons'),
      },
    ],
    ...requiredAnswer(t),
  };
};

const getCivilStatusQuestion = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.CivilStatus,
    title: t('CivilStatus.Title'),
    type: 'radiogroup',
    choices: [
      {
        value: CivilStatusEnum.Single,
        text: t('CivilStatus.Single'),
      },
      {
        value: CivilStatusEnum.Married,
        text: t('CivilStatus.Married'),
      },
      {
        value: CivilStatusEnum.Widowed,
        text: t('CivilStatus.Widowed'),
      },
      {
        value: CivilStatusEnum.Divorced,
        text: t('CivilStatus.Divorced'),
      },
      {
        value: CivilStatusEnum.RegisteredPartnership,
        text: t('CivilStatus.RegisteredPartnership'),
      },
      {
        value: CivilStatusEnum.DissolvedPartnership,
        text: t('CivilStatus.DissolvedPartnership'),
      },
    ],
    ...requiredAnswer(t),
  };
};

// clustering questions
const getClusterQuestionInitial = (t: TranslationFunction) => {
  return {
    name: StammdatenQuestionnaireQuestionKey.Clustering,
    title: t('ClusterQuestion.Title'),
    description: t('ClusterQuestion.Description'),
    addRowText: t('ClusterQuestion.AddRow'),
    type: 'matrixdynamic',
    rowCount: 1,
    maxRowCount: 10,
    choices: [t('ClusterQuestion.Surname'), t('ClusterQuestion.Name')],
    columns: [
      {
        name: StammdatenQuestionnaireQuestionKey.ClusteringName,
        title: t('ClusterQuestion.Name'),
        cellType: 'text',
        validators: [
          { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
        ],
      },
      {
        name: StammdatenQuestionnaireQuestionKey.ClusteringSurname,
        title: t('ClusterQuestion.Surname'),
        cellType: 'text',
        validators: [
          { type: 'text', minLength: 1, maxLength: stammdatenFieldLength2 },
        ],
      },
    ],
  };
};

export function getStammdatenQuestionnaireSurveyJSON(
  t: TranslationFunction,
  birthday?: string,
) {
  return {
    checkErrorsMode: 'onValueChanged',
    pages: [
      {
        elements: [
          getIntro(t),
          getSurnameQuestion(t),
          getNameQuestion(t),
          getAddressQuestion(t),
          getPlzQuestion(t),
          getOrtQuestion(t),

          birthday
            ? {
                ...getBirthdateQuestion(t),
                defaultValue: birthday,
              }
            : getBirthdateQuestion(t),
          getAhvNumberQuestion(t),
        ],
      },
      {
        elements: [
          getSexQuestion(t),
          getBirtPlaceInitialQuestion(t),
          getBirthPlaceSpecificQuestion(t),
          getSwissNationalityQuestion(t),
          getOtherNationalityQuestion(t),
          getGeburtsOrtQuestion(t),
          getPassportTypeQuestion(t),
          getHeimatOrtQuestion(t),
          getAusweisTypQuestion(t),
          getAusweisNrQuestion(t),
          getCurrentLivingSituationQuestion(t),
          getCivilStatusQuestion(t),
        ],
      },
      { elements: [getClusterQuestionInitial(t)] },
    ],
  };
}
