import { AppShell, Container } from '@mantine/core';
import { observer } from 'mobx-react';
import { Header } from '../../public/navigation';
import { useEffect, useState } from 'react';
import { useWindowSize } from '../../../hooks';
import { MobileNavBar } from './NavBar/MobileNavBar';
import { NavBar } from './NavBar';
import { useKeycloakStore } from '../../../store';
import { RealmRole } from '@web/common';
import { ParticipantSection, StudyOrganizerSection } from './NavBar/sections';

function isNavOpen() {
  return window.sessionStorage.getItem('navBarOpen') === 'true';
}

export const BaseLayout = observer(
  ({
    children,
    hideNavbar = false,
  }: {
    children: JSX.Element;
    hideNavbar?: boolean;
  }) => {
    const [navBarOpen, setNavBarOpen] = useState<boolean>(isNavOpen());
    const isSmallScreen = useWindowSize(500);
    const navWidth = hideNavbar ? 0 : navBarOpen ? 300 : 90;
    const baseNavWidth = hideNavbar ? 0 : 90;
    const kcStore = useKeycloakStore();
    const currentRole = kcStore.currentRealmRole;

    const additionalNavItems = (
      <>
        {currentRole === RealmRole.StudyOrganizer && (
          <StudyOrganizerSection navBarOpen={navBarOpen} />
        )}
        {currentRole === RealmRole.Participant && <ParticipantSection />}
      </>
    );

    function chanageNavBarOpen(open: boolean) {
      // this prevents that the users opens the navbar, when there is no space left
      if (!isSmallScreen) setNavBarOpen(open);
    }

    useEffect(() => {
      // automatically sets the navbar to collapsed when the width is unter 750
      if (isSmallScreen) {
        setNavBarOpen(false);
      }
    }, [isSmallScreen]);

    useEffect(() => {
      // gets the inital value from the session storage
      setNavBarOpen(isNavOpen());
    }, []);

    useEffect(() => {
      // when changing the value, it is saved in the session storage
      window.sessionStorage.setItem('navBarOpen', navBarOpen.toString());
    }, [navBarOpen]);

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    }, []);

    return (
      <AppShell
        header={{ height: 70 }}
        navbar={{
          width: {
            base: baseNavWidth,
            sm: navWidth,
            md: navWidth,
            lg: navWidth,
            xl: navWidth,
          },
          breakpoint: 500,
          collapsed: { mobile: true },
        }}
        padding={isSmallScreen ? 0 : 5}
        withBorder={false}
        bg={isSmallScreen ? '#fff' : 'transparent'}
      >
        <AppShell.Header>
          <Header />
        </AppShell.Header>
        <AppShell.Navbar
          p={isSmallScreen ? 0 : '20px 0px 20px 20px'}
          bg="#F1F0E899"
          h="calc(100vh - 3.5em - 70px)" // 3.5em for the footer, 70 for the header
        >
          {!hideNavbar && (
            <NavBar
              setNavBarOpen={chanageNavBarOpen}
              navBarOpen={navBarOpen}
              additionalNavItems={additionalNavItems}
            />
          )}
        </AppShell.Navbar>
        <AppShell.Main mih="calc(100vh - 3.5em)">
          <Container
            fluid
            p={isSmallScreen ? 0 : '15px'}
            maw={hideNavbar ? '800px' : '100%'}
          >
            {children}
          </Container>
        </AppShell.Main>
        {isSmallScreen && !hideNavbar && <MobileNavBar />}
      </AppShell>
    );
  },
);
