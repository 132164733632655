import { observer } from 'mobx-react';
import { ContentWrapper } from './ContentWrapper';
import { DiaryDialogStore, DiaryDialogStoreProvider } from './DiaryDialogStore';

export const DiaryDialog = observer(() => {
  return (
    <DiaryDialogStoreProvider store={new DiaryDialogStore()}>
      <ContentWrapper />
    </DiaryDialogStoreProvider>
  );
});
