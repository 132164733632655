import { z } from 'zod';

export const userRepResDto = z.object({
  id: z.string().uuid(),
  username: z.string(),
  enabled: z.boolean(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
});
export type UserRepResDto = z.infer<typeof userRepResDto>;
