import { Outlet } from 'react-router-dom';
import { Footer } from './navigation';
import { Flex } from '@mantine/core';
import { observer } from 'mobx-react';
import { useKeycloakStore } from '../../store';
import { Loading } from '../../component';
import { Routes } from '../../route/Routes';

const redirectRoutes = [
  Routes.RescheduleAppointment,
  Routes.SimpleOnboardingChecklist,
];

export const Root = observer(() => {
  const kcStore = useKeycloakStore();
  const currentPath = window.location.pathname;

  const isRescheduleOrOnboarding = redirectRoutes.some((route) =>
    currentPath.includes(route),
  );

  // when accessing a perma link while beeing logged out, we redirect to kc first
  // later on we should use this logic for all authenticated routes!
  // TODO: https://quickbird.atlassian.net/browse/SCC-503

  if (isRescheduleOrOnboarding) {
    // we initialize here, so we can get a valid redirect url from kc
    if (!kcStore.initialized && !kcStore.initializingInProgress) {
      kcStore.initKeycloak();
      return <Loading />;
    }

    // use the redirect url from kc to redirect
    if (
      !kcStore.authenticated &&
      kcStore.initialized &&
      !kcStore.initializingInProgress
    ) {
      window.location.href = kcStore.kcRedirectUrl;
      return <Loading />;
    }
  }

  if (kcStore.initializingInProgress) {
    return <Loading />;
  }

  return (
    <Flex direction="column" mih="100vh">
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>

      <Footer />
    </Flex>
  );
});
