import { Divider, Flex } from '@mantine/core';
import { BaseLayout } from '../../../../navigation';
import classes from '../../../../../../sharedStyles/InfoCard.module.css';
import { Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Loading } from '../../../../../../component';
import { OrderDetailHeader } from './OrderDetailHeader';
import { useTranslation } from 'react-i18next';
import { useShopStore } from '../../../../../../store';
import { useEffect } from 'react';
import { OrderDetailContent } from './OrderDetailContent';
import PickUp from '../PickUp/PickUp';

export const OrderDetail = observer(() => {
  const { orderId } = useParams();
  const { t } = useTranslation('shop');
  const shopStore = useShopStore();
  const orders = shopStore.orders;
  const currentOrder = orders?.find((order) =>
    order.id.includes(orderId ?? ''),
  );

  useEffect(() => {
    shopStore.loadOpenOrders();
  }, [shopStore]);

  if (!currentOrder) return <Loading />;

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={30}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('Orderdetails')}
        </Text>
        <OrderDetailHeader order={currentOrder} />
        {currentOrder.userId && <PickUp userId={currentOrder.userId} />}
        <Divider color="#F1F1F1" />
        <OrderDetailContent orderDetails={currentOrder} />
      </Flex>
    </BaseLayout>
  );
});
