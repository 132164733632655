import { Flex, Text } from '@mantine/core';

export const TextWrapper = ({
  title,
  value,
}: {
  title: string;
  value: string | number | undefined | null;
}) => {
  return (
    <Flex justify="space-between">
      <Text size="16px" lh="18px">
        {title}
      </Text>
      <Text fw="500" size="16px" lh="18px" ta="start">
        {value}
      </Text>
    </Flex>
  );
};
