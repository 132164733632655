import { observer } from 'mobx-react';
import { useQuestionnaireEvaluationStore } from '../../../../store';
import { BaseModal } from '../../../../component/userStatus/dialogs';
import { Button } from '@mantine/core';
import { StudienArztin } from './StudienArztin';
import { useTranslation } from 'react-i18next';
import { getDialogContent } from './getDialogContent';

export const QuestionnaireEvaluationModal = observer(() => {
  const store = useQuestionnaireEvaluationStore();
  const { t } = useTranslation('questionnaire-evaluation');

  return (
    <BaseModal
      opened={store.showDialog}
      onClose={() => store.closeDialog()}
      title={t('title')}
      description={getDialogContent(t, store.dialogContentType)}
      buttons={[
        <Button key={1} onClick={() => store.closeDialog()}>
          {t('button')}
        </Button>,
      ]}
    >
      <StudienArztin />
    </BaseModal>
  );
});
