import {
  WorkingDay,
  WorkingHourSchemaDto,
  WorkingHoursSchemaDto,
} from '@web/common';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

function mapDayToString(day: WorkingDay, translationLanguage: string): string {
  const isGerman = translationLanguage === 'de';
  switch (day) {
    case WorkingDay.Sunday:
      return isGerman ? 'So' : 'Sun';
    case WorkingDay.Monday:
      return isGerman ? 'Mo' : 'Mon';
    case WorkingDay.Tuesday:
      return isGerman ? 'Di' : 'Tue';
    case WorkingDay.Wednesday:
      return isGerman ? 'Mi' : 'Wed';
    case WorkingDay.Thursday:
      return isGerman ? 'Do' : 'Thu';
    case WorkingDay.Friday:
      return isGerman ? 'Fr' : 'Fri';
    case WorkingDay.Saturday:
      return isGerman ? 'Sa' : 'Sat';
  }
}

function minutesToDaytime(minutes: number): string {
  const datetime = DateTime.now().startOf('day').plus({ minutes });
  return datetime.toFormat('HH:mm');
}

export const WorkingHours = ({
  workingHours,
}: {
  workingHours: WorkingHoursSchemaDto;
}) => {
  const { i18n } = useTranslation();
  if (!workingHours) return null;

  const workingHour = (workingHour: WorkingHourSchemaDto) => {
    if (workingHour.days.length === 1) {
      const day: WorkingDay = workingHour.days[0];
      return (
        <div>
          {mapDayToString(day, i18n.language)}.{' '}
          {minutesToDaytime(workingHour.startTime)} -{' '}
          {minutesToDaytime(workingHour.endTime)} Uhr
        </div>
      );
    } else {
      const min: WorkingDay = Math.min(...workingHour.days);
      const max: WorkingDay = Math.max(...workingHour.days);
      return (
        <div>
          {mapDayToString(min, i18n.language)}.-
          {mapDayToString(max, i18n.language)}.{' '}
          {minutesToDaytime(workingHour.startTime)} -{' '}
          {minutesToDaytime(workingHour.endTime)} Uhr
        </div>
      );
    }
  };

  return <div>{workingHours.map(workingHour)}</div>;
};
