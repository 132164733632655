import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { ParticipantStatus } from '@prisma/client';

export const participantStatusZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(ParticipantStatus)))
    .transform(
      (value) =>
        ParticipantStatus[
          value as keyof typeof ParticipantStatus
        ] as ParticipantStatus,
    );
