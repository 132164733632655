import { action, makeObservable, observable, runInAction } from 'mobx';
import type { EntryQuestionnaireReqDtoType } from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { ErrorCode, entryQuestionnaireReqDto } from '@web/common';
import { entryQuestionnaireEvaluation } from '../view/public/entry-questionnaire/entry-questionnaire.evaluation';
import { showErrorNotification } from '../component/notification';

export class EntryQuestionnaireStore {
  @observable public questionnaireResult: null | EntryQuestionnaireReqDtoType =
    null;
  @observable public isEligibleForParticipation: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public loadQuestionnaireResult() {
    // storing the survey data in the session storage helps keeping the data after initializing kcStore
    const surveyData = sessionStorage.getItem('entryQuestionnaire');

    if (surveyData) {
      this.questionnaireResult = JSON.parse(surveyData);
    }
  }

  @action public submitQuestionnaire(questionnaireResult: unknown) {
    const parsedQuestionnaireResult =
      entryQuestionnaireReqDto.safeParse(questionnaireResult);

    if (parsedQuestionnaireResult.success) {
      runInAction(() => {
        this.questionnaireResult = parsedQuestionnaireResult.data;
        const eligibleForParticipation = entryQuestionnaireEvaluation(
          parsedQuestionnaireResult.data,
        );
        this.isEligibleForParticipation = eligibleForParticipation;
      });
    } else {
      showErrorNotification(ErrorCode.QuestionnaireParsingError);
    }
  }
}

const { provider, useStore } = setupMobX<EntryQuestionnaireStore>();
export const useEntryQuestionnaireStore = useStore;
export const EntryQuestionnaireStoreProvider = provider;
