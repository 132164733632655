import { observer } from 'mobx-react';
import { useOnboardingChecklistStore } from '../../../../store';
import { SimpleOnboardingChecklist } from './simple-checklist';
import { OnboardingChecklist } from './OnboardingChecklist';

export const OnboardingChecklistWrapper = observer(() => {
  const checklistStore = useOnboardingChecklistStore();

  return checklistStore.showSimpleChecklist ? (
    <SimpleOnboardingChecklist />
  ) : (
    <OnboardingChecklist />
  );
});
