import { useParticipantDataStore } from '../../../store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useParticipantData = () => {
  const store = useParticipantDataStore();
  const userInformation = store.data;
  const { userId } = useParams();

  useEffect(() => {
    if (userId) store.retrieveUserInformation(userId);
  }, [store, userId]);

  return {
    noData: !userId || !userInformation,
    isLoading: store.isLoading,
    userInformation: userInformation,
  };
};
