import { TextInput, Button } from '@mantine/core';
import { PauseModalType, useUserStateStore } from '../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from './BaseModal';

const textByType = {
  [PauseModalType.Default]: {
    title: 'Teilnehmer pausieren',
    description: `Durch das Pausieren wird der Teilnehmer für die weitere Bearbeitung dem Studienorganisator zugewiesen.`,
    submitButton: 'Ja, Teilnehmer pausieren',
    inpuText: 'Grund',
  },
  [PauseModalType.KontraIndikation]: {
    title: 'Kontra Indikation, die geprüft werden muss',
    description: `Tritt bei dem Teilnehmer eine Kontra Indikation auf, die geprüft
      werden muss und er möchte seine Daten bei einem Arzt abgeben?`,
    submitButton: 'Ja, Teilnehmer pausieren',
    inpuText: 'Kontra Indikation',
  },
};

export const PauseModal = observer(
  ({
    userId,
    onUserStateUpdated,
  }: {
    userId: string;
    onUserStateUpdated: () => void;
  }) => {
    const stateStore = useUserStateStore();
    const type = stateStore.pauseModalType;

    const onPauseModalClose = () => {
      stateStore.setPauseModalOpen(false);
      stateStore.setPauseReason(null);
    };

    return (
      <BaseModal
        opened={stateStore.pauseModalOpen}
        onClose={onPauseModalClose}
        title={textByType[type].title}
        description={textByType[type].description}
        iconName="warning_fill"
        iconColor="#202020"
        buttons={[
          <Button key="cancel" variant="outline" onClick={onPauseModalClose}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            disabled={!stateStore.pauseReason || stateStore.loading}
            onClick={() => {
              stateStore.setPaused(userId, stateStore.pauseReason || '', () => {
                onPauseModalClose();
                onUserStateUpdated();
              });
            }}
          >
            Ja, Teilnehmer pausieren
          </Button>,
        ]}
      >
        <TextInput
          label={textByType[type].inpuText}
          required
          placeholder={textByType[type].inpuText}
          type="text"
          onChange={(event) =>
            stateStore.setPauseReason(event.currentTarget?.value)
          }
        />
      </BaseModal>
    );
  },
);
