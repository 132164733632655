import { Accordion, Flex, Space, Button, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { CheckListId, CheckListItem } from '@web/common';
import download from '../../../../assets/icons/download_white.svg';

type Props = {
  id: string;
  title: () => JSX.Element;
  description: JSX.Element | string;
  item: CheckListItem;
  type: CheckListId;
  route: string;
  containsDownload?: boolean;
  btnText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routeState?: any;
};

export const ChecklistItemWrapper = ({
  id,
  title,
  description,
  item,
  route,
  containsDownload = false,
  btnText = 'Herunterladen',
  routeState,
}: Props) => {
  return (
    <Accordion.Item key={id} value={id}>
      {title()}
      <Accordion.Panel key={'accordion-panel'}>
        <Flex direction="column" align="flex-start" m="0px 34px">
          <Text ta="left">{description}</Text>
          <Space h="xl" />

          <Link
            children={
              <Button
                disabled={!containsDownload && item.done}
                rightSection={
                  containsDownload ? (
                    <img src={download} alt="clock" style={{ color: '#fff' }} />
                  ) : null
                }
              >
                {btnText}
              </Button>
            }
            to={route}
            state={routeState}
            target={containsDownload ? id : undefined}
          />
        </Flex>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
