import { showNotification } from '@mantine/notifications';
import { PointsAwardedNotification } from './PointsAwardedNotification';
import pointsAwardedClasses from './PointsAwardedNotification.module.css';
import { baseNotificationProps } from '../baseNotificationProps';
import { LoyalityPointsLogReason } from '@prisma/client';
import { getRank } from '@web/common';
import { NewRankNotification } from './NewRankNotification';

export const showPointsAwardedNotification = (
  points: number,
  occasion: LoyalityPointsLogReason,
  totalPoints: number,
) => {
  showNotification({
    ...baseNotificationProps,
    message: <PointsAwardedNotification points={points} occasion={occasion} />,
    classNames: pointsAwardedClasses,
  });

  const previousTotalPoints = totalPoints - points;
  const previousRank = getRank(previousTotalPoints);
  const currentRank = getRank(totalPoints);

  if (previousRank !== currentRank) {
    showNotification({
      ...baseNotificationProps,
      message: <NewRankNotification rank={currentRank} />,
      classNames: pointsAwardedClasses,
    });
  }
};
