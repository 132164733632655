import { type AvailableThcResDto } from '@web/common';
import { observable, makeObservable, action, runInAction } from 'mobx';
import { showErrorNotification } from '../../component/notification';
import { getAvailableThcForParticipant } from '../../request/authenticated-requests/participant-thc';
import { setupMobX } from '../../util/setupMobX';

export class ThcStore {
  @observable public loading: boolean = false;
  @observable public availableThc: AvailableThcResDto | null = null;

  constructor() {
    makeObservable(this);
  }

  @action public loadAvailableThc(userId: string) {
    this.loading = true;

    getAvailableThcForParticipant(userId)
      .then((res) => runInAction(() => (this.availableThc = res)))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loading = false)));
  }
}

const { provider, useStore } = setupMobX<ThcStore>();
export const useThcStore = useStore;
export const ThcStoreProvider = provider;
