import { RequestErrorType } from './RequestErrorType';

export class RequestError extends Error {
  constructor(
    public readonly errorCode: RequestErrorType,
    public readonly message: string = '',
  ) {
    super(message);
  }
}
