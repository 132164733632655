import { useParticipantDataStore } from '../../../store';
import { observer } from 'mobx-react';
import { ParticipantIdentification } from '../ParticipantIdentification';
import { Navigate, useParams } from 'react-router-dom';
import { Routes } from '../../../route/Routes';
import { ViewParticipantOnboarding } from '../ViewParticipantOnboarding';
import { UserStatusButtons } from '../../userStatus';
import { Divider, Flex, ScrollArea, Skeleton, Text } from '@mantine/core';
import { BaseLayout } from '../../../view/authenticated/navigation';
import { Breadcrumbs } from '../../../view/authenticated/navigation/Breadcrumbs';
import classes from '../Participant.module.css';
import { UserStatus } from '@prisma/client';
import { useEffect } from 'react';
import { OpenOrdersForUser } from '../../../view/authenticated/organizer/open-shop-orders/OpenOrdersForUser';

export const ParticipantDetailUI = observer(() => {
  const participantDataStore = useParticipantDataStore();
  const { userId } = useParams();
  const isLoading = participantDataStore.isLoading;
  const userInformation = participantDataStore.data;
  const showChecklist = userInformation?.status === UserStatus.OPEN;
  const filledOutBagSurvey = participantDataStore.filledOutBagSurvey;
  const isUserActive = userInformation?.status === UserStatus.ACTIVE;

  useEffect(() => {
    if (userId) participantDataStore.retrieveUserInformation(userId);
    return () => participantDataStore.reset();
  }, [participantDataStore, userId]);

  if (!userId) return <Navigate to={Routes.Study} />;

  return (
    <BaseLayout>
      <Flex className={classes.wrapper}>
        <Breadcrumbs
          style={{ borderColor: 'transparent', borderWidth: 0 }}
          path="/ Studienteilnehmer prüfen "
        />
        <Divider />

        <Skeleton visible={isLoading}>
          <ScrollArea className={classes.scrollArea} scrollbarSize={8}>
            <Flex className={classes.onboardingCont}>
              {!userInformation?.checkListData || !userInformation ? (
                <div>
                  Keine oder fehlerhafte Daten. <br />
                  Bitte teilen sie dem Nutzer mit, dass er die übrigen Punkte
                  der Checkliste ausfüllen muss.
                </div>
              ) : (
                <>
                  <ParticipantIdentification />
                  {showChecklist && <ViewParticipantOnboarding />}
                  {isUserActive && (
                    <>
                      <Text size="21px" lh="24px" fw="600">
                        Information
                      </Text>
                      <Flex gap="20px">
                        <Text>BAG-Fragebogen ausgefüllt: </Text>
                        <Text fw="500">
                          {filledOutBagSurvey ? 'Ja' : 'Nein'}
                        </Text>
                      </Flex>
                      <OpenOrdersForUser />
                    </>
                  )}
                  <UserStatusButtons
                    currentStatus={userInformation.status}
                    onUserStateUpdated={() => participantDataStore.reload()}
                  />
                </>
              )}
            </Flex>
          </ScrollArea>
        </Skeleton>
      </Flex>
    </BaseLayout>
  );
});
