import { DateTime } from 'luxon';
import { createBooking } from './cal-dot-com.links';

// email can only be null, once a user has been deleted
export function makeAppointment(
  selectedTeamSlug: string,
  selectedTeamPath: string,
  selectedTeamId: number,
  id: string | null,
  userSearchID: string | null,
  email: string | null,
) {
  const path = createBooking(
    selectedTeamSlug,
    selectedTeamPath,
    id || '',
    userSearchID || '',
    selectedTeamId,
    email || '',
    DateTime.now(),
  );

  window.location.href = path;
}
