import { Accordion, Flex, Text } from '@mantine/core';
import classes from './Faq.module.css';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';

export const AccordionSection = ({
  title,
  content,
}: {
  title?: string;
  content: { value: string; description: string | JSX.Element }[];
}) => {
  const location = useLocation();
  const hash = location.hash.replace('#', '') || null;
  const navigateId = location.state?.navigateId.toLocaleLowerCase() ?? null;
  const sessionNavigateId = localStorage
    .getItem('navigateId')
    ?.toLocaleLowerCase();
  const [openItem, setOpenItem] = useState<string | null>(
    hash ?? navigateId ?? sessionNavigateId,
  );

  useLayoutEffect(() => {
    const navigateDestination = hash ?? navigateId ?? sessionNavigateId;
    if (
      navigateDestination &&
      content.find(
        (item) => item.value.toLocaleLowerCase() === navigateDestination,
      )
    ) {
      const element = document.getElementById(navigateDestination);
      if (element)
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [content, hash, location, navigateId, sessionNavigateId]);

  const items = content.map((item) => (
    <Accordion.Item
      key={item.value.toLocaleLowerCase()}
      value={item.value.toLocaleLowerCase()}
    >
      <Accordion.Control>{item.value}</Accordion.Control>
      <Accordion.Panel p="20px">{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Flex className={classes.accordionWrapper}>
      {title && <Text className={classes.titleText}>{title}</Text>}
      <Accordion
        variant="separated"
        w="100%"
        ta="start"
        value={openItem}
        onChange={setOpenItem}
        styles={{
          control: {
            border: '1px solid #4A4A48',
            background: '#fff',
            padding: '10px 20px',
          },
          item: {
            borderColor: '#fff',
            marginTop: 8,
          },
        }}
      >
        {items}
      </Accordion>
    </Flex>
  );
};
