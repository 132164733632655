import { observer } from 'mobx-react';
import { useSearchUserStore } from '../../../../store';
import { ActionIcon, Flex, Loader, Table, Text } from '@mantine/core';
import { Routes } from '../../../../route/Routes';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '../../../../component';
import { useTranslation } from 'react-i18next';
import { CSVExport } from '../../../../util/CSVExport';
import { UsersFilterResDto, UsersSearchResultResDto } from '@web/common';
import { VCFExport } from '../../../../util/VCFExport';

export const List = observer(
  ({
    showExtendedFields = false,
    allowExport = false,
  }: {
    showExtendedFields?: boolean;
    allowExport?: boolean;
  }) => {
    const searchUserStore = useSearchUserStore();
    const navigate = useNavigate();
    const foundUsers = searchUserStore.matchingUsers.length;
    const { t } = useTranslation('organizer-dashboard');

    if (searchUserStore.loading) return <Loader />;

    const route = (userId: string) => {
      navigate(`${Routes.Study}/${Routes.User}/${userId}`);
    };

    const mapData = (data: UsersSearchResultResDto | UsersFilterResDto) => {
      return data.map((user) => {
        const fullname =
          user.fullname === ' ' || null ? 'TBD' : (user.fullname ?? 'TBD');
        const email =
          showExtendedFields && 'email' in user ? (user.email ?? '-') : '-';
        const phoneNumber =
          'phoneNumber' in user ? (user.phoneNumber ?? '-') : '-';
        const groupAssignment =
          showExtendedFields && 'groupAssignment' in user
            ? user.groupAssignment
            : 'TBD';
        const BAGQuestionnaireFilledOut =
          showExtendedFields && 'BAGQuestionnaireFilledOut' in user
            ? user.BAGQuestionnaireFilledOut
            : '-';

        const additionalFields = showExtendedFields
          ? {
              email,
              groupAssignment:
                (showExtendedFields &&
                  t('UserGroupAssignment.' + groupAssignment)) ||
                '-',
              BAGQuestionnaireFilledOut:
                (showExtendedFields &&
                  t(
                    'UserBAGQuestionnaireFilledOut.' +
                      BAGQuestionnaireFilledOut,
                  )) ||
                '-',
            }
          : {};

        return {
          id: user.id,
          fullname,
          userSearchID: user.userSearchID,
          phoneNumber: phoneNumber,
          plz: user.plz ?? 'TBD',
          status: user.status,
          ...additionalFields,
        };
      });
    };

    return (
      <>
        <div>
          <Text fw="600" lh="24px" size="16px">
            {foundUsers}{' '}
            {foundUsers > 1 || foundUsers === 0 ? t('Results') : t('Result')}
          </Text>
          {allowExport && (
            <Flex gap="10px">
              <CSVExport
                csvData={mapData(searchUserStore.matchingUsers)}
                disabled={foundUsers === 0}
              />
              <VCFExport data={mapData(searchUserStore.matchingUsers)} />
            </Flex>
          )}
        </div>
        <Table stickyHeader highlightOnHover highlightOnHoverColor="#f6f5ef">
          <Table.Thead bg="#f6f5ef" fw="500" lh="24px">
            <Table.Tr>
              <Table.Td>{t('Table.Columns.Name')}</Table.Td>
              {showExtendedFields && (
                <Table.Td>{t('Table.Columns.Email')}</Table.Td>
              )}
              <Table.Td>{t('Table.Columns.PhoneNumber')}</Table.Td>
              <Table.Td>{t('Table.Columns.Code')}</Table.Td>
              <Table.Td>{t('Table.Columns.PLZ')}</Table.Td>
              <Table.Td>{t('Table.Columns.Status')}</Table.Td>
              {showExtendedFields && (
                <>
                  <Table.Td>{t('Table.Columns.GroupAssignment')}</Table.Td>
                  <Table.Td>
                    {t('Table.Columns.BAGQuestionnaireFilledOut')}
                  </Table.Td>
                </>
              )}
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {mapData(searchUserStore.matchingUsers).map((user) => {
              return (
                <Table.Tr key={user.id} onClick={() => route(user.id)}>
                  <Table.Td>{user.fullname}</Table.Td>
                  {showExtendedFields && <Table.Td>{user.email}</Table.Td>}
                  <Table.Td>{user.phoneNumber}</Table.Td>
                  <Table.Td>{user.userSearchID}</Table.Td>
                  <Table.Td>{user.plz ?? 'TBD'}</Table.Td>
                  <Table.Td>{t('UserStatusEnum.' + user.status)}</Table.Td>
                  {showExtendedFields && (
                    <>
                      <Table.Td>{user.groupAssignment}</Table.Td>
                      <Table.Td>{user.BAGQuestionnaireFilledOut}</Table.Td>
                    </>
                  )}
                  <Table.Td>
                    <Flex w="100%" justify="flex-end">
                      <ActionIcon variant="white">
                        <MyIcon name="chevron_right" />
                      </ActionIcon>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
        {!foundUsers && (
          <Text fw="500" lh="24px" size="16px" ta="center" p="40px 0px">
            {t('Table.NoData')}
          </Text>
        )}
      </>
    );
  },
);
