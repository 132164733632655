import { SF12QuestionnaireQuestionKey } from '..';
import { requiredAnswer, requiredMatrixAnswer } from './requiredAnswer';

type TranslationFunction = {
  (key: string): string;
};

// ghp1
export const generalHealthQuestion = (t: TranslationFunction) => {
  return {
    name: SF12QuestionnaireQuestionKey.GENERAL_HEALTH,
    title: t('GeneralHealthQuestion.Title'),
    type: 'radiogroup',
    choices: [
      {
        physicalValue: 0,
        mentalValue: 0,
        value: 1,
        text: t('GeneralHealthQuestion.Excellent'),
      },
      {
        physicalValue: -1.31872,
        mentalValue: -0.06064,
        value: 2,
        text: t('GeneralHealthQuestion.VeryGood'),
      },
      {
        physicalValue: -3.02396,
        mentalValue: -0.06064,
        value: 3,
        text: t('GeneralHealthQuestion.Good'),
      },
      {
        physicalValue: -5.56461,
        mentalValue: -0.16891,
        value: 4,
        text: t('GeneralHealthQuestion.LessGood'),
      },
      {
        physicalValue: -8.37399,
        mentalValue: -1.71175,
        value: 5,
        text: t('GeneralHealthQuestion.Poor'),
      },
    ],
    ...requiredAnswer(t),
  };
};
// pfi02/pfi04
export const normalDayActivitiesMatrixQuestion = (t: TranslationFunction) => {
  return {
    name: SF12QuestionnaireQuestionKey.NORMAL_DAY_ACTIVITIES_MATRIX,
    title: t('NormalDayActivities.Title'),
    description: t('NormalDayActivities.Description'),
    type: 'matrix',
    ...requiredMatrixAnswer(t),
    columns: [
      {
        values: [
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
        ],
        value: 1,
        text: t('NormalDayActivities.NotAtAll'),
      },
      {
        values: [
          {
            physicalValue: -3.45555,
            mentalValue: 1.8684,
          },
          {
            physicalValue: -2.73557,
            mentalValue: 1.43103,
          },
        ],
        value: 2,
        text: t('NormalDayActivities.Slightly'),
      },
      {
        values: [
          {
            physicalValue: -7.23216,
            mentalValue: 3.93115,
          },
          {
            physicalValue: -6.24397,
            mentalValue: 2.68282,
          },
        ],
        value: 3,
        text: t('NormalDayActivities.Strongly'),
      },
    ],
    rows: [
      {
        value: SF12QuestionnaireQuestionKey.MIDDLE_HEAVY_WORK,
        text: t('NormalDayActivities.MiddleHeavyWork'),
      },
      {
        value: SF12QuestionnaireQuestionKey.MULTIPLE_STAIRS,
        text: t('NormalDayActivities.MultitpleStairs'),
      },
    ],
  };
};

// rolph2/rolph3
export const last4WeeksWorkPhysicalIssuesQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: SF12QuestionnaireQuestionKey.LAST_4_WEEKS_WORK_PHYSICAL_ISSUES,
    title: t('Last4WeeksWorkPhysial.Title'),
    type: 'matrix',
    ...requiredMatrixAnswer(t),
    columns: [
      {
        values: [
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
        ],
        value: 1,
        text: t('Last4WeeksWorkPhysial.No'),
      },
      {
        values: [
          {
            physicalValue: -4.61617,
            mentalValue: 1.4406,
          },
          {
            physicalValue: -5.51747,
            mentalValue: 1.66968,
          },
        ],
        value: 2,
        text: t('Last4WeeksWorkPhysial.Yes'),
      },
    ],
    rows: [
      {
        value: SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_PHYSICAL,
        text: t('Last4WeeksWorkPhysial.IDidLessThanIWanted'),
      },
      {
        value:
          SF12QuestionnaireQuestionKey.I_COULD_ONLY_DO_CERTAIN_WORK_PHYSICAL,
        text: t('Last4WeeksWorkPhysial.ICouldOnlyDoCertainWork'),
      },
    ],
  };
};

// rolem2/rolem3
export const last4WeeksMentalIssuesQuestion = (t: TranslationFunction) => {
  return {
    name: SF12QuestionnaireQuestionKey.LAST_4_WEEKS_MENTAL_ISSUES,
    title: t('Last4WeeksMentalIssues.Title'),
    type: 'matrix',
    ...requiredMatrixAnswer(t),
    columns: [
      {
        values: [
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
        ],
        value: 1,
        text: t('Last4WeeksMentalIssues.No'),
      },
      {
        values: [
          {
            physicalValue: 3.04365,
            mentalValue: -6.82672,
          },
          {
            physicalValue: 2.32091,
            mentalValue: -5.69921,
          },
        ],
        value: 2,
        text: t('Last4WeeksMentalIssues.Yes'),
      },
    ],
    rows: [
      {
        value: SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_MENTAL,
        text: t('Last4WeeksMentalIssues.IDidLessThanIWanted'),
      },
      {
        value: SF12QuestionnaireQuestionKey.I_COULDNT_WORK_AS_CAREFULLY_MENTAL,
        text: t('Last4WeeksMentalIssues.ICouldOnlyDoCertainWork'),
      },
    ],
  };
};

// pain2
export const dailyActivitiesIssuesBcOfPainQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: SF12QuestionnaireQuestionKey.DAILY_ACTIVITIES_ISSUES_BC_OF_PAIN,
    title: t('DailyActivities.Title'),
    type: 'radiogroup',
    choices: [
      {
        physicalValue: 0.0,
        mentalValue: 0,
        value: 1,
        text: t('DailyActivities.NotAtAll'),
      },
      {
        physicalValue: -3.8013,
        mentalValue: 0.90384,
        value: 2,
        text: t('DailyActivities.Slightly'),
      },
      {
        physicalValue: -6.50522,
        mentalValue: 1.49384,
        value: 3,
        text: t('DailyActivities.Moderately'),
      },
      {
        physicalValue: -8.38063,
        mentalValue: 1.76691,
        value: 4,
        text: t('DailyActivities.Severely'),
      },
      {
        physicalValue: -11.25544,
        mentalValue: 1.48619,
        value: 5,
        text: t('DailyActivities.Extremely'),
      },
    ],
    ...requiredAnswer(t),
  };
};

// mhi3/vital2/mhi4
export const last4WeeksFeelingQuestion = (t: TranslationFunction) => {
  return {
    name: SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING,
    title: t('Last4WeeksFeeling.Title'),
    description: t('Last4WeeksFeeling.Description'),
    type: 'matrix',
    ...requiredMatrixAnswer(t),
    columns: [
      {
        values: [
          {
            physicalValue: 3.46638,
            mentalValue: -10.19085,
          },
          {
            physicalValue: -2.44706,
            mentalValue: -6.02409,
          },
          {
            physicalValue: 0.0,
            mentalValue: 0,
          },
        ],
        value: 1,
        text: t('Last4WeeksFeeling.Never'),
      },
      {
        values: [
          {
            physicalValue: 2.90426,
            mentalValue: -7.92717,
          },
          {
            physicalValue: -2.02168,
            mentalValue: -4.88962,
          },
          {
            physicalValue: 0.41188,
            mentalValue: -1.95934,
          },
        ],
        value: 2,
        text: t('Last4WeeksFeeling.Rarely'),
      },
      {
        values: [
          {
            physicalValue: 2.37241,
            mentalValue: -6.31121,
          },
          {
            physicalValue: -1.6185,
            mentalValue: -3.29805,
          },
          {
            physicalValue: 1.28044,
            mentalValue: -4.59055,
          },
        ],
        value: 3,
        text: t('Last4WeeksFeeling.Sometimes'),
      },
      {
        values: [
          {
            physicalValue: 1.36689,
            mentalValue: -4.09842,
          },
          {
            physicalValue: -1.14387,
            mentalValue: -1.65178,
          },
          {
            physicalValue: 2.34247,
            mentalValue: -8.09914,
          },
        ],
        value: 4,
        text: t('Last4WeeksFeeling.VeryOften'),
      },
      {
        values: [
          {
            physicalValue: 0.66514,
            mentalValue: -1.94949,
          },
          {
            physicalValue: -0.42251,
            mentalValue: -0.92057,
          },
          {
            physicalValue: 3.41593,
            mentalValue: -10.77911,
          },
        ],
        value: 5,
        text: t('Last4WeeksFeeling.Mostly'),
      },
      {
        values: [
          {
            physicalValue: 0.0,
            mentalValue: 0.0,
          },
          {
            physicalValue: 0.0,
            mentalValue: 0.0,
          },
          {
            physicalValue: 4.61446,
            mentalValue: -16.15395,
          },
        ],
        value: 6,
        text: t('Last4WeeksFeeling.Always'),
      },
    ],
    rows: [
      {
        value: SF12QuestionnaireQuestionKey.RELAXED_AND_CALM,
        text: t('Last4WeeksFeeling.RelaxedAndCalm'),
      },
      {
        value: SF12QuestionnaireQuestionKey.FULL_OF_ENERGY,
        text: t('Last4WeeksFeeling.FullOfEnergy'),
      },
      {
        value: SF12QuestionnaireQuestionKey.DISCOURAGED_AND_SAD,
        text: t('Last4WeeksFeeling.DiscouragedAndSad'),
      },
    ],
  };
};

// soc2
export const contactToFriendsAndFamily = (t: TranslationFunction) => {
  return {
    name: SF12QuestionnaireQuestionKey.CONTACT_TO_FRIENDS_AND_FAMILY,
    title: t('ContactToFriends.Title'),
    type: 'radiogroup',
    choices: [
      {
        physicalValue: -0.33682,
        mentalValue: -6.29724,
        value: 1,
        text: t('ContactToFriends.Always'),
      },
      {
        physicalValue: -0.94342,
        mentalValue: -8.26066,
        value: 2,
        text: t('ContactToFriends.Mostly'),
      },
      {
        physicalValue: -0.18043,
        mentalValue: -5.63286,
        value: 3,
        text: t('ContactToFriends.Sometimes'),
      },
      {
        physicalValue: 0.11038,
        mentalValue: -3.13896,
        value: 4,
        text: t('ContactToFriends.Rarely'),
      },
      {
        physicalValue: 0.0,
        mentalValue: 0,
        value: 5,
        text: t('ContactToFriends.Never'),
      },
    ],
    ...requiredAnswer(t),
  };
};

export const physicalBaselineScore = () => 56.57706;
export const mentalBaselineScore = () => 60.75781;

export const shouldGoToPhysicianPhysical = (value: number) => value < 44.24;
export const shouldGoToPhysicianMental = (value: number) => value < 48.3;
