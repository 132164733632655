import { Flex, Image, Text } from '@mantine/core';
import classes from '../LandingPage.module.css';
import qb from '../../../../assets/cooperation/qb.png';
import zurich from '../../../../assets/cooperation/zurich_new.png';
import university from '../../../../assets/cooperation/university.png';
import { useTranslation } from 'react-i18next';

export const Cooperations = () => {
  const { t } = useTranslation('landingpage');
  return (
    <Flex className={classes.introContainer} p="20px">
      <Text ta="center" p="20px">
        {t('Cooperations.Title')}
      </Text>
      <Flex className={classes.wrapper}>
        <Image className={classes.universityImg} src={university} />
        <Image className={classes.qbImg} src={qb} />
        <Image className={classes.zurichImg} src={zurich} />
      </Flex>
    </Flex>
  );
};
