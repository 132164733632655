import { Grid, Flex, Text } from '@mantine/core';
import { OrderedProduct } from './OrderedProduct';
import { useTranslation } from 'react-i18next';
import { Banner } from '../../../../../../component';
import { OrderDto } from '@web/common';

export const OrderDetailContent = ({
  orderDetails,
}: {
  orderDetails: OrderDto;
}) => {
  const { t } = useTranslation('shop');

  function getTotalTHC() {
    let totalTHC = 0;

    orderDetails?.orderItems.forEach((item) => {
      const thcAmount = item.currentQuantity * item.product.thcAmount;
      totalTHC += thcAmount;
    });

    return totalTHC;
  }

  return (
    <>
      <Text fw="600" size="21px" lh="24px">
        {t('Items')}
      </Text>
      <Grid align="stretch">
        {orderDetails.orderItems.map((item) => (
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }} key={item.variantId}>
            <OrderedProduct item={item} />
          </Grid.Col>
        ))}
        {orderDetails.orderItems.length === 0 && (
          <Banner
            padding="0px 5px 0px 5px"
            maxWidth="auto"
            title={t('CannotFindUserToOrderBanner.Title')}
            description={t('CannotFindUserToOrderBanner.Description', {
              orderName: orderDetails.name,
            })}
          />
        )}
      </Grid>
      <div>
        <Flex justify="space-between">
          <Text size="16px" lh="24px">
            {t('Total')}
          </Text>
          <Text size="16px" lh="24px" fw="600">
            {`${orderDetails.totalPrice} ${orderDetails.currencyCode}`}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text c="#4A4A48" size="14px" lh="21px">
            {t('pureTHC')}
          </Text>
          <Text c="#4A4A48" size="14px" lh="21px">
            {getTotalTHC()?.toFixed(2)}g
          </Text>
        </Flex>
      </div>
    </>
  );
};
