import { Container, Center, Flex, Image, Progress } from '@mantine/core';
import classes from '../sharedStyles/InfoCard.module.css';
import logo from '../assets/scc.svg';

export function Loading() {
  return (
    <Container fluid className={classes.container} bg="#F1F0E899">
      <Center className={classes.centerContainer}>
        <Flex
          direction="column"
          bg="#fff"
          gap="10px"
          className={classes.contentWrapper}
        >
          <Image p={20} radius="md" src={logo} />
          <Progress style={{ width: '100%' }} size="lg" value={100} animated />
        </Flex>
      </Center>
    </Container>
  );
}
