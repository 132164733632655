import { CohortType } from '@prisma/client';
import { RealmRole } from '@web/common';
import { navItems } from './navigationItems';

export const getNavItemsForRole = (
  role: RealmRole,
  cohortType?: CohortType | null,
) =>
  navItems
    .filter((item) => item.role.includes(role))
    .filter(
      (item) =>
        item.cohortType === undefined ||
        (!!cohortType && item.cohortType?.includes(cohortType)),
    );
