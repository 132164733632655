import { Container, Flex, Space } from '@mantine/core';
import { observer } from 'mobx-react';
import { Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { SelectPlaceStep, IsZuricanStep, SelectAppointmentStep } from './steps';
import { useEventStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { Header } from '../navigation';

const invalidLivingPlace = 'Keins davon'; // translate!

export const QuickEnrollPage = observer(() => {
  const [selectedPlace, setSelectedPlace] = useState<string | null>(null);
  const [isZurican, setIsZurican] = useState<boolean>(false);
  const eventStore = useEventStore();
  const { t } = useTranslation('quick-enroll');
  const events = eventStore.events;

  useEffect(() => {
    eventStore.loadEvents();
  }, [eventStore]);

  return (
    <Container fluid w="100vw" h="auto" p={0} style={{ overflow: 'clip' }}>
      <Header hideLogout />
      <Container maw="600px" p="0px">
        <Space h="40px" />
        <Flex gap="20px" direction="column" p="20px">
          <Text
            c="##202020"
            size="32px"
            lh="38px"
            ta="center"
            fw="600"
            lts="-0.5px"
          >
            {t('Hello')},
          </Text>
          <Text ta="center">
            {t('ThankYou')}
            <br /> <br /> {t('YouAreOnly3StepsAway')}
          </Text>
        </Flex>
        <Flex gap="20px" direction="column" p="20px" bg="#fff">
          <SelectPlaceStep
            invalidLivingPlace={invalidLivingPlace}
            setSelectedPlace={setSelectedPlace}
          />

          {selectedPlace && (
            <IsZuricanStep
              selectedPlace={selectedPlace}
              invalidLivingPlace={invalidLivingPlace}
              setIsZurican={setIsZurican}
            />
          )}

          {selectedPlace && selectedPlace !== invalidLivingPlace && (
            <SelectAppointmentStep isZurican={isZurican} events={events} />
          )}
        </Flex>
        <Space h="100px" />
      </Container>
    </Container>
  );
});
