import { observer } from 'mobx-react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import {
  useOnboardingChecklistStore,
  useQuestionnaireEvaluationStore,
} from '../../../../store';
import {
  CheckListId,
  SF12QuestionnaireResult,
  sf12QuestionnaireReqDto,
  CheckListItemType,
  ErrorCode,
} from '@web/common';
import { Button, Container, Flex } from '@mantine/core';
import classes from '../../../../sharedStyles/Containers.module.css';
import { Questionnaire } from '../../../../component/questionnaire';
import { Header } from '../../../public/navigation';
import { DevContent } from '../../../../util/DevContent';
import { useState } from 'react';
import { devRandomize } from './devRandomize';
import { getSf12QuestionnaireSurveyJSON } from './sf12-survey-specification';
import { showErrorNotification } from '../../../../component/notification';
import { useTranslation } from 'react-i18next';

export const SF12Questionnaire = observer(() => {
  const onboardingChecklistStore = useOnboardingChecklistStore();
  const questionnaireEvaluationStore = useQuestionnaireEvaluationStore();
  const { t } = useTranslation('sf12-questionnaire');
  const [initialData, setInitialData] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();
  const checkListItemId = state?.id as CheckListId.SF12Questionnaire | null;

  if (!checkListItemId || checkListItemId !== CheckListId.SF12Questionnaire) {
    return <Navigate to={Routes.Study} />;
  }

  const surveyComplete = (surveyResult: SF12QuestionnaireResult) => {
    Promise.all([
      onboardingChecklistStore.storeQuestionnaireResults({
        id: checkListItemId,
        result: null,
      }),
      questionnaireEvaluationStore.evaluateSf12Questionnaire(surveyResult),
    ])
      .then(() => {
        navigate(Routes.Study, {
          state: {
            id: checkListItemId,
            result: surveyResult,
            reoccurrence: 0,
          },
          replace: true,
        });
      })
      .catch(() => showErrorNotification());
  };

  const checkListItem = onboardingChecklistStore.checklist
    ? onboardingChecklistStore.checklist[checkListItemId]
    : null;

  const alreadyAnswered =
    checkListItem?.type === CheckListItemType.Questionnaire12SF &&
    !!checkListItem?.questionnaireResult;

  if (alreadyAnswered) {
    return <Navigate to={Routes.Study} />;
  }

  return (
    <>
      <Header canLogout />
      <Container fluid className={classes.container} maw="1200px">
        <Questionnaire
          title={t('Title')}
          questionnaire={{
            json: getSf12QuestionnaireSurveyJSON(t),
            schema: sf12QuestionnaireReqDto,
            initial: initialData,
          }}
          onComplete={surveyComplete}
          onError={() =>
            showErrorNotification(ErrorCode.QuestionnaireParsingError)
          }
        />
      </Container>
      <DevContent>
        <Flex direction="row" gap="10px">
          <Button onClick={() => setInitialData(devRandomize(t))}>
            Randomize
          </Button>
        </Flex>
      </DevContent>
    </>
  );
});
