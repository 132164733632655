import { useState } from 'react';
import { Burger, Drawer, Flex, Button, Box } from '@mantine/core';
import classes from './Header.module.css';

import { Link } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import { defaultFontColor } from '../utils';
import { LoginButton, LogoutButton } from '../../../../component';
import { LanguagePicker } from './LanguagePicker';

import { useTranslation } from 'react-i18next';

const BurgerMenu = ({
  getItems,
  username,
  canLogout = false,
  showItems = false,
  hideLogout = false,
}: {
  getItems?: (
    t: (key: string) => string,
    fontColor?: string,
    style?: React.CSSProperties,
    additionalOnClickFunc?: () => void,
  ) => JSX.Element[];
  username: string;
  canLogout: boolean;
  hideLogout?: boolean;

  showItems?: boolean;
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const handleToggle = () => setOpened((prevOpened) => !prevOpened);
  const linkItems = getItems
    ? getItems(t, defaultFontColor, {}, () => setOpened(false))
    : [];

  return (
    <>
      <Burger opened={opened} onClick={handleToggle} p="30px 0px" />
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        size="240px"
        position="right"
        padding="15px"
        shadow="md"
      >
        <div className={classes.container}>
          {showItems && (
            <Flex direction="column" align="center">
              <Flex direction="column" gap="25px" ta="center">
                {linkItems}
              </Flex>
            </Flex>
          )}
          <div className={classes.buttonContainer}>
            {!hideLogout && (
              <Box className={classes.button}>
                {canLogout ? <LogoutButton /> : <LoginButton />}
              </Box>
            )}
            <Box className={classes.button}>
              {(window.location.pathname as Routes) === Routes.Root && (
                <Link
                  children={<Button>{t('Buttons.Register')}</Button>}
                  to={Routes.EntryQuestionnaire}
                />
              )}
            </Box>
          </div>

          <LanguagePicker />
        </div>
      </Drawer>
    </>
  );
};

export default BurgerMenu;
