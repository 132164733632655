import { showNotification } from '@mantine/notifications';
import { MyIcon } from '../icon';
import classes from './Notification.module.css';
import { ErrorCode } from '@web/common';
import { notificationCodeToCustomMessage } from './notificationCodeToCustomMessage';
import { RequestErrorType } from '../../request';
import * as Sentry from '@sentry/react';
import { baseNotificationProps } from './baseNotificationProps';

export const showSuccessNotification = (
  text = 'Daten erfolgreich gespeichert',
  title?: string,
) => {
  showNotification({
    ...baseNotificationProps,
    title,
    message: text,
    icon: <MyIcon name="check" className={classes.myIcon} />,
    classNames: classes,
  });
};

export const showFailedRequestNotification = (
  errorCode: ErrorCode | RequestErrorType,
  tracingId: string | null,
) => {
  showErrorNotification(errorCode, tracingId);
};

export const showErrorNotification = (
  errorCode?: ErrorCode | RequestErrorType,
  tracingId?: string | null,
) => {
  let { title, message } = notificationCodeToCustomMessage(errorCode);

  if (!title) {
    title = `Ein Fehler ist aufgetreten.`;
  }
  if (!message) {
    message = `Sollte der Fehler weiterhin bestehen, melden Sie sich bitte per
    Anfrage bei dem technischen Support.`;
  }

  Sentry.captureEvent({
    message: `Error: ${title} - ${message}, ErrorCode: ${errorCode}, TracingId: ${tracingId}`,
    level: 'error',
  });

  showNotification({
    ...baseNotificationProps,
    title: title,
    message: (
      <div>
        <div>{message} </div>
        {tracingId && <div> ID: {tracingId}</div>}
        {errorCode && <div>Fehlercode: {errorCode}</div>}
      </div>
    ),
    icon: <MyIcon name="close" className={classes.myIconError} />,
    classNames: classes,
  });
};
