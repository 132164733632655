export function randomChoiceValue<ValueType>(obj: {
  choices: { value: ValueType }[];
}): ValueType {
  return obj.choices[Math.floor(Math.random() * obj.choices.length)].value;
}

export function randomColumnValue<ValueType>(obj: {
  columns: { value: ValueType }[];
}): ValueType {
  return obj.columns[Math.floor(Math.random() * obj.columns.length)].value;
}
