import { observer } from 'mobx-react';
import { useOpenOrderStore } from './OpenOrdersStore';
import { useEffect } from 'react';
import { OpenOrderList } from './OpenOrderList';

export const AllOpenOrders = observer(() => {
  const store = useOpenOrderStore();
  const openOrders = store.openOrders;

  useEffect(() => {
    store.loadAllOpenOrders();
  }, [store]);

  return <OpenOrderList openOrders={openOrders} isLoading={store.isLoading} />;
});
