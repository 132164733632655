import { useTranslation } from 'react-i18next';
import { Flex, List, Text } from '@mantine/core';

export const QuestionYourConsumption = () => {
  const { t } = useTranslation('consumption-dialog');
  const options = t('QuestionYourConsumption.Options', {
    returnObjects: true,
  }) as string[];
  return (
    <Flex direction="column" gap="10px">
      <Text size="16px" lh="24px">
        {t('QuestionYourConsumption.Description1')}
      </Text>
      <Text size="16px" lh="24px">
        {t('QuestionYourConsumption.OptionsTitle')}
      </Text>
      <List type="ordered" py="20px">
        {options.map((option) => (
          <List.Item key={option}>
            <Text key={option} size="16px" lh="24px">
              {option}
            </Text>
          </List.Item>
        ))}
      </List>

      <Text size="16px" lh="24px">
        {t('QuestionYourConsumption.Description2')}
      </Text>
    </Flex>
  );
};
