import { Stack, Button, Text } from '@mantine/core';
import { BirdyClub } from '../BirdyClub';
import { makeAppointment } from '../../../authenticated/participant/book-in-person/makeAppointment';
import { EventSchemaDto, EventsSchemaDto } from '@web/common';
import {
  GoogleAnalyticsEvent,
  useGoogleAnalyticsStore,
} from '../../../../store';
import { useTranslation } from 'react-i18next';

export const SelectAppointmentStep = ({
  isZurican,
  events,
}: {
  isZurican: boolean;
  events: EventsSchemaDto;
}) => {
  const { t } = useTranslation('quick-enroll');
  const googleAnalyticsStore = useGoogleAnalyticsStore();

  function book(event: EventSchemaDto) {
    const selectedTeamSlug = event.team.slug;
    const selectedTeamId = event.id;

    googleAnalyticsStore.track(GoogleAnalyticsEvent.Enroll_Appointment);

    makeAppointment(
      selectedTeamSlug,
      event.slug,
      selectedTeamId,
      'MadeAppointmentOverQuickEnroll',
      'Dein Name',
      'Deine E-Mail',
    );
  }
  return !isZurican ? (
    <>
      <Text fw="600" size="16px" lh="24px">
        {t('Step')} 3:
      </Text>

      <Stack gap="8px">
        <Text>{t('SelectAppointment.BookAppointment')}</Text>
        {events.map((event) => (
          <Button
            onClick={() => book(event)}
            key={event.id}
            disabled={isZurican}
          >
            {event.title}
          </Button>
        ))}
      </Stack>
    </>
  ) : (
    <BirdyClub />
  );
};
