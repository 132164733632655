import { Flex } from '@mantine/core';
import classes from './MobileNavBar.module.css';
import { observer } from 'mobx-react';
import useNavItems from '../useNavItems';

export const MobileNavBar = observer(() => {
  const navItemElements = useNavItems(true);

  return (
    <Flex className={classes.navBar}>
      <Flex className={classes.innerNavBar}>
        <Flex w="100%" direction="row" justify="space-around">
          {navItemElements}
        </Flex>
      </Flex>
    </Flex>
  );
});
