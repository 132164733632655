const availableCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function devRandomCharString(length: number) {
  return 'x'
    .repeat(length)
    .replace(
      /./g,
      () =>
        availableCharacters[
          Math.floor(Math.random() * availableCharacters.length)
        ],
    );
}
