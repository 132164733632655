import { z } from 'zod';
import { userStatusZodEnum } from '../../enum';

export const userSearchSchema = z.object({
  id: z.string().uuid(),
  userSearchID: z.string(),
  fullname: z.string().nullable(),
  plz: z.string().nullable(),
  status: userStatusZodEnum(),
  phoneNumber: z.string().nullable().optional(),
});
export const usersSearchResultResDto = z.array(userSearchSchema);
export type UsersSearchResultResDto = z.infer<typeof usersSearchResultResDto>;
