import { EnrollStore, EnrollStoreProvider } from './EnrollStore';
import { ShortIntroUI } from './ShortIntroUI';

export const ShortIntro = ({ id }: { id: string }) => {
  return (
    <EnrollStoreProvider store={new EnrollStore()}>
      <ShortIntroUI id={id} />
    </EnrollStoreProvider>
  );
};
