const message = `the location setting in (e.g. https://app.cal.com/event-types/469101?tabName=setup) is not set correctly. It should be separated by a pipe | (e.g. "phonenumber|address|maps string")`;

export function splitLocationString(
  locations: { address: string; type: string }[],
) {
  if (locations.length >= 1) {
    const location = locations[0];
    const [phoneNr, address, link] = location.address.split('|');
    if (phoneNr && address && link) {
      return {
        phoneNr,
        address,
        link,
      };
    } else {
      console.warn(message);
      return {
        phoneNr: '',
        address: '',
        link: '',
      };
    }
  } else {
    console.warn(message);
    return {
      phoneNr: '',
      address: '',
      link: '',
    };
  }
}
