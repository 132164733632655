import {
  Button,
  Checkbox,
  Flex,
  PasswordInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { ErrorCode, registerReqDto } from '@web/common';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { isPasswordStrongEnough } from './is-password-strong-enough';
import { useEffect, useState } from 'react';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useEntryQuestionnaireStore,
  useGoogleAnalyticsStore,
  useKeycloakStore,
  useSnapPixelStore,
} from '../../../store';
import {
  handleRequestError,
  registerUser,
  RequestErrorType,
} from '../../../request';
import { DevContent } from '../../../util/DevContent';
import { onRandomize } from './dev-onRandomize';
import { Routes } from '../../../route/Routes';
import style from './Register.module.css';
import { useTranslation } from 'react-i18next';
import { Language } from '@prisma/client';

export const RegisterForm = () => {
  const kcStore = useKeycloakStore();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const entryQuestionnaireStore = useEntryQuestionnaireStore();
  const { i18n } = useTranslation();
  const [registerFormValid, setRegisterFormValid] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [readTermsError, setReadTermsError] = useState<string | undefined>(
    undefined,
  );
  const { t } = useTranslation('register');

  useEffect(() => {
    if (readTerms) {
      setReadTermsError(undefined);
    }
  }, [readTerms]);

  function setFormError(error: RequestErrorType | ErrorCode) {
    if (error === ErrorCode.KeycloakUserAlreadyExists) {
      Form.setErrors({
        email: t('Errors.UserAlreadyExists'),
      });
    } else if (error === ErrorCode.UniqueConstraintFailed) {
      Form.setErrors({
        phoneNumber: t('Errors.NumberAlreadyExists'),
      });
    } else if (error === ErrorCode.AlreadyRegisteredEmailOnce) {
      Form.setErrors({
        email: t('Errors.UserAlreadyRegisteredEmailOnce'),
      });
    } else if (error === ErrorCode.AlreadyRegisteredPhoneNumberOnce) {
      Form.setErrors({
        phoneNumber: t('Errors.UserAlreadyRegisteredPhoneNumberOnce'),
      });
    } else if (error === ErrorCode.UserRegisterError) {
      Form.setErrors({
        email: t('Errors.UserRegisterError'),
      });
    }
  }

  const Form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(
      registerReqDto
        .omit({ entryQuestionnaireData: true, language: true })
        .extend({ confirmPassword: z.string() })
        .refine((data) => data.password === data.confirmPassword, {
          message: t('Errors.PasswordDoesNotMatch'),
          path: ['confirmPassword'],
        })
        .refine(({ password }) => isPasswordStrongEnough(password), {
          message: t('Errors.PasswordIsNotStrongEnough'),
          path: ['password'],
        }),
    ),
    initialValues: {
      email: '',
      phoneNumberPrefix: '+41',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    const isFormEmpty = () => {
      return (
        Form.values.email === '' &&
        Form.values.phoneNumber === '' &&
        Form.values.password === '' &&
        Form.values.confirmPassword === ''
      );
    };

    setRegisterFormValid(
      !Form.errors.email &&
        !Form.errors.phoneNumber &&
        !Form.errors.password &&
        !Form.errors.confirmPassword,
    );
    if (isFormEmpty()) {
      setRegisterFormValid(false);
    }
  }, [Form]);

  const onSubmit = () => {
    if (!readTerms) {
      setReadTermsError(t('Errors.PleaseReadTerms'));
      return;
    }

    if (!Form.validate().hasErrors) {
      const data = {
        ...Form.values,
        entryQuestionnaireData: entryQuestionnaireStore.questionnaireResult!,
        language: i18n.language.toLocaleLowerCase() as Language,
      };

      registerUser(data)
        .then(() => {
          snapPixelStore.track(SnapEvent.User_registerd);
          googleAnalyticsStore.track(GoogleAnalyticsEvent.User_registerd);
          kcStore.login(Form.values.email, Form.values.password);
          // delete survey data after successful registration
          sessionStorage.removeItem('entryQuestionnaire');
        })
        .catch((error) => {
          handleRequestError(
            error,
            [
              ErrorCode.KeycloakUserAlreadyExists,
              () => setFormError(ErrorCode.KeycloakUserAlreadyExists),
            ],
            [
              ErrorCode.UniqueConstraintFailed,
              () => setFormError(ErrorCode.UniqueConstraintFailed),
            ],
            [
              ErrorCode.AlreadyRegisteredEmailOnce,
              () => setFormError(ErrorCode.AlreadyRegisteredEmailOnce),
            ],
            [
              ErrorCode.AlreadyRegisteredPhoneNumberOnce,
              () => setFormError(ErrorCode.AlreadyRegisteredPhoneNumberOnce),
            ],
            [
              ErrorCode.UserRegisterError,
              () => setFormError(ErrorCode.UserRegisterError),
            ],
          );
        });
    }
  };

  return (
    <form onSubmit={Form.onSubmit(onSubmit)}>
      <TextInput
        required
        type="email"
        label={t('Inputs.Email')}
        placeholder={t('Inputs.EmailPlaceholder')}
        my="sm"
        data-cy="register-email"
        {...Form.getInputProps('email')}
      />
      <Flex gap={10} wrap="wrap" my="sm">
        <Select
          w="20%"
          miw="100px"
          required
          label={t('Inputs.Prefix')}
          data={['+41', '+49', '+39']}
          clearable={false}
          allowDeselect={false}
          data-cy="register-phone-prefix"
          {...Form.getInputProps('phoneNumberPrefix')}
        />
        <TextInput
          style={{ flex: 1 }}
          miw="200px"
          required
          label={t('Inputs.Number')}
          placeholder={t('Inputs.NumberPlaceholder')}
          data-cy="register-phone"
          {...Form.getInputProps('phoneNumber')}
        />
      </Flex>

      <PasswordInput
        required
        label={t('Inputs.Password')}
        type="password"
        placeholder={t('Inputs.PasswordPlaceholder')}
        my="sm"
        data-cy="register-password"
        {...Form.getInputProps('password')}
      />
      <PasswordInput
        required
        label={t('Inputs.PasswordRepeat')}
        type="password"
        placeholder={t('Inputs.PasswordRepeatPlaceholder')}
        my="sm"
        data-cy="register-password-repeated"
        {...Form.getInputProps('confirmPassword')}
      />

      <Flex>
        <TextInput
          style={{ flex: 1 }}
          miw="200px"
          label={t('Inputs.AffiliateCode')}
          placeholder={t('Inputs.AffiliateCodePlaceholder')}
          {...Form.getInputProps('affiliateCode')}
        />
      </Flex>

      <Flex gap="12px" my="20px" align="center">
        <Checkbox
          styles={{ label: { fontSize: 16 } }}
          checked={readTerms}
          onChange={() => setReadTerms(!readTerms)}
          error={readTermsError}
          data-cy="register-terms-checkbox"
          label={
            <Text size="14px" lh="18px">
              {t('Messages.Checkbox.Text1')}{' '}
              <Link to={Routes.Nutzungsbedingungen} style={{ color: '#000' }}>
                {t('Messages.Checkbox.Text2')}
              </Link>{' '}
              {t('Messages.Checkbox.Text3')}{' '}
              <Link to={Routes.Datenschutz} style={{ color: '#000' }}>
                {t('Messages.Checkbox.Text4')}
              </Link>
            </Text>
          }
        />
      </Flex>

      <Button
        className={style.registerBtn}
        disabled={!registerFormValid}
        type="submit"
        data-cy="register-button"
      >
        {t('Buttons.Register')}
      </Button>

      <Text my="10px" c="#7F898F">
        {t('Messages.YouAlreadyHaveAnAccount')}{' '}
        <Link to={Routes.Study} style={{ color: '#3D7F53' }}>
          {t('Buttons.Login')}
        </Link>
      </Text>

      <DevContent>
        <Flex direction="row" gap="10px">
          <Text my="5px">(Copies email to clipboard, PW: 1234)</Text>
          <Button onClick={() => onRandomize(Form)}>Randomize</Button>
        </Flex>
      </DevContent>
    </form>
  );
};
