import { Box, Flex, Space, Button, Divider, Text } from '@mantine/core';
import { InfoItem, MyIcon } from '../../../../component';
import classes from './BookInPerson.module.css';
import { useUserMeStore } from '../../../../store';
import { EventSchemaDto, EventsSchemaDto } from '@web/common';
import { splitLocationString } from '../../../../util/appointment/splitLocationString';
import { WorkingHours } from './WorkingHours';
import { useTranslation } from 'react-i18next';
import { makeAppointment } from './makeAppointment';

export const BookingItemList = ({
  data,
  selectedTeamSlug,
  setSelectedTeamSlug,
  selectedEventSlug,
  setSelectedEventSlug,
  setSelectedLocation,
}: {
  data: EventsSchemaDto;
  selectedTeamSlug: string | null;
  setSelectedTeamSlug: (slug: string) => void;
  selectedEventSlug: number | null;
  setSelectedEventSlug: (slug: number) => void;
  selectedLocation: string | null;
  setSelectedLocation: (location: string) => void;
}) => {
  const userMeStore = useUserMeStore();
  const { t } = useTranslation('appointment-booking');

  return data.map((event: EventSchemaDto, index: number) => (
    <Box key={index} style={{ cursor: 'pointer' }}>
      <Flex
        className={classes.itemContainer}
        onClick={() => {
          setSelectedTeamSlug(event.team.slug);
          setSelectedEventSlug(event.id);
          setSelectedLocation(splitLocationString(event.locations).link);
        }}
        style={{
          borderColor: selectedTeamSlug === event.slug ? '#3D7F53' : '#fff',
        }}
      >
        <Flex direction="column" gap="5px">
          <Text fw="600">{event.title}</Text>
          <Space />
          <InfoItem
            icon={<MyIcon name="location" className={classes.icon} />}
            text={splitLocationString(event.locations).address}
            textColor="#7F898F"
          />
          <InfoItem
            icon={<MyIcon name="clock" className={classes.icon} />}
            text={<WorkingHours workingHours={event.workingHours} />}
            textColor="#7F898F"
          />
        </Flex>
        {selectedTeamSlug === event.team.slug &&
          selectedEventSlug === event.id && (
            <Button
              fw="500"
              onClick={() =>
                makeAppointment(
                  selectedTeamSlug,
                  event.slug,
                  selectedEventSlug,
                  userMeStore.myInfo!.id,
                  userMeStore.myInfo!.userSearchID,
                  userMeStore.myInfo!.email,
                )
              }
            >
              {t('SelectAppointment')}
            </Button>
          )}
      </Flex>
      <Divider color="#B1B1B1" />
    </Box>
  ));
};
