import { Flex, Text } from '@mantine/core';

export const InfoItem = ({
  icon,
  text,
  secondText,
  textColor,
  customText,
}: {
  icon: JSX.Element;
  text?: string | JSX.Element;
  secondText?: string;
  textColor?: string;
  customText?: JSX.Element;
}) => (
  <Flex gap="15px" align="center" c={textColor}>
    {icon}
    <Flex direction="column">
      {customText}
      <Text w="100%" size="14px" lh="18px">
        {text}
      </Text>
      {secondText && (
        <Text w="100%" size="14px" lh="18px">
          {secondText}
        </Text>
      )}
    </Flex>
  </Flex>
);
