import { Slider } from '@mantine/core';
import classes from './MySlider.module.css';
import useSliderHighlight from './useSliderHighlight';

export const MySlider = ({
  setValue,
  value,
}: {
  setValue: (value: number) => void;
  value: number;
}) => {
  useSliderHighlight();

  return (
    <Slider
      p="20px 30px 80px 30px"
      defaultValue={10}
      value={value}
      showLabelOnHover={false}
      label={null}
      onChange={(v) => setValue(v)}
      w="100%"
      marks={[
        { value: 0, label: '0g' },
        { value: 20, label: '2' },
        { value: 40, label: '4' },
        { value: 60, label: '6' },
        { value: 80, label: '8' },
        { value: 100, label: '10g' },
      ]}
      size={12}
      step={5}
      classNames={classes}
    />
  );
};
