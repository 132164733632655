import {
  Divider,
  Drawer,
  Flex,
  LoadingOverlay,
  ScrollArea,
  Space,
} from '@mantine/core';
import { observer } from 'mobx-react';
import { ItemCard } from './ItemCard';
import { useCart } from '@shopify/hydrogen-react';
import { CartLine } from '@shopify/hydrogen-react/storefront-api-types';
import { useShopStore } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import classes from './shoppingCart.module.css';
import { CheckoutSection } from './CheckoutSection';
import { LimitBanner } from './LimitBanner';
import { useCheckoutData } from './useCheckoutData';
import { useEffect } from 'react';

export const ShoppingCartDrawer = observer(
  ({ opened, toggle }: { opened: boolean; toggle: () => void }) => {
    const { t } = useTranslation('shop');
    const { lines } = useCart();
    const lineArray = lines as CartLine[];
    const store = useShopStore();

    useEffect(() => {
      if (!lines || lines?.length === 0) store.setCartDrawerOpen(false);
    }, [lines, store, opened]);

    const {
      totalCost,
      totalThc,
      totalWeight,
      canCheckout,
      availableAmount,
      currentThcUnit,
    } = useCheckoutData(lineArray);

    return (
      <Drawer.Root opened={opened} onClose={toggle} position="right" size="sm">
        <Drawer.Content>
          <Drawer.Header>
            <Drawer.Title className={classes.title}>
              {t('ShoppingCart.Title')}
            </Drawer.Title>
            <Drawer.CloseButton />
          </Drawer.Header>

          <Drawer.Body p="0px">
            <Divider />
            <ScrollArea h="calc(100vh - 280px)">
              {!canCheckout && !!lines?.length && <LimitBanner />}
              <LoadingOverlay
                visible={store.loadingCart}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 0.5 }}
              />
              <Flex gap="15px" direction="column" p="15px">
                {lineArray.map((line) => (
                  <ItemCard key={line.id} order={line} />
                ))}
              </Flex>
            </ScrollArea>
            <Divider />
            <Space h="lg" />
            <CheckoutSection
              orderAmount={lineArray.length}
              toggle={toggle}
              totalCost={totalCost}
              totalThc={totalThc}
              totalWeight={totalWeight}
              availableAmount={availableAmount}
              currentThcUnit={currentThcUnit}
              canCheckout={canCheckout}
            />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    );
  },
);
