import { Flex, Text } from '@mantine/core';
import { StammDaten } from '@web/common';
import { getCannavigiaSourceData } from '../../../../store/UserState/getCannavigiaData';

const InfoText = ({ title, content }: { title: string; content: string }) => (
  <Flex>
    <Text w="220px" fw="500">
      {title}:
    </Text>
    <Text fw="400">{content}</Text>
  </Flex>
);

export enum SexEnumTranslation {
  male = 'Männlich',
  female = 'Weiblich',
  divers = 'Divers',
}

export const UserCard = (stammdaten: StammDaten | null) => {
  if (!stammdaten) return null;

  const { vorname, nachname, sex, birthdate, birthPlace, idNumber, idType } =
    getCannavigiaSourceData(stammdaten);

  return (
    <div>
      Daten zur Kontrolle:
      <div style={{ background: '#F7F6F1', padding: 10, borderRadius: 5 }}>
        <InfoText title={'Vorname'} content={vorname} />
        <InfoText title={'Nachname'} content={nachname} />
        <InfoText title={'Geschlecht'} content={SexEnumTranslation[sex]} />
        <InfoText title={'Geburtsdatum'} content={birthdate} />
        <InfoText
          title={'Heimatort / Geburtsort'}
          content={birthPlace ?? '-'}
        />
        <InfoText title={'Ausweisnummer'} content={idNumber ?? '-'} />
        <InfoText title={'Ausweisart'} content={idType} />
      </div>
    </div>
  );
};
