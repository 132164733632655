import { Feeling } from '@prisma/client';

export const feelings = [
  { icon: '🤩', value: Feeling.EXITED },
  { icon: '😨', value: Feeling.FEARFUL },
  { icon: '😳', value: Feeling.FLUSHED },
  { icon: '😄', value: Feeling.HAPPY },
  { icon: '😓', value: Feeling.STRESSED },
  { icon: '😞', value: Feeling.DEPRESSED },
  { icon: '😴', value: Feeling.TIRED },
  { icon: '😔', value: Feeling.LONELY },
  { icon: '😇', value: Feeling.GOOD },
  { icon: '🥰', value: Feeling.LOVE },
  { icon: '🥱', value: Feeling.YAWNING },
  { icon: '😡', value: Feeling.ANGRY },
  { icon: '🤒', value: Feeling.SICK },
  { icon: '😎', value: Feeling.COOL },
  { icon: '😰', value: Feeling.NERVOUS },
  { icon: '🥳', value: Feeling.PARTY },
  { icon: '🤠', value: Feeling.FIT },
  { icon: '😦', value: Feeling.HUNTED },
  { icon: '😵‍💫', value: Feeling.DIZZY },
] as const;

export const getFeelingIcon = (value: Feeling) => {
  const feeling = feelings.find((f) => f.value === value);
  return feeling?.icon;
};
