import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { sentrySettings } from '@web/common';
import { browserTracingIntegration } from '@sentry/react';

export function setupSentry() {
  const hostUrl = window.location.origin;
  const sentryDsn = process.env['NX_SENTRY_DSN_FRONTEND'];
  const isDev = hostUrl.includes('localhost');

  // if testing locally, don't initialize
  if (isDev) return;

  console.debug('initializing Sentry...');
  Sentry.init({
    dsn: sentryDsn,
    environment: process.env['NX_ENVIRONMENT'],
    release: process.env['NX_REVISION'],
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      browserTracingIntegration(),
    ],

    ...sentrySettings,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.5,
  });
}
