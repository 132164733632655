import { Flex, Text, Textarea } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useDiaryDialogStore } from '../DiaryDialogStore';

export const OptionalNotes = observer(() => {
  const store = useDiaryDialogStore();
  const { t } = useTranslation('diary-dialog');

  return (
    <Flex direction="column" gap="10px">
      <Text fw="600" size="16px" lh="24px">
        {t('OptionalNotes.Title')}
      </Text>
      <Textarea
        placeholder="Notizen"
        onChange={(event) => store.setNotes(event.target.value)}
        maxLength={2048}
        autosize={true}
      />
    </Flex>
  );
});
