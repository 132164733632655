import { z } from 'zod';

const lineItem = z.object({
  id: z.string(),
  name: z.string(),
  variant: z.object({
    id: z.string(),
  }),
  currentQuantity: z.number(),
});

const totalPriceSet = z.object({
  presentmentMoney: z.object({
    amount: z.coerce.number(),
    currencyCode: z.string(),
  }),
  shopMoney: z.object({
    amount: z.coerce.number(),
    currencyCode: z.string(),
  }),
});

export const billingAddressSchemaDto = z.object({
  address1: z.string(),
  address2: z.string().nullable(),
  city: z.string(),
  countryCode: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string(),
  phone: z.string().nullable(),
  province: z.string().nullable(),
  zip: z.string(),
});

const displayFinancialStatusEnum = z.enum([
  'AUTHORIZED',
  'EXPIRED',
  'PAID',
  'PARTIALLY_PAID',
  'PARTIALLY_REFUNDED',
  'PENDING',
  'REFUNDED',
  'VOIDED',
]);
export type DisplayFinancialStatusEnum = z.infer<
  typeof displayFinancialStatusEnum
>;

/*
the displayFulfillmentStatus is not very useful to determine, if it is for pickup or something else
 */
const displayFulfillmentStatus = z.enum([
  'FULFILLED',
  'IN_PROGRESS',
  'ON_HOLD',
  'OPEN',
  'PARTIALLY_FULFILLED',
  'PENDING_FULFILLMENT',
  'RESTOCKED',
  'SCHEDULED',
  'UNFULFILLED',
]);
export type DisplayFulfillmentStatus = z.infer<typeof displayFulfillmentStatus>;

const node = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),

  cancelReason: z
    .enum(['CUSTOMER', 'DECLINED', 'FRAUD', 'INVENTORY', 'OTHER', 'STAFF'])
    .nullable(),
  cancellation: z
    .object({
      staffNote: z.string(),
    })
    .nullable(),
  cancelledAt: z.string().nullable(),
  closed: z.boolean(),
  closedAt: z.string().nullable(),
  unpaid: z.boolean(),
  fullyPaid: z.boolean(),
  confirmed: z.boolean(),
  displayFinancialStatus: displayFinancialStatusEnum,
  displayFulfillmentStatus: displayFulfillmentStatus,

  createdAt: z.string(),
  processedAt: z.string(),

  lineItems: z.object({
    nodes: z.array(lineItem),
  }),
  totalPriceSet,
  billingAddress: billingAddressSchemaDto,
});

const order = z.object({
  node: node,
});

export type ShopifyOrderNode = z.infer<typeof node>;

export const shopifyOrderResDto = z.object({
  orders: z.object({
    edges: z.array(order),
  }),
});
export type ShopifyOrderResDto = z.infer<typeof shopifyOrderResDto>;
