import { z } from 'zod';

export const shopifyMarkAsPickedUpResDto = z.object({
  fulfillmentCreateV2: z.object({
    fulfillment: z
      .object({
        id: z.string(),
        status: z.string(),
      })
      .nullable(),
    userErrors: z.array(
      z.object({
        field: z.array(z.string()),
        message: z.string(),
      }),
    ),
  }),
});
