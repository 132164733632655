import { z } from 'zod';

export const TWILIO_CB_MESSAGE_STATUS_DELIVERED = 'delivered';

const TWILIO_CB_MESSAGE_STATUS_UNDELIVERED = 'undelivered';
const TWILIO_CB_MESSAGE_STATUS_FAILED = 'failed';
export const TWILIO_CB_MESSAGE_FAILING_STATUSES = [
  TWILIO_CB_MESSAGE_STATUS_UNDELIVERED,
  TWILIO_CB_MESSAGE_STATUS_FAILED,
];
export const TWILIO_CB_MESSAGE_RELEVANT_STATUSES = [
  TWILIO_CB_MESSAGE_STATUS_DELIVERED,
  ...TWILIO_CB_MESSAGE_FAILING_STATUSES,
];

export const twilioNotificationCbResDto = z
  .object({
    MessagingServiceSid: z.string(),
    ApiVersion: z.string(),
    MessageStatus: z.string(),
    SmsSid: z.string(),
    SmsStatus: z.union([
      z.literal('queued'),
      z.literal('sent'),
      z.literal(TWILIO_CB_MESSAGE_STATUS_DELIVERED),
      z.literal(TWILIO_CB_MESSAGE_STATUS_UNDELIVERED),
      z.literal(TWILIO_CB_MESSAGE_STATUS_FAILED),
      z.string(),
    ]),
    To: z.string(),
    From: z.string(),
    MessageSid: z.string(),
    AccountSid: z.string(),
    StructuredMessage: z.string().optional(),
    ChannelToAddress: z.string().optional(),
    ChannelPrefix: z.string().optional(),
    ChannelInstallSid: z.string().optional(),
    RawDlrDoneDate: z.string().optional(),
  })
  // we want to keep unknown additional fields, because of the header validation
  .passthrough();
export type TwilioNotificationCbResDto = z.infer<
  typeof twilioNotificationCbResDto
>;
