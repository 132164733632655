import {
  AffiliateCodeProvider,
  AffiliateCodeStore,
} from './AffiliateCodeStore';
import { Modal } from './Modal';

export const AffiliateCodeModal = () => (
  <AffiliateCodeProvider store={new AffiliateCodeStore()}>
    <Modal />
  </AffiliateCodeProvider>
);
