import { Button, Flex, Grid, Group, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useDiaryDialogStore } from '../DiaryDialogStore';
import { useTranslation } from 'react-i18next';
import { feelings } from '@web/common';
import { Feeling } from '../../../../../../component';

export const Feelings = observer(({ makeStep }: { makeStep: () => void }) => {
  const store = useDiaryDialogStore();
  const didConsume = store.didConsume;
  const { t } = useTranslation('diary-dialog');

  return (
    <Flex direction="column" gap="10px">
      <Text fw="600" size="16px" lh="24px">
        {t(
          'Feelings.' +
            (didConsume ? 'HowDidYouFeel' : 'HowDidYouFeelAboutNotConsuming'),
        )}
      </Text>
      <Text c="#4A4A48"> {t('Feelings.SelectYourFeelings')}</Text>

      <Grid gutter="sm">
        {feelings.map((feeling) => {
          const isActive = store.consumptionFeelings.includes(feeling.value);
          return (
            <Grid.Col span={4} key={feeling.value}>
              <Feeling
                isActive={isActive}
                value={feeling.value}
                icon={feeling.icon}
                onClick={() => store.toggleConsumptionFeelings(feeling.value)}
              />
            </Grid.Col>
          );
        })}
      </Grid>
      <Group justify="center" mt="xl">
        <Button onClick={makeStep} w="100%">
          {t('Next')}
        </Button>
      </Group>
    </Flex>
  );
});
