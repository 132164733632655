import { observer } from 'mobx-react';
import {
  ResponsibleUserStore,
  ResponsibleUserStoreProvider,
} from './ResponsibleUserStore';
import { ResponsibleUserUI } from './ResponsibleUserUI';

export const ResponsibleUser = observer(() => {
  return (
    <ResponsibleUserStoreProvider store={new ResponsibleUserStore()}>
      <ResponsibleUserUI />
    </ResponsibleUserStoreProvider>
  );
});
