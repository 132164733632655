import { action, makeObservable, observable } from 'mobx';
import { setupMobX } from '../util/setupMobX';
import ReactPixel from 'react-snapchat-pixel';

export enum SnapEvent {
  Survey_Complete = 'CUSTOM_EVENT_1',
  Register_Page = 'CUSTOM_EVENT_2',
  Not_Eligible_Page = 'CUSTOM_EVENT_3',
  User_registerd = 'CUSTOM_EVENT_4',
  Wants_to_book_appointment = 'CUSTOM_EVENT_5',
  Enroll_Register = 'SIGN_UP',
}

export class SnapPixelStore {
  @observable public authenticated = false;

  constructor() {
    makeObservable(this);
  }

  @action public init() {
    const pixelCode = process.env['NX_SNAP_PIXEL_CODE'];
    const isProd = process.env['NX_ENVIRONMENT'] === 'Production';

    if (isProd && pixelCode) {
      ReactPixel.init(pixelCode);
      ReactPixel.pageView();
    }
  }

  @action public track(event: SnapEvent) {
    ReactPixel.track(event);
  }
}

const { provider, useStore } = setupMobX<SnapPixelStore>();
export const SnapPixelProvider = provider;
export const useSnapPixelStore = useStore;
