import { TierList } from '../enum';

export function getRank(points: number): keyof typeof TierList {
  const tierKeys = Object.keys(TierList) as Array<keyof typeof TierList>;
  const rank = tierKeys.reverse().find((key) => TierList[key] <= points);
  const lowestRank = tierKeys[0];
  return rank ?? lowestRank;
}

export function getRankPointLimit(
  rank: keyof typeof TierList,
): number | undefined {
  // get the rank of the next tier
  const tierKeys = Object.keys(TierList) as Array<keyof typeof TierList>;
  const rankIndex = tierKeys.indexOf(rank);

  // if the rank is the highest rank, return the points of the highest rank
  if (rankIndex === tierKeys.length - 1) {
    return undefined;
  }

  // return the points of the next rank
  return TierList[tierKeys[rankIndex + 1]] - 1;
}

export function isRankValid(rank: keyof typeof TierList): boolean {
  return Object.keys(TierList).includes(rank);
}
