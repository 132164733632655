import { z } from 'zod';
import { cannabisConsumptionQuestionnaireReqDto } from '../cannabis-consumption-questionnaire';
import { sf12QuestionnaireReqDto } from '../sf12-questionnaire';
import { stammdatenQuestionnaireReqDto } from '../stammdaten-questionnaire/stammdaten-questionnaire.req.dto';

// if you change this, check anonymize-user.service deletePIIStammdaten and user-search.service!
export enum CheckListId {
  EmailVerified = 'email-verified',
  Einwilligungserklaerung = 'einwilligungserklaerung',
  StammdatenQuestionnaire = 'stammdaten-questionnaire',
  CannabisConsumptionQuestionnaire = 'cannabis-consumption-questionnaire',
  SF12Questionnaire = 'sf-12-questionnaire',
  Wohnsitznachweis = 'wohnsitznachweis',
  AHVNummerNachweis = 'ahv-nummer-nachweis',
  IdentitaetsNachweis = 'identitaets-nachweis',
}

// if you change this, check anonymize-user.service deletePIIStammdaten and user-search.service!
export enum CheckListItemType {
  SimpleCheck = 'SimpleCheck',
  QuestionnaireStammdaten = 'QuestionnaireStammdaten',
  Questionnaire12SF = 'Questionnaire12SF',
  QuestionnaireCannabisConsumption = 'QuestionnaireCannabisConsumption',
  DocumentDownloaded = 'DocumentDownloaded',
}
// if you change this, check anonymize-user.service deletePIIStammdaten and user-search.service!
export const questionnaireResultField = 'questionnaireResult';

const commonFields = z.object({
  type: z.nativeEnum(CheckListItemType),
  done: z.boolean(),
});
export type CheckListItem = z.infer<typeof commonFields>;
export type StammDaten = z.infer<typeof stammdatenQuestionnaireReqDto>;

export const checkListData = z.object({
  [CheckListId.EmailVerified]: commonFields,
  [CheckListId.Einwilligungserklaerung]: commonFields,
  [CheckListId.StammdatenQuestionnaire]: z
    .object({
      [questionnaireResultField]: stammdatenQuestionnaireReqDto.optional(),
    })
    .merge(commonFields),
  [CheckListId.CannabisConsumptionQuestionnaire]: z
    .object({
      [questionnaireResultField]:
        cannabisConsumptionQuestionnaireReqDto.optional(),
    })
    .merge(commonFields),
  [CheckListId.SF12Questionnaire]: z
    .object({
      [questionnaireResultField]: sf12QuestionnaireReqDto.optional().nullable(),
    })
    .merge(commonFields),
  [CheckListId.Wohnsitznachweis]: commonFields,
  [CheckListId.AHVNummerNachweis]: commonFields,
  [CheckListId.IdentitaetsNachweis]: commonFields,
});
export type CheckListDataSchema = z.infer<typeof checkListData>;

export const versionSchema = z.object({
  version: z.number(),
});

export const storeChecklistReqDto = versionSchema.extend({
  data: checkListData.optional(),
});
export type StoreChecklistReqDto = z.infer<typeof storeChecklistReqDto>;
