import type {
  CreateDiaryEntryReqDto,
  DiaryEntry,
  DiaryEntryArraySchema,
} from '@web/common';
import { setupMobX } from '../../../../../util/setupMobX';
import { observable, makeObservable, action, runInAction } from 'mobx';
import {
  createDiaryEntry,
  getDiaryEntries,
} from '../../../../../request/authenticated-requests/diary';
import { showErrorNotification } from '../../../../../component/notification';
import { DateTime } from 'luxon';

export class DiaryStore {
  @observable public loading: boolean = false;
  @observable public diaryEntries: DiaryEntryArraySchema | null = null;
  @observable public hasMore: boolean = true;
  @observable public step: number = 0;
  @observable public earliestDate: Date | null = null;

  @observable public openDiaryEntry: null | DiaryEntry = null;

  constructor() {
    makeObservable(this);
  }

  @action public loadDiaryEntries(step?: number) {
    if (this.loading) return;
    const currentStep = step ?? this.step;

    this.loading = true;

    const baseDate = DateTime.now().minus({ month: currentStep }).toUTC();
    const from = baseDate.startOf('month').toJSDate();
    const until = baseDate.endOf('month').toJSDate();

    getDiaryEntries(from, until)
      .then((res) =>
        runInAction(() => {
          if (currentStep === 0) {
            this.diaryEntries = res.entries;
          } else {
            this.diaryEntries = [...(this.diaryEntries || []), ...res.entries];
          }

          this.step = currentStep + 1;
          this.earliestDate = res.meta.earliestEntryDate;
          this.hasMore = Boolean(this.earliestDate && from > this.earliestDate);
        }),
      )
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loading = false)));
  }

  @action public addDiaryEntry(data: CreateDiaryEntryReqDto) {
    createDiaryEntry(data)
      .then(() => this.loadDiaryEntries())
      .catch(() => showErrorNotification());
  }

  @action public showInDialog(entry: DiaryEntry) {
    this.openDiaryEntry = entry;
  }

  @action public closeDialog() {
    this.openDiaryEntry = null;
  }
}

const { provider, useStore } = setupMobX<DiaryStore>();
export const useDiaryStore = useStore;
export const DiaryStoreProvider = provider;
