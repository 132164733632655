import { GetPodcastSchema } from '@web/common';
import classes from './podcast.module.css';
import { Card, MyIcon } from '../../../../component';
import { Flex, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  ObtainablePoints,
  ObtainablePointsStatus,
} from '../../../../component/ObtainablePoints';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';

export enum PodcastListItemType {
  Default,
  Important,
}

export const PodcastListItem = observer(
  ({ podcast }: { podcast: GetPodcastSchema }) => {
    const { t } = useTranslation('podcast');
    const navigate = useNavigate();

    const { id, unlocked, loyalityPointsAvailable, loyalityPoints } = podcast;

    return (
      <div
        onClick={
          podcast.unlocked
            ? () => navigate(`${Routes.Study}/${Routes.PodcastDetails}/${id}`)
            : undefined
        }
      >
        <Card
          className={classes.card}
          flexProps={{ align: 'start', direction: 'column' }}
        >
          <Flex
            direction={'row'}
            justify={'space-between'}
            align={'center'}
            w={'100%'}
          >
            <Flex
              direction={'row'}
              align={'center'}
              justify={'center'}
              gap={'1em'}
            >
              <MyIcon name={unlocked ? 'podcast_open' : 'podcast_locked'} />
              <Text fw={700} size={'16px'} lh={'24px'} c={'#202020'}>
                {t(`Podcast.${id}.Title`)}
              </Text>
            </Flex>
            {loyalityPointsAvailable && (
              <ObtainablePoints
                points={loyalityPoints}
                status={
                  unlocked
                    ? ObtainablePointsStatus.Available
                    : ObtainablePointsStatus.Unavailable
                }
              />
            )}
          </Flex>
          <Text fw={400} size={'16px'} lh={'24px'} c={'#202020'}>
            {t(`Podcast.${id}.Subtitle`)}
          </Text>
        </Card>
      </div>
    );
  },
);
