import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import Keycloak from 'keycloak-js';
import { setupMobX } from '../util/setupMobX';
import { allRealmRoles, RealmRole } from '@web/common';
import { newKeycloakInstance } from '../config/keycloakUrl';
import { Routes } from '../route/Routes';
import { DateTime } from 'luxon';
import { isLocalOrDev } from '../config/environment';

const logging = !isLocalOrDev;

export class KeycloakStore {
  @observable public initializingInProgress: boolean = false;

  @observable public initialized = false;
  @observable public authenticated = false;
  @observable public realmRoleList: RealmRole[] | null = null;
  @observable public currentRealmRole: RealmRole | null = null;

  // do not change this from the outside!!!
  private keycloak: null | Keycloak = null;
  private tokenRefresherHandle: NodeJS.Timeout | null = null;

  constructor() {
    makeObservable(this);
  }

  @action public async initKeycloak() {
    if (this.initializingInProgress) {
      return Promise.resolve();
    }
    runInAction(() => {
      this.initializingInProgress = true;
    });

    this.keycloak = newKeycloakInstance();
    this.setupAccessTokenRefresh(this.keycloak);

    return this.keycloak
      .init({
        onLoad: 'check-sso',
        enableLogging: logging,
      })
      .then((authenticated) => {
        console.debug(
          `successfully initialized keycloak. Authenticated: ${authenticated}`,
        );
        runInAction(() => {
          this.initialized = true;
          this.initializingInProgress = false;
          this.authenticated = this.keycloak?.authenticated || false;
        });
      })
      .catch((error) => {
        console.error(
          `Failed to initialize Keycloak: ${JSON.stringify(error)}`,
        );
      });
  }

  @action public login(username?: string, password?: string) {
    if (!this.keycloak) {
      console.warn(`This shouldn't happen, talk to the developers!`);
      this.initKeycloak()
        .then(() => {
          this.login(username, password);
        })
        .catch(console.error);
      return;
    }
    const redirectUri = window.location.origin + `${Routes.Study}`;
    const url = this.keycloak?.createLoginUrl({ redirectUri });
    if (!url) {
      console.error(`Failed to create login url`);
      return;
    }

    if (!!username && !!password) {
      const params = new URLSearchParams({ username, password });
      window.location.assign(`${url}#${params.toString()}`);
    } else {
      window.location.assign(url);
    }
  }

  @action public logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.keycloak
      ?.logout({
        redirectUri: window.location.origin,
      })
      .catch(console.error);
  }

  @computed public get token() {
    return this.keycloak?.token || ``;
  }

  @computed public get tokenParsed() {
    return this.keycloak?.tokenParsed || {};
  }

  @computed private get buildLoginUrl(): string {
    const redirectUri = window.location.href;

    return (
      this.keycloak?.createLoginUrl({
        redirectUri: redirectUri,
      }) || ''
    );
  }

  @computed public get kcRedirectUrl(): string {
    if (!this.keycloak) {
      this.initKeycloak()
        .then(() => {
          return this.buildLoginUrl;
        })
        .catch(console.error);
    }

    return this.buildLoginUrl;
  }

  public async updateToken() {
    const keycloak = this.keycloak;
    if (!keycloak) {
      // warning
      return;
    }

    return keycloak
      .updateToken(1000000)
      .then((refreshed) => {
        if (refreshed) {
          const exp = keycloak.tokenParsed?.exp ?? 0;
          console.debug(
            `Token refreshed, valid until: ${DateTime.fromSeconds(exp)}`,
          );

          if (this.tokenRefresherHandle) {
            clearInterval(this.tokenRefresherHandle);
            this.tokenRefresherHandle = null;
          }

          const millisecondsValid = Math.round(
            exp * 1000 - new Date().getTime(),
          );
          console.debug(`Token valid for ${millisecondsValid / 1000} seconds`);
          const tokenRefreshInterval = Math.ceil(
            (millisecondsValid / 100) * 80,
          );
          console.debug(`Token refresh interval: ${tokenRefreshInterval}`);
          this.tokenRefresherHandle = setTimeout(
            () => {
              console.debug(`Refreshing token via interval...`);
              this.updateToken().catch(console.error);
            },
            Math.max(15 * 1000, tokenRefreshInterval),
          );
        } else {
          console.debug('Token not refreshed, still valid');
        }
      })
      .catch((error) => {
        console.error(`Failed to refresh token: ${JSON.stringify(error)}`);
        runInAction(() => {
          this.login();
        });
      });
  }

  private setupAccessTokenRefresh(keycloak: Keycloak) {
    keycloak.onAuthSuccess = () => {
      console.debug('Auth success');
      runInAction(() => {
        this.realmRoleList = this.extractRoles(keycloak);
        this.currentRealmRole = this.extractCurrentRealmRole(
          this.realmRoleList,
        );
      });
    };
    keycloak.onAuthRefreshSuccess = () => {
      console.debug('Access token refreshed');
      runInAction(() => {
        this.realmRoleList = this.extractRoles(keycloak);
        this.currentRealmRole = this.extractCurrentRealmRole(
          this.realmRoleList,
        );
      });
    };
    keycloak.onTokenExpired = () => {
      console.debug('Access token expired');
      const { exp } = keycloak.tokenParsed ?? {};
      if (!exp) {
        this.login();
        return;
      }

      this.updateToken().catch(console.error);
    };
  }

  private extractRoles(keycloak: Keycloak): RealmRole[] {
    const roles = keycloak.tokenParsed?.realm_access?.roles || [];
    return roles.filter((role) =>
      allRealmRoles.includes(role as RealmRole),
    ) as RealmRole[];
  }

  private extractCurrentRealmRole(realmRoles: RealmRole[]): RealmRole | null {
    /*
    this might need to be improved, if we ever have the case, that a user can have multiple roles
     */
    if (realmRoles.length === 0) {
      return null;
    }
    return realmRoles[0];
  }
}

const { provider, useStore } = setupMobX<KeycloakStore>();
export const KeycloakProvider = provider;
export const useKeycloakStore = useStore;
