import { Tooltip, Flex, Text } from '@mantine/core';
import { MyIcon } from '../../../../../../component';
import { useTranslation } from 'react-i18next';

export const THCTooltip = () => {
  const { t } = useTranslation('homescreen');
  return (
    <Tooltip
      multiline
      maw={250}
      withArrow
      arrowSize={8}
      position="right"
      styles={{
        tooltip: {
          padding: '10px 15px',
        },
      }}
      inline
      events={{ hover: true, focus: true, touch: true }}
      label={
        <Flex gap="10px" direction="column">
          <Text size="14px" lh="20px">
            {t('THC.Tooltip.Description1')}
          </Text>

          <Text size="14px" lh="20px">
            {t('THC.Tooltip.Description2')}
          </Text>
        </Flex>
      }
    >
      <MyIcon
        name="info"
        style={{
          fill: '#3D7F53',
          height: '17px',
          width: '17px',
          margin: '0px 0px -3px 3px',
        }}
      />
    </Tooltip>
  );
};
