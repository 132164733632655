import { Language } from '@prisma/client';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../../component/notification';
import { requestEnrollMail, requestEnrollSMS } from '../../../../../request';
import { setupMobX } from '../../../../../util/setupMobX';
import { observable, makeObservable, action, runInAction } from 'mobx';

const success = {
  de: 'Weitere Details wurden an Dich versendet!',
  en: 'Further details have been sent to you!',
};

export class EnrollStore {
  @observable public loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public enrollUser(mailOrPhonenumber: string, userLanguage: Language) {
    this.loading = true;

    const isEmail = /\S+@\S+\.\S+/.test(mailOrPhonenumber);
    const enrollRequest = isEmail ? requestEnrollMail : requestEnrollSMS;

    enrollRequest(mailOrPhonenumber, userLanguage)
      .then(() => showSuccessNotification(success[userLanguage]))
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loading = false)));
  }
}

const { provider, useStore } = setupMobX<EnrollStore>();
export const useEnrollStore = useStore;
export const EnrollStoreProvider = provider;
