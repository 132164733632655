import { observer } from 'mobx-react';

import { ChangeDispensaryModal } from './ChangeDispensaryModal';
import {
  ChangeDispensaryStore,
  ChangeDispensaryStoreProvider,
} from './ChangeDispensaryStore';
import { DispensaryStore, DispensaryStoreProvider } from '../../../../store';

export const ChangeDispensaryWrapper = observer(
  ({ userId }: { userId: string }) => {
    return (
      <DispensaryStoreProvider store={new DispensaryStore()}>
        <ChangeDispensaryStoreProvider store={new ChangeDispensaryStore()}>
          <ChangeDispensaryModal userId={userId} />
        </ChangeDispensaryStoreProvider>
      </DispensaryStoreProvider>
    );
  },
);
