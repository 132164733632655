/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Text } from '@mantine/core';
import { Product } from './Product';
import { ProductDto, ProductsResDto } from '@web/common';
import { useTranslation } from 'react-i18next';

export function ProductList({ products }: { products: ProductsResDto }) {
  const { t } = useTranslation('shop');

  return (
    <div>
      <Text size="16px" lh="24px" fw="600" py="20px">
        {products.length} {t('Products')}
      </Text>
      <Grid align="stretch" grow>
        {products?.map((product: ProductDto) => (
          <Grid.Col span={{ base: 6, md: 3, lg: 3 }} key={product.id}>
            <Product key={product.id} product={product} />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
