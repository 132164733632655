import {
  UserStatusChangeReqDto,
  markUserAsResDto,
  userDataResDto,
  emailVerificationMailResDto,
  pausedUsersResDto,
  usersSearchResultResDto,
  onboardingDataResDto,
  userBagSurveyStatusResDto,
  UserFilterQueryReqDto,
  MyDataReqDto,
  usersFilterResDto,
  myCohortResDto,
} from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';
import { z } from 'zod';

export const searchUser = async (searchText: string) => {
  return authenticatedAPI.get(
    `/api/users/search/${searchText}`,
    usersSearchResultResDto,
  );
};

export const filterUsers = async (query: UserFilterQueryReqDto) => {
  return authenticatedAPI.get(`/api/users/search`, usersFilterResDto, query);
};

export const getUserById = async (userId: string) => {
  return authenticatedAPI.get(`/api/users/${userId}`, onboardingDataResDto);
};

export const getMe = async () => {
  return authenticatedAPI.get(`/api/users/me`, userDataResDto);
};

export const markUserAs = async (
  userId: string,
  data: UserStatusChangeReqDto,
) => {
  return authenticatedAPI.post(
    `/api/users/${userId}/status-change`,
    data,
    markUserAsResDto,
  );
};

export const requestEmailVerificationMail = async () => {
  return authenticatedAPI.get(
    `/api/users/me/request-email-verification`,
    emailVerificationMailResDto,
  );
};

export const pausedUsers = async () => {
  return authenticatedAPI.get(`/api/users/paused`, pausedUsersResDto);
};

export const getMyCohort = async () => {
  return authenticatedAPI.get(`/api/users/me/cohort`, myCohortResDto);
};

export const getBagSurveyStatus = async (userId: string) => {
  return authenticatedAPI.get(
    `/api/users/${userId}/bag-survey-status`,
    userBagSurveyStatusResDto,
  );
};

export const updateMyData = async (data: MyDataReqDto) => {
  return authenticatedAPI.patch(`/api/users/my-data`, data, z.string());
};
