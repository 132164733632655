import { Flex, Text } from '@mantine/core';
import register_img from '../../../assets/register.svg';
import { useTranslation } from 'react-i18next';

export const CongratulationsMessageDesktop = () => {
  const { t } = useTranslation('register');
  return (
    <Flex w="50%" direction="column" align="center" gap="30px">
      <img
        src={register_img}
        alt="register"
        style={{ minWidth: '200px', maxWidth: '250px' }}
      />
      <Text size="28px" ta="center">
        {t('Congratulation.TitleDesktop')}
      </Text>
      <Text maw="380px" ta="center">
        {t('Congratulation.Description')}
      </Text>
    </Flex>
  );
};

export const CongratulationsMessageMobile = () => {
  const { t } = useTranslation('register');
  return (
    <Flex w="100%" direction="column" align="start" gap="25px">
      <img
        src={register_img}
        alt="register"
        style={{ minWidth: '80px', maxWidth: '140px' }}
      />
      <Text size="28px" lh="24px" ta="center" fw="500">
        {t('Congratulation.TitleMobile')}
      </Text>
      <Text lh="24px" maw="380px" c="#4A4A48">
        {t('Congratulation.Description')}
      </Text>
    </Flex>
  );
};
