import { observer } from 'mobx-react';
import { useDiaryStore } from '../../stores/DiaryStore';
import { useTranslation } from 'react-i18next';
import { Loader, Stack, Text } from '@mantine/core';
import classes from './DiaryList.module.css';
import { useEffect, useRef, useCallback } from 'react';
import { DiaryListItem } from './DiaryListItem';
import { DateTime } from 'luxon';
import { DiaryListDetailDialog } from './DiaryListDetailDialog';

export const DiaryList = observer(() => {
  const diaryStore = useDiaryStore();
  const entries = diaryStore.diaryEntries;
  const { t, i18n } = useTranslation('consumption');
  const previousMonths = [] as string[];
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const isLoading = diaryStore.loading;

  useEffect(() => {
    diaryStore.loadDiaryEntries();
  }, [diaryStore]);

  const fetchMoreEntries = useCallback(() => {
    if (isLoading || !diaryStore.hasMore) return;

    diaryStore.loadDiaryEntries();
  }, [isLoading, diaryStore]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) fetchMoreEntries();
      },
      { threshold: 1 },
    );

    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(loaderRef.current);
    };
  }, [fetchMoreEntries, loaderRef]);

  if (!entries) {
    return (
      <div className={classes.noEntries}>
        <Text ta="center" c="#4A4A48">
          {t('NoEntries')}
        </Text>
      </div>
    );
  }

  return (
    <Stack w="100%">
      <DiaryListDetailDialog />

      {entries.map((entry) => {
        const formattedMonthYear = DateTime.fromJSDate(entry.entryDate)
          .setLocale(i18n.language)
          .toFormat('LLLL yyyy');
        const showHeader = previousMonths.indexOf(formattedMonthYear) === -1;
        if (showHeader) previousMonths.push(formattedMonthYear);

        return (
          <DiaryListItem key={entry.id} entry={entry} showHeader={showHeader} />
        );
      })}
      {isLoading && <Loader m="auto" />}
      <div ref={loaderRef} />
    </Stack>
  );
});
