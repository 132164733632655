export const sentrySettings = {
  debug: false, // sentry debugging itself

  attachStacktrace: true,
  autoSessionTracking: true,
  sendClientReports: true,
  enableTracing: true,
  sampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
};
