import { Button, Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BaseModal } from '../../../userStatus/dialogs';
import { useAffiliateCodeStore } from './AffiliateCodeStore';
import { showSuccessNotification } from '../../../notification';
import { MyIcon } from '../../../icon';

export const Modal = observer(() => {
  const [opened, { open, close }] = useDisclosure(false);
  const store = useAffiliateCodeStore();
  const { t } = useTranslation('affiliate-code');
  const { userId } = useParams();
  const Form = useForm();

  function success() {
    showSuccessNotification(t('Success'));
    close();
  }

  function handleSubmit() {
    const { code } = Form.values;
    if (!code || !userId) return;

    store.updateAffiliateCode(userId, code, success);
  }

  useEffect(() => {
    Form.setValues({ code: store.affiliateCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.affiliateCode]);

  useEffect(() => {
    if (userId) {
      store.getAffiliateCode(userId);
    }
  }, [store, userId]);

  return (
    <div>
      <Button
        variant="subtle"
        onClick={open}
        leftSection={
          <MyIcon name="redeem" style={{ fill: '#3D7F53', height: 20 }} />
        }
      >
        {t('Title')}
      </Button>
      <BaseModal
        opened={opened}
        onClose={close}
        title={t('Title')}
        description={t('Description')}
        size="500px"
        buttons={[
          <Button
            key="cancel"
            variant="outline"
            onClick={handleSubmit}
            w="100%"
          >
            {t('Action')}
          </Button>,
        ]}
        children={
          <Flex direction="column" gap="10px" w="100%">
            <TextInput
              label={t('Inputs.Code')}
              placeholder={t('Inputs.CodePlaceholder')}
              spellCheck={false}
              {...Form.getInputProps('code')}
            />
          </Flex>
        }
      />
    </div>
  );
});
