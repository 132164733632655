import { Flex, Button, Image, Text } from '@mantine/core';
import intro_img from '../../../../assets/scc_hero_image.jpg';
import classes from '../LandingPage.module.css';
import { Routes } from '../../../../route/Routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Intro = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');

  return (
    <Flex
      id={id}
      className={classes.introContainer}
      direction="column"
      justify="center"
      bg="#fff"
    >
      <Flex align="center" wrap="wrap" className={classes.introBox}>
        <Text className={classes.introTitle}>{t('Intro.Title')}</Text>
        <Text size="16px" lh="24px" p="0px 20px" ta="center" maw="780px">
          {t('Intro.Description1')}
          <br />
          {t('Intro.Description2')}
          <br />
        </Text>
        <Link
          style={{ padding: 20 }}
          children={<Button>{t('Buttons.RegisterNow')}</Button>}
          to={Routes.EntryQuestionnaire}
        />
        <Image
          radius="md"
          src={intro_img}
          title="Cannabis Studie Zürich"
          alt="Cannabis Studie Zürich"
          className={classes.introImg}
        />
      </Flex>
    </Flex>
  );
};
