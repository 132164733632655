import { authenticatedAPI } from '../AuthenticatedAPI';
import { StoreChecklistReqDto, storeChecklistResDto } from '@web/common';
import { z } from 'zod';

export const getMyChecklist = async () => {
  return authenticatedAPI.get(`/api/checklist`, z.any());
};

export const storeMyChecklist = async (checklist: StoreChecklistReqDto) => {
  return authenticatedAPI.put(
    `/api/checklist`,
    checklist,
    storeChecklistResDto,
  );
};

export const getChecklistForUser = async (userId: string) => {
  return authenticatedAPI.get(`/api/checklist/${userId}`, z.any());
};

export const storeChecklistForUser = async (
  userId: string,
  checklist: StoreChecklistReqDto,
) => {
  return authenticatedAPI.put(
    `/api/checklist/${userId}`,
    checklist,
    storeChecklistResDto,
  );
};
