import { CartLine } from '@shopify/hydrogen-react/storefront-api-types';

export const maxWeight = 10;
export const maxTHCAmountMixed = 2;

export enum ProductType {
  MIXED,
  UNMIXED,
}
export type ExtendedCartLine = CartLine & {
  thcAmount: number;
} & { weight: number };

function getProductType(title: string): ProductType {
  if (title.includes('Blüte') || title.includes('Haschisch')) {
    return ProductType.UNMIXED;
  }
  return ProductType.MIXED;
}

export function getTotals(products: ExtendedCartLine[]): {
  totalWeight: number;
  totalThc: number;
  totalCost: number;
  productTypes: ProductType[];
} {
  let totalWeight = 0;
  let totalThc = 0;
  let totalCost = 0;
  const productTypes: ProductType[] = [];

  for (const product of products) {
    totalWeight += product.quantity * product.weight; // this needs to be multiplied by the quantity
    totalThc += product.thcAmount; // this is always the total amount of the product
    totalCost += Number(product.cost.totalAmount.amount);
    productTypes.push(getProductType(product.merchandise.product.title));
  }

  return { totalWeight, totalThc, totalCost, productTypes };
}

// the order can only be accepted when these criterias are met:
// 1. there is at least one product in the order
// 2. the total thc amount must not exceed the available thc amount
// 3. if the products are of the type unmixed: the total weight must not exceed 10 grams
// 4. if the products are of the type mixed: the thc amount can only be max 2g
// 5. if the products are mixed and unmixed: the rule 3 & 4 applies
export function canAcceptOrder(
  productsLength: number,
  availableTHC: number,
  totalThc: number,
  totalWeight: number,
  productTypes: ProductType[],
): boolean {
  if (productsLength === 0) return false;

  const hasMixedProducts = productTypes.includes(ProductType.MIXED);

  // check if the order is valid
  if (totalThc > availableTHC) return false;
  if (totalWeight > maxWeight) return false;
  if (hasMixedProducts && totalThc > maxTHCAmountMixed) return false;

  return true;
}
