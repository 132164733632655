import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../../util/setupMobX';
import {
  ErrorCode,
  type GetPodcastSchema,
  type GetPodcastsResDto,
  LoyalityRewardType,
} from '@web/common';
import {
  getPodcasts,
  markPodcastAsFinished,
} from '../../request/authenticated-requests/podcasts';
import { showPointsAwardedNotification } from '../../component/notification';
import { handleRequestError } from '../../request';

export class PodcastStore {
  @observable public loading: boolean = true;
  @observable public podcastList: GetPodcastsResDto = [];
  @observable public podcastListImportant: GetPodcastsResDto = [];
  @observable public currentOpenPodcast: null | GetPodcastSchema = null;

  constructor() {
    makeObservable(this);
  }

  @action public getPodcasts() {
    this.loading = true;
    getPodcasts()
      .then((podcastList) => {
        runInAction(() => {
          this.podcastList = podcastList;
          this.podcastListImportant = this.findImportantPodcasts(podcastList);
          this.currentOpenPodcast = this.findCurrentOpenPodcast(podcastList);
          this.loading = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  @action public markPodcastAsFinished(
    podcastId: number,
    afterAction?: () => void,
  ) {
    markPodcastAsFinished(Number(podcastId))
      .then((result) => {
        result.forEach((loyalityPoints) => {
          if (loyalityPoints.type === LoyalityRewardType.NoReward) return;
          showPointsAwardedNotification(
            loyalityPoints.points,
            loyalityPoints.reason,
            loyalityPoints.totalPoints,
          );
          runInAction(() => {
            afterAction?.();
          });
        });
      })
      .catch((error) => {
        handleRequestError(error, [
          ErrorCode.PodcastAlreadyMarkedAsFinished,
          () => {},
        ]);
      });
  }

  private findCurrentOpenPodcast(
    podcasts: GetPodcastsResDto,
  ): null | GetPodcastSchema {
    return podcasts.find((podcast) => podcast.prompt) ?? null;
  }

  private findImportantPodcasts(
    podcasts: GetPodcastsResDto,
  ): GetPodcastsResDto {
    if (podcasts.length <= 3) return podcasts;

    const amount = 3;
    const indexOfPromptedPodcast = podcasts.findIndex(
      (podcast) => podcast.prompt,
    );

    if (indexOfPromptedPodcast === -1) {
      return podcasts.slice(podcasts.length - amount, podcasts.length);
    } else if (indexOfPromptedPodcast === 0) {
      return podcasts.slice(0, amount);
    } else if (indexOfPromptedPodcast === podcasts.length - 1) {
      return podcasts.slice(podcasts.length - amount, podcasts.length);
    } else {
      return podcasts.slice(
        indexOfPromptedPodcast - 1,
        indexOfPromptedPodcast + 2,
      );
    }
  }
}

const { provider, useStore } = setupMobX<PodcastStore>();
export const usePodcastStore = useStore;
export const PodcastStoreProvider = provider;
