import { z } from 'zod';

/**
 * the searchString can either be an order id, or a study code
 *
 * for the order id, which is normally prefixed with #, the searchString accepts both #<order_id> and <order_id> (e.g. #1010 and 1010)
 * for the study code, the searchString accepts the study code as is (e.g. 123456, but it needs to be the exact study code, no partial match)
 */
export const getOrdersQueryReqDto = z.object({
  searchString: z.string().optional(), // order id or study code
});
export type GetOrdersQueryReqDto = z.infer<typeof getOrdersQueryReqDto>;
