import { z } from 'zod';

export const responseSchema = z.object({
  sg_message_id: z.string(),
  event: z.enum([
    'processed',
    'open',
    'delivered',
    'click',
    'bounce',
    'deferred',
    'spamreport',
    'dropped',
    'unsubscribe',
    'group_resubscribe',
    'group_unsubscribe',
  ]),
  email: z.string().email(),
  timestamp: z.number().transform((v) => new Date(v * 1000)),
});
export const sendgridCbResDto = z.array(responseSchema).min(1);
export type SendgridCbResDto = z.infer<typeof sendgridCbResDto>;
