import classes from './BookingSoonPossible.module.css';
import fancyClock from '../../../../assets/icons/fancy_clock.svg';

export const BookingSoonPossible = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={fancyClock} alt="location" height={48} width={48} />
      </div>
      <div className={classes.textColumn}>
        <span className={classes.headerText}>
          Termine werden bald freigeschaltet{' '}
        </span>
        <div className={classes.bodyText}>
          Super, du hast die Checkliste durchgemacht und bist bereit einen
          Termin für Dein Aufnahmegespräch zu buchen. Aktuell können wir Dir
          leider noch keine Termine anbieten, hab daher noch ein wenig Geduld.
          Wir schicken Dir eine E-Mail, sobald es möglich ist Termine zu buchen.
        </div>
      </div>
    </div>
  );
};
