import { CohortType } from '@prisma/client';
import { AcceptedUserInfoUI } from '../accepted-user/AcceptedUserInfoUI';
import {
  ThcStore,
  ThcStoreProvider,
} from '../../../../../src/store/shopEmployee/ThcStore';

export function AcceptedUserInfo(
  participant: {
    cohortId: string;
    createdAt: Date;
    cannavigiaCode: string;
    cohortType: CohortType;
    participantId: string;
  } | null,
) {
  if (!participant) return null;

  return (
    <ThcStoreProvider store={new ThcStore()}>
      <AcceptedUserInfoUI {...participant} />
    </ThcStoreProvider>
  );
}
