import { Select, Text } from '@mantine/core';
import { livingPlaces } from '@web/common';
import { useTranslation } from 'react-i18next';

export const SelectPlaceStep = ({
  invalidLivingPlace,
  setSelectedPlace,
}: {
  invalidLivingPlace: string;
  setSelectedPlace: (value: string | null) => void;
}) => {
  const { t } = useTranslation('quick-enroll');
  return (
    <>
      <Text fw="600" size="16px" lh="24px">
        {t('Step')} 1:
      </Text>
      <div>
        <Text p="0px">{t('SelectPlace.WhereDoYouLive')}</Text>
        <Select
          placeholder={t('SelectPlace.Search')}
          data={[...livingPlaces, invalidLivingPlace]}
          onChange={(value) => setSelectedPlace(value)}
          searchable
        />
      </div>
    </>
  );
};
