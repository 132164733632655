import { Card, MyIcon } from '../../../../../../component';
import { Flex, Space, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { OpenOrdersForUser, PaidStatus } from '@web/common';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../../route/Routes';
import { DateTime } from 'luxon';

export const OrderCard = ({
  order,
  showDate,
}: {
  order: OpenOrdersForUser;
  showDate: boolean;
}) => {
  const { t, i18n } = useTranslation('shop');
  const navigate = useNavigate();
  const formattedDate = DateTime.fromJSDate(order.orderPlacedDate)
    .setLocale(i18n.language)
    .toFormat('ccc, dd.MM.yy');

  function navigateToOrderDetail() {
    const extractIdFromGid = (gid: string) => Number(gid.split('/').pop());
    navigate(
      `${Routes.Study}/${Routes.ShopOrder}/${extractIdFromGid(order.id)}`,
    );
  }

  return (
    <Card
      style={{
        padding: showDate ? '20px 15px' : 15,
        flex: 1,
        minWidth: 300,
      }}
    >
      <div key={order.id} onClick={navigateToOrderDetail}>
        <Flex justify="space-between">
          {!showDate && (
            <Text size="12px" lh="18px" c="#4A4A48">
              {t('OrderCard.OrderNumber')}: {order.name}
            </Text>
          )}
          <Text
            fw={showDate ? '600' : 'normal'}
            size={showDate ? '16px' : '12px'}
            lh={showDate ? '24px' : '18px'}
          >
            {Number(order.totalPrice).toLocaleString()} {order.currencyCode}
          </Text>
          {showDate && (
            <Flex gap="5px">
              <MyIcon name="calendar" style={{ fill: '#4A4A48', height: 20 }} />
              <Text c="#4A4A48" size="14px" lh="21px">
                {formattedDate}
              </Text>
            </Flex>
          )}
        </Flex>
        {showDate && (
          <>
            <Space h="5px" />
            <Text size="12px" lh="18px" c="#4A4A48">
              {t('OrderCard.Total', {
                amount: order.orderItems.length,
              })}
            </Text>
          </>
        )}
        {showDate && (
          <Text size="12px" lh="18px" c="#4A4A48">
            {t('OrderCard.OrderNumber')}: {order.name}
          </Text>
        )}
        <Space h="sm" />
        <Flex direction="column">
          <MyIcon
            name={order.paidStatus === PaidStatus.Unpaid ? 'payments' : 'store'}
            style={{ fill: '#3D7F53', height: 25 }}
          />
          <Text size="12px" c="#4A4A48">
            {t('PaidStatus.' + order.paidStatus)}
          </Text>
        </Flex>
      </div>
    </Card>
  );
};
