import { authenticatedAPI } from '../AuthenticatedAPI';
import { z } from 'zod';

export const exportOnboardingData = async (cohortId: string) => {
  return authenticatedAPI.get(`/api/export/onboarding/${cohortId}`, z.any());
};

export const clusteringData = async (cohortId: string) => {
  return authenticatedAPI.get(`/api/export/clustering/${cohortId}`, z.any());
};

export const studyData = async (cohortId: string) => {
  return authenticatedAPI.get(`/api/export/study-data/${cohortId}`, z.any());
};
