import { z } from 'zod';
import { dateTimeTransformer } from '../../lib/util/date-time-transformer';

// csv header: "Logged date";"Effective Date";User;"Type ID";Type;GTIN;Product;"Batch ID";"Quantity (pcs)";"Stock (pcs)";Reason
export const inventoryLogRowResDto = z.object({
  'Logged date': z.string().transform(
    dateTimeTransformer(`yyyy-MM-dd, HH:mm`, {
      overwriteLocale: true,
      overwriteZone: true,
    }),
  ),
  'Effective Date': z.string().transform(
    dateTimeTransformer(`yyyy-MM-dd`, {
      overwriteLocale: true,
      overwriteZone: true,
    }),
  ),
  User: z.string(),
  'Type ID': z.coerce.number(),
  Type: z.string(),
  GTIN: z.string(),
  Product: z.string(),
  'Batch ID': z.string(),
  'Quantity (pcs)': z.coerce.number(),
  'Stock (pcs)': z.coerce.number(),
  Reason: z.string(),
});

export type InventoryLogRowResDto = z.infer<typeof inventoryLogRowResDto>;
