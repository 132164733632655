import { Box, Text } from '@mantine/core';

export const Title = ({
  text,
  width = 640,
  boxPadding = '0px 24px',
  textPadding = '40px 20px 0px 0px',
  margin = 'auto',
}: {
  text: string;
  width?: number;
  boxPadding?: string;
  textPadding?: string;
  margin?: string;
}) => {
  return (
    <Box p={boxPadding}>
      <Text
        size="28px"
        lh="38px"
        m={margin}
        maw={`${width}px`}
        p={textPadding}
        fw="500"
      >
        {text}
      </Text>
    </Box>
  );
};
