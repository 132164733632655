import { z } from 'zod';
import { AppointmentType } from '@prisma/client';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';

export const appointmentTypeZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(AppointmentType)))
    .transform(
      (value) =>
        AppointmentType[
          value as keyof typeof AppointmentType
        ] as AppointmentType,
    );
