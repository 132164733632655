import { z } from 'zod';
import { userStatusZodEnum } from '../../enum';
import { storeChecklistResDto } from '../checklist';

const pausedUserScheme = z.object({
  id: z.string().uuid(),
  userSearchID: z.string(),
  status: userStatusZodEnum(),
  pauseReason: z.string().nullable(),
  pauseDate: z.coerce.date().nullable(),
  CheckList: storeChecklistResDto.nullable(),
});
export const pausedUsersResDto = z.array(pausedUserScheme);
export type PausedUsersResDto = z.infer<typeof pausedUsersResDto>;
