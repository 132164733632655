import { Button, Center, Flex, Text } from '@mantine/core';
import { Header } from '../public/navigation';
import { useTranslation } from 'react-i18next';

export const NoAccess = () => {
  const { t } = useTranslation('no-access');

  function redirect() {
    window.location.href = '/';
  }

  return (
    <>
      <Header canLogout />

      <Center h="calc(100vh - 126px)" p="20px">
        <Flex
          bg="#fff"
          p="20px"
          m="auto"
          maw="500px"
          direction="column"
          gap="20px"
        >
          <Text fw="600" size="18px">
            {t('Title')}
          </Text>
          <Text>{t('Description')}</Text>
          <Button onClick={redirect}> {t('BackToHome')}</Button>
        </Flex>
      </Center>
    </>
  );
};
