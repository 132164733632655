import { Button, Flex, Space, Text } from '@mantine/core';
import classes from '../LandingPage.module.css';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import contents from '../../faq/contents';
import { AccordionSection } from '../../faq/AccordionSection';
import { useTranslation } from 'react-i18next';

export const FAQShort = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');
  const shortFaq = contents.getFullFaq(t);

  const items = (
    <AccordionSection
      key={shortFaq[0].title}
      content={[
        ...shortFaq[0].content.slice(0, 2),
        ...shortFaq[0].content.slice(4, 5),
        ...shortFaq[2].content.slice(0, 2),
      ]}
    />
  );

  return (
    <Flex
      id={id}
      className={classes.baseSection}
      p="40px 20px 100px 20px"
      direction="column"
      style={{ background: '#fff' }}
      gap="16px"
    >
      <Space h="md" />
      <Text className={classes.faqTitle}>{t('FaqShort.Title')}</Text>
      <Text maw="660px">{t('FaqShort.Description')}</Text>
      <Space h="0px" />
      {items}

      <Link
        style={{ alignSelf: 'center' }}
        children={
          <Button variant="outline" w="fit-content" fw="500">
            {t('FaqShort.ShowAll')}
          </Button>
        }
        to={Routes.Faq}
      />
    </Flex>
  );
};
