import { Flex, Divider, Button, Box, Text } from '@mantine/core';
import { reschedule } from '../book-in-person/cal-dot-com.links';
import { MapView } from './MapView';
import { useEventStore, useUserMeStore } from '../../../../store';
import { DateTime } from 'luxon';
import classes from './BookedAppointment.module.css';
import calendar from '../../../../assets/icons/calendar.svg';
import clock from '../../../../assets/icons/clock.svg';
import location from '../../../../assets/icons/location.svg';
import phone from '../../../../assets/icons/phone.svg';

import { splitLocationString } from '../../../../util/appointment/splitLocationString';
import { useEffect } from 'react';
import { InfoItem } from '../../../../component/dataCards';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../route/Routes';
import { Link } from 'react-router-dom';
import { FeatureAccess } from '../../../../util/ff/FeatureAccess';
import { FeatureFlag } from '@web/common';

export const AppointmentDetails = () => {
  const eventStore = useEventStore();
  const myInfoStore = useUserMeStore();
  const { t, i18n } = useTranslation('appointment-booking');
  const isGerman = i18n.language === 'de';

  const startTime = DateTime.fromJSDate(myInfoStore.appointment!.startTime);
  const endTime = DateTime.fromJSDate(myInfoStore.appointment!.endTime);

  const formattedStartTime = startTime
    .setLocale(i18n.language)
    .toFormat('HH:mm');
  const formattedEndTime = endTime.toFormat('HH:mm');
  const diffInMinutes = endTime.diff(startTime, 'minutes').minutes;

  const formattedStartDate = startTime
    .setLocale(i18n.language)
    .toFormat('EEEE, d. MMMM yyyy');
  const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;
  const formattedDuration = `${diffInMinutes} ${isGerman ? 'Minuten' : 'minutes'}`;

  const currentEvent = eventStore.events.find(
    (event) => event.id === myInfoStore.appointment?.eventTypeId,
  );
  const locationObj = splitLocationString(currentEvent?.locations ?? []);

  useEffect(() => {
    eventStore.loadEvents();
  }, [eventStore]);

  // TODO: SCC-128 get correct values for location, phonenumber, and location name
  return (
    <Flex p="20px" wrap="wrap">
      <Flex className={classes.appointmentBox}>
        <Flex direction="column" gap="8px">
          <Text>{t('AppointmentDetails.Card.Title')}</Text>
          <Text fw="700" size="21px">
            {t('AppointmentDetails.Card.Onboarding')}
          </Text>
          <Text>{currentEvent?.title}</Text>
        </Flex>

        <Divider my="sm" />

        <InfoItem
          icon={
            <img
              src={calendar}
              alt="calendar"
              height={20}
              width={20}
              style={{ alignSelf: 'flex-start' }}
            />
          }
          text={formattedStartDate}
          secondText={formattedTimeRange}
        />
        <InfoItem
          icon={<img src={clock} alt="clock" height={20} width={20} />}
          text={formattedDuration}
        />
        <InfoItem
          icon={<img src={location} alt="location" height={20} width={20} />}
          text={locationObj?.address ?? ''}
        />
        <InfoItem
          icon={<img src={phone} alt="phone" height={20} width={20} />}
          text={
            <a
              style={{ color: '#E4032E' }}
              href={`tel:${
                locationObj ? locationObj.phoneNr.replaceAll(' ', '') : ''
              }`}
            >
              {locationObj ? locationObj.phoneNr : ''}
            </a>
          }
        />

        <a
          href={reschedule(myInfoStore.appointment!.id)}
          target="_self"
          rel="noreferrer"
        >
          <Button variant="outline" w="200px" fw="400" my="10px">
            {t('AppointmentDetails.Card.ChangeAppointment')}
          </Button>
        </a>
        <FeatureAccess flag={FeatureFlag.Cancel_Appointments}>
          <Link to={Routes.BookInPerson}>
            {t('AppointmentDetails.Card.AppointmentWithNewLocation')}
          </Link>
        </FeatureAccess>
      </Flex>
      <Box className={classes.mapView}>
        <MapView link={locationObj.link} />
      </Box>
    </Flex>
  );
};
