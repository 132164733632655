import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { setupMobX } from '../util/setupMobX';
import { getStateOfBaselineSurveyData } from '../request/authenticated-requests/questionnaire';
import { CohortType } from '@prisma/client';
import { DateTime } from 'luxon';
import { showErrorNotification } from '../component/notification';
import { MyCohortStore } from './MyCohortStore';

export class WaitingStateStore {
  @observable public currentStep: number = 0;
  @observable public baseLineSurveyLink: string | undefined = undefined;

  @computed public get myCohortStartDate(): null | DateTime {
    return this.myCohortStore.cohortStartDate;
  }
  @computed public get myCohortType() {
    return this.myCohortStore.cohortType ?? CohortType.NONE;
  }
  @computed public get canAccessStudy(): boolean {
    return (
      this.myCohortType !== CohortType.NONE &&
      !!this.myCohortStartDate &&
      this.myCohortStartDate <= DateTime.now()
    );
  }
  @computed public get loadingAccessState(): boolean {
    return this.myCohortStore.loading;
  }

  constructor(private readonly myCohortStore: MyCohortStore) {
    makeObservable(this);
  }

  @action public loadDataAndCheckAccess() {
    // first checking if baseline survey is filled out
    this.loadBaselineSurveyData();
    // if so, it will check the cohort type
    // after that it will check the start date
  }

  @action public checkCurrentStep() {
    if (this.myCohortStore.cohortType !== CohortType.NONE) this.currentStep = 2;
  }

  @action private loadBaselineSurveyData() {
    getStateOfBaselineSurveyData()
      .then((res) => {
        runInAction(() => {
          if (res.state === 'notFilledOutYet') {
            this.baseLineSurveyLink = res?.link ?? undefined;
          } else if (res.state === 'alreadyFilledOut') {
            this.currentStep = 1;
            this.myCohortStore.loadMyCohort(() => this.checkCurrentStep());
          }
        });
      })
      .catch(() => {
        showErrorNotification();
      });
  }
}

const { provider, useStore } = setupMobX<WaitingStateStore>();
export const useWaitingStateStore = useStore;
export const WaitingStateStoreProvider = provider;
