import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Flex, Loader, Text } from '@mantine/core';
import { usePodcastStore } from '../../../../store/podcast';
import { PodcastListItem } from './PodcastListItem';
import classes from './podcast.module.css';
import { Routes } from '../../../../route/Routes';
import { useTranslation } from 'react-i18next';
import { InteractiveText } from '../../../../component/InteractiveText';
import { useMyCohortStore } from '../../../../store/MyCohortStore';
import { PODCAST_STREAK_LIMIT_AFTER_COHORT_START } from '@web/common';

export enum PodcastListVersion {
  Short,
  Long,
}

export const PodcastList = observer(
  ({ version }: { version: PodcastListVersion }) => {
    const { t } = useTranslation('podcast');
    const myCohortStore = useMyCohortStore();
    const podcastListStore = usePodcastStore();

    useEffect(() => {
      podcastListStore.getPodcasts();
    }, [podcastListStore]);

    const showAllComponent = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return (
            <InteractiveText
              text={t('List.Short.ShowAll')}
              navigateTo={`${Routes.Study}/${Routes.Podcasts}`}
            />
          );
        }
        case PodcastListVersion.Long: {
          return null;
        }
      }
    };

    const listTitle = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return t('List.Short.Title');
        }
        case PodcastListVersion.Long: {
          return t('List.Long.Title');
        }
      }
    };

    const subtitleText = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return t('List.Short.Subtitle');
        }
        case PodcastListVersion.Long: {
          return t('List.Long.Subtitle', {
            podcastStreakDate: myCohortStore.cohortStartDate
              ? myCohortStore.cohortStartDate
                  .plus(PODCAST_STREAK_LIMIT_AFTER_COHORT_START)
                  .toFormat(`dd.MM.yy`)
              : '-', // should not be possible
          });
        }
      }
    };

    const items = () => {
      switch (version) {
        case PodcastListVersion.Short: {
          return podcastListStore.podcastListImportant;
        }
        case PodcastListVersion.Long: {
          return podcastListStore.podcastList;
        }
      }
    };

    if (podcastListStore.loading) {
      return <Loader />;
    }

    return (
      <Flex direction={'column'} gap={'0.5em'} style={{ width: '100%' }}>
        <Text fw="500" size="28px" lh="38px">
          {listTitle()}
        </Text>
        <Text>{subtitleText()}</Text>
        <div className={classes.list}>
          {items().map((podcast) => (
            <PodcastListItem key={`podcast-${podcast.id}`} podcast={podcast} />
          ))}
        </div>
        <div>{showAllComponent()}</div>
      </Flex>
    );
  },
);
