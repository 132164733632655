import { z } from 'zod';
import { DateTime } from 'luxon';
import { ZONE } from '../../enum/ZONE';

const unknownSubItem = z.object({
  is_unknown: z.literal(1),
  id: z.number(),
  stock_amount: z.number(),
});
const normalSubItem = unknownSubItem.extend({
  is_unknown: z.literal(0),
  batch_code: z.string().max(32).nullable(),
  expire_date: z
    .string()
    .transform((dateString) => {
      const date = DateTime.fromFormat(dateString, `yyyy-MM-dd`, {
        zone: ZONE,
      });
      return date.toFormat(`yyyy-MM-dd`)!;
    })
    .nullable(),
  thc: z.coerce.number().nullable(),
  total_thc: z.coerce.number().nullable(),
  stock_amount: z.number(),
  ordered_stock_amount: z.number().nullable(),
});

export const dispensarySubItemResDto = z.discriminatedUnion('is_unknown', [
  normalSubItem,
  unknownSubItem,
]);
export type DispensarySubItemResDto = z.infer<typeof dispensarySubItemResDto>;
