import { z } from 'zod';

const notFilledOutYet = z.object({
  state: z.literal('notFilledOutYet'),
  link: z.string(),
});
const alreadyFilledOut = z.object({
  state: z.literal('alreadyFilledOut'),
  filledOutAt: z.coerce.date(),
});
export const getBaselineQuestionnaireStateResDto = z.discriminatedUnion(
  'state',
  [notFilledOutYet, alreadyFilledOut],
);
export type GetBaselineQuestionnaireStateResDto = z.infer<
  typeof getBaselineQuestionnaireStateResDto
>;
