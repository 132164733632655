import { Flex, ActionIcon } from '@mantine/core';
import { MyIcon } from '../../../../component';
import classes from '../../../../sharedStyles/Layout.module.css';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useNavItems from './useNavItems';

export const NavBar = observer(
  ({
    navBarOpen,
    setNavBarOpen,
    additionalNavItems,
  }: {
    navBarOpen: boolean;
    setNavBarOpen: (open: boolean) => void;
    additionalNavItems?: JSX.Element;
  }) => {
    useTranslation('navbar');

    const navItemElements = useNavItems(false);

    return (
      <Flex className={classes.navBar}>
        <Flex justify={navBarOpen ? 'flex-end' : 'center'} px="5px">
          <ActionIcon
            variant="white"
            onClick={() => setNavBarOpen(!navBarOpen)}
          >
            <MyIcon name={navBarOpen ? 'chevron_collapse' : 'chevron_expand'} />
          </ActionIcon>
        </Flex>
        <Flex
          className={classes.innerNavBar}
          p={navBarOpen ? '15px 15px' : '15px 0px'}
        >
          <Flex w="100%" gap="15px" direction="column">
            {navItemElements}
          </Flex>

          {/* additional nav items are placed here and will occur at the bottom of the list */}
          <Flex p="0px 5px" w="100%" gap="10px" direction="column">
            {additionalNavItems}
          </Flex>
        </Flex>
      </Flex>
    );
  },
);
