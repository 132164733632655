import { Container, Flex, Text } from '@mantine/core';
import { Header } from './navigation';

export function Impressum() {
  return (
    <>
      <Header canLogout={false} />
      <Container fluid maw={1000}>
        <Flex
          direction="column"
          gap={20}
          py={80}
          style={{ overflowWrap: 'anywhere' }}
        >
          <Text size="50px" fw="500">
            Impressum
          </Text>
          <Text>
            Der «Pilotversuch Zürich» ist ein Projekt des Vereins Swiss Cannabis
            Research
            <br />
            Langstrasse 20
            <br />
            8004 Zürich
          </Text>
          <Text>
            Verantwortlich für diese Website ist:
            <br />
            <br />
            Rivamo AG
            <br />
            Neue Winterthurerstrasse 99
            <br />
            8304 Wallisellen
            <br />
            E-Mail: <a href="mailto:hello@rivamo.com">hello@rivamo.com</a>
          </Text>
        </Flex>
      </Container>
    </>
  );
}
