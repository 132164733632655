import { Flex, Select, Divider, Text } from '@mantine/core';
import { useInventoryStore } from '../InventoryStore';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { MonthPickerInput } from '@mantine/dates';
import { observer } from 'mobx-react';
import { useDispensaryStore } from '../../../../../store';
import { PointOfSalesType, TimeOption } from '@web/common';

export const Filters = observer(() => {
  const { t } = useTranslation('inventory');
  const store = useInventoryStore();
  const dispensaryStore = useDispensaryStore();

  const timeOptions = useMemo(
    () =>
      Object.values(TimeOption).map((status) => ({
        label: t(`Select.PointInTime.TimeOptions.${status}`),
        value: status,
      })),
    [t],
  );

  const dispensaries = dispensaryStore.dispensaries.map((dispensary) => ({
    label: dispensary.name,
    value: dispensary.gln.toString(),
  }));

  const dispensarieSelection = [
    { label: t('Select.PointOfSale.All'), value: PointOfSalesType.All },
    ...dispensaries,
  ];

  useEffect(() => {
    dispensaryStore.loadDispensaries();
  }, [dispensaryStore]);

  return (
    <Flex gap="8px" direction="column">
      <Text fw="600" size="21px" lh="25.2px" lts="-0.5px" c="#3D7F53">
        {t('Filter')}
      </Text>

      <Flex gap="10px">
        <Select
          placeholder={t('Select.PointOfSale.Placeholder')}
          label={t('Select.PointOfSale.Label')}
          w="300px"
          data={dispensarieSelection}
          value={store.dispensary}
          onChange={(option) => store.changeDispensary(option)}
        />
        <Divider orientation="vertical" c="#F1F1F1" mx="10px" />
        <Select
          placeholder={t('Select.PointInTime.Placeholder')}
          label={t('Select.PointInTime.Label')}
          w="300px"
          data={timeOptions}
          value={store.timeOption}
          onChange={(item) => store.changeTimeOption(item as TimeOption)}
        />
        {store.timeOption !== TimeOption.Current && (
          <Divider orientation="vertical" />
        )}
        {/*
        will be used in future release
        {store.timeOption === TimeOption.Day && (
          <DatePickerInput
            placeholder={t('Select.Date.PlaceholderDay')}
            label={t('Select.Date.Label')}
            w="350px"
            onChange={handleDateFilter}
          />
        )} */}
        {store.timeOption === TimeOption.Month && (
          <MonthPickerInput
            placeholder={t('Select.Date.PlaceholderMonth')}
            label={t('Select.Date.Label')}
            w="350px"
            value={store.selectedMonth}
            onChange={store.handleDateFilter}
          />
        )}
        {/*
        will be used in future release
         {store.timeOption === TimeOption.Year && (
          <YearPickerInput
            placeholder={t('Select.Date.PlaceholderYear')}
            label={t('Select.Date.Label')}
            w="350px"
            onChange={handleDateFilter}
          />
        )} */}
      </Flex>
    </Flex>
  );
});
