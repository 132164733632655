import { z } from 'zod';

export const updateParticipantTHCLimitResDto = z.object({
  data: z.object({
    thc_limit: z.number(),
    // left_current_month_thc_limit: z.number(),
  }),
});
export type UpdateParticipantTHCLimitResDto = z.infer<
  typeof updateParticipantTHCLimitResDto
>;
