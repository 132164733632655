import { observer } from 'mobx-react';
import { Flex, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Path } from '@remix-run/router/history';

export const InteractiveText = observer(
  ({
    text,
    navigateTo,
  }: {
    text: string;
    navigateTo: string | Partial<Path>;
  }) => {
    const navigate = useNavigate();

    return (
      <Flex align="center" justify={'space-around'}>
        <Text
          c={'#3D7F53'}
          fw={600}
          size={'16px'}
          lh={'24px'}
          onClick={() => navigate(navigateTo)}
        >
          {text}
        </Text>
      </Flex>
    );
  },
);
