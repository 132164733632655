import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StudyDashboardDetailPage } from '../../../../component/page';
import { PodcastDetails } from './PodcastDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { usePodcastStore } from '../../../../store/podcast';
import { Routes } from '../../../../route/Routes';

export const PodcastDetailsScreen = observer(() => {
  const { t } = useTranslation('consumption');
  const { t: tPodcasts } = useTranslation('podcast');
  const navigate = useNavigate();
  const store = usePodcastStore();

  const { podcastId } = useParams();
  if (!podcastId) {
    navigate(`${Routes.Study}/${Routes.Konsum}`);
    return null;
  }

  const podcast = store.podcastList.find(
    (podcast) => podcast.id === parseInt(podcastId, 10),
  );
  if (!podcast) {
    navigate(`${Routes.Study}/${Routes.Konsum}`);
    return null;
  }

  return (
    <StudyDashboardDetailPage
      breadcrumbText={`/ ${t('BreadcrumbText')} / ${tPodcasts(`Podcast.${podcast.id}.Title`)}`}
    >
      <PodcastDetails podcast={podcast} />
    </StudyDashboardDetailPage>
  );
});
