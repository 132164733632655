import {
  CannabisConsumptionQuestionnaireQuestionKey,
  CannabisConsumptionQuestionnaireResult,
  devRandomCharString,
  consumptionInDangerousSituationQuestion,
  howManyHoursWhereYouHighQuestion,
  howOftenCouldYouNotStopSmokingQuestion,
  howOftenDoYouConsumeCannabisQuestion,
  memoryOrConcentrationProblemsQuestion,
  missedExpectationsQuestion,
  organizingCannabisConsumptionQuestion,
  thoughtAboutStoppingConsumption,
} from '@web/common';
import { randomChoiceValue } from '../../../../util/randomChoice';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

export const devRandomize = (
  t: TranslationFunction,
): CannabisConsumptionQuestionnaireResult => {
  return {
    [CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS]:
      randomChoiceValue(howOftenDoYouConsumeCannabisQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_HOURS_WERE_YOU_HIGH]:
      randomChoiceValue(howManyHoursWhereYouHighQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_COULD_YOU_NOT_STOP_SMOKING]:
      randomChoiceValue(howOftenCouldYouNotStopSmokingQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.FAILED_TO_MEET_EXPECTATIONS]:
      randomChoiceValue(missedExpectationsQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.ORGANIZING_CANNABIS_CONSUMPTION]:
      randomChoiceValue(organizingCannabisConsumptionQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.MEMORY_OR_CONCENTRATION_PROBLEMS]:
      randomChoiceValue(memoryOrConcentrationProblemsQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.CONSUMPTION_IN_DANGEROUS_SITUATIONS]:
      randomChoiceValue(consumptionInDangerousSituationQuestion(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.THOUGHT_ABOUT_STOPPING_CONSUMPTION]:
      randomChoiceValue(thoughtAboutStoppingConsumption(t)),
    [CannabisConsumptionQuestionnaireQuestionKey.OTHER_DRUGS]:
      devRandomCharString(20),
    [CannabisConsumptionQuestionnaireQuestionKey.HOW_DO_YOU_CONSUME_CANNABIS]:
      devRandomCharString(20),
    [CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_TIMES_DID_YOU_CONSUME_WHAT]:
      devRandomCharString(30),
    [CannabisConsumptionQuestionnaireQuestionKey.WHEN_DID_YOU_START_CONSUMING_CANNABIS]:
      devRandomCharString(50),
    [CannabisConsumptionQuestionnaireQuestionKey.WHAT_CANNABIS_PRODUCTS_DID_YOU_CONSUME]:
      devRandomCharString(200),
  };
};
