import {
  CheckListDataSchema,
  CheckListId,
  CheckListItemType,
} from '@web/common';

export const currentChecklistVersion = 6;
const commonFields = {
  done: false,
};

export const defaultOnboardingChecklist: CheckListDataSchema = {
  [CheckListId.EmailVerified]: {
    type: CheckListItemType.SimpleCheck,
    ...commonFields,
  },
  [CheckListId.Einwilligungserklaerung]: {
    type: CheckListItemType.DocumentDownloaded,
    ...commonFields,
  },
  [CheckListId.StammdatenQuestionnaire]: {
    type: CheckListItemType.QuestionnaireStammdaten,
    ...commonFields,
  },
  [CheckListId.CannabisConsumptionQuestionnaire]: {
    type: CheckListItemType.QuestionnaireCannabisConsumption,
    ...commonFields,
  },
  [CheckListId.SF12Questionnaire]: {
    type: CheckListItemType.Questionnaire12SF,
    ...commonFields,
  },
  [CheckListId.Wohnsitznachweis]: {
    type: CheckListItemType.SimpleCheck,
    ...commonFields,
  },
  [CheckListId.AHVNummerNachweis]: {
    type: CheckListItemType.SimpleCheck,
    ...commonFields,
  },
  [CheckListId.IdentitaetsNachweis]: {
    type: CheckListItemType.SimpleCheck,
    ...commonFields,
  },
};

// Function to apply default values to the checklist data if it doesn't match the schema
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyDefaultValues(checklistData: any): CheckListDataSchema {
  const defaultData: CheckListDataSchema = { ...defaultOnboardingChecklist };

  for (const key in checklistData.data) {
    if (
      key in defaultData &&
      Object.prototype.hasOwnProperty.call(checklistData.data, key)
    ) {
      defaultData[key as keyof CheckListDataSchema] = {
        ...defaultData[key as keyof CheckListDataSchema],
        ...checklistData.data[key],
      };
    }
  }

  return defaultData;
}
