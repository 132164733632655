import { z } from 'zod';

export const dispensaryItemDataDto = z.object({
  id: z.number().int(),
  product_id: z.number().int(),
  name: z.string().max(256),
  description: z.string().max(1024).nullable(),
  unit_amount: z.coerce.number(),
  unit_abbr: z.string().max(16),
  producer_number: z.string().max(16),
  gtin: z.string().max(32).nullable(),
  in_stock: z.number().int(),
  total_thc: z.coerce.number(),
  supplier: z.object({
    name: z.string().max(256),
  }),
});
export const dispensaryItemResDto = dispensaryItemDataDto;
export type DispensaryItemResDto = z.infer<typeof dispensaryItemResDto>;
