import { z } from 'zod';

export const createContestResDto = z.object({
  id: z.number(),
  name: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  createdAt: z.coerce.date(),
});
export type CreateContestResDto = z.infer<typeof createContestResDto>;
