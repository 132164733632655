import { useEffect } from 'react';
import './style.css';

const useSliderHighlight = () => {
  useEffect(() => {
    const markLabels = document.querySelectorAll('.mantine-Slider-markLabel');
    if (markLabels.length > 0) {
      // Apply class to the first occurrence
      markLabels[0].classList.add('highlight');

      // Apply class to the last occurrence
      const lastElement = markLabels[markLabels.length - 1];
      lastElement.classList.add('highlight', 'highlight-end');

      // Append a new text div if it does not exist
      if (!lastElement.querySelector('.appended-div')) {
        const newDiv = document.createElement('div');
        newDiv.textContent = 'BAG Limit';
        newDiv.classList.add('appended-div');
        lastElement.appendChild(newDiv);
      }
    }
  }, []);
};

export default useSliderHighlight;
