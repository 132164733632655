import { z } from 'zod';
import { teamsSchema } from './get-teams.res.dto';
import { eventTypesSchema } from './get-event-types.req.dto';
import { workingHoursSchema } from './get-working-hours.req.dto';

const eventSchema = eventTypesSchema.extend({
  workingHours: z.array(workingHoursSchema),
  team: teamsSchema,
});
export type EventSchemaDto = z.infer<typeof eventSchema>;

export const eventsSchemaDto = z.array(eventSchema);
export type EventsSchemaDto = z.infer<typeof eventsSchemaDto>;
