import { z } from 'zod';
import { missionTypeZodEnum } from '../../enum';

export const availableMission = z.object({
  type: missionTypeZodEnum(),
  points: z.number(),
  completed: z.boolean().optional(),
  endDate: z.coerce.date().optional(),
  link: z.string().optional(),
});

export type AvailableMission = z.infer<typeof availableMission>;
export const availableMissionsResDto = z.array(availableMission);
export type AvailableMissionResDto = z.infer<typeof availableMissionsResDto>;
