import { Button, Divider, Flex, Space, Text } from '@mantine/core';
import { Card, useParticipantData } from '../../../../../../component';
import { observer } from 'mobx-react';
import classes from './StudyCard.module.css';
import { QRCodeSVG } from 'qrcode.react';
import { useParticipantDataStore } from '../../../../../../store';
import { useEffect, useState } from 'react';
import { QrCodeModal } from './QrCodeModal';
import { translateValue } from '../../../../../../util/translateLabels';
import { cohortTypeTranslation } from '@web/common';

export const StudyCard = observer(() => {
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState<boolean>(false);
  const store = useParticipantDataStore();
  const participantInfo = useParticipantData();
  const qrCodeContent = store.qrCodeContent;

  const cohortStartDate = store.myCohortStartDate?.toFormat('dd.MM.yy') ?? '-';
  const cohortEndDate = store.myCohortStartDate
    ? store.myCohortStartDate.plus({ years: 4 }).toFormat('dd.MM.yy')
    : '-';
  const cohortType = store.myCohortType;
  const cohortTypeTranslated = cohortType
    ? translateValue(cohortType, cohortTypeTranslation)
    : '-';

  useEffect(() => {
    store.getParticipantQrCodeContent();
  }, [store]);

  return (
    <Card
      className={classes.card}
      flexProps={{ align: 'start', direction: 'column' }}
    >
      <div style={{ width: '100%' }}>
        <Flex gap="20px" align="center">
          {qrCodeContent && (
            <Flex direction="column">
              <QRCodeSVG size={80} value={qrCodeContent} />
              <Button
                variant="subtle"
                style={{ textDecoration: 'underline' }}
                p="0px"
                c="#202020"
                onClick={() => setQrCodeModalOpen(true)}
              >
                vergrößern
              </Button>
            </Flex>
          )}

          <div>
            <Text fw="400" lh="21px" size="16px">
              Swiss Cannabis Research
            </Text>
            <Text fw="400" lh="21px" size="16px">
              Studienausweis
            </Text>
            <Text fw="600" lh="24px" size="16px">
              {participantInfo.userInformation?.userSearchID}
            </Text>
            <Space h="lg" />
          </div>
        </Flex>
        <Divider my="10px" size="1px" color="#B1B1B1" />
        <Flex justify="space-between">
          <>
            {[
              { label: 'Gruppe', value: cohortTypeTranslated },
              {
                label: 'Start',
                value: cohortStartDate,
              },
              { label: 'Ende', value: cohortEndDate },
            ].map(({ label, value }) => (
              <Flex key={label} direction="column">
                <Text lh="21px" size="14px">
                  {label}
                </Text>
                <Text lh="24px" size="16px">
                  {value}
                </Text>
              </Flex>
            ))}
          </>
        </Flex>
        <QrCodeModal
          modalOpen={qrCodeModalOpen}
          setModalClose={setQrCodeModalOpen}
          qrCodeContent={qrCodeContent}
        />
      </div>
    </Card>
  );
});
