import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectLocation = sessionStorage.getItem('redirectLocation');
    if (redirectLocation) {
      console.log('redirecting to', redirectLocation);
      sessionStorage.removeItem('redirectLocation');
      navigate(redirectLocation);
    }
  }, [navigate]);
};

export default useRedirect;
