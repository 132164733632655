import { MissionType } from '@prisma/client';
import { authenticatedAPI } from '../AuthenticatedAPI';
import {
  availableMissionsResDto,
  loyalityPointsRewardResDto,
} from '@web/common';

export const getAvailableMissions = async () => {
  return authenticatedAPI.get(
    'api/mission/available-missions',
    availableMissionsResDto,
  );
};

export const completeMission = async (missionType: MissionType) => {
  return authenticatedAPI.post(
    `api/mission/mission-completion/${missionType}`,
    undefined,
    loyalityPointsRewardResDto,
  );
};
