import { observer } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { verifyEmail } from '../../../request/public-requests/user';
import { DebugPrettyJSON } from '../../../util/DebugPrettyJSON';
import { Routes } from '../../../route/Routes';
import { isLocalOrDev } from '../../../config/environment';
import { DevContent } from '../../../util/DevContent';

export const VerifyEmail = observer(() => {
  const token = useLocation().hash;

  if (!token) {
    return <Navigate to={Routes.Root} />;
  }

  const [emailVerificationSuccessful, setEmailVerificationSuccessful] =
    useState<boolean>(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const initialized = useRef(false);

  useEffect(() => {
    const removeHashToken = token.replace('#', '');

    // workaround, to get it to work with reactjs Strict mode (locally)
    if (isLocalOrDev) {
      if (!initialized.current) {
        initialized.current = true;

        verifyEmail(removeHashToken)
          .then((result) => {
            setEmailVerificationSuccessful(result.success);
          })
          .catch((error) => {
            setInvalidToken(true);
          });
      }
    } else {
      verifyEmail(removeHashToken)
        .then((result) => {
          setEmailVerificationSuccessful(result.success);
        })
        .catch((error) => {
          setInvalidToken(true);
        });
    }
  }, [token]);

  if (invalidToken) {
    return (
      <>
        Ungültiger E-Mail Verifizierungs-Link!
        <p />
        Bitte frage einen neuen Link an.
      </>
    );
  }

  if (emailVerificationSuccessful) {
    return <Navigate to={Routes.Study} />;
  }

  return (
    <div>
      <div>Wir verifizieren deine E-Mail...</div>
      <DevContent>
        <DebugPrettyJSON header={'Token'} data={{ token }} />
      </DevContent>
    </div>
  );
});
