import { action, makeObservable, observable, runInAction } from 'mobx';
import { setupMobX } from '../../util/setupMobX';
import {
  getChecklistForUser,
  getMyChecklist,
  storeChecklistForUser,
  storeMyChecklist,
} from '../../request/authenticated-requests/checklist';
import {
  versionSchema,
  storeChecklistResDto,
  CheckListId,
  StammdatenQuestionnaireQuestionKey,
  AusweisTypEnum,
  PassportTypeEnum,
  ErrorCode,
} from '@web/common';
import type {
  CannabisConsumptionQuestionnaireResult,
  CheckListDataSchema,
  StammdatenQuestionnaireReqDto,
  StoreChecklistReqDto,
  StoreChecklistResDto,
} from '@web/common';
import {
  currentChecklistVersion,
  defaultOnboardingChecklist,
  applyDefaultValues,
} from './defaultOnboardingChecklist';
import { showErrorNotification } from '../../component/notification';

export const allowedItems = [
  CheckListId.IdentitaetsNachweis,
  CheckListId.CannabisConsumptionQuestionnaire,
  CheckListId.EmailVerified,
  CheckListId.SF12Questionnaire,
  CheckListId.StammdatenQuestionnaire,
];

type ResultProps =
  | {
      id: CheckListId.StammdatenQuestionnaire;
      result: StammdatenQuestionnaireReqDto;
      userId?: string;
    }
  | {
      id: CheckListId.CannabisConsumptionQuestionnaire;
      result: CannabisConsumptionQuestionnaireResult;
      userId?: string;
    }
  | {
      id: CheckListId.SF12Questionnaire;
      result: null;
      userId?: string;
    };

export class OnboardingChecklistStore {
  @observable public showSimpleChecklist: boolean = false;
  @observable public loadingChecklist: boolean = true;
  @observable public allChecked: boolean = false;
  @observable public checklist: null | CheckListDataSchema = null;
  @observable private container: null | StoreChecklistResDto = null;

  constructor() {
    makeObservable(this);
  }

  @action public toggleSimpleChecklist() {
    this.showSimpleChecklist = !this.showSimpleChecklist;
  }

  @action public loadOnboardingChecklist() {
    this.loadingChecklist = true;
    getMyChecklist()
      .then(async (res) => await this.parseOrMigrateChecklist(res))
      .catch(() => showErrorNotification())
      .finally(() =>
        runInAction(() => {
          this.loadingChecklist = false;
        }),
      );
  }

  @action public async checkItem(id: CheckListId, checkState: boolean) {
    await this.setChecklistItemState(id, checkState);
  }

  @action public async updateQuestionnaireResults({
    id,
    result,
    userId,
  }: ResultProps) {
    if (!userId) return;

    const res = await getChecklistForUser(userId);
    await this.parseOrMigrateChecklist(res, userId);

    this.storeQuestionnaireResults({ id, result, userId } as ResultProps).catch(
      () => showErrorNotification(),
    );
  }

  @action public async storeQuestionnaireResults({
    id,
    result,
    userId,
  }: ResultProps) {
    if (!this.container) {
      showErrorNotification(ErrorCode.CannotSaveQuestionnaireResult);
      return;
    }
    const item = this.container.data?.[id];
    if (!item) {
      showErrorNotification(ErrorCode.CannotSaveQuestionnaireResult);
      return;
    }
    item.done = true;
    item.questionnaireResult = result;
    if (userId)
      return this.storeOnboardingChecklistForUser(this.container, userId);
    return this.storeOnboardingChecklist(this.container);
  }

  private updateData(res: StoreChecklistResDto) {
    runInAction(() => {
      this.container = res;
      this.checklist = res.data ?? null;
      this.checkIfAllChecked(res);
    });
  }

  // TODO: SCC-250 also allow changing other questionnaire answers
  private async storeOnboardingChecklistForUser(
    checklist: StoreChecklistReqDto,
    userId: string,
  ) {
    return storeChecklistForUser(userId, checklist)
      .then((res) => this.updateData(res))
      .catch(() => showErrorNotification());
  }

  private async storeOnboardingChecklist(checklist: StoreChecklistReqDto) {
    return storeMyChecklist(checklist)
      .then((res) => this.updateData(res))
      .catch(() => showErrorNotification());
  }

  private setChecklistItemState(id: CheckListId, checkState: boolean) {
    if (!this.container) {
      showErrorNotification(ErrorCode.CannotCheckItem);
      return;
    }
    const item = this.container.data?.[id];
    if (!item) {
      showErrorNotification(ErrorCode.CannotCheckItem);
      return;
    }
    item.done = checkState;
    return this.storeOnboardingChecklist(this.container);
  }

  private checkIfAllChecked(checkList: StoreChecklistResDto) {
    if (!checkList.data) return;

    const checklistItems = Object.entries(checkList.data);
    const filteredList = checklistItems.filter(([id]) =>
      allowedItems.includes(id as CheckListId),
    );
    this.allChecked = filteredList.every(([, item]) => item.done);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @action private parseOrMigrateChecklist(res: any, userId?: string) {
    const versionOnly = versionSchema.parse(res);
    if (versionOnly.version < currentChecklistVersion) {
      return this.migrateChecklist(versionOnly.version, res, userId);
    } else {
      const parsed = storeChecklistResDto.parse(res);
      this.updateData(parsed);
    }
  }

  // NOTE: do not forget to increase the `currentChecklistVersion` in defaultOnboardingChecklist.ts
  // TODO https://quickbird.atlassian.net/browse/SCC-172
  // this can only work, if this is the only place, where the checklist is retrieved
  // currently, that is not the case!
  private migrateChecklist(
    version: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checklistData: any,
    userId?: string,
  ) {
    if (version === 0) {
      return this.storeOnboardingChecklist({
        version: currentChecklistVersion,
        data: defaultOnboardingChecklist,
      });
    }

    let migratedChecklist = applyDefaultValues(checklistData);
    if (version === 2) {
      migratedChecklist = {
        ...migratedChecklist,
        [CheckListId.SF12Questionnaire]: {
          ...migratedChecklist[CheckListId.SF12Questionnaire],
          questionnaireResult: null,
          done: false,
        },
      };
    }
    if (version <= 3) {
      const stammdatenQuestionnaire =
        migratedChecklist[CheckListId.StammdatenQuestionnaire]
          .questionnaireResult;
      if (stammdatenQuestionnaire) {
        stammdatenQuestionnaire[StammdatenQuestionnaireQuestionKey.AHVNumber] =
          checklistData.data[
            CheckListId.StammdatenQuestionnaire
          ].questionnaireResult['avh-number'];
      }

      migratedChecklist = {
        ...migratedChecklist,
        [CheckListId.StammdatenQuestionnaire]: {
          ...migratedChecklist[CheckListId.StammdatenQuestionnaire],
          questionnaireResult: stammdatenQuestionnaire,
        },
      };
    }

    if (version <= 4) {
      const stammdatenQuestionnaire =
        migratedChecklist[CheckListId.StammdatenQuestionnaire]
          .questionnaireResult;
      if (stammdatenQuestionnaire) {
        if (
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.SwissNationality
          ]
        ) {
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.HeimatOrt
          ] = 'Migrated Heimatort';
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.AusweisType
          ] = AusweisTypEnum.Ausweis;
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.AusweisNr
          ] = 'Migrated AusweisNr';
        } else {
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.GeburtsOrt
          ] = 'Migrated Geburtsort';
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.PassportType
          ] = PassportTypeEnum.OtherPassport;
          stammdatenQuestionnaire[
            StammdatenQuestionnaireQuestionKey.AusweisNr
          ] = 'Migrated AusweisNr';
        }

        migratedChecklist = {
          ...migratedChecklist,
          [CheckListId.StammdatenQuestionnaire]: {
            ...migratedChecklist[CheckListId.StammdatenQuestionnaire],
            questionnaireResult: stammdatenQuestionnaire,
          },
        };
      }
    }

    if (version <= 5) {
      const stammdatenQuestionnaireResults =
        migratedChecklist[CheckListId.StammdatenQuestionnaire]
          .questionnaireResult;

      if (stammdatenQuestionnaireResults) {
        stammdatenQuestionnaireResults[
          StammdatenQuestionnaireQuestionKey.AusweisNr
        ] =
          stammdatenQuestionnaireResults[
            StammdatenQuestionnaireQuestionKey.AusweisNr
          ] ?? 'Ausweis Nr. wurde noch nicht eingetragen';

        migratedChecklist = {
          ...migratedChecklist,
          [CheckListId.StammdatenQuestionnaire]: {
            ...migratedChecklist[CheckListId.StammdatenQuestionnaire],
            questionnaireResult: stammdatenQuestionnaireResults,
          },
        };
      }
    }

    const updatedData = {
      version: currentChecklistVersion,
      data: migratedChecklist,
    };

    if (userId)
      return this.storeOnboardingChecklistForUser(updatedData, userId);
    return this.storeOnboardingChecklist(updatedData);
  }
}

const { provider, useStore } = setupMobX<OnboardingChecklistStore>();
export const useOnboardingChecklistStore = useStore;
export const OnboardingChecklistStoreProvider = provider;
