import { z } from 'zod';
import { languageZodEnum } from '../../enum';

/**
 * this should be used for user metadata: language, etc.
 */
export const myDataReqDto = z
  .object({
    language: languageZodEnum(),
  })
  .partial();

export type MyDataReqDto = z.infer<typeof myDataReqDto>;
