export enum RealmRole {
  Participant = 'participant',
  ShopEmployee = 'shop-employee',
  StudyOrganizer = 'study-organizer',
  Deactivated = 'deactivated',
  Admin = 'realm-admin',
}

export const allRealmRoles = Object.values(RealmRole);

export const participantRole = `realm:${RealmRole.Participant}` as const;
export const shopEmployeeRole = `realm:${RealmRole.ShopEmployee}` as const;
export const studyOrganizerRole = `realm:${RealmRole.StudyOrganizer}` as const;
export const deactivatedRole = `realm:${RealmRole.Deactivated}` as const;
export const adminRole = `realm-management:${RealmRole.Admin}` as const;

export type RealmRoleType =
  | typeof participantRole
  | typeof shopEmployeeRole
  | typeof studyOrganizerRole
  | typeof deactivatedRole
  | typeof adminRole;
