import { Flex, Text } from '@mantine/core';
import { useWindowSize } from '../../../../../hooks';
import { LoyalityPointInfo } from './LoyalityPoints';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const isSmallScreen = useWindowSize(850);
  const { t } = useTranslation('homescreen');
  return (
    <Flex
      gap={20}
      justify={isSmallScreen ? 'flex-start' : 'space-between'}
      direction={isSmallScreen ? 'column' : 'row'}
    >
      <Text fw="500" size="28px" lh="38px">
        {t('WelcomeBack')}
      </Text>
      <LoyalityPointInfo />
    </Flex>
  );
};
