import { observer } from 'mobx-react';
import {
  StatisticStore,
  StatisticStoreProvider,
  useStatisticStore,
} from '../../../store';
import { Flex, Loader, Space, Text } from '@mantine/core';
import { useEffect } from 'react';
import { BaseLayout } from '../navigation';
import classes from '../../../sharedStyles/InfoCard.module.css';
import { TextWrapper } from '../../../component';
import { useTranslation } from 'react-i18next';

const Statistics = observer(() => {
  const statisticStore = useStatisticStore();
  const isLoading = statisticStore.isLoading;
  const { t } = useTranslation('statistics');
  const {
    registeredUsers,
    usersWithAppointment,
    usersForEveryCohort,
    deactivatedUsers,
    usersWithPassedHealthQuestionnaire,
    usersWithFilledOutHealthQuestionnaire,
    usersWithoutProblematicConsumption,
    usersWithFilledOutCuditQuestionnaire,
    usersWithFilledOutBaselineSurvey,
    usersWithAppointmentBeforeCohortStart,
  } = statisticStore.statisticData || {};

  useEffect(() => {
    statisticStore.loadStatisticData();
  }, [statisticStore]);

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={700}
        gap={20}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('Title')}
        </Text>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Space h="20px" />
            <TextWrapper title={t('RegisterAmount')} value={registeredUsers} />
            <TextWrapper
              title={t('UsersWithAppointment')}
              value={usersWithAppointment + '/' + registeredUsers}
            />

            <TextWrapper
              title={t('UsersWithProblematicConsumption')}
              value={
                usersWithoutProblematicConsumption +
                '/' +
                usersWithFilledOutCuditQuestionnaire
              }
            />
            <TextWrapper
              title={t('UsersWithPassedHealthQuestionnaire')}
              value={
                usersWithPassedHealthQuestionnaire +
                '/' +
                usersWithFilledOutHealthQuestionnaire
              }
            />

            {usersForEveryCohort?.map((cohort, index) => (
              <TextWrapper
                key={index}
                title={t('UsersForEveryCohort') + ` „${cohort.cohortId}“ :`}
                value={cohort.amount}
              />
            ))}
            <TextWrapper
              title={t('UsersWithFilledOutBaselineSurvey')}
              value={usersWithFilledOutBaselineSurvey}
            />
            <TextWrapper
              title={t('DeactivatedUsers')}
              value={deactivatedUsers}
            />
            <TextWrapper
              title={t('UsersWithAppointmentBeforeCohortStart')}
              value={usersWithAppointmentBeforeCohortStart}
            />
          </>
        )}
      </Flex>
    </BaseLayout>
  );
});

export const StatisticsView = () => {
  return (
    <StatisticStoreProvider store={new StatisticStore()}>
      <Statistics />
    </StatisticStoreProvider>
  );
};
