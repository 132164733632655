import { z } from 'zod';

export const participantNotFoundResDto = z.object({
  error: z.enum(['participant_not_found']),
  code: z.string(),
  error_description: z.string(),
  message: z.string(),
  hint: z.string(),
});
export type ParticipantNotFoundResDto = z.infer<
  typeof participantNotFoundResDto
>;
