import { observer } from 'mobx-react';
import { Questionnaire } from '../questionnaire';
import { CheckListId, sf12QuestionnaireReqDto } from '@web/common';
import { QuestionnaireWrapper } from './QuestionnaireWrapper';
import { useParticipantData } from './hooks/useParticipantData';
import { getSf12QuestionnaireSurveyJSON } from '../../view/authenticated/participant/sf12-questionnaire/sf12-survey-specification';
import { useTranslation } from 'react-i18next';

export const ViewSF12Questionnaire = observer(() => {
  const { userInformation } = useParticipantData();
  const { t } = useTranslation('sf12-questionnaire');
  const questionnaireData =
    userInformation?.checkListData?.data?.[CheckListId.SF12Questionnaire]
      ?.questionnaireResult ?? undefined;

  return (
    <QuestionnaireWrapper>
      <Questionnaire
        title={t('Title')}
        questionnaire={{
          json: getSf12QuestionnaireSurveyJSON(t),
          schema: sf12QuestionnaireReqDto,
          initial: questionnaireData,
        }}
        onComplete={() => {}}
        onError={() => {}}
        readonly={true}
      />
    </QuestionnaireWrapper>
  );
});
