import { PickUpStore, PickUpStoreProvider } from './PickUpStore';
import { PickUpDetails } from './PickUpDetails';
import { memo } from 'react';

const PickUp = ({ userId }: { userId: string }) => {
  return (
    <PickUpStoreProvider store={new PickUpStore()}>
      <PickUpDetails userId={userId} />
    </PickUpStoreProvider>
  );
};

export default memo(PickUp);
