import { z } from 'zod';

export const bagIdSchema = z.string().max(32); // limit asserted because of database
export const bagSurveyLinkSchema = z.string().max(2048); // limit asserted because of database
export const bagSurveyHorizonSchema = z.union([
  z.coerce.number().refine((v) => v >= 0 && v <= 2 && v % 1 === 0, {
    message: 'Must v >= 0 && v <= 2 && v % 1 === 0',
  }),
  z.coerce.number().refine((v) => v >= 0 && v <= 2 && v % 1 === 0, {
    message: 'Must v >= 0 && v <= 2 && v % 1 === 0',
  }),
  z.coerce.number().refine((v) => v >= 0 && v <= 2 && v % 1 === 0, {
    message: 'Must v >= 0 && v <= 2 && v % 1 === 0',
  }),
]);
export const bagIdsSurveyImportRow = z.array(z.string());
