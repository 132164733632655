import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { UserStatus } from '@prisma/client';

export const userStatusZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(UserStatus)))
    .transform(
      (value) => UserStatus[value as keyof typeof UserStatus] as UserStatus,
    );

export const userStatusColors = {
  OPEN: 'transparent',
  ACTIVE: '#3D7F53',
  PAUSED: '#202020',
  DEACTIVATED: '#E4032E',
};

export const userStatusIcons = {
  OPEN: '',
  ACTIVE: 'check',
  PAUSED: 'hourglass',
  DEACTIVATED: 'close',
};
