import React from 'react';

export function setupMobX<Store>() {
  const Context = React.createContext({} as Store);

  const Provider = ({
    children,
    store,
  }: {
    children: React.ReactNode;
    store: Store;
  }) => {
    return <Context.Provider value={store}>{children}</Context.Provider>;
  };

  return {
    useStore: () => React.useContext<Store>(Context),
    provider: Provider,
  };
}
