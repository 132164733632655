import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type CardProps = {
  fullname: string;
  phoneNumber?: string;
  email?: string;
};

export const VCFExport = ({ data }: { data: CardProps[] }) => {
  const { t } = useTranslation('common');

  function downloadVCard() {
    const vCard = data
      .map(
        (user) =>
          `BEGIN:VCARD
VERSION:3.0
N:${user.fullname.split(' ').reverse().join(';')};;;
FN:${user.fullname}
EMAIL;TYPE=INTERNET:${user.email}
${user.phoneNumber ? `TEL;TYPE=CELL:${user.phoneNumber}` : ''}
ORG;CHARSET=UTF-8:Pilotversuch Zürich
END:VCARD`,
      )
      .join('\n');

    const element = document.createElement('a');
    const file = new Blob([vCard], { type: 'text/vcard' });
    element.href = URL.createObjectURL(file);
    element.download = 'contacts.vcf';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return <Button onClick={downloadVCard}> {t('Buttons.ExportvCard')}</Button>;
};
