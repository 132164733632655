import { Button, Flex, Space, Text } from '@mantine/core';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { BaseLayout } from '../../navigation';
import { Header, QuestionnaireBanner } from './components';
import { THCAmountCard } from './components/THCAmountCard';
import { observer } from 'mobx-react';
import { useUserMeStore } from '../../../../store';
import {
  CohortType,
  LoyalityPointsLogReason,
  MissionType,
} from '@prisma/client';
import { useWindowSize } from '../../../../hooks';
import { MissionWrapper } from './components';
import { useMissionStore } from '../../../../store/MissionStore';
import { useEffect } from 'react';
import { LogoutButton } from '../../../../component';
import { useTranslation } from 'react-i18next';
import { ErrorCode } from '@web/common';
import { DevContent } from '../../../../util/DevContent';
import {
  showErrorNotification,
  showFailedRequestNotification,
  showPointsAwardedNotification,
  showSuccessNotification,
} from '../../../../component/notification';
import useRedirect from '../../../../hooks/useRedirect';
import { OpenPodcastPrompt } from '../podcast/OpenPodcastPrompt';

const canAccessTHCAmount = [CohortType.LIMITED_ACCESS] as CohortType[];

export const HomeScreen = observer(() => {
  const missionStore = useMissionStore();
  const missions = missionStore.missions;
  const isSmallScreen = useWindowSize(500);
  const { t } = useTranslation('homescreen');
  useRedirect();

  const myInfoStore = useUserMeStore();
  const cohortType = myInfoStore.myInfo?.Participant?.cohortType;
  const canSeeTHCAmount = canAccessTHCAmount.includes(
    cohortType ?? CohortType.CONTROL_GROUP,
  );
  const isControlGroup = cohortType === CohortType.CONTROL_GROUP;

  const questionnaireMission = missions.find(
    (mission) =>
      mission.type === MissionType.RegularQuestionnaire ||
      mission.type === MissionType.RegularQuestionnaireStreak,
  );

  useEffect(() => {
    missionStore.completeMission(MissionType.WeeklyLogin);
  }, [missionStore]);

  return (
    <BaseLayout hideNavbar={isControlGroup}>
      <Flex
        direction="column"
        maw={1100}
        gap={20}
        bg="#fff"
        p={isSmallScreen ? 20 : 40}
        className={classes.borderRadius}
      >
        <Header />

        <OpenPodcastPrompt />

        {questionnaireMission && (
          <QuestionnaireBanner mission={questionnaireMission} />
        )}

        <Flex gap="24px" align="flex-start" wrap="wrap">
          {canSeeTHCAmount && (
            <div>
              {!isSmallScreen && (
                <Text fw="600" size="21px" lh="24px">
                  {t('THC.Title')}
                </Text>
              )}
              <Space h="20px" />
              <THCAmountCard />
            </div>
          )}

          <MissionWrapper />
        </Flex>

        {isControlGroup && (
          <Flex gap={20} direction="column" maw="200px">
            <Space h="lg" />
            <Text lh="24px" size="21px" fw="500">
              {t('Settings')}
            </Text>
            <LogoutButton />
          </Flex>
        )}

        <DevContent>
          <Button
            onClick={() =>
              showPointsAwardedNotification(
                100,
                LoyalityPointsLogReason.PodcastListened,
                2050,
              )
            }
          >
            showPointsAwardedNotification
          </Button>
          <Button onClick={() => showSuccessNotification('Text', 'Title')}>
            showSuccessNotification
          </Button>
          <Button
            onClick={() =>
              showFailedRequestNotification(
                ErrorCode.GenericError,
                'tracing uuid',
              )
            }
          >
            showFailedRequestNotification
          </Button>
          <Button
            onClick={() =>
              showErrorNotification(ErrorCode.GenericError, 'tracing uuid')
            }
          >
            showFailedRequestNotification
          </Button>
        </DevContent>
      </Flex>
    </BaseLayout>
  );
});
