import { z } from 'zod';

const platformDeliveryStats = z.object({
  successful: z.number(),
  failed: z.number(),
  errored: z.number(),
  converted: z.number(),
  received: z.number(),
  suppressed: z.number(),
  opened: z.number(),
  unique_opens: z.number(),
  clicks: z.number(),
  unique_clicks: z.number(),
  bounced: z.number(),
  reported_spam: z.number(),
  unsubscribed: z.number(),
});

export const getEmailNotificationResDot = z.object({
  platform_delivery_stats: z.object({
    email: platformDeliveryStats,
  }),
});

export const getSmsNotificationResDot = z.object({
  platform_delivery_stats: z.object({
    sms: platformDeliveryStats,
  }),
});

export type GetEmailNotificationResDot = z.infer<
  typeof getEmailNotificationResDot
>;
export type GetSmsNotificationResDot = z.infer<typeof getSmsNotificationResDot>;
export type PlatformDeliveryStatsSchema = z.infer<typeof platformDeliveryStats>;
