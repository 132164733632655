import { z } from 'zod';
import { dateTimeTransformer } from '../../../lib/util/date-time-transformer';

export enum PointOfSalesType {
  All = 'all',
  Gln = 'gln',
  Seller = 'seller',
}

export enum PeriodType {
  Range = 'range',
  Day = 'day',
  All = 'all',
}

const pointOfSalesQuery = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PointOfSalesType.All),
  }),
  z.object({
    type: z.literal(PointOfSalesType.Gln),
    gln: z.string(),
  }),
  z.object({
    type: z.literal(PointOfSalesType.Seller),
    which: z.union([z.literal('pharmacy'), z.literal('specialistShop')]),
  }),
]);

const periodQuery = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(PeriodType.Range),
    from: z.string().transform(dateTimeTransformer()),
    to: z.string().transform(dateTimeTransformer()),
  }),
  z.object({
    type: z.literal(PeriodType.Day),
    day: z.string().transform(dateTimeTransformer()),
  }),
  z.object({
    type: z.literal(PeriodType.All),
  }),
]);

export const inventoryQueryReqDto = z
  .object({
    pointOfSales: pointOfSalesQuery,
    period: periodQuery,
  })
  .partial();
export type InventoryQueryReqDto = z.infer<typeof inventoryQueryReqDto>;
