import { Flex, Box, Text } from '@mantine/core';
import { Title } from '../../../../component';
import { useWindowSize } from '../../../../hooks';
import { StudyCode } from '../study-code/StudyCode';
import { Routes } from '../../../../route/Routes';
import { useTranslation } from 'react-i18next';

const smallScreenWidth = 760;

export const ChecklistHeader = () => {
  const isSmallScreen = useWindowSize(smallScreenWidth);
  const { t } = useTranslation('onboarding-checklist');

  return (
    <Flex
      justify={isSmallScreen ? 'flex-start' : 'center'}
      p="40px 20px 20px 20px"
      gap="30px"
      wrap={isSmallScreen ? 'wrap' : 'nowrap'}
    >
      <Box>
        <Title
          text={t('Header.Title')}
          boxPadding="0px 0px 10px 0px"
          textPadding="0px 0px 10px 0px"
          margin={isSmallScreen ? '0px' : '0px'}
        />
        <Text w={isSmallScreen ? '100%' : '100%'} maw="620px" miw="200px">
          {t('Header.Description1')}
          <br />
          {t('Header.Description2')}{' '}
          <a style={{ color: '#black' }} href={Routes.Datenschutz}>
            {t('Header.PrivacyPolicy')}
          </a>{' '}
          {t('Header.Description3')}
        </Text>
      </Box>
      <StudyCode smallScreenWidth={smallScreenWidth} />
    </Flex>
  );
};
