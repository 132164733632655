import Keycloak from 'keycloak-js';
import { REALM_NAME } from './REALM_NAME';
import { CLIENT_ID } from './CLIENT_ID';

const location = window.location;

// depending on which environment, we need to use different URL parts
const protocol = process.env['NX_KEYCLOAK_HOST']
  ? ''
  : `${location.protocol}//`;
const hostname = process.env['NX_KEYCLOAK_HOST']
  ? process.env['NX_KEYCLOAK_HOST']
  : 'auth.' + location.hostname.replace('www.', '');
const port = process.env['NX_KEYCLOAK_PORT']
  ? `:${process.env['NX_KEYCLOAK_PORT']}`
  : '';

export const url = `${protocol}${hostname}${port}`;

let realm = REALM_NAME;

if (process.env['NX_KEYCLOAK_REALM_OVERRIDE']) {
  realm = process.env['NX_KEYCLOAK_REALM_OVERRIDE'];
  console.warn(`Overwritten realm: ${realm}`);
}

const keycloakConfig = {
  url,
  realm: realm,
  clientId: CLIENT_ID,
};
console.log(`Keycloak Config: `, keycloakConfig);

/**
 * this is the issue: this keycloak instance can only be instantiated once.
 * but we have to instantiate it 2 different times:
 * - when the user registers
 * - when the user logs in normally
 *
 * so we need to reset it sometimes
 */
export function newKeycloakInstance() {
  return new Keycloak(keycloakConfig);
}
