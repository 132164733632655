import { useState } from 'react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UpdateQRCodeModal } from './UpdateQRCodeModal';
import { observer } from 'mobx-react';

export const UpdateQRCodeButton = observer(() => {
  const { t } = useTranslation('update-qr-code');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <UpdateQRCodeModal
        modalOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
      <Button onClick={() => setIsOpen(true)} color="red" variant="outline">
        {t('Button')}
      </Button>
    </>
  );
});
