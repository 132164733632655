import { observer } from 'mobx-react';
import { useOpenOrderStore } from './OpenOrdersStore';
import { useEffect } from 'react';
import { OpenOrderList } from './OpenOrderList';
import { useParams } from 'react-router-dom';

export const OpenOrdersForUser = observer(() => {
  const store = useOpenOrderStore();
  const openOrders = store.openOrdersForUser;
  const { userId } = useParams();

  useEffect(() => {
    if (userId) store.getAllOpenOrdersForUser(userId);
  }, [store, userId]);

  return <OpenOrderList openOrders={openOrders} isLoading={store.isLoading} />;
});
