import { observer } from 'mobx-react';
import { Questionnaire } from '../questionnaire';
import { getEntryQuestionnaireSurveyJSON } from '../../view/public/entry-questionnaire/entry-questionnaire-survey-specification';
import { entryQuestionnaireReqDto } from '@web/common';
import { QuestionnaireWrapper } from './QuestionnaireWrapper';
import { useParticipantData } from './hooks/useParticipantData';
import { useTranslation } from 'react-i18next';

export const ViewEntryQuestionnaire = observer(() => {
  const { userInformation } = useParticipantData();
  const { t } = useTranslation('entry-questionnaire');
  const entryQuestionnaireData = userInformation?.entryQuestionnaire;

  return (
    <QuestionnaireWrapper>
      <Questionnaire
        title={'Eintrittsfragebogen'}
        questionnaire={{
          json: getEntryQuestionnaireSurveyJSON(t),
          schema: entryQuestionnaireReqDto,
          initial: entryQuestionnaireData,
        }}
        onComplete={() => {}}
        onError={() => {}}
        readonly={true}
      />
    </QuestionnaireWrapper>
  );
});
