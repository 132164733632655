import { DateTime } from 'luxon';
import { setupMobX } from '../../../../../util/setupMobX';
import { observable, makeObservable, action } from 'mobx';
import {
  ConsumptionConcentration,
  EntryTime,
  ConsumptionType,
  Feeling,
} from '@prisma/client';
import { createDiaryEntry } from '../../../../../request/authenticated-requests/diary';
import { CreateDiaryEntryReqDto, CreateDiaryEntryResDto } from '@web/common';
import { showErrorNotification } from '../../../../../component/notification';

export class DiaryDialogStore {
  @observable public activeStep: number = 0;
  @observable public didConsume: boolean = false;
  @observable public entryDate: DateTime = DateTime.now();
  @observable public entryTime: EntryTime = EntryTime.MORNING;
  @observable public consumptionType: ConsumptionType | null = null;
  @observable public consumptionAmount: number = 0;
  @observable public consumptionConcentration: ConsumptionConcentration | null =
    null;
  @observable public consumptionFeelings: Feeling[] = [];
  @observable public consumptionReasons: string[] = [];
  @observable public notes: null | string = null;

  constructor() {
    makeObservable(this);
  }

  @action public setActiveStep = (step: number) => {
    this.activeStep = step;
  };

  @action public setDidConsume = (didConsume: boolean) => {
    this.didConsume = didConsume;
    if (!didConsume) this.resetConsumptionData();
  };

  @action public setConsumptionDate = (entryDate: DateTime) => {
    this.entryDate = entryDate;
  };

  @action public setEntryTime = (entryTime: EntryTime) => {
    this.entryTime = entryTime;
  };

  @action public setConsumptionType = (consumptionType: ConsumptionType) => {
    this.consumptionType = consumptionType;
  };

  @action public setConsumptionAmount = (consumptionAmount: number) => {
    this.consumptionAmount = consumptionAmount;
  };

  @action public setConsumptionConcentration = (
    consumptionConcentration: ConsumptionConcentration,
  ) => {
    this.consumptionConcentration = consumptionConcentration;
  };

  @action public toggleConsumptionFeelings = (consumptionFeeling: Feeling) => {
    if (this.consumptionFeelings.includes(consumptionFeeling)) {
      this.consumptionFeelings = this.consumptionFeelings.filter(
        (feeling) => feeling !== consumptionFeeling,
      );
    } else {
      this.consumptionFeelings.push(consumptionFeeling);
    }
  };

  @action public addConsumptionReason = (reason: string) => {
    if (this.consumptionReasons.includes(reason)) {
      this.consumptionReasons = this.consumptionReasons.filter(
        (item) => item !== reason,
      );
    } else {
      this.consumptionReasons.push(reason);
    }
  };

  @action public setNotes = (notes: string) => {
    this.notes = notes;
  };

  @action public submitDiaryEntry = (
    successAction: (result: CreateDiaryEntryResDto) => void,
  ) => {
    const data = {
      didConsume: this.didConsume,
      entryDate: this.entryDate.toJSDate(),
      entryTime: this.entryTime,
      consumptionType: this.consumptionType,
      consumptionAmount: this.consumptionAmount,
      consumptionConcentration: this.consumptionConcentration,
      feelings: this.consumptionFeelings,
      reasons: this.consumptionReasons,
      notes: this.notes,
    } as CreateDiaryEntryReqDto;

    createDiaryEntry(data)
      .then((result) => {
        successAction(result);
        // wait for the dialog to close to avoid flickering
        setTimeout(() => this.resetConsumptionData(), 400);
      })
      .catch(() => showErrorNotification());
  };

  @action public resetConsumptionData = () => {
    this.didConsume = false;
    this.entryDate = DateTime.now();
    this.entryTime = EntryTime.MORNING;
    this.consumptionType = null;
    this.consumptionAmount = 0;
    this.consumptionConcentration = null;
    this.consumptionFeelings = [];
    this.consumptionReasons = [];
    this.activeStep = 0;
  };
}

const { provider, useStore } = setupMobX<DiaryDialogStore>();
export const useDiaryDialogStore = useStore;
export const DiaryDialogStoreProvider = provider;
