import { renderToString } from 'react-dom/server';
import {
  contactToFriendsAndFamily,
  dailyActivitiesIssuesBcOfPainQuestion,
  generalHealthQuestion,
  last4WeeksFeelingQuestion,
  last4WeeksMentalIssuesQuestion,
  last4WeeksWorkPhysicalIssuesQuestion,
  normalDayActivitiesMatrixQuestion,
} from '@web/common';
import classes from '../../../public/entry-questionnaire/IntroBox.module.css';
import stammdaten from '../../../../assets/stammdaten.svg';

const introComponent = (t: TranslationFunction) => {
  return (
    <div className={classes.flexBox}>
      <img
        src={stammdaten}
        alt={t('Title')}
        style={{ flex: '0 10%', justifySelf: 'center' }}
      />
      <div style={{ flex: '1', minWidth: 300 }}>
        <span className={classes.header}>{t('Header.Title')}</span>
        <p className={classes.firstParagraph}>{t('Header.Description')}</p>
      </div>
    </div>
  );
};

const intro = (t: TranslationFunction) => {
  return {
    name: 'intro',
    type: 'html',
    html: renderToString(introComponent(t)),
  };
};

type TranslationFunction = {
  (key: string): string;
};

export const getSf12QuestionnaireSurveyJSON = (t: TranslationFunction) => {
  return {
    pages: [
      {
        elements: [
          intro(t),
          generalHealthQuestion(t),
          normalDayActivitiesMatrixQuestion(t),
          last4WeeksWorkPhysicalIssuesQuestion(t),
          last4WeeksMentalIssuesQuestion(t),
          dailyActivitiesIssuesBcOfPainQuestion(t),
          last4WeeksFeelingQuestion(t),
          contactToFriendsAndFamily(t),
        ],
      },
    ],
  };
};
