import { observer } from 'mobx-react';
import { ActionIcon, Flex, Loader, Table, Text } from '@mantine/core';
import { Routes } from '../../../../route/Routes';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '../../../../component';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  OpenOrdersForUserResDto,
  OpenOrdersResDto,
  PaidStatus,
} from '@web/common';

export const OpenOrderList = observer(
  ({
    openOrders,
    isLoading,
  }: {
    openOrders: OpenOrdersResDto | OpenOrdersForUserResDto;
    isLoading: boolean;
  }) => {
    const navigate = useNavigate();
    const foundUsers = openOrders?.length;
    const { t } = useTranslation('shop');

    if (isLoading) return <Loader />;

    const route = (orderId: string) => {
      const extractIdFromGid = (gid: string) => Number(gid.split('/').pop());
      navigate(
        `${Routes.Study}/${Routes.OpenShopOrders}/${extractIdFromGid(orderId)}`,
      );
    };

    return (
      <>
        <Text fw="600" lh="24px" size="16px">
          {foundUsers}{' '}
          {foundUsers > 1 || foundUsers === 0
            ? t('OpenOrderTable.Results')
            : t('OpenOrderTable.Result')}
        </Text>
        <Table stickyHeader highlightOnHover highlightOnHoverColor="#f6f5ef">
          <Table.Thead bg="#f6f5ef" fw="500" lh="24px">
            <Table.Tr>
              <Table.Td>{t('OpenOrderTable.Columns.Ordernumber')}</Table.Td>
              <Table.Td>{t('OpenOrderTable.Columns.StudyCode')}</Table.Td>
              <Table.Td>{t('OpenOrderTable.Columns.Name')}</Table.Td>
              <Table.Td>{t('OpenOrderTable.Columns.Date')}</Table.Td>
              <Table.Td>{t('OpenOrderTable.Columns.Amount')}</Table.Td>
              <Table.Td>{t('OpenOrderTable.Columns.Status')}</Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {openOrders?.map((openOrder) => {
              const { firstName, lastName } = openOrder.billingAddress;
              const fullname = firstName + ' ' + lastName;
              const paidIcon =
                openOrder.paidStatus === PaidStatus.Unpaid
                  ? 'payments'
                  : 'store';
              const formattedDate = DateTime.fromJSDate(
                openOrder.orderPlacedDate,
              ).toFormat('dd.MM.yy');
              const amount =
                typeof openOrder.orderItems === 'number'
                  ? openOrder.orderItems
                  : openOrder.orderItems.length;

              return (
                <Table.Tr
                  key={openOrder.id}
                  onClick={() => route(openOrder.id)}
                >
                  <Table.Td>{openOrder.name}</Table.Td>
                  <Table.Td>{openOrder.studyCode ?? '-'}</Table.Td>
                  <Table.Td>{fullname}</Table.Td>
                  <Table.Td>{formattedDate}</Table.Td>
                  <Table.Td>{amount}</Table.Td>
                  <Table.Td>
                    <Flex align="center" gap="10px">
                      <MyIcon
                        name={paidIcon}
                        style={{ fill: '#3D7F53', height: 25 }}
                      />
                      <Text size="12px" c="#202020">
                        {t('PaidStatus.' + openOrder.paidStatus)}
                      </Text>
                    </Flex>
                  </Table.Td>
                  <Table.Td>
                    <Flex w="100%" justify="flex-end">
                      <ActionIcon variant="white">
                        <MyIcon name="chevron_right" />
                      </ActionIcon>
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
        {!foundUsers && (
          <Text fw="500" lh="24px" size="16px" ta="center" p="40px 0px">
            {t('OpenOrderTable.NoData')}
          </Text>
        )}
      </>
    );
  },
);
