import { setupMobX } from '../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import type { DispensariesResDto } from '@web/common';
import { getDispensaries } from '../request/authenticated-requests/dispensary';
import { showErrorNotification } from '../component/notification';

export class DispensaryStore {
  @observable public dispensaries: DispensariesResDto = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadDispensaries = () => {
    getDispensaries()
      .then((dispensaries) =>
        runInAction(() => (this.dispensaries = dispensaries)),
      )
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<DispensaryStore>();
export const useDispensaryStore = useStore;
export const DispensaryStoreProvider = provider;
