import { action, makeObservable, observable } from 'mobx';
import { CheckListId } from '@web/common';
import { setupMobX } from '../util/setupMobX';

export enum CheckOffId {
  StammdatenQuestionnaire = CheckListId.StammdatenQuestionnaire,
  EinwilligungserklaerungErhalten = CheckListId.Einwilligungserklaerung,
  Wohnsitznachweis = CheckListId.Wohnsitznachweis,
  EntryQuestionnaire = 'EntryQuestionnaire',
  CannabisConsumptionQuestionnaire = CheckListId.CannabisConsumptionQuestionnaire,
  SF12Questionnaire = CheckListId.SF12Questionnaire,
  KontraIndikationen = 'KontraIndikationen',
}
export enum CheckOffType {
  SimpleCheck,
  Questionnaire,
}

export type Checkpoint = {
  id: string;
  type: CheckOffType;
  title: string;
  description: string;
  done: boolean;
};

const defaultCheckpoints: Checkpoint[] = [
  {
    id: CheckOffId.StammdatenQuestionnaire,
    type: CheckOffType.SimpleCheck,
    title: 'Stammdaten prüfen',
    description:
      'Lass dir den Ausweis und den AHV Ausweis zeigen. Stimmen der Name mit dem Studiencode und der AHV-Nummer über ein und hat der Teilnehmer die richtige Adresse angegeben?',
    done: false,
  },
  {
    id: CheckOffId.EinwilligungserklaerungErhalten,
    type: CheckOffType.SimpleCheck,
    title: 'Einwilligungserklärung erhalten?',
    description: '',
    done: false,
  },
  {
    id: CheckOffId.Wohnsitznachweis,
    type: CheckOffType.SimpleCheck,
    title: 'Wohnsitzbestätigung erhalten?',
    description: '',
    done: false,
  },
  {
    id: CheckOffId.EntryQuestionnaire,
    type: CheckOffType.Questionnaire,
    title: 'Antworten des Eintrittsfragebogens prüfen',
    description:
      'Bitte gehe die Antworten auf den Fragebogen im Gespräch durch und prüfe, ob die Daten übereinstimmen',
    done: false,
  },
  {
    id: CheckOffId.CannabisConsumptionQuestionnaire,
    type: CheckOffType.Questionnaire,
    title: 'Cannabis Konsum mit Hilfe der Fragebogen Antworten prüfen',
    description:
      'Bitte gehe die Antworten auf den Fragebogen im Gespräch durch und prüfe, ob die Daten übereinstimmen',
    done: false,
  },
  {
    id: CheckOffId.KontraIndikationen,
    type: CheckOffType.SimpleCheck,
    title: 'Kontra Indikationen prüfen',
    description:
      'Prüfe alle möglichen Kontra Indikationen, die bei dem Teilnehmer auftreten können.',
    done: false,
  },
];

export class ViewParticipantOnboardingStore {
  @observable public checkpoints: Checkpoint[] =
    structuredClone(defaultCheckpoints);

  constructor() {
    makeObservable(this);
  }

  @action public getCheckpointState(id: CheckOffId): boolean {
    return (
      this.checkpoints.find((checkpoint) => checkpoint.id === id)?.done || false
    );
  }

  @action public setCurrentCheckpointState(id: CheckOffId, done: boolean) {
    const checkpoint = this.checkpoints.find(
      (checkpoint) => checkpoint.id === id,
    );
    if (checkpoint) {
      checkpoint.done = done;
    }
  }
}

const { provider, useStore } = setupMobX<ViewParticipantOnboardingStore>();
export const useViewParticipantOnboardingStore = useStore;
export const ViewParticipantOnboardingProvider = provider;
