import { z } from 'zod';

export const givePodcastFeedbackReqDto = z.object({
  feedback1: z.string().max(2048),
  feedback2: z.string().max(2048),
  feedback3: z.string().max(2048),
});
export type GivePodcastFeedbackReqDto = z.infer<
  typeof givePodcastFeedbackReqDto
>;
