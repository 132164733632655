import { HomeScreen } from './HomeScreen';

import { MissionStore, MissionStoreProvider } from '../../../../store';

export const HomeScreenWrapper = () => {
  return (
    <MissionStoreProvider store={new MissionStore()}>
      <HomeScreen />
    </MissionStoreProvider>
  );
};
