import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { CohortType } from '@prisma/client';

export const cohortTypeZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(CohortType)))
    .transform(
      (value) => CohortType[value as keyof typeof CohortType] as CohortType,
    );

export const cohortTypeTranslation = {
  NONE: '-',
  ACCESS: 'HR',
  LIMITED_ACCESS: 'HR Plus',
  CONTROL_GROUP: 'KG',
};
