/* eslint-disable @typescript-eslint/no-explicit-any */
import { PieChart, Pie, Cell, Label } from 'recharts';
import { InnerLabel, LeftLabel, RightLabel } from './Labels';

const COLORS = ['#B1B1B1', '#202020'];

export const ConsumptionChart = ({
  availableAmount,
  usedAmount,
  thcLimit,
  unit,
}: {
  availableAmount: number;
  usedAmount: number;
  thcLimit: number;
  unit: string;
}) => {
  const data = [
    { name: 'Group A', value: availableAmount },
    { name: 'Group B', value: usedAmount },
  ];

  return (
    <PieChart
      width={270}
      height={200}
      margin={{ top: 10, right: 0, bottom: -20, left: 0 }}
    >
      <Pie
        data={data}
        startAngle={-20}
        endAngle={200}
        innerRadius={82}
        outerRadius={95}
        fill="#8884d8"
        paddingAngle={-10}
        cornerRadius={50}
        stroke="none"
        dataKey="value"
        animationDuration={0}
      >
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}

        <Label
          position="center"
          content={<InnerLabel availableAmount={availableAmount} unit={unit} />}
        />
        <Label position="center" content={<LeftLabel />} />
        <Label position="center" content={<RightLabel thcLimit={thcLimit} />} />
      </Pie>
    </PieChart>
  );
};
