import { Accordion, Flex, Space, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { Checkpoint } from '../../store/ViewParticipantOnboardingStore';

export const CheckOffItem = ({
  checkpoint,
  children,
  title,
}: {
  checkpoint: Checkpoint;
} & {
  title: () => JSX.Element;
} & PropsWithChildren) => {
  const { id, description } = checkpoint;
  switch (checkpoint.type) {
    default: {
      return (
        <Accordion.Item key={id} value={id}>
          {title()}
          <Accordion.Panel key={'accordion-panel'}>
            <Flex direction="column" align="flex-start" m="0px 35px">
              <Text ta="left">{description}</Text>
              <Space h="sm" />
              {children}
              <Space h="sm" />
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      );
    }
  }
};
