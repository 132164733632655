import { useTranslation } from 'react-i18next';
import { Box, Divider, Flex, Skeleton, Space, Text } from '@mantine/core';
import { ConsumptionBarChart } from './ConsumptionBarChart';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { MyIcon } from '../../../../../../component';
import { useConsumptionStore } from '../../stores';

export const ConsumptionStatistics = observer(() => {
  const { t } = useTranslation('consumption-dialog');
  const { i18n } = useTranslation();
  const store = useConsumptionStore();
  const statistics = store.statistics;
  const isLoading = store.loadingStatistics;
  const averageOfCurrent6Months = averageOfCurrent6MonthsCalc();
  const difference = getDifference();
  const period = getPeriod();
  const currentLimit = store.currentLimit;

  useEffect(() => {
    store.getStatistics();
    store.getCurrentLimit();
  }, [store]);

  function averageOfCurrent6MonthsCalc() {
    if (!statistics) return;

    const average =
      statistics?.thcPerMonth.reduce(
        (acc, curr) => acc + curr.thcConsumptionInGrams,
        0,
      ) / statistics?.thcPerMonth.length;
    return Number(average.toPrecision(2));
  }

  function getDifference() {
    if (!statistics || !averageOfCurrent6Months) return 0;
    const difference =
      averageOfCurrent6Months -
      statistics?.averageThcConsumptionPreviousTimeframe;
    return Number(difference.toPrecision(2));
  }

  function getPeriod() {
    if (!statistics) return;
    const firstDate = statistics?.thcPerMonth[0];
    const lastDate =
      statistics?.thcPerMonth[statistics?.thcPerMonth.length - 1];
    return `${formatDate(firstDate.month, firstDate.year)} - ${formatDate(lastDate.month, lastDate.year)}`;
  }

  function formatDate(month: number, year: number) {
    return DateTime.fromObject(
      { year, month },
      { locale: i18n.language },
    ).toFormat('MMM yy');
  }

  return (
    <Flex direction="column" gap="10px">
      <Box
        style={{
          width: '100%',
          background: '#F7F6F1',
          border: '1px solid #D0CDB1',
          borderRadius: 5,
          padding: '20px',
        }}
      >
        <Text>{t('ConsumptionStatistics.Chart.Title')}</Text>
        <Flex gap="10px" align="center">
          <Text fw="600" size="16px" lh="24px" miw="80px">
            ø {averageOfCurrent6Months}g THC
          </Text>
          <Flex gap="5px">
            <MyIcon
              name={
                (difference ?? 0) > 0 ? 'arrow_circle_up' : 'arrow_circle_down'
              }
              style={{
                fill: (difference ?? 0) > 0 ? '#EB0037' : '#3D7F53',
                height: '24px',
              }}
            />
            <Text
              size="12px"
              lh="24px"
              lts="-0.4"
              c={(difference ?? 0) > 0 ? '#EB0037' : '#3D7F53'}
            >
              {t('ConsumptionStatistics.Chart.XThenBefore', {
                difference: Math.abs(difference),
                direction:
                  (difference ?? 0) > 0
                    ? t('ConsumptionStatistics.Chart.More')
                    : t('ConsumptionStatistics.Chart.Less'),
              })}
            </Text>
          </Flex>
        </Flex>
        <Space h="20px" />
        <Divider />
        <Skeleton visible={isLoading}>
          {statistics?.thcPerMonth && (
            <ConsumptionBarChart
              data={statistics.thcPerMonth}
              yourLimit={currentLimit}
            />
          )}
        </Skeleton>
        <Divider py="5px" />
        <Text ta="center" c="#575757">
          {period}
        </Text>
        <Space h="20px" />
        <Text size="14px" lh="21px">
          {t('ConsumptionStatistics.Chart.LimitDescription', {
            limit: currentLimit,
          })}
        </Text>
      </Box>
      <Text>
        {t('ConsumptionStatistics.AverageConsumption')}
        <Text fw="600" span>
          {t('ConsumptionStatistics.AverageConsumptionBold', {
            average: averageOfCurrent6Months,
          })}
        </Text>
        {t('ConsumptionStatistics.AverageConsumptionRest')}
      </Text>
      <Text size="16px" lh="24px">
        {t('ConsumptionStatistics.MaxLimitDescription')}
      </Text>
      <Text size="12px" lh="18px" c="#4A4A48">
        {t('ConsumptionStatistics.MaxLimitDescription2')}
      </Text>
    </Flex>
  );
});
