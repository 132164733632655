import { z } from 'zod';
import { appointmentTypeZodEnum } from '../../enum';
import { userIdDto } from '../user';

export const appointmentResDto = z.object({
  id: z.string(),
  organizerId: z.string(),
  userId: userIdDto,
  teamId: z.number(),
  eventTypeId: z.number(),
  type: appointmentTypeZodEnum(),
  location: z.string(),
  cancellationReason: z.string().nullable(),
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type AppointmentResDto = z.infer<typeof appointmentResDto>;
