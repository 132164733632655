import { observer } from 'mobx-react';
import { useDisclosure } from '@mantine/hooks';
import { BaseModal } from '../../../../../component/userStatus/dialogs';
import { usePodcastFeedbackStore } from '../../../../../store/podcast';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Text,
  Flex,
  Stepper,
  Space,
  Loader,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { SimpleProgressModalHeader } from '../../../../../component';
import classes from './FeedbackModal.module.css';
import stepperClasses from '../../../../../component/stepper/Stepper.module.css';

type Selection = {
  label: string;
  value: string;
};

export const FeedbackModal = observer(
  ({ podcastId }: { podcastId: number }) => {
    const podcastFeedbackStore = usePodcastFeedbackStore();
    const { t } = useTranslation('podcast');
    const [opened, { open, close }] = useDisclosure(false);
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(false);
    const [feedbackStepValue, setFeedbackStepValue] = useState<
      null | (typeof selectionOptions)[0]
    >(null);
    const [feedback, setFeedback] = useState<{
      feedback1: null | Selection;
      feedback2: null | Selection;
      feedback3: null | Selection;
    }>({
      feedback1: null,
      feedback2: null,
      feedback3: null,
    });
    const totalSteps = 3;

    function makeStep(step: 1 | -1) {
      const nextStep = active + step;

      if (nextStep >= 0 && nextStep <= totalSteps) {
        setActive(nextStep);
        if (nextStep === 0) {
          setFeedbackStepValue(
            selectionOptions.find(
              (option) => option.value === feedback['feedback1']?.value,
            ) ?? null,
          );
        } else if (nextStep === 1) {
          setFeedbackStepValue(
            selectionOptions.find(
              (option) => option.value === feedback['feedback2']?.value,
            ) ?? null,
          );
        } else {
          setFeedbackStepValue(
            selectionOptions.find(
              (option) => option.value === feedback['feedback3']?.value,
            ) ?? null,
          );
        }
      }
    }

    const sendFeedback = () => {
      setLoading(true);
      podcastFeedbackStore.giveFeedback(
        podcastId,
        {
          feedback1: feedback.feedback1?.label
            ? `${t(`FeedbackModal.StepHeader.feedback1`)}: ${feedback.feedback1?.label}`
            : '',
          feedback2: feedback.feedback2?.label
            ? `${t(`FeedbackModal.StepHeader.feedback2`)}: ${feedback.feedback2?.label}`
            : '',
          feedback3: feedback.feedback3?.label
            ? `${t(`FeedbackModal.StepHeader.feedback1`)}: ${feedback.feedback3?.label}`
            : '',
        },
        () => setLoading(false),
      );
    };

    const selectionOptions: Selection[] = [
      {
        value: '1',
        label: t('FeedbackModal.Selection.1'),
      },
      {
        value: '2',
        label: t('FeedbackModal.Selection.2'),
      },
      {
        value: '3',
        label: t('FeedbackModal.Selection.3'),
      },
      {
        value: '4',
        label: t('FeedbackModal.Selection.4'),
      },
    ];

    const selections = (key: 'feedback1' | 'feedback2' | 'feedback3') => {
      return (
        <Flex direction={'column'} gap={'0.5em'}>
          {selectionOptions.map((selection) => {
            return (
              <Checkbox.Card
                key={selection.value}
                value={selection.value}
                checked={feedbackStepValue?.value === selection.value}
                onClick={() => {
                  if (feedbackStepValue?.value === selection?.value) {
                    setFeedbackStepValue(null);
                    setFeedback({
                      ...feedback,
                      [key]: null,
                    });
                    return;
                  } else {
                    setFeedbackStepValue(selection);
                    setFeedback({
                      ...feedback,
                      [key]: selection,
                    });
                  }
                }}
                radius={'md'}
                className={classes.root}
              >
                <Checkbox.Group>
                  <Flex
                    direction={'row'}
                    justify={'space-between'}
                    align={'center'}
                  >
                    <Text>{selection.label}</Text>
                    <Checkbox.Indicator />
                  </Flex>
                </Checkbox.Group>
              </Checkbox.Card>
            );
          })}
        </Flex>
      );
    };

    const stepContent = (step: typeof totalSteps | number) => {
      if (step === totalSteps) {
        // last step
        return (
          <Flex gap={'1em'} direction={'column'}>
            <Text
              style={{ fontWeight: '400', lineHeight: '24px' }}
              size={'16px'}
            >
              {t('FeedbackModal.Subtitle')}
            </Text>
            <Text
              style={{ fontWeight: '600', lineHeight: '24px' }}
              size={'21px'}
            >
              {t(`FeedbackModal.StepHeader.feedback3`)}
            </Text>
            {selections('feedback3')}
            <Space />
            <Button onClick={sendFeedback} disabled={loading}>
              {loading ? <Loader /> : t('FeedbackModal.Finished')}
            </Button>
          </Flex>
        );
      } else if (step === 1) {
        return (
          <Flex gap={'1em'} direction={'column'}>
            <Text
              style={{ fontWeight: '400', lineHeight: '24px' }}
              size={'16px'}
            >
              {t('FeedbackModal.Subtitle')}
            </Text>
            <Text
              style={{ fontWeight: '600', lineHeight: '24px' }}
              size={'21px'}
            >
              {t(`FeedbackModal.StepHeader.feedback1`)}
            </Text>
            {selections('feedback1')}
            <Space />
            <Button onClick={() => makeStep(1)}>
              {t('FeedbackModal.Next')}
            </Button>
          </Flex>
        );
      } else if (step === 2) {
        return (
          <Flex gap={'1em'} direction={'column'}>
            <Text
              style={{ fontWeight: '400', lineHeight: '24px' }}
              size={'16px'}
            >
              {t('FeedbackModal.Subtitle')}
            </Text>
            <Text
              style={{ fontWeight: '600', lineHeight: '24px' }}
              size={'21px'}
            >
              {t(`FeedbackModal.StepHeader.feedback2`)}
            </Text>
            {selections('feedback2')}
            <Space />
            <Button onClick={() => makeStep(1)}>
              {t('FeedbackModal.Next')}
            </Button>
          </Flex>
        );
      }
    };

    const stepper = (
      <Stepper
        active={active}
        onStepClick={() => makeStep(1)}
        className={stepperClasses.hiddenProgress}
      >
        <Stepper.Step>{stepContent(1)}</Stepper.Step>
        <Stepper.Step>{stepContent(2)}</Stepper.Step>
        <Stepper.Step>{stepContent(3)}</Stepper.Step>
      </Stepper>
    );

    useEffect(() => {
      if (podcastFeedbackStore.modalOpen) {
        setActive(0);
        setFeedbackStepValue(null);
        setFeedback({
          feedback1: null,
          feedback2: null,
          feedback3: null,
        });
        setLoading(false);
        open();
      } else {
        close();
      }
    }, [podcastFeedbackStore.modalOpen, open, close]);

    return (
      <BaseModal
        opened={opened}
        onClose={() => {
          podcastFeedbackStore.modalOpen = false;
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={true}
        customHeader={
          <SimpleProgressModalHeader
            active={active}
            max={totalSteps}
            onBackClick={() => makeStep(-1)}
          />
        }
        children={stepper}
      />
    );
  },
);
