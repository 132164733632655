import { z } from 'zod';

export const createParticipantReqDto = z.object({
  hash: z.string(),
  idType: z.string(),
  idNumber: z.string(),
  placeOfBirthOrResidence: z.string(),
  dispensaries: z.array(z.string()),
});
export type CreateParticipantReqDto = z.infer<typeof createParticipantReqDto>;
