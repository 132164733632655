import { Radio, Group } from '@mantine/core';
import { observer } from 'mobx-react';
import { PauseModalType, useUserStateStore } from '../../../store';
import {
  CheckOffId,
  useViewParticipantOnboardingStore,
} from '../../../store/ViewParticipantOnboardingStore';

enum ContraIndicationRadioGroupValue {
  schizophrenic = 'schizophrenic',
  heartDisease = 'heartDisease',
  lungDisease = 'lungDisease',
  noContraIndications = 'noContraIndications',
}

export const ContraIndicationRadioGroup = observer(() => {
  const userStateStore = useUserStateStore();
  const participantOnboardingStore = useViewParticipantOnboardingStore();

  const handleOptionChange = (value: string) => {
    switch (value) {
      case ContraIndicationRadioGroupValue.schizophrenic:
        userStateStore.setContraIndicationModalOpen(true);
        break;
      case ContraIndicationRadioGroupValue.heartDisease:
        userStateStore.setPauseModalOpen(true, PauseModalType.KontraIndikation);
        break;
      case ContraIndicationRadioGroupValue.lungDisease:
        userStateStore.setContraIndicationModalOpen(true);
        break;
      case ContraIndicationRadioGroupValue.noContraIndications:
        participantOnboardingStore.setCurrentCheckpointState(
          CheckOffId.KontraIndikationen,
          true,
        );
        break;
      default:
        break;
    }
  };

  console.debug(userStateStore.contraIndication);

  return (
    <Radio.Group onChange={handleOptionChange} maw="700px">
      <Group>
        <Radio
          value={ContraIndicationRadioGroupValue.schizophrenic}
          label="Leiden Sie an einer Erkrankung aus dem schizophrenen Formenkreis?"
          description="Beispiele: Schizophrenie, Paranoide Schizophrenie, Hebephrene Schizophrenie, Katatone Schizophrenie, Undifferenzierte Schizophrenie, Postschizophrene Depression, Schizophrenes Residuum, Schizophrenia Simplex"
        />
        <Radio
          value={ContraIndicationRadioGroupValue.heartDisease}
          label="Leiden Sie an einer schweren Herz-Kreislauferkrankung?"
          description="Beispiele: Herzrhythmusstörungen, Ungenügend eingestellter Bluthochdruck, Schwere Herzinsuffizienz"
        />
        <Radio
          value={ContraIndicationRadioGroupValue.lungDisease}
          label="Leiden Sie an einer schweren Lungenkrankheit?"
          description="COPD (chronisch obstruktive Lungenerkrankung), Asthma, Lungenfibrose, Lungenhochdruck, Lungenemphysem, Mukoviszidose, Sarkoidose, Lungenentzündung, Tuberkulose, Lungenkrebs"
        />
        <Radio
          value={ContraIndicationRadioGroupValue.noContraIndications}
          label="Keine Kontraindikationen"
        />
      </Group>
    </Radio.Group>
  );
});
