import { volumeRegex, thcRegex, cbdRegex } from './regexStrings';

export function getTitleAndDescriptionFromProduct(productTitle: string) {
  const volumeMatch = productTitle.match(volumeRegex);
  const thcMatch = productTitle.match(thcRegex);
  const cbdMatch = productTitle.match(cbdRegex);

  let thcString = '';
  let cbdString = '';
  let mlString = '';
  let modifiedTitle = productTitle;

  if (volumeMatch) {
    mlString = volumeMatch[0];
    modifiedTitle = modifiedTitle.replace(volumeRegex, '').trim();
  }

  if (thcMatch) {
    const thcOperator = thcMatch[1];
    const thcValue = thcMatch[2];

    thcString = `THC ${thcOperator}${thcValue}%`;
    modifiedTitle = modifiedTitle.replace(thcRegex, '').trim();
  }

  if (cbdMatch) {
    const cbdOperator = cbdMatch[1];
    const cbdValue = cbdMatch[2];

    cbdString = `CBD ${cbdOperator}${cbdValue}%`;
    modifiedTitle = modifiedTitle.replace(cbdRegex, '').trim();
  }

  return {
    title: modifiedTitle,
    description: `${mlString} ${thcString} ${cbdString}`.trim(),
  };
}
