import { Button, Text } from '@mantine/core';
import { GoogleAnalyticsEvent, useGoogleAnalyticsStore } from '../../../store';
import { useTranslation } from 'react-i18next';

export const BirdyClub = () => {
  const googleAnalyticsStore = useGoogleAnalyticsStore();

  const { t } = useTranslation('quick-enroll');
  return (
    <>
      <Text fw="600" size="16px" lh="24px">
        {t('Birdy.YouAreInvited')}
      </Text>
      <Text fw="600" size="16px" lh="24px">
        {t('Birdy.RegisterHere')}
      </Text>
      <Button
        bg="#202020"
        onClick={() => {
          googleAnalyticsStore.track(GoogleAnalyticsEvent.Enroll_Club);
          window.location.href = 'https://biirdy.club/';
        }}
      >
        {t('Birdy.OpenBirdy')}
      </Button>
    </>
  );
};
