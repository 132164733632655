import { z } from 'zod';
import { cohortTypeZodEnum } from '../../enum';

export const myCohortResDto = z.object({
  cohort: z
    .object({
      id: z.string(),
      startDate: z.coerce.date(),
      onboardingEndDate: z.coerce.date(),
      cohortType: cohortTypeZodEnum(),
    })
    .nullable(),
});

export type MyCohortResDto = z.infer<typeof myCohortResDto>;
