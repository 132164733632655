import { CheckListId } from '@web/common';
import { useLayoutEffect } from 'react';

const useScrollToCheckItem = (openItem: string | null) => {
  useLayoutEffect(() => {
    if (openItem && openItem !== CheckListId.EmailVerified) {
      const targetDiv = document.getElementById(openItem);

      if (targetDiv) {
        new Promise((resolve) => setTimeout(resolve, 200))
          .then(() => {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
          })
          .catch(() => {});
      }
    }
  }, [openItem]);
};

export default useScrollToCheckItem;
