import { FeatureFlag } from '@web/common';
import { FeatureAccess } from '../../../../../util/ff/FeatureAccess';
import { UpdateTHCLimitDialog } from './UpdateTHCLimitDialog';
import { useUserMeStore } from '../../../../../store';
import { CohortType } from '@prisma/client';
import { observer } from 'mobx-react';

export const UpdateTHCLimitWrapper = observer(() => {
  const myInfoStore = useUserMeStore();
  const cohortType = myInfoStore.myInfo?.Participant?.cohortType;
  const isAllowedToAccess = cohortType === CohortType.LIMITED_ACCESS;

  if (process.env['NX_ENVIRONMENT'] !== 'Production')
    console.log('isAllowedToAccess', isAllowedToAccess);

  if (!isAllowedToAccess) return null;

  return (
    <FeatureAccess flag={FeatureFlag.Update_THC_Limit}>
      <UpdateTHCLimitDialog />
    </FeatureAccess>
  );
});
