import { useEffect, useMemo, useRef } from 'react';
import { useCart } from '@shopify/hydrogen-react';
import { useKeycloakStore, useShopStore } from '../../../../../store';
import { useAvailableThcAmount } from '../../../../../hooks';
import { CartLine } from '@shopify/hydrogen-react/storefront-api-types';
import {
  ProductType,
  canAcceptOrder,
  getTotals,
  maxTHCAmountMixed,
} from '../utils/calculateTHCLimit';

export const useCheckoutData = (lines: CartLine[]) => {
  const { buyerIdentityUpdate } = useCart();
  const kc = useKeycloakStore();
  const userMail = kc.tokenParsed.email;
  const store = useShopStore();
  const { availableAmount, currentThcUnit } = useAvailableThcAmount();
  const buyerIdentityUpdateRef = useRef(buyerIdentityUpdate);

  useEffect(() => {
    store.loadProducts();
    if (lines.length > 0)
      buyerIdentityUpdateRef.current({
        email: userMail,
      });
  }, [lines.length, store, userMail]);

  const extendedLineArray = useMemo(() => {
    return lines.map((line) => {
      const product = store.products.find(
        (product) => product.id === line.merchandise.product.id,
      );

      return {
        ...line,
        thcAmount: (product?.thcAmount ?? 0) * line.quantity,
        weight: product?.weight.value ?? 0,
      };
    });
  }, [lines, store.products]);

  const { totalCost, totalThc, totalWeight, productTypes } = useMemo(() => {
    return getTotals(extendedLineArray);
  }, [extendedLineArray]);

  const canCheckout = useMemo(() => {
    return canAcceptOrder(
      extendedLineArray.length,
      availableAmount,
      totalThc,
      totalWeight,
      productTypes,
    );
  }, [extendedLineArray, availableAmount, totalThc, totalWeight, productTypes]);

  const dynamicAvailableAmount = useMemo(() => {
    // If the user has mixed products in the cart, the available amount is 2g
    if (productTypes.includes(ProductType.MIXED)) return maxTHCAmountMixed;
    return availableAmount;
  }, [availableAmount, productTypes]);

  return {
    totalCost,
    totalThc,
    totalWeight,
    productTypes,
    canCheckout,
    availableAmount: dynamicAvailableAmount,
    currentThcUnit,
  };
};
