import { Box, Button, Center, Flex, Image } from '@mantine/core';
import logo from '../../../../assets/scc_text_logo.svg';
import BurgerMenu from './BurgerMenu';
import { useWindowSize } from '../../../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import { getItems } from '../utils';
import { LoginButton, LogoutButton } from '../../../../component';
import { LanguagePicker } from './LanguagePicker';
import { useTranslation } from 'react-i18next';

export const LandingPageHeader = ({
  canLogout = false,
}: {
  canLogout?: boolean;
}) => {
  const isSmallScreen = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleLogoClick() {
    navigate('/');
  }

  return (
    <Center w="100%">
      <Box w="1100px">
        {isSmallScreen ? (
          <Flex bg="#fff" justify="space-between" p="10px 20px 0px 0px">
            <Image
              radius="md"
              h={70}
              w="auto"
              fit="contain"
              src={logo}
              p="5px"
              px={15}
              m="-5px 0px 0px 0px"
              onClick={handleLogoClick}
              style={{ zIndex: 10 }}
            />

            <BurgerMenu
              getItems={getItems}
              username={'Username'}
              canLogout={canLogout}
              showItems
            />
          </Flex>
        ) : (
          <Flex
            bg="#fff"
            justify="space-between"
            align="flex-start"
            p="5px 0px"
            maw="1100px"
          >
            <Flex justify="flex-start" style={{ flex: 1 }}>
              <Image
                radius="md"
                h={70}
                w="auto"
                fit="contain"
                p={5}
                px={15}
                src={logo}
                onClick={handleLogoClick}
                style={{ zIndex: 10 }}
              />
            </Flex>

            <Flex
              justify="flex-end"
              style={{ flex: '1 80%', zIndex: 1 }}
              direction="row"
              gap="15px"
              align="center"
              p="17px 0px 17px 0px"
            >
              {getItems(t)}
              {canLogout ? <LogoutButton /> : <LoginButton />}
              <Link
                children={<Button>{t('Buttons.Register')}</Button>}
                data-cy="register-button"
                to={Routes.EntryQuestionnaire}
              />
              <LanguagePicker />
            </Flex>
          </Flex>
        )}
      </Box>
    </Center>
  );
};
