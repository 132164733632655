import { updateUserDispensary } from '../../../../request/authenticated-requests/dispensary';
import { setupMobX } from '../../../../../../ui/src/util/setupMobX';
import { observable, makeObservable, action, runInAction } from 'mobx';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../notification';

export class ChangeDispensaryStore {
  @observable public selectedDispensary: string | null = null;
  @observable public loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action public setSelectedDispensary = (dispensaryId: string | null) => {
    this.selectedDispensary = dispensaryId;
  };

  @action public updateDispensary = (userId: string, onSubmit: () => void) => {
    if (!this.selectedDispensary) return;

    this.loading = true;
    updateUserDispensary(userId, this.selectedDispensary)
      .then(() => {
        onSubmit();
        showSuccessNotification('Ausgabestelle geändert');
      })
      .catch(() => showErrorNotification())
      .finally(() => runInAction(() => (this.loading = false)));
  };
}

const { provider, useStore } = setupMobX<ChangeDispensaryStore>();
export const useChangeDispensaryStore = useStore;
export const ChangeDispensaryStoreProvider = provider;
