import { Modal, Flex, Text } from '@mantine/core';
import { MyIcon } from '../../icon';
import classes from './Modal.module.css';
import React from 'react';

type Props = {
  opened: boolean;
  onClose: () => void;
  title?: string;
  description?: JSX.Element | string;
  customHeader?: JSX.Element;
  iconName?: string;
  iconColor?: string;
  children?: React.ReactNode | React.ReactNode[];
  buttons?: React.ReactNode[];
  size?: string;
  closeOnEscape?: boolean;
  closeOnClickOutside?: boolean;
  withCloseButton?: boolean;
};

export const BaseModal = ({
  opened,
  onClose,
  title,
  description,
  customHeader,
  iconName,
  iconColor,
  children,
  buttons,
  size,
  closeOnEscape,
  closeOnClickOutside,
  withCloseButton,
}: Props) => {
  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}
      size={size ?? 'lg'}
      classNames={{ close: classes.close }}
      closeOnEscape={closeOnEscape}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          {customHeader ? (
            customHeader
          ) : (
            <Modal.Title>
              <Flex className={classes.header}>
                {iconName && iconColor && (
                  <MyIcon
                    name={iconName}
                    className={classes.icon}
                    style={{ fill: iconColor }}
                  />
                )}
                <Text className={classes.headerTitle}>{title}</Text>
              </Flex>
            </Modal.Title>
          )}
          {withCloseButton && <Modal.CloseButton />}
        </Modal.Header>
        <Modal.Body>
          <Flex className={classes.contentContainer}>
            {description && <Text>{description}</Text>}
            {children}
            {buttons && (
              <Flex className={classes.buttonContainer}>{buttons}</Flex>
            )}
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
