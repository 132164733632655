import { z } from 'zod';

export const shopifyOrderCancelResDto = z.object({
  orderCancel: z.object({
    job: z
      .object({
        id: z.string(),
        done: z.boolean(),
      })
      .nullable(),
    orderCancelUserErrors: z.array(
      z.object({
        field: z.array(z.string()),
        message: z.string(),
        code: z.string(),
      }),
    ),
  }),
});
export type ShopifyCancelOrderResDto = z.infer<typeof shopifyOrderCancelResDto>;
