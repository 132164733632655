import { setupMobX } from '../util/setupMobX';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getCohorts } from '../request/authenticated-requests/cohort';
import { CohortResDto } from '@web/common';
import { showErrorNotification } from '../component/notification';
import { DateTime } from 'luxon';

export class CohortStore {
  @observable public cohorts: CohortResDto[] = [];
  @observable public cohortsForOnboarding: CohortResDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action public loadCohorts = () => {
    getCohorts()
      .then((cohorts) => {
        runInAction(() => {
          this.cohorts = cohorts;
          this.cohortsForOnboarding = cohorts.filter(
            (cohort) =>
              DateTime.fromJSDate(cohort.startDate).diffNow().milliseconds > 0,
          );
        });
      })
      .catch(() => showErrorNotification());
  };
}

const { provider, useStore } = setupMobX<CohortStore>();
export const useCohortStore = useStore;
export const CohortStoreProvider = provider;
