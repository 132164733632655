import { Divider, Flex, Skeleton } from '@mantine/core';
import classes from '../../../../sharedStyles/InfoCard.module.css';
import { Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { OrderDetailContent } from '../../participant/shop/components/OrderDetail/OrderDetailContent';
import { useShopStore } from '../../../../store';
import { BaseLayout } from '../../navigation';
import { OrderDetailHeader } from '../../participant/shop/components/OrderDetail/OrderDetailHeader';
import { OrderAdminButtons } from './OrderAdminButtons';
import { OrderOverview } from '../../participant/shop/components/OrderDetail/OrderOverview';

export const OrderDetailsWrapper = observer(() => {
  const { orderId } = useParams();
  const { t } = useTranslation('shop');
  const shopStore = useShopStore();
  const orderDetails = shopStore.orderDetails;

  useEffect(() => {
    if (orderId) shopStore.loadOrderDetails(orderId);
    return () => shopStore.clearOrderDetails();
  }, [orderId, shopStore]);

  return (
    <BaseLayout>
      <Flex
        direction="column"
        maw={1100}
        gap={30}
        bg="#fff"
        p={40}
        className={classes.borderRadius}
      >
        <Text fw="500" size="28px" lh="24px">
          {t('Orderdetails')}
        </Text>
        {orderDetails ? (
          <>
            <OrderDetailHeader order={orderDetails} />
            <OrderDetailContent orderDetails={orderDetails} />
            <Divider c="#F1F1F1" />
            <OrderOverview order={orderDetails} />
            <Divider c="#F1F1F1" />
            <OrderAdminButtons
              orderId={orderDetails.id}
              orderName={orderDetails.name}
              paidStatus={orderDetails.paidStatus}
            />
          </>
        ) : (
          <>
            <Skeleton height={30} />
            <Skeleton height={200} />
            <Skeleton height={110} />
          </>
        )}
      </Flex>
    </BaseLayout>
  );
});
