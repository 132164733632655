import { ErrorCode } from '@web/common';

export class ServerError extends Error {
  constructor(
    public readonly errorCode: ErrorCode,
    public readonly message: string = '',
  ) {
    super(message);
  }
}
