import { z } from 'zod';
import { participantStatusZodEnum } from '../../enum';

export const updateParticipantStatusResDto = z.object({
  message: z.string(),
  data: z.object({
    participantID: z.string(),
    status: participantStatusZodEnum(),
    reasons: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
      }),
    ),
  }),
});
export type UpdateParticipantStatusResDto = z.infer<
  typeof updateParticipantStatusResDto
>;
