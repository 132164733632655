import { z } from 'zod';
import {
  consumptionConcentrationZodEnum,
  entryTimeZodEnum,
  consumptionTypeZodEnum,
} from '../../enum';
import { feelingTypeZodEnum } from './diary-feeling.zod-enum';

const common = z.object({
  entryDate: z.coerce.date(),
  entryTime: entryTimeZodEnum(),
  feelings: z.array(feelingTypeZodEnum()),
  reasons: z.array(z.string().max(256)),
  notes: z.string().max(2048).nullable(),
});

const didNotConsume = z
  .object({
    didConsume: z.literal(false),
  })
  .merge(common)
  .strip();
const didConsume = z
  .object({
    didConsume: z.literal(true),

    consumptionType: consumptionTypeZodEnum(),
    consumptionAmount: z.number(),
    consumptionConcentration: consumptionConcentrationZodEnum(),
  })
  .merge(common)
  .strip();

export const createDiaryEntryReqDto = z.discriminatedUnion('didConsume', [
  didConsume,
  didNotConsume,
]);

export type CreateDiaryEntryReqDto = z.infer<typeof createDiaryEntryReqDto>;
