import { Accordion, Text } from '@mantine/core';
import { useWaitingStateStore } from '../../../../store';
import { Loading } from '../../../../component';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LoyalityPointInfo } from '../home-screen/components';
import { useMemo } from 'react';
import { StudyDashboardDetailPage } from '../../../../component/page';
import { CohortType } from '@prisma/client';
import { useMyCohortStore } from '../../../../store/MyCohortStore';

export const PointsScreen = observer(() => {
  const { t } = useTranslation('points');
  const waitingStateStore = useWaitingStateStore();
  const cohortStore = useMyCohortStore();
  const isLoading = waitingStateStore.loadingAccessState;
  const isHrPlus = cohortStore.cohortType === CohortType.LIMITED_ACCESS;

  const content = useMemo(
    () => [
      {
        key: 'EarnPoints',
        title: t('EarnPointsTitle'),
        description: [
          ...(t('EarnPointsDescriptions', {
            returnObjects: true,
          }) as string[]),
          ...(isHrPlus
            ? (t('EarnExtraPointsDescriptions', {
                returnObjects: true,
              }) as string[])
            : []),
        ],
      },
      {
        key: 'UsePoints',
        title: t('UsePointsTitle'),
        description: t('UsePointsDescriptions', {
          returnObjects: true,
        }) as string[],
      },
    ],
    [isHrPlus, t],
  );
  const keys = useMemo(() => content.map((item) => item.key), [content]);
  const items = useMemo(
    () =>
      content.map((item) => (
        <Accordion.Item key={item.key} value={item.key}>
          <Accordion.Control>
            <Text size="19px" lh="24px" fw="600" key={item.title}>
              {item.title}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {item.description.map((description: string) => (
              <Text size="16px" lh="24px" py="8px" key={description}>
                {description}
              </Text>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      )),
    [content],
  );

  if (isLoading) return <Loading />;

  return (
    <StudyDashboardDetailPage breadcrumbText={t('Breadcrumb')}>
      <LoyalityPointInfo />
      <Accordion multiple defaultValue={keys} w="100%">
        {items}
      </Accordion>
    </StudyDashboardDetailPage>
  );
});
