import { Flex, Space, Stepper, Text } from '@mantine/core';
import { useWindowSize } from '../../../../hooks';
import classes from './Stepper.module.css';
import { StepItemDetail, StepItem } from './StepItem';
import { stepsData, StepDataType } from './stepsData';
import { useWaitingStateStore } from '../../../../store';
import { observer } from 'mobx-react';
import { MyIcon } from '../../../../component';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { CohortType } from '@prisma/client';

export const TaskStepper = observer(
  ({ currentAction }: { currentAction: JSX.Element }) => {
    const waitingStateStore = useWaitingStateStore();
    const { currentStep, myCohortStartDate, myCohortType } = waitingStateStore;
    const isSmallScreen = useWindowSize(900);
    const { t } = useTranslation('waiting-screen');

    const elements = (cohortStartDate: DateTime, cohortType: CohortType) =>
      stepsData(cohortStartDate, cohortType, t).map((step: StepDataType) => (
        <Stepper.Step
          key={step.id}
          label={isSmallScreen ? step.title : undefined}
          description={isSmallScreen ? step.description : undefined}
        >
          <Flex gap="10px" wrap="wrap">
            <img
              src={step.img}
              alt="register"
              style={{
                margin: '0px 10px',
                minWidth: '170px',
                maxWidth: '250px',
              }}
            />
            {StepItemDetail(
              step.title_long,
              step.description,
              currentStep,
              step.description_long,
              currentAction,
            )}
          </Flex>

          <Text fw="700" m="40px 0px 20px 0px" size="21px" lh="24px">
            {t('Overview')}
          </Text>
        </Stepper.Step>
      ));

    return (
      <div>
        <Stepper
          active={currentStep}
          color="#3D7F53"
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          my={isSmallScreen ? '20px' : '0px'}
          classNames={classes}
          styles={{
            steps: {
              width: isSmallScreen ? 'auto' : '81%',
            },
          }}
          completedIcon={<MyIcon name="check" style={{ fill: '#fff' }} />}
        >
          {myCohortStartDate &&
            myCohortType &&
            elements(myCohortStartDate, myCohortType)}
        </Stepper>

        {!isSmallScreen && (
          <>
            <Space h="lg" />
            <Flex gap="10px" justify="space-between">
              {myCohortStartDate &&
                myCohortType &&
                stepsData(myCohortStartDate, myCohortType, t).map(
                  (step: StepDataType) => StepItem(step),
                )}
            </Flex>
            <Space h="lg" />
          </>
        )}
      </div>
    );
  },
);
