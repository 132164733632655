import { Indicator } from '@mantine/core';
import { MyIcon } from '../../../component';
import { observer } from 'mobx-react';
import { useCart } from '@shopify/hydrogen-react';
import classes from '../../../sharedStyles/Layout.module.css';

export const ShoppingCartIcon = observer(
  ({ isMobile = false }: { isMobile?: boolean }) => {
    const { lines } = useCart();
    const isDisabled = !lines || lines?.length === 0;

    return (
      <Indicator
        size={16}
        offset={10}
        inline
        label={lines?.length}
        color="#EB0037"
        disabled={isDisabled}
      >
        <MyIcon
          name={'shopping_cart'}
          className={classes.icon}
          style={{
            fill: isMobile ? '#fff' : isDisabled ? '#bbb' : '#000',
            marginTop: 6,
            height: '25px',
          }}
        />
      </Indicator>
    );
  },
);
