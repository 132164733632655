import { authenticatedAPI } from '../AuthenticatedAPI';
import {
  CreateDiaryEntryReqDto,
  createDiaryEntryResDto,
  getDiaryEntriesResDto,
} from '@web/common';

export const getDiaryEntries = async (from: Date, until: Date) => {
  return authenticatedAPI.get('api/diary', getDiaryEntriesResDto, {
    from,
    until,
  });
};

export const createDiaryEntry = async (data: CreateDiaryEntryReqDto) => {
  return authenticatedAPI.post('api/diary', data, createDiaryEntryResDto);
};
