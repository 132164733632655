export const defaultFontColor = '#202020';

function scrollToSection(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

export function getItems(
  t: (key: string) => string,
  fontColor = defaultFontColor,
  style?: React.CSSProperties,
  additionalOnClickFunc?: () => void,
): JSX.Element[] {
  style = {
    ...{
      textDecoration: 'none',
      color: fontColor,
      fontSize: '14px',
      lineHeight: '21px',
      padding: '0px 15px',
      fontWeight: 500,
    },
    ...style,
  };
  return [
    { label: t('NavItems.Home'), route: '#intro' },
    { label: t('NavItems.FAQ'), route: '#faq' },
    { label: t('NavItems.About'), route: '#aboutus' },
    { label: t('NavItems.Contact'), route: '#contact' },
  ].map((link) => (
    <a
      key={link.label}
      href={link.route}
      style={style}
      onClick={() => {
        scrollToSection(link.route);
        if (additionalOnClickFunc) additionalOnClickFunc();
      }}
    >
      {link.label}
    </a>
  ));
}
