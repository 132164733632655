import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { StudyCard } from '.';
import {
  useKeycloakStore,
  useParticipantDataStore,
} from '../../../../../../store';
import { Banner } from '../../../../../../component';
import { useTranslation } from 'react-i18next';

export const StudyCardWrapper = observer(() => {
  const kc = useKeycloakStore();
  const participantDataStore = useParticipantDataStore();
  const filledOutBagSurvey = participantDataStore.filledOutBagSurvey;
  const bagSurveyLink = participantDataStore.bagSurveyLink;
  const { t } = useTranslation('profilescreen');

  useEffect(() => {
    const userId = kc.tokenParsed.sub;
    if (userId) participantDataStore.getBagSurveyStatus(userId);
  }, [kc.tokenParsed.sub, participantDataStore]);

  if (!bagSurveyLink)
    return (
      <Banner
        padding="0px"
        margin="0px"
        title={t('StudyCard.BannerTitle')}
        description={t('StudyCard.BannerDescription')}
      />
    );

  return filledOutBagSurvey ? (
    <StudyCard />
  ) : (
    <Banner
      padding="0px"
      margin="0px"
      title={'Du hast einen offenen Fragebogen!'}
      description={
        'Du musst erst den folgenden Fragebogen ausfüllen bevor du deinen Studienausweis erhalten kannst.'
      }
      link={bagSurveyLink}
    />
  );
});
