import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  filterUsers,
  searchUser,
} from '../request/authenticated-requests/user';
import { UsersFilterResDto, UsersSearchResultResDto } from '@web/common';
import { ErrorCode } from '@web/common';
import type { UserFilterQueryReqDto } from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { handleRequestError } from '../request';

export class SearchUserStore {
  @observable public loading: boolean = false;
  @observable public matchingUsers:
    | UsersSearchResultResDto
    | UsersFilterResDto = [];
  @observable public error: string | null = null;
  @observable public queryFilter: UserFilterQueryReqDto = {};

  constructor() {
    makeObservable(this);
  }

  @action public search(userSearchId: string) {
    runInAction(() => {
      this.loading = true;
    });
    searchUser(userSearchId)
      .then((response) => {
        runInAction(() => {
          this.matchingUsers = response;
          this.loading = false;
          this.error = null;
        });
      })
      .catch((error) => {
        handleRequestError(error, [
          ErrorCode.RecordNotFound,
          () => {
            runInAction(() => {
              this.error = 'Bewerber nicht gefunden';
              this.loading = false;
              this.matchingUsers = [];
            });
          },
        ]);
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  @action public filter(query: Partial<UserFilterQueryReqDto>) {
    this.loading = true;
    this.queryFilter = {
      ...this.queryFilter,
      ...query,
    };

    filterUsers(this.queryFilter)
      .then((response) => {
        runInAction(() => {
          this.matchingUsers = response;
          this.loading = false;
          this.error = null;
        });
      })
      .catch((error) => {
        handleRequestError(error, [
          ErrorCode.RecordNotFound,
          () => {
            runInAction(() => {
              this.error = 'Bewerber nicht gefunden';
              this.loading = false;
              this.matchingUsers = [];
            });
          },
        ]);
      });
  }

  @action public reset() {
    this.matchingUsers = [];
    this.error = null;
    this.queryFilter = {};
    this.loading = false;
  }
}

const { provider, useStore } = setupMobX<SearchUserStore>();
export const useSearchUserStore = useStore;
export const SearchUserStoreProvider = provider;
