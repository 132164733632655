import { z } from 'zod';
import { userStatusZodEnum } from '../../enum/user-status.zod-enum';

export const registerResDto = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  email: z.string().email(),
  status: userStatusZodEnum(),
  userSearchID: z.string(),
  createdAt: z.coerce.date(),
});

export type RegisterResDto = z.infer<typeof registerResDto>;
