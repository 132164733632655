import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OrderCard } from './OrderCard';
import { useShopStore } from '../../../../../../store';
import { Flex } from '@mantine/core';

export const OpenOrders = observer(
  ({ showDate = false }: { showDate?: boolean }) => {
    const shopStore = useShopStore();
    const orders = shopStore.orders;

    useEffect(() => {
      shopStore.loadOpenOrders();
    }, [shopStore]);

    return (
      <Flex w="100%" wrap="wrap" justify="space-between" gap="10px">
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} showDate={showDate} />
        ))}
      </Flex>
    );
  },
);
