import { observer } from 'mobx-react';
import { ContestStore, ContestStoreProvider } from '../../../../../../store';
import { LoyalityPointInfoUI } from './LoyalityPointsInfoUI';

export const LoyalityPointInfo = observer(() => {
  return (
    <ContestStoreProvider store={new ContestStore()}>
      <LoyalityPointInfoUI />
    </ContestStoreProvider>
  );
});
