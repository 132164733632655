import { Box, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { BannerContainer } from './BannerContainer';

type BannerProps = {
  maxWidth?: string;
  padding?: string;
  margin?: string;
  link?: string | null;
  title: string;
  description: string;
};

export const Banner = observer(
  ({ maxWidth, padding, margin, title, description, link }: BannerProps) => {
    return (
      <BannerContainer margin={margin} padding={padding} maxWidth={maxWidth}>
        <Box p="20px" style={{ borderRadius: 8, border: 'solid 2px red' }}>
          <Text fw="600">{title}</Text>
          <Text>{description}</Text>
          {link && (
            <a style={{ wordBreak: 'break-word' }} href={link}>
              {link}
            </a>
          )}
        </Box>
      </BannerContainer>
    );
  },
);
