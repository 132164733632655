import { z } from 'zod';
import { userIdDto } from './user-id.dto';
import { userStatusZodEnum } from '../../enum/user-status.zod-enum';

export const markUserAsResDto = z.object({
  id: userIdDto,
  status: userStatusZodEnum(),
  email: z.string().email().nullable(),
  phoneNumber: z.string().nullable(),
});

export type MarkUserAsResDto = z.infer<typeof markUserAsResDto>;
