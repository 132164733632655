import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Group,
  NumberInput,
  Select,
  Space,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import { observer } from 'mobx-react';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { ActionButton } from '../../components';
import { useDiaryDialogStore } from '../DiaryDialogStore';
import {
  ConsumptionConcentration,
  ConsumptionType,
  EntryTime,
} from '@prisma/client';
import { DateTime } from 'luxon';
import { MyIcon } from '../../../../../../../src/component';

const entryTimes = [
  { iconName: 'morning', value: EntryTime.MORNING },
  { iconName: 'sunny', value: EntryTime.NOON },
  { iconName: 'evening', value: EntryTime.EVENING },
  { iconName: 'night', value: EntryTime.NIGHT },
];

const consumptionConcentrations = [
  { iconName: 'speed_easy', value: ConsumptionConcentration.WEAK },
  { iconName: 'speed_medium', value: ConsumptionConcentration.MEDIUM },
  { iconName: 'speed_hard', value: ConsumptionConcentration.HEAVY },
];

export const Consumption = observer(
  ({ makeStep }: { makeStep: () => void }) => {
    const { i18n, t } = useTranslation('diary-dialog');
    const store = useDiaryDialogStore();
    const didConsume = store.didConsume;
    useTranslation();

    // user can only continue if they have filled out all required fields or did not consume
    function canContinue() {
      if (!didConsume) return !!store.entryDate && !!store.entryTime;

      return (
        !!store.entryDate &&
        !!store.entryTime &&
        !!store.consumptionAmount &&
        !!store.consumptionType &&
        !!store.consumptionConcentration
      );
    }

    return (
      <Flex direction="column" gap="20px">
        <Stack>
          <Text fw="600" size="16px" lh="24px">
            {t('GeneralData.DidYouConsume')}
          </Text>
          <Switch
            size="xl"
            onLabel={t('Yes')}
            offLabel={t('No')}
            onChange={(val) => store.setDidConsume(val.target.checked)}
          />
        </Stack>

        <Stack>
          <Text fw="600" size="16px" lh="24px">
            {t(
              'GeneralData.' +
                (didConsume
                  ? 'WhenDidYouConsume'
                  : 'WhenDidYouDecideToNotConsume'),
            )}
          </Text>
          <DatePickerInput
            locale={i18n.language}
            maxDate={DateTime.now().toJSDate()}
            leftSection={<MyIcon name="calendar" style={{ height: '20px' }} />}
            leftSectionPointerEvents="none"
            onChange={(date: DateValue) => {
              if (date) {
                const dateObj = DateTime.fromJSDate(date);

                if (dateObj.isValid) {
                  store.setConsumptionDate(dateObj);
                } else {
                  console.error(
                    'Invalid DateTime:',
                    dateObj.invalidExplanation,
                  );
                }
              }
            }}
            placeholder={t('SelectADate')}
          />
          <Flex gap="20px">
            {entryTimes.map((time) => (
              <ActionButton
                key={time.value}
                iconName={time.iconName}
                text={t('EntryTime.' + time.value)}
                variant={store.entryTime === time.value ? 'filled' : 'greyed'}
                onClick={() => store.setEntryTime(time.value)}
              />
            ))}
          </Flex>
        </Stack>
        {didConsume && (
          <Stack>
            <Text fw="600" size="16px" lh="24px">
              {t('GeneralData.HowMuchDidYouConsume')}
            </Text>
            <Flex align="center" gap="10px">
              <NumberInput
                min={0}
                disabled={!didConsume}
                w="80px"
                value={store.consumptionAmount}
                onChange={(val) => store.setConsumptionAmount(Number(val))}
              />
              <Select
                disabled={!didConsume}
                w="100%"
                onChange={(value) =>
                  store.setConsumptionType(value as ConsumptionType)
                }
                data={Object.values(ConsumptionType).map((item) => ({
                  value: item,
                  label: t('ConsumptionType.' + item),
                }))}
                placeholder={t('SelectPlaceholder')}
              />
            </Flex>
          </Stack>
        )}
        {didConsume && (
          <Stack>
            <Text fw="600" size="16px" lh="24px">
              {t('GeneralData.HowConcentratedWasIt')}
            </Text>
            <Flex gap="20px">
              {consumptionConcentrations.map((concentration) => (
                <ActionButton
                  disabled={!didConsume}
                  key={concentration.value}
                  iconName={concentration.iconName}
                  text={t('ConsumptionConcentration.' + concentration.value)}
                  variant={
                    store.consumptionConcentration === concentration.value
                      ? 'filled'
                      : 'greyed'
                  }
                  onClick={() =>
                    store.setConsumptionConcentration(concentration.value)
                  }
                />
              ))}
            </Flex>
          </Stack>
        )}
        <Space h="20px" />

        <Group justify="center" mt="xl">
          <Button w="100%" onClick={makeStep} disabled={!canContinue()}>
            {t('Next')}
          </Button>
        </Group>
      </Flex>
    );
  },
);
