import { authenticatedAPI } from '../AuthenticatedAPI';
import {
  getBaselineQuestionnaireStateResDto,
  RecordAnonymousQuestionnaireResultReqDto,
  recordAnonymousQuestionnaireResultResDto,
} from '@web/common';

export const getStateOfBaselineSurveyData = async () => {
  return authenticatedAPI.get(
    `/api/questionnaires/baseline`,
    getBaselineQuestionnaireStateResDto,
  );
};

export const recordAnonymousQuestionnaireResult = async (
  body: RecordAnonymousQuestionnaireResultReqDto,
) => {
  return authenticatedAPI.post(
    `api/questionnaires/anonymous-record`,
    body,
    recordAnonymousQuestionnaireResultResDto,
  );
};
