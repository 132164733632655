import { action, observable, runInAction } from 'mobx';
import { setupMobX } from '../../../../util/setupMobX';
import { showErrorNotification } from '../../../../component/notification';
import {
  getAffiliateCode,
  updateAffiliateCode,
} from '../../../../request/authenticated-requests/participant';

export class AffiliateCodeStore {
  @observable public affiliateCode: string = '';
  @observable public isLoading = false;

  @action public getAffiliateCode(userId: string) {
    if (this.isLoading) return;
    this.isLoading = true;

    getAffiliateCode(userId)
      .then((response) => {
        runInAction(() => {
          this.affiliateCode = response.affiliateCode;
          this.isLoading = false;
        });
      })
      .catch(() => {
        showErrorNotification();
        runInAction(() => {
          this.affiliateCode = '';
          this.isLoading = false;
        });
      });
  }

  @action public updateAffiliateCode(
    userId: string,
    affiliateCode: string,
    successAction: () => void,
  ) {
    if (this.isLoading) return;
    this.isLoading = true;

    updateAffiliateCode(userId, affiliateCode)
      .then((response) => {
        runInAction(() => {
          this.affiliateCode = response.affiliateCode;
          this.isLoading = false;
          successAction();
        });
      })
      .catch(() => {
        showErrorNotification();
        runInAction(() => {
          this.affiliateCode = '';
          this.isLoading = false;
        });
      });
  }
}

const { provider, useStore } = setupMobX<AffiliateCodeStore>();
export const useAffiliateCodeStore = useStore;
export const AffiliateCodeProvider = provider;
