import { Button, TextInput } from '@mantine/core';
import { useUserStateStore } from '../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from './BaseModal';

export const DeactivateModal = observer(
  ({
    userId,
    onUserStateUpdated,
  }: {
    userId: string;
    onUserStateUpdated: () => void;
  }) => {
    const stateStore = useUserStateStore();

    return (
      <BaseModal
        opened={stateStore.deactivateModalOpen}
        onClose={() => stateStore.setDeactivateModalOpen(false)}
        title="Ablehnen: Bist du sicher?"
        description="Wenn du den Teilnehmer ablehnst, kann er nicht an der Studie zum
          Cannabis Konsum teilnehmen."
        iconName="cancel"
        iconColor="#E4032E"
        children={
          <TextInput
            key="reason"
            label="Grund"
            placeholder="Ausschlussgrund"
            type="text"
            onChange={(event) =>
              stateStore.setContraIndication(
                'Ausschlussgrund: ' + event.currentTarget?.value,
              )
            }
          />
        }
        buttons={[
          <Button
            key="cancel"
            variant="outline"
            onClick={() => stateStore.setDeactivateModalOpen(false)}
          >
            Abbrechen
          </Button>,
          <Button
            key="submit"
            disabled={stateStore.loading || !stateStore.contraIndication}
            w="fit-content"
            onClick={() => {
              stateStore.deactivateUser(
                userId,
                stateStore.contraIndication || '',
                () => {
                  stateStore.setDeactivateModalOpen(false);
                  onUserStateUpdated();
                },
              );
            }}
          >
            Ja, Teilnehmer ablehnen
          </Button>,
        ]}
      />
    );
  },
);
