import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useDiaryStore } from '../../stores/DiaryStore';
import { BaseModal } from '../../../../../../component/userStatus/dialogs';
import { Button, Flex, Grid, Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { Feeling, Box, Reason } from '../../../../../../component';
import { feelings } from '@web/common';

export const DiaryListDetailDialog = observer(() => {
  const diaryStore = useDiaryStore();
  const { t } = useTranslation('diary-dialog');

  if (diaryStore.openDiaryEntry === null) {
    return null;
  }

  const date = diaryStore.openDiaryEntry
    ? DateTime.fromJSDate(diaryStore.openDiaryEntry.entryDate)
        .toLocal()
        .toFormat('EEEE, dd.MM.yy') +
      ' - ' +
      t(`EntryTime.${diaryStore.openDiaryEntry.entryTime}`)
    : '';

  const feelingsComponent =
    diaryStore.openDiaryEntry.feelings.length === 0 ? (
      <Grid.Col span={12} key={'no-feelings'}>
        <Text c={'#4A4A48'}>{t('NoFeelings')}</Text>
      </Grid.Col>
    ) : (
      diaryStore.openDiaryEntry.feelings
        .flatMap((feeling) => feelings.find((f) => f.value === feeling) || [])
        .map((feeling) => (
          <Grid.Col span={4} key={feeling.value}>
            <Feeling
              isActive={false}
              value={feeling.value}
              icon={feeling.icon}
              onClick={() => {}}
            />
          </Grid.Col>
        ))
    );

  const reasonsComponent =
    diaryStore.openDiaryEntry.reasons.length === 0 ? (
      <Text c={'#4A4A48'}>{t('NoConsumptionReasons')}</Text>
    ) : (
      diaryStore.openDiaryEntry.reasons.map((reason) => (
        <Reason
          key={reason}
          reason={reason}
          onChange={() => {}}
          checked={true}
        />
      ))
    );

  const what =
    diaryStore.openDiaryEntry.consumptionAmount &&
    diaryStore.openDiaryEntry.consumptionType &&
    diaryStore.openDiaryEntry.consumptionConcentration
      ? `${diaryStore.openDiaryEntry.consumptionAmount} ${t('ConsumptionType.' + diaryStore.openDiaryEntry.consumptionType)} / ${t('ConsumptionConcentration.' + diaryStore.openDiaryEntry.consumptionConcentration)}`
      : t('NoConsumption');

  const notesComponent = !diaryStore.openDiaryEntry.notes ? (
    <Text c={'#4A4A48'}>{t('NoNotes')}</Text>
  ) : (
    <Box>
      <Text c={'#4A4A48'}>{diaryStore.openDiaryEntry.notes}</Text>
    </Box>
  );

  return (
    <BaseModal
      opened={!!diaryStore.openDiaryEntry}
      onClose={() => diaryStore.closeDialog()}
      title={''}
      description={''}
      withCloseButton={true}
      size="md"
    >
      <Flex direction="column" gap="1em">
        <Flex direction={'column'} gap={'0.5em'}>
          <Text
            style={{ fontWeight: 400, lineHeight: '24px' }}
            size={'16px'}
            c={'#4A4A48'}
          >
            {date}
          </Text>
          <Text
            style={{ fontWeight: 600, lineHeight: '24px' }}
            size={'21px'}
            c={'#202020'}
          >
            {what}
          </Text>
        </Flex>

        <Flex direction={'column'} gap={'0.5em'}>
          <Text style={{ fontWeight: 600, lineHeight: '24px' }} size={'16px'}>
            {t('Feeling')}
          </Text>
          <Grid gutter="sm">{feelingsComponent}</Grid>
        </Flex>

        <Flex direction={'column'} gap={'0.5em'}>
          <Text
            style={{ fontWeight: 600, lineHeight: '24px' }}
            size={'16px'}
            c={'#202020'}
          >
            {t('ConsumptionReasons')}
          </Text>
          {reasonsComponent}
        </Flex>

        <Flex direction={'column'} gap={'0.5em'}>
          <Text
            style={{ fontWeight: 600, lineHeight: '24px' }}
            size={'16px'}
            c={'#202020'}
          >
            {t('Notes')}
          </Text>
          {notesComponent}
        </Flex>
      </Flex>
      <Button variant={'outline'} onClick={() => diaryStore.closeDialog()}>
        {t('Finished')}
      </Button>
    </BaseModal>
  );
});
