import { Slider, Text } from '@mantine/core';
import { DateTime } from 'luxon';

export const ProgressDisplay = ({
  currentTime,
  updateCurrentTime,
  duration,
}: {
  currentTime: number;
  updateCurrentTime: (time: number) => void;
  duration: number;
}) => {
  const formatTime = (timeInSeconds: number) => {
    return DateTime.fromSeconds(timeInSeconds).toFormat('mm:ss');
  };

  return (
    <>
      <Text c="#101828" size="16px" lh="24px">
        {formatTime(currentTime)}/{formatTime(duration)}
      </Text>
      <Slider
        value={currentTime}
        max={duration}
        onChange={updateCurrentTime}
        label={null}
        w="100%"
      />
    </>
  );
};
