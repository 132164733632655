import { Box, Flex, Tooltip, Text } from '@mantine/core';
import { MyIcon } from '../../../../component';
import { useWindowSize } from '../../../../hooks';
import { useUserMeStore } from '../../../../store';
import classes from './StudyCode.module.css';
import { useTranslation } from 'react-i18next';

export const StudyCode = ({
  smallScreenWidth = 600,
}: {
  smallScreenWidth?: number;
}) => {
  const { t } = useTranslation('onboarding-checklist');
  const myInfoStore = useUserMeStore();
  const isSmallScreen = useWindowSize(smallScreenWidth);
  return (
    <Box
      maw={isSmallScreen ? '100%' : '220px'}
      my="10px"
      className={classes.titleHeader}
    >
      <Flex justify={isSmallScreen ? 'start' : 'space-between'} gap="10px">
        <Text>{t('StudyCode.Title')}</Text>
        <Tooltip
          multiline
          w={250}
          withArrow
          arrowSize={8}
          position="bottom"
          offset={{ mainAxis: 5, crossAxis: 45 }}
          styles={{
            tooltip: {
              padding: '10px 15px',
            },
          }}
          events={{ hover: true, focus: true, touch: true }}
          label={t('StudyCode.Description')}
        >
          <div>
            <MyIcon name="info" style={{ fill: '#3D7F53' }} />
          </div>
        </Tooltip>
      </Flex>

      <Text fw="700">{myInfoStore.myInfo?.userSearchID}</Text>
    </Box>
  );
};
