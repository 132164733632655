import { Button, Flex, TextInput } from '@mantine/core';
import { observer } from 'mobx-react';
import { BaseModal } from '../../../../component/userStatus/dialogs';
import { useTranslation } from 'react-i18next';
import { useParticipantDataStore } from '../../../../store';
import { useState } from 'react';
import { showErrorNotification } from '../../../../component/notification';

export const UpdateQRCodeModal = observer(
  ({
    modalOpen,
    closeModal,
  }: {
    modalOpen: boolean;
    closeModal: () => void;
  }) => {
    const store = useParticipantDataStore();
    const { t } = useTranslation('update-qr-code');
    const [textFieldValue, setTextFieldValue] = useState<null | string>('');
    const onOnboardModalClose = () => {
      closeModal();
    };

    const setQrCodeContent = () => {
      if (!textFieldValue || !store.data?.Participant?.participantId) return;
      store
        .setParticipantQrCodeContent(
          store.data.Participant.participantId,
          textFieldValue,
        )
        .then(() => {
          store.reload();
          closeModal();
        })
        .catch((error) => {
          showErrorNotification(error.code, error.tracingId);
        });
    };

    return (
      <BaseModal
        opened={modalOpen}
        onClose={onOnboardModalClose}
        title={t('Title')}
        description=""
        iconName="qr_code"
        iconColor="#3D7F53"
        size="sm"
        buttons={[
          <Button
            disabled={!textFieldValue}
            key="cancel"
            variant="outline"
            onClick={setQrCodeContent}
            w="100%"
          >
            {t('Action')}
          </Button>,
        ]}
        children={
          <Flex direction="column" gap="10px" align="center">
            {t('Prompt')}
            <TextInput
              style={{ flex: 1 }}
              placeholder={t('Placeholder')}
              label={t('Label')}
              onChange={(event) => setTextFieldValue(event.currentTarget.value)}
              maw="500px"
            />
          </Flex>
        }
      />
    );
  },
);
