const defaultLink =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86456.44144709255!2d8.454335050917129!3d47.377528422960395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900b9749bea219%3A0xe66e8df1e71fdc03!2sZ%C3%BCrich%2C%20Switzerland!5e0!3m2!1sen!2sde!4v1701331007106!5m2!1sen!2sde';

export const MapView = ({
  link,
  borderRadius = '0px 5px 5px 0px',
}: {
  link?: null | string;
  borderRadius?: string;
}) => {
  return (
    <>
      <iframe
        title="map"
        src={link ?? defaultLink}
        width="100%"
        height="100%"
        loading="lazy"
        style={{ border: 'none', borderRadius: borderRadius }}
      ></iframe>
      <br />
    </>
  );
};
