import { DiaryEntry } from '@web/common';
import { DateTime } from 'luxon';
import { i18n } from 'i18next';
import { Text } from '@mantine/core';

export const getDiaryHeader = (entry: DiaryEntry, i18n: i18n) => {
  const formattedMonthYear = DateTime.fromJSDate(entry.entryDate)
    .setLocale(i18n.language)
    .toFormat('LLLL yyyy');

  return (
    <Text lh="24px" size="16px" fw={600}>
      {formattedMonthYear}
    </Text>
  );
};
