import { z } from 'zod';
import { customNumber } from './participant-handover.res.dto';

export const getParticipantTHCLimitResDto = z.object({
  data: z.object({
    thc_limit: customNumber,
  }),
});
export type GetParticipantTHCLimitResDto = z.infer<
  typeof getParticipantTHCLimitResDto
>;
