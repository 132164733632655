import { Checkbox } from '@mantine/core';
import { ChangeEventHandler } from 'react';
import classes from './Reasons.module.css';

export const Reason = ({
  reason,
  onChange,
  checked = undefined,
}: {
  reason: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  checked?: undefined | boolean;
}) => {
  return (
    <Checkbox
      w="100%"
      key={reason}
      label={reason}
      classNames={classes}
      onChange={onChange}
      checked={checked}
    />
  );
};
