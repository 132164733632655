import { Button, Flex, Space, Text } from '@mantine/core';
import { Card } from '../../../../../component';
import { CartLine } from '@shopify/hydrogen-react/storefront-api-types';
import placeholder from '../../../../../assets/placeholder.svg';
import { getTitleAndDescriptionFromProduct } from '../utils/getTitleAndDescriptionFromProduct';
import { useCart } from '@shopify/hydrogen-react';
import { useShopStore } from '../../../../../store';

export const ItemCard = ({ order }: { order: CartLine }) => {
  const { linesUpdate } = useCart();
  const store = useShopStore();
  const { title, description } = getTitleAndDescriptionFromProduct(
    order.merchandise.product.title,
  );

  const updateQuantity = (id: string, delta: number) => {
    store.setLoadingCart(true);
    linesUpdate([{ id, quantity: order.quantity + delta }]);
  };

  return (
    <Card
      style={{
        padding: '20px 15px',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <div>
        <Flex justify="space-between" gap="20px" align="center">
          <img
            src={order.merchandise.image?.url ?? placeholder}
            alt="product"
            height={70}
          />
          <Flex direction="column" w="100%">
            <Text fw="600" size="16px" lh="24px">
              {title}
            </Text>
            <Text size="12px" lh="18px" c="#4A4A48">
              {description}
            </Text>
            <Space h={10} />
            <Flex justify="space-between" align="center">
              <Flex
                style={{ border: '1px solid #B1B1B1', borderRadius: 5 }}
                align="center"
              >
                <Button
                  variant="transparent"
                  c="#000"
                  h="24px"
                  onClick={() => updateQuantity(order.id, -1)}
                >
                  <Text size="16px" lh="24px">
                    -
                  </Text>
                </Button>
                <Text size="16px" lh="24px">
                  {order.quantity}
                </Text>
                <Button
                  variant="transparent"
                  c="#000"
                  h="24px"
                  onClick={() => updateQuantity(order.id, 1)}
                >
                  <Text size="16px" lh="24px">
                    +
                  </Text>
                </Button>
              </Flex>

              <Text size="16px" lh="24px">
                {Number(order.cost.totalAmount.amount).toLocaleString()}{' '}
                {order.cost.totalAmount.currencyCode}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </Card>
  );
};
