import { Button, Flex, Tooltip } from '@mantine/core';
import {
  cancelOrder,
  markOrderAsPickedUp,
} from '../../../../request/authenticated-requests/shopify';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../component/notification';
import { useTranslation } from 'react-i18next';
import { PaidStatus } from '@web/common';
import { useNavigate } from 'react-router-dom';

export const OrderAdminButtons = ({
  orderId,
  orderName,
  paidStatus,
}: {
  orderId: string;
  orderName: string;
  paidStatus: PaidStatus;
}) => {
  const canMarkAsPickedUp = paidStatus === PaidStatus.Paid;
  const canCancelOrder = paidStatus !== PaidStatus.Unpaid; // an order can only be canceled if its expired or paid (refunded)
  const navigate = useNavigate();
  const { t } = useTranslation('shop');

  function pickUpOrder() {
    markOrderAsPickedUp(orderId)
      .then(() => {
        showSuccessNotification(
          t('OrderAdminButtons.MarkAsPickedUpSuccessMessage', { orderName }),
        );
        navigate(-1);
      })
      .catch(() => showErrorNotification());
  }
  // TODO: SCC-441 use enum here, maybe add dialog later on -> create ticket
  function cancel() {
    cancelOrder({
      orderId: orderId,
      reason: 'STAFF',
      staffNote: 'cancel order',
    })
      .then(() => {
        showSuccessNotification(
          t('OrderAdminButtons.CancelOrderSuccessMessage', { orderName }),
        );
        navigate(-1);
      })
      .catch(() => showErrorNotification());
  }

  return (
    <Flex gap="10px">
      <Tooltip
        maw={250}
        multiline
        label={t('OrderAdminButtons.MarkAsPickedUpTooltip')}
      >
        <Button onClick={pickUpOrder} disabled={!canMarkAsPickedUp}>
          {t('OrderAdminButtons.MarkAsPickedUp')}
        </Button>
      </Tooltip>
      <Tooltip
        maw={250}
        multiline
        label={t('OrderAdminButtons.CancelOrderTooltip')}
      >
        <Button onClick={cancel} variant="outline" disabled={!canCancelOrder}>
          {t('OrderAdminButtons.CancelOrder')}
        </Button>
      </Tooltip>
    </Flex>
  );
};
