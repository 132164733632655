import {
  getQrCodeContentResDto,
  participantResponsibleUserResDto,
  SetQrCodeContentResDto,
  userAffiliateCodeResDto,
  UserContactInfoReqDto,
} from '@web/common';
import { authenticatedAPI } from '../AuthenticatedAPI';
import { z } from 'zod';

export const getParticipantQrCodeContent = async () => {
  return authenticatedAPI.get(
    `/api/participant/qr-code-content`,
    getQrCodeContentResDto,
  );
};

export const setParticipantQrCodeContent = async (
  participantId: string,
  body: SetQrCodeContentResDto,
) => {
  return authenticatedAPI.post(
    `/api/participant/${participantId}/qr-code-content`,
    body,
    z.string(),
  );
};

export const participantResponsibleUser = async (participantId: string) => {
  return authenticatedAPI.get(
    `/api/participant/${participantId}/responsible-user`,
    participantResponsibleUserResDto,
  );
};
export const updateContactInfo = async (data: UserContactInfoReqDto) => {
  return await authenticatedAPI.post(
    `/api/participant/update-contact-info`,
    data,
    z.object({ success: z.boolean() }),
  );
};

export const getAffiliateCode = async (userId: string) => {
  return await authenticatedAPI.get(
    `/api/participant/affiliate-code/${userId}`,
    userAffiliateCodeResDto,
  );
};

export const updateAffiliateCode = async (
  userId: string,
  affiliateCode: string,
) => {
  return await authenticatedAPI.post(
    `/api/participant/affiliate-code/${userId}`,
    { affiliateCode },
    z.object({ affiliateCode: z.string() }),
  );
};
