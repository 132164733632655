import { Button } from '@mantine/core';
import { useUserStateStore } from '../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from './BaseModal';

export const NotSmokingModal = observer(
  ({
    userId,
    onUserStateUpdated,
  }: {
    userId: string;
    onUserStateUpdated: () => void;
  }) => {
    const stateStore = useUserStateStore();

    const onPauseModalClose = () => {
      stateStore.setNotSmokingModalOpen(false);
      stateStore.setPauseReason(null);
    };

    return (
      <BaseModal
        opened={stateStore.notSmokingModalOpen}
        onClose={onPauseModalClose}
        title="Du denkst der Teilnehmer kifft nicht?"
        description="Wenn du das Gefühl hast, dass der Teilnehmer nicht in seinem Alltag kifft,
          wird er pausiert. Durch das Pausieren wird der Teilnehmer für die weitere Bearbeitung dem Studienorganisator zugewiesen."
        iconName="warning_fill"
        iconColor="#202020"
        buttons={[
          <Button key="cancel" variant="outline" onClick={onPauseModalClose}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            disabled={stateStore.loading}
            onClick={() => {
              stateStore.setPaused(userId, 'Teilnehmer kifft nicht', () => {
                onPauseModalClose();
                onUserStateUpdated();
              });
            }}
          >
            Ja, Teilnehmer pausieren
          </Button>,
        ]}
      />
    );
  },
);
