import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { i18nFallbackLang } from '@web/common';

void i18n
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`../../public/locales/${language}/${namespace}.json`),
    ),
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: [
      'common',
      'homescreen',
      'profilescreen',
      'landingpage, dialogs',
      'faq',
      'onboarding-checklist',
      'participant-dashboard',
      'organizer-dashboard',
      'check-participant',
      'navbar',
      'statistics',
      'appointment-booking',
      'register',
      'stammdaten-questionnaire',
      'entry-questionnaire',
      'consumption-questionnaire',
      'sf12-questionnaire',
      'points',
      'deactivation',
      'no-access',
      'consumption',
      'quick-enroll',
    ],
    defaultNS: 'common',
    lng: i18nFallbackLang,
    fallbackLng: i18nFallbackLang,
    fallbackNS: 'common',

    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
