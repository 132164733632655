import { Button, MantineThemeOverride } from '@mantine/core';
import classes from './Button.module.css';

export const mantineCustomTheme: MantineThemeOverride = {
  fontFamily: 'Poppins, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Poppins, sans-serif' },
  primaryColor: 'primary',
  colors: {
    primary: [
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
      '#3D7F53',
    ],
    red: [
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
      '#CE0000',
    ],
    backgroundcolor: [
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
      '#f7f8f8',
    ],
    nordischblau: [
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
      '#dde1e1',
    ],
    secondary: [
      '#0F5454', // SW-d2
      '#207979', // SW-d1
      '#42AFAF', // SW-1
      '#7FC9C9', // SW-2
      '#9DD6D6', // SW-3
      '#B7E1E1', // SW-4
      '#D9EFEF', // SW-5
      '#F0F9F9', // SW-6
      '#F0F9F9',
      '#F0F9F9',
    ],
    main: [
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
      '#6D3371',
    ],
    bg: [
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
      '#F1F0E899',
    ],
    bgShade: [
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
      '#F0EEF3',
    ],
    lightred: [
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
      '#fbe6e6',
    ],
    rossocorsa: [
      // Red
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
      '#d40000',
    ],
    denimblau: [
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
      '#8da6ba',
    ],
    warningYellow: [
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
      '#ffff0073',
    ],
  },
  other: {
    lineHeights: [1.43],
    paddings: [24, 56, 32, 100],
  },
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
  },
};
