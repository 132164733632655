import { observer } from 'mobx-react';
import { BaseModal } from '../userStatus/dialogs/BaseModal';
import { ExportType, useExportStore } from '../../store/ExportStore';
import { Flex, Select, Space } from '@mantine/core';
import { useCohortStore } from '../../store';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { BaseExportButton } from './BaseExportButton';
import { useTranslation } from 'react-i18next';

export const ExportModal = observer(() => {
  const exportStore = useExportStore();
  const cohortStore = useCohortStore();
  const { t } = useTranslation('organizer-dashboard');

  const cohortData = cohortStore.cohorts.map((cohort) => ({
    label: `${cohort.id} - ${DateTime.fromJSDate(cohort.startDate)}`,
    value: cohort.id,
  }));

  useEffect(() => {
    cohortStore.loadCohorts();
  }, [cohortStore]);

  useEffect(() => {
    if (exportStore.downloadData) {
      exportData(
        exportStore.downloadData.data,
        exportStore.downloadData.filename,
      );
    }
  }, [exportStore.downloadData]);

  const onModalClose = () => {
    exportStore.resetModal();
  };

  const exportData = (data: string, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode!.removeChild(link);
  };

  const optionList = (
    <Flex direction="column" gap="10px">
      <Space h="10px" />
      <BaseExportButton
        label="Onboarding Daten"
        onClick={() => (exportStore.exportType = ExportType.Onboarding)}
      />
      <BaseExportButton
        label="Clusterin Daten eportieren"
        onClick={() => (exportStore.exportType = ExportType.Clustering)}
      />
      <BaseExportButton
        label="Alle Studiendaten exportieren"
        onClick={() => (exportStore.exportType = ExportType.AllStudyData)}
      />
    </Flex>
  );

  const downloadSettings = () => {
    switch (exportStore.exportType) {
      case ExportType.Onboarding: {
        return cohortSelection(exportStore.download);
      }
      case ExportType.Clustering: {
        return cohortSelection(exportStore.download);
      }
      case ExportType.AllStudyData: {
        return cohortSelection(exportStore.download);
      }
    }
  };

  const cohortSelection = (onDownload: () => void) => (
    <Flex direction="column" gap="40px">
      <Select
        placeholder={t('ExportModal.Select')}
        label={t('ExportModal.Cohort')}
        required
        w="350px"
        data={cohortData}
        onChange={(value) => {
          if (value) {
            exportStore.downloadSettings = {
              type: ExportType.Onboarding,
              cohortId: value,
            };
          }
        }}
      />

      <BaseExportButton label={t('ExportModal.Button')} onClick={onDownload} />
    </Flex>
  );

  return (
    <BaseModal
      size="500px"
      opened={exportStore.open}
      onClose={onModalClose}
      title={t('ExportModal.Title')}
      description={t('ExportModal.Description')}
      children={!exportStore.exportType ? optionList : downloadSettings()}
    ></BaseModal>
  );
});
