import { z } from 'zod';
import { arrayToNonEmptyArray } from './array-to-non-empty-array';
import { ConsumptionType } from '@prisma/client';

export const consumptionTypeZodEnum = () =>
  z
    .enum(arrayToNonEmptyArray(Object.values(ConsumptionType)))
    .transform(
      (value) =>
        ConsumptionType[
          value as keyof typeof ConsumptionType
        ] as ConsumptionType,
    );
