import { Text } from '@mantine/core';
import { Card } from '../../../../../../component';
import { observer } from 'mobx-react';
import { THCTooltip } from './THCTooltip';
import classes from './THC.module.css';
import { ConsumptionChart } from './Chart/ConsumptionChart';
import { useTranslation } from 'react-i18next';
import { useAvailableThcAmount } from '../../../../../../hooks';

export const THCAmountCard = observer(() => {
  const { availableAmount, currentThcAmount, thcLimit, currentThcUnit } =
    useAvailableThcAmount();
  const { t } = useTranslation('homescreen');

  return (
    <Card
      className={classes.card}
      flexProps={{ align: 'center', direction: 'column' }}
    >
      <>
        <Text fw="600" lh="24px" size="21px">
          {t('THC.CardTitle')}
        </Text>

        <Text lh="21px" size="14px">
          {t('THC.CardDescription', {
            availableAmount: availableAmount,
            currentThcUnit: currentThcUnit,
          })}
          <THCTooltip />
        </Text>

        <ConsumptionChart
          availableAmount={availableAmount}
          usedAmount={currentThcAmount}
          thcLimit={thcLimit}
          unit={currentThcUnit}
        />
        {/* <Button
          w="fit-content"
          rightSection={
            <MyIcon name="shopping_bag" style={{ fill: '#fff', height: 19 }} />
          }
          onClick={() =>
            alert('TODO: https://quickbird.atlassian.net/browse/SCC-340')
          }
        >
          Kaufen
        </Button> */}
      </>
    </Card>
  );
});
