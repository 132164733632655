import { observer } from 'mobx-react';
import { Questionnaire } from '../questionnaire';
import {
  cannabisConsumptionQuestionnaireReqDto,
  CheckListId,
  CannabisConsumptionQuestionnaireResult,
} from '@web/common';
import { QuestionnaireWrapper } from './QuestionnaireWrapper';
import { useParticipantData } from './hooks/useParticipantData';
import { showErrorNotification } from '../notification';
import { useOnboardingChecklistStore } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getCannabisConsumptionQuestionnaireSurveyJSON } from '../../view/authenticated/participant/cannabis-consumption-questionnaires/cannabis-consumption-survey-specification';
import { useTranslation } from 'react-i18next';

export const ViewCannabisConsumptionQuestionnaire = observer(() => {
  const { userInformation } = useParticipantData();
  const { userId } = useParams();
  const navigate = useNavigate();
  const onboardingChecklistStore = useOnboardingChecklistStore();
  const { t } = useTranslation('consumption-questionnaire');

  const surveyComplete = (
    surveyResult: CannabisConsumptionQuestionnaireResult,
  ) => {
    Promise.all([
      onboardingChecklistStore.updateQuestionnaireResults({
        id: CheckListId.CannabisConsumptionQuestionnaire,
        result: surveyResult,
        userId,
      }),
    ])
      .then(() => navigate(-1))
      .catch(() => showErrorNotification());
  };

  const questionnaireData =
    userInformation?.checkListData?.data?.[
      CheckListId.CannabisConsumptionQuestionnaire
    ]?.questionnaireResult;

  return (
    <QuestionnaireWrapper>
      <Questionnaire
        title={'Cannabis Konsum'}
        questionnaire={{
          json: getCannabisConsumptionQuestionnaireSurveyJSON(t),
          schema: cannabisConsumptionQuestionnaireReqDto,
          initial: questionnaireData,
        }}
        onComplete={surveyComplete}
        onError={() => showErrorNotification()}
      />
    </QuestionnaireWrapper>
  );
});
