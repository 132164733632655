import { Switch, Text } from '@mantine/core';
import { BirdyClub } from '../BirdyClub';
import { useTranslation } from 'react-i18next';

export const IsZuricanStep = ({
  selectedPlace,
  invalidLivingPlace,
  setIsZurican,
}: {
  selectedPlace: string | null;
  invalidLivingPlace: string;
  setIsZurican: (isZurican: boolean) => void;
}) => {
  const { t } = useTranslation('quick-enroll');

  return selectedPlace !== invalidLivingPlace ? (
    <>
      <Text fw="600" size="16px" lh="24px">
        {t('Step')} 2:
      </Text>
      <div>
        <Text>{t('IsZurican.DoYouParticipate')}</Text>
        <Switch
          disabled={selectedPlace === null}
          size="xl"
          onLabel={t('IsZurican.Yes')}
          offLabel={t('IsZurican.No')}
          onChange={(event) => setIsZurican(event.currentTarget.checked)}
        />
      </div>
    </>
  ) : (
    <BirdyClub />
  );
};
