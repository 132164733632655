export const livingPlaces = [
  'Zürich',
  'Winterthur',
  'Bülach',
  'Uster',
  'Dübendorf',
  'Dietikon',
  'Wetzikon',
  'Wädenswil',
  'Horgen',
  'Opfikon',
  'Kloten',
  'Schlieren',
  'Volketswil',
  'Adliswil',
  'Regensdorf',
  'Thalwil',
  'Illnau-Effretikon',
  'Wallisellen',
  'Küsnacht',
  'Stäfa',
  'Meilen',
  'Richterswil',
  'Zollikon',
  'Rüti',
  'Affoltern am Albis',
  'Pfäffikon',
  'Bassersdorf',
  'Hinwil',
  'Männedorf',
  'Maur',
  'Wald',
  'Gossau',
  'Urdorf',
  'Dielsdorf',
];
