import { Flex, Chip, Text } from '@mantine/core';
import { MyIcon } from '../../../../component/icon';
import classes from './BookInPerson.module.css';
import { useTranslation } from 'react-i18next';

export const BookingHeader = ({ count }: { count: number }) => {
  const { t } = useTranslation('appointment-booking');
  return (
    <Flex className={classes.headerBox}>
      <Chip
        icon={<MyIcon name="location" className={classes.chip} />}
        color="#202020"
        variant="filled"
        checked
        size="md"
      >
        Kanton Zürich
      </Chip>
      <Text className={classes.headerText}>
        {count} {count === 1 ? t('Result') : t('Results')}
      </Text>
    </Flex>
  );
};
