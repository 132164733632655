export function userSearchRemoveInvalidCharacters(value: string) {
  // if at least 3 digits are present, then I'm formatting as a phone number (removing the spaces)
  if (value.match(/\d{1}/)) {
    // only keeping digits and the plus sign
    /* eslint-disable no-useless-escape */
    return value.replace(/[^\d\+]/g, '');
  }
  // otherwise not removing the spaces
  /* eslint-disable no-useless-escape */
  return value.replace(/[^\w\-\s]/g, '');
}

export function simpleSearchRemoveInvalidCharacters(value: string) {
  /* eslint-disable no-useless-escape */
  return value.replace(/[^\+\w\d\s]/g, '');
}
