import classes from '../Deactivation.module.css';
import { Button, Flex, Space, Text } from '@mantine/core';
import { MyIcon } from '../../../../component';
import { useTranslation } from 'react-i18next';

export const Warning = ({
  makeStep,
  cancel,
}: {
  makeStep: () => void;
  cancel: () => void;
}) => {
  const { t } = useTranslation('deactivation');
  const points = t('Warning.Points', { returnObjects: true }) as string[];

  return (
    <div className={classes.stepContainer}>
      <Text size="21px" lh="24px" fw="600">
        {t('Warning.Title')}
      </Text>
      {points.map((point: string, index: number) => (
        <Flex key={index} gap="10px">
          <MyIcon name="cancel_outlined" style={{ fill: '#D12300' }} />
          <Text key={index} size="16px" lh="24px" c="#4A4A48">
            {point}
          </Text>
        </Flex>
      ))}
      <Space h="30px" />
      <Button w="100%" onClick={cancel}>
        {t('Cancel')}
      </Button>
      <Button w="100%" variant="outline" onClick={makeStep}>
        {t('Deactivate')}
      </Button>
    </div>
  );
};
