import { DateTime } from 'luxon';

// TODO this link should probably come from the backend https://quickbird.atlassian.net/browse/SCC-58
export const createBooking = (
  team: string,
  teamPath: string,
  userId: string,
  userSearchId: string,
  teamId: number,
  email: string,
  prefDate: DateTime,
  type: string = 'ONBOARDING',
) => {
  const urlParams = new URLSearchParams({
    userid: userId,
    teamId: teamId.toString(),
    eventtype: type,
    date: prefDate.toFormat('yyyy-MM-dd'),
    month: prefDate.toFormat('yyyy-MM'),
    email: email,
    name: userSearchId,
  });
  console.debug(`urlParams: ${urlParams.toString()}`);
  return `https://cal.com/team/${team}/${teamPath}?${urlParams.toString()}`;
};

export const reschedule = (bookingId: string) => {
  return `https://cal.com/reschedule/${bookingId}`;
};
