import { ActionIcon, Flex, Skeleton, Space, Text } from '@mantine/core';
import { MyIcon } from '../../../../component';
import { showSuccessNotification } from '../../../../component/notification';
import { useUserMeStore } from '../../../../store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../hooks';
import { useMyCohortStore } from '../../../../store/MyCohortStore';

export const AffiliateCode = observer(() => {
  const { t } = useTranslation('affiliate-code');
  const store = useUserMeStore();
  const myCohortStore = useMyCohortStore();
  const studyCode = store.myInfo?.userSearchID;
  const isSmallScreen = useWindowSize(600);
  const cohortStartDate = myCohortStore.cohortStartDate?.toFormat('dd.MM.yyyy');
  const cohortOnboardingEndDate =
    myCohortStore.cohortOnboardingEndDate?.toFormat('dd.MM.yyyy');

  function copyToClipboard() {
    if (studyCode) {
      navigator.clipboard
        .writeText(studyCode)
        .then(() => showSuccessNotification(t('ProfileHint.Copied')))
        .catch(() => console.error(t('ProfileHint.CopyError')));
    }
  }

  return (
    <Flex gap="10px" direction="column">
      <Space />
      <Flex gap="10px">
        <Text size="18px" fw="600" lh="22px">
          {t('ProfileHint.Title')}:
        </Text>

        <Skeleton visible={!studyCode} height={21} width={120}>
          <Text fw="600" lh="22px" size="18px" c="#3D7F53">
            {studyCode}
          </Text>
        </Skeleton>

        <ActionIcon
          variant="transparent"
          radius="xl"
          size="md"
          onClick={copyToClipboard}
          disabled={!studyCode}
          m="-4px 0px 0px -36px"
        >
          <MyIcon name="copy" style={{ fill: '#3D7F53', height: 20 }} />
        </ActionIcon>
      </Flex>
      <div>
        <Flex>
          <Text size="14px" lh="21px" c="#4A4A48" style={{ flexGrow: 2 }}>
            {t('ProfileHint.Description')}
          </Text>
        </Flex>
        <Text
          fs="italic"
          c="#7F898F"
          size="12px"
          lh="18px"
          my={isSmallScreen ? 10 : 0}
        >
          *
          {t('ProfileHint.ValidUntil', {
            cohortStartDate,
            cohortOnboardingEndDate,
          })}
        </Text>
      </div>
    </Flex>
  );
});
