import { z } from 'zod';

export enum WorkingDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const workingHoursSchema = z.object({
  days: z.array(
    z
      .number()
      .refine((val) => Object.values(WorkingDay).includes(val as WorkingDay))
      .transform((val) => val as WorkingDay),
  ),
  startTime: z.number(), // between 0 and 1440
  endTime: z.number(), // between 0 and 1440
});
export type WorkingHourSchemaDto = z.infer<typeof workingHoursSchema>;
const workingHoursArray = z.array(workingHoursSchema);
export type WorkingHoursSchemaDto = z.infer<typeof workingHoursArray>;
const userResponse = z.object({
  availability: z.object({
    workingHours: workingHoursArray.min(1),
  }),
});
export const getWorkingHoursReqDto = z.array(userResponse).min(1);

/* keeping this for easy reference
example response
[
   {
     "userId": 137707,
     "availability": {
       "busy": [],
       "timeZone": "Europe/Rome",
       "dateRanges": [],
       "workingHours": [
         {
           "days": [
             1,
             2,
             3,
             4
           ],
           "startTime": 480,
           "endTime": 960,
           "userId": 137707
         },
         {
           "days": [
             5
           ],
           "startTime": 480,
           "endTime": 780,
           "userId": 137707
         }
       ],
       "dateOverrides": [],
       "currentSeats": null
     }
   },
   {
     "userId": 137847,
     "availability": {
       "busy": [],
       "timeZone": "Europe/Rome",
       "dateRanges": [],
       "workingHours": [
         {
           "days": [
             1,
             2,
             3,
             4
           ],
           "startTime": 480,
           "endTime": 960,
           "userId": 137847
         },
         {
           "days": [
             5
           ],
           "startTime": 480,
           "endTime": 780,
           "userId": 137847
         }
       ],
       "dateOverrides": [],
       "currentSeats": null
     }
   }
 ]
 */
