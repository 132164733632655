import {
  EntryQuestionnaireReqDtoType,
  EntryQuestionnaireHowOldAreYouAnswer,
  EntryQuestionnaireQuestionKey,
  EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer,
} from '@web/common';

export const entryQuestionnaireEvaluation = (
  questionnaire: EntryQuestionnaireReqDtoType,
) => {
  const needToBeFalse = [
    EntryQuestionnaireQuestionKey.ARE_YOU_UNDER_GUARDIANSHIP,
    EntryQuestionnaireQuestionKey.ARE_YOU_PREGNANT,
    EntryQuestionnaireQuestionKey.ARE_YOU_PROFESSIONAL_DRIVER,
    EntryQuestionnaireQuestionKey.SUFFERING_FROM_MEDICAL_CONDITION,
    EntryQuestionnaireQuestionKey.ARE_YOU_PLANNING_RELOCATION,
    EntryQuestionnaireQuestionKey.ARE_YOU_MEMBER_OF_ZURICAN,
  ];

  for (let i = 0; i < needToBeFalse.length; i++) {
    const key = needToBeFalse[i];
    if (questionnaire[key] !== false) {
      return false;
    }
  }

  if (
    questionnaire[EntryQuestionnaireQuestionKey.HOW_OLD_ARE_YOU] ===
    EntryQuestionnaireHowOldAreYouAnswer.UNDER_18
  ) {
    return false;
  }

  if (
    questionnaire[
      EntryQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS
    ] === EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.NEVER
  ) {
    return false;
  }

  return true;
};
