import { RefinementCtx } from 'zod';
import { DateTime } from 'luxon';
import { LOCALE, ZONE } from '../../enum';

export function dateTimeTransformer(
  format?: string,
  config?: {
    overwriteZone?: boolean;
    overwriteLocale?: boolean;
  },
) {
  const fromConfig = config
    ? {
        zone: config.overwriteZone ? ZONE : undefined,
        locale: config.overwriteLocale ? LOCALE : undefined,
      }
    : undefined;

  const fromFn = format
    ? (dateString: string) =>
        DateTime.fromFormat(dateString, format, fromConfig)
    : (dateString: string) => DateTime.fromISO(dateString, fromConfig);

  return (dateString: string, ctx: RefinementCtx) => {
    const formattedDateTime = fromFn(dateString);

    if (!formattedDateTime.isValid) {
      ctx.addIssue({
        code: 'invalid_date',
        message: `Invalid date format: ${formattedDateTime.invalidExplanation}`,
      });
    }

    return formattedDateTime as DateTime<true>;
  };
}
