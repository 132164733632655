import { observer } from 'mobx-react';
import { getDiaryHeader } from './getDiaryHeader';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { DiaryEntry, getFeelingIcon } from '@web/common';
import { getFormattedDate } from './getFormattedDate';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../../../hooks';
import { useDiaryStore } from '../../stores/DiaryStore';
import classes from './DiaryListItem.module.css';
import { MyIcon } from '../../../../../../component';

export const DiaryListItem = observer(
  ({ entry, showHeader }: { entry: DiaryEntry; showHeader: boolean }) => {
    const diaryStore = useDiaryStore();
    const isSmallScreen = useWindowSize(1000);
    const { t, i18n } = useTranslation('consumption');

    return (
      <div key={entry.id} onClick={() => diaryStore.showInDialog(entry)}>
        {showHeader && getDiaryHeader(entry, i18n)}
        <Stack
          gap="0px"
          w="100%"
          bg="#F7F6F1"
          style={{ borderRadius: 8, border: '1px solid #D0CDB1' }}
          p="24px 16px"
          className={classes.clickableItem}
        >
          {entry.notes ? (
            <MyIcon
              name="clipboard_filled"
              style={{ width: '20px', fill: 'rgb(127, 137, 143)' }}
              className={classes.notesIcon}
            />
          ) : null}
          {isSmallScreen && (
            <Flex gap="5px" py="0px 5px" wrap="wrap">
              {entry.feelings.map((feeling) => (
                <Text key={feeling}>{getFeelingIcon(feeling)}</Text>
              ))}
            </Flex>
          )}
          <Flex justify="space-between">
            <Box>
              <Text c="#4A4A48" size="14px" lh="21px">
                {getFormattedDate(entry, t, i18n)}
              </Text>
              <Text
                fw="700"
                size="16px"
                lh="24px"
                c={entry.didConsume ? '#202020' : '#3D7F53'}
              >
                {entry.didConsume
                  ? `${entry.consumptionAmount} ${t('ConsumptionType.' + entry.consumptionType, { ns: 'diary-dialog' })} / ${t('ConsumptionConcentration.' + entry.consumptionConcentration)}`
                  : t('DidNotConsume')}
              </Text>
            </Box>
            {!isSmallScreen && (
              <Flex gap="5px" py="0px 5px" wrap="wrap" maw="500px">
                {entry.feelings.map((feeling) => (
                  <Text key={feeling} size="40px">
                    {getFeelingIcon(feeling)}
                  </Text>
                ))}
              </Flex>
            )}
          </Flex>
        </Stack>
      </div>
    );
  },
);
