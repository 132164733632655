import { Flex, Loader } from '@mantine/core';
import { Header } from '../navigation';
import classes from './Deactivation.module.css';
import { useWindowSize } from '../../../hooks';
import { Success } from './steps';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from '../../../route/Routes';
import { useEffect, useRef, useState } from 'react';
import { isLocalOrDev } from '../../../config/environment';
import { verifyDeactivation } from '../../../request';

export const VerifyDeactivation = () => {
  const isSmallScreen = useWindowSize(550);
  const token = useLocation().hash;

  const [verificationSuccessful, setVerificationSuccessful] =
    useState<boolean>(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const initialized = useRef(false);

  useEffect(() => {
    const removeHashToken = token.replace('#', '');

    // workaround, to get it to work with reactjs Strict mode (locally)
    if (isLocalOrDev) {
      if (!initialized.current) {
        initialized.current = true;
        verifyDeactivation(removeHashToken)
          .then((result: { success: boolean }) =>
            setVerificationSuccessful(result.success),
          )
          .catch(() => setInvalidToken(true));
      }
    } else {
      verifyDeactivation(removeHashToken)
        .then((result: { success: boolean }) =>
          setVerificationSuccessful(result.success),
        )
        .catch(() => setInvalidToken(true));
    }
  }, [token]);

  if (!token) return <Navigate to={Routes.Root} />;

  if (invalidToken) {
    return (
      <>
        Ungültiger E-Mail Verifizierungs-Link!
        <p />
        Bitte frage einen neuen Link an.
      </>
    );
  }

  function redirect() {
    window.location.href = '/';
  }

  return (
    <Flex direction="column" mih="calc(100vh - 3.5em)">
      <Header canLogout />

      <Flex style={{ flex: 1 }}>
        <Flex
          className={isSmallScreen ? classes.smallContainer : classes.container}
        >
          {verificationSuccessful ? <Success close={redirect} /> : <Loader />}
        </Flex>
      </Flex>
    </Flex>
  );
};
