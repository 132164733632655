import { Flex, Text, Image } from '@mantine/core';
import icon from './questionnaire.svg';
import { MyIcon } from '../../../../../../component';
import { AvailableMission } from '@web/common';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

export const QuestionnaireBanner = ({
  mission,
}: {
  mission: AvailableMission;
}) => {
  const endDateFormatted = mission.endDate
    ? DateTime.fromJSDate(mission.endDate).toFormat('dd.MM.yyyy')
    : undefined;
  const isEndDateInTheFuture =
    mission.endDate && DateTime.fromJSDate(mission.endDate) >= DateTime.now();

  const textWithDate = `bis zum ${endDateFormatted}`;
  const textWithoutDate = `so bald wie möglich`;

  const content = isEndDateInTheFuture ? textWithDate : textWithoutDate;
  return (
    <Flex
      style={{ border: '2px #DA3448 solid', borderRadius: 8, padding: 24 }}
      align="center"
      gap="25px"
    >
      <Image src={icon} w="50px" style={{ objectFit: 'contain' }} />
      <div>
        {mission.link ? (
          <Link
            to={mission.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: 600,
              textDecoration: 'none',
              color: '#202020',
            }}
          >
            Neuer Fragebogen &gt;
          </Link>
        ) : (
          <Text fw="600">Neuer Fragebogen</Text>
        )}

        <Text>
          {`Fülle den Fragebogen zu deinem Konsum für die Studie ${content} aus und sammle dabei Punkte.`}
        </Text>
      </div>
      <Flex gap="5px">
        <MyIcon
          name="local_activity"
          style={{ fill: '#DA3448', width: '20px' }}
        />
        <Text c="#DA3448">+{mission.points}</Text>
      </Flex>
    </Flex>
  );
};
