const location = window.location;

const protocol = process.env['NX_API_HOST'] ? '' : `${location.protocol}//`;

const hostname = process.env['NX_API_HOST']
  ? process.env['NX_API_HOST']
  : `api.${location.hostname}`;

const port = process.env['NX_API_PORT'] ? `:${process.env['NX_API_PORT']}` : '';

const apiUrl = `${protocol}${hostname}${port}/`;

console.debug(`apiUrl: ${apiUrl}`);

export default apiUrl;
