import { z } from 'zod';
import { DateTime } from 'luxon';
import { ZONE } from '../../enum/ZONE';

const participantId = z
  .string()
  .max(32)
  .transform((str) => {
    const match = str.match(/^\[.*\]\s*(.*)/);
    if (match && match.length === 2) {
      return match[1].trim();
    }
    return str.trim();
  });

const germanNumber = z.string().transform<number>((str) => {
  /* eslint-disable no-useless-escape */
  const matchKomma = str.match(/(\,)/);
  if (matchKomma && matchKomma.length >= 1) {
    return parseFloat(str.replace(',', '.'));
  } else {
    return parseFloat(str);
  }
});

export const participantHandoverRowResDto = z.object({
  'Participant ID': participantId,
  'Handover ID': z.coerce.number().int(),
  'Date ': z.string().transform((dateString) => {
    const date = DateTime.fromFormat(dateString, `yyyy-MM-dd, HH:mm`, {
      zone: ZONE,
    });
    return date.toISO()!;
  }),
  Dispensary: z.string().max(256),
  GLN: z.string().max(32),
  Type: z.string().max(64),
  Product: z.string().max(256),
  GTIN: z.string().max(32),
  'Batch ID': z.string().max(16),
  'Sales unit': z.string().max(16),
  'THC (g)': germanNumber,
  'Labeled THC (g)': germanNumber,
});

export type ParticipantHandoverRowResDto = z.infer<
  typeof participantHandoverRowResDto
>;

export const currentThcResDto = z.object({
  thcConsumptionThisMonth: z.number(),
  unit: z.string(),
});
export type CurrentThcResDto = z.infer<typeof currentThcResDto>;

export const availableThcResDto = z.object({
  availableThcThisMonth: z.number(),
  unit: z.string(),
});
export type AvailableThcResDto = z.infer<typeof availableThcResDto>;
