import {
  OpenOrderStoreProvider,
  OpenOrderStore,
} from '../../../view/authenticated/organizer/open-shop-orders/OpenOrdersStore';
import {
  PickUpStoreProvider,
  PickUpStore,
} from '../../../view/authenticated/participant/shop/components/PickUp/PickUpStore';
import { ParticipantDetailUI } from './ParticipantDetailUI';

export const ParticipantDetail = () => {
  return (
    <PickUpStoreProvider store={new PickUpStore()}>
      <OpenOrderStoreProvider store={new OpenOrderStore()}>
        <ParticipantDetailUI />
      </OpenOrderStoreProvider>
    </PickUpStoreProvider>
  );
};
