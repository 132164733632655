import {
  EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer,
  EntryQuestionnaireHowOldAreYouAnswer,
  EntryQuestionnaireQuestionKey,
  whereDoYouLiveChoices,
} from '@web/common';

export const validSelection = () => {
  return {
    [EntryQuestionnaireQuestionKey.ARE_YOU_MEMBER_OF_ZURICAN]: false,
    [EntryQuestionnaireQuestionKey.HOW_OLD_ARE_YOU]:
      EntryQuestionnaireHowOldAreYouAnswer.BETWEEN_18_AND_65,
    [EntryQuestionnaireQuestionKey.WHERE_DO_YOU_LIVE]:
      whereDoYouLiveChoices[
        Math.floor(Math.random() * whereDoYouLiveChoices.length - 1)
      ],
    [EntryQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS]:
      EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.DAILY,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PREGNANT]: false,
    [EntryQuestionnaireQuestionKey.ARE_YOU_UNDER_GUARDIANSHIP]: false,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PROFESSIONAL_DRIVER]: false,
    [EntryQuestionnaireQuestionKey.SUFFERING_FROM_MEDICAL_CONDITION]: false,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PLANNING_RELOCATION]: false,
    [EntryQuestionnaireQuestionKey.ARE_YOU_READY_TO_FILL_OUT_QUESTIONNAIRES]:
      true,
  };
};

export const invalidSelection = () => {
  return {
    [EntryQuestionnaireQuestionKey.ARE_YOU_MEMBER_OF_ZURICAN]: true,
    [EntryQuestionnaireQuestionKey.HOW_OLD_ARE_YOU]:
      EntryQuestionnaireHowOldAreYouAnswer.UNDER_18,
    [EntryQuestionnaireQuestionKey.WHERE_DO_YOU_LIVE]:
      whereDoYouLiveChoices[
        Math.floor(Math.random() * whereDoYouLiveChoices.length - 1)
      ],
    [EntryQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS]:
      EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.NEVER,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PREGNANT]: true,
    [EntryQuestionnaireQuestionKey.ARE_YOU_UNDER_GUARDIANSHIP]: true,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PROFESSIONAL_DRIVER]: true,
    [EntryQuestionnaireQuestionKey.SUFFERING_FROM_MEDICAL_CONDITION]: true,
    [EntryQuestionnaireQuestionKey.ARE_YOU_PLANNING_RELOCATION]: true,
    [EntryQuestionnaireQuestionKey.ARE_YOU_READY_TO_FILL_OUT_QUESTIONNAIRES]:
      false,
  };
};
