import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { RealmRole, eligibleForHRPlusContent } from '@web/common';
import { setupMobX } from '../util/setupMobX';
import { getMyCohort } from '../request/authenticated-requests/user';
import { KeycloakStore } from './KeycloakStore';
import { DateTime } from 'luxon';
import { CohortType } from '@prisma/client';

export class MyCohortStore {
  @observable public loading: boolean = false;
  @observable public cohortId: null | string = null;
  @observable public cohortType: null | CohortType = null;
  @observable public cohortStartDate: null | DateTime = null;
  @observable public cohortOnboardingEndDate: null | DateTime = null;
  @observable public eligibleForHRPlusContent: boolean = false;

  constructor(kcStore: KeycloakStore) {
    makeObservable(this);
    autorun(() => {
      if (
        kcStore.authenticated &&
        kcStore.currentRealmRole === RealmRole.Participant
      ) {
        console.debug(`User is authenticated, loading my cohort`);
        this.loadMyCohort();
      }
    });
  }

  @action public loadMyCohort = (successAction?: () => void) => {
    this.loading = true;
    getMyCohort()
      .then((cohort) => {
        runInAction(() => {
          this.cohortId = cohort?.cohort?.id ?? null;
          this.cohortType = cohort?.cohort?.cohortType ?? null;
          this.cohortStartDate = cohort?.cohort?.startDate
            ? DateTime.fromJSDate(cohort.cohort.startDate)
            : null;
          this.cohortOnboardingEndDate = cohort?.cohort?.onboardingEndDate
            ? DateTime.fromJSDate(cohort.cohort.onboardingEndDate)
            : null;
          if (cohort.cohort) {
            this.eligibleForHRPlusContent = eligibleForHRPlusContent(
              cohort.cohort.cohortType,
              DateTime.fromJSDate(cohort.cohort.startDate),
            );
          }
          this.loading = false;
          successAction?.();
        });
      })
      .catch((error) => {
        console.error(error);
        runInAction(() => {
          this.loading = false;
        });
      });
  };
}

const { provider, useStore } = setupMobX<MyCohortStore>();
export const useMyCohortStore = useStore;
export const MyCohortStoreProvider = provider;
