import { observer } from 'mobx-react';
import { Questionnaire } from '../questionnaire';
import {
  CheckListId,
  ErrorCode,
  QuestionnaireOutput,
  StammdatenQuestionnaireQuestionKey,
  questionnaireOutputSchema,
} from '@web/common';
import { QuestionnaireWrapper } from './QuestionnaireWrapper';
import { useParticipantData } from './hooks/useParticipantData';
import { getStammdatenQuestionnaireSurveyJSON } from '../../view/authenticated/participant/stammdaten-questionnaire/stammdaten-questionnaire-specification';
import { DateTime } from 'luxon';
import { useNavigate, useParams } from 'react-router-dom';
import { useOnboardingChecklistStore } from '../../store';
import { showErrorNotification } from '../notification';
import { useTranslation } from 'react-i18next';

export const ViewStammdatenQuestionnaire = observer(() => {
  const { userInformation } = useParticipantData();
  const navigate = useNavigate();
  const onboardingChecklistStore = useOnboardingChecklistStore();
  const { userId } = useParams();
  const { t } = useTranslation('stammdaten-questionnaire');

  const questionnaireData =
    userInformation?.checkListData?.data?.[CheckListId.StammdatenQuestionnaire]
      ?.questionnaireResult;
  const birthday =
    questionnaireData?.[StammdatenQuestionnaireQuestionKey.BirthDate];
  const formattedData = {
    ...questionnaireData,
    [StammdatenQuestionnaireQuestionKey.BirthDate]: birthday
      ? DateTime.fromFormat(birthday, 'dd-MM-yyyy').toFormat('yyyy-MM-dd')
      : '',
  };

  const surveyComplete = (surveyResult: QuestionnaireOutput) => {
    const {
      [StammdatenQuestionnaireQuestionKey.BirthDate]: birthdate,
      [StammdatenQuestionnaireQuestionKey.Clustering]: clustering,
      ...rest
    } = surveyResult;
    const resultData = {
      ...rest,
      [StammdatenQuestionnaireQuestionKey.BirthDate]: DateTime.fromFormat(
        birthdate,
        'yyyy-MM-dd',
      ).toFormat('dd-MM-yyyy'),
      [StammdatenQuestionnaireQuestionKey.Clustering]: clustering?.flatMap(
        (clusterObj) => {
          if (
            !clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringName] &&
            !clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringSurname]
          ) {
            return [];
          }
          return [
            {
              [StammdatenQuestionnaireQuestionKey.ClusteringName]:
                clusterObj[StammdatenQuestionnaireQuestionKey.ClusteringName] ??
                '',
              [StammdatenQuestionnaireQuestionKey.ClusteringSurname]:
                clusterObj[
                  StammdatenQuestionnaireQuestionKey.ClusteringSurname
                ] ?? '',
            },
          ];
        },
      ),
    };
    if (userId)
      onboardingChecklistStore
        .updateQuestionnaireResults({
          id: CheckListId.StammdatenQuestionnaire,
          result: resultData,
          userId: userId,
        })
        .then(() => navigate(-1));
  };

  return (
    <QuestionnaireWrapper>
      <Questionnaire
        title={'Stammdaten'}
        questionnaire={{
          json: getStammdatenQuestionnaireSurveyJSON(t, birthday),
          schema: questionnaireOutputSchema,
          initial: formattedData,
        }}
        onComplete={surveyComplete}
        onError={() =>
          showErrorNotification(ErrorCode.QuestionnaireParsingError)
        }
      />
    </QuestionnaireWrapper>
  );
});
