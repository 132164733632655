import { Stack, Text, UnstyledButton } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const Feeling = observer(
  ({
    isActive,
    icon,
    value,
    onClick,
  }: {
    isActive: boolean;
    value: string;
    icon: string;
    onClick: () => void;
  }) => {
    const { t } = useTranslation('diary-dialog');
    const border = isActive ? '2px solid #3D7F53' : '1px solid #B1B1B1';

    return (
      <UnstyledButton variant="transparent" onClick={onClick} w="100%">
        <Stack
          align="center"
          gap="10px"
          key={value}
          bg="#F5F5F5"
          p={isActive ? '19px' : '20px'}
          style={{ border: border, borderRadius: 16 }}
        >
          <Text size="35px">{icon}</Text>
          <Text c={isActive ? '#3D7F53' : '#4A4A48'} size="14px" lh="21px">
            {t('ConsumptionFeelings.' + value)}
          </Text>
        </Stack>
      </UnstyledButton>
    );
  },
);
