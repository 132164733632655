import { Box } from '@mantine/core';
import { MapView } from '../booked-appointment/MapView';
import classes from './BookInPerson.module.css';

export const MapWrapper = ({
  location = null,
}: {
  location: null | string;
}) => {
  return (
    <Box className={classes.mapView}>
      <MapView borderRadius="5px" link={location} />
    </Box>
  );
};
