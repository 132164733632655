import { z } from 'zod';

export enum SF12QuestionnaireQuestionKey {
  GENERAL_HEALTH = 'general-health',

  NORMAL_DAY_ACTIVITIES_MATRIX = 'normal-day-activities-matrix',
  MIDDLE_HEAVY_WORK = 'middle-heavy-work',
  MULTIPLE_STAIRS = 'multiple-stairs',

  LAST_4_WEEKS_WORK_PHYSICAL_ISSUES = 'last-4-weeks-work-physical-issues',
  I_DID_LESS_THAN_I_WANTED_PHYSICAL = 'i-did-less-than-i-wanted-physical',
  I_COULD_ONLY_DO_CERTAIN_WORK_PHYSICAL = 'i-could-only-do-certain-work-physical',

  LAST_4_WEEKS_MENTAL_ISSUES = 'last-4-weeks-mental-issues',
  I_DID_LESS_THAN_I_WANTED_MENTAL = 'i-did-less-than-i-wanted-mental',
  I_COULDNT_WORK_AS_CAREFULLY_MENTAL = 'i-couldnt-work-as-carefully-mental',

  DAILY_ACTIVITIES_ISSUES_BC_OF_PAIN = 'daily-activities-issues-bc-of-pain',

  LAST_4_WEEEKS_FEELING = 'last-4-weeks-feeling',
  RELAXED_AND_CALM = 'relaxed-and-calm',
  FULL_OF_ENERGY = 'full-of-energy',
  DISCOURAGED_AND_SAD = 'discouraged-and-sad',

  CONTACT_TO_FRIENDS_AND_FAMILY = 'contact-to-friends-and-family',
}

export const sf12QuestionnaireReqDto = z.object({
  [SF12QuestionnaireQuestionKey.GENERAL_HEALTH]: z.number(),
  [SF12QuestionnaireQuestionKey.NORMAL_DAY_ACTIVITIES_MATRIX]: z.object({
    [SF12QuestionnaireQuestionKey.MIDDLE_HEAVY_WORK]: z.number(),
    [SF12QuestionnaireQuestionKey.MULTIPLE_STAIRS]: z.number(),
  }),
  [SF12QuestionnaireQuestionKey.LAST_4_WEEKS_WORK_PHYSICAL_ISSUES]: z.object({
    [SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_PHYSICAL]:
      z.number(),
    [SF12QuestionnaireQuestionKey.I_COULD_ONLY_DO_CERTAIN_WORK_PHYSICAL]:
      z.number(),
  }),
  [SF12QuestionnaireQuestionKey.LAST_4_WEEKS_MENTAL_ISSUES]: z.object({
    [SF12QuestionnaireQuestionKey.I_DID_LESS_THAN_I_WANTED_MENTAL]: z.number(),
    [SF12QuestionnaireQuestionKey.I_COULDNT_WORK_AS_CAREFULLY_MENTAL]:
      z.number(),
  }),
  [SF12QuestionnaireQuestionKey.DAILY_ACTIVITIES_ISSUES_BC_OF_PAIN]: z.number(),
  [SF12QuestionnaireQuestionKey.LAST_4_WEEEKS_FEELING]: z.object({
    [SF12QuestionnaireQuestionKey.RELAXED_AND_CALM]: z.number(),
    [SF12QuestionnaireQuestionKey.FULL_OF_ENERGY]: z.number(),
    [SF12QuestionnaireQuestionKey.DISCOURAGED_AND_SAD]: z.number(),
  }),
  [SF12QuestionnaireQuestionKey.CONTACT_TO_FRIENDS_AND_FAMILY]: z.number(),
});
export type SF12QuestionnaireResult = z.infer<typeof sf12QuestionnaireReqDto>;
