import { z } from 'zod';

export const dataMetaSchema = <T>(item: z.Schema<T>) => {
  return z.object({
    data: z.array(item),
    links: z.object({
      first: z.string(),
      last: z.string(),
      prev: z.string().nullable(),
      next: z.string().nullable(),
    }),
    meta: z.object({
      current_page: z.number(),
      from: z.number().nullable(),
      last_page: z.number(),
      links: z.array(
        z.object({
          url: z.string().nullable(),
          label: z.string(),
          active: z.boolean(),
        }),
      ),
    }),
  });
};
export const dataMetaResDto = dataMetaSchema(z.any());
export type DataMetaResDto = z.infer<typeof dataMetaResDto>;
