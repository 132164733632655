import { Button } from '@mantine/core';
import { useWaitingStateStore } from '../../../../store';
import { observer } from 'mobx-react';

export function getStepperActionButton(
  currentStep: number,
  baseLineSurveyLink: string | undefined,
): JSX.Element {
  if (currentStep === 0 && baseLineSurveyLink) {
    return <SurveyButton />;
  }
  return <div />;
}

const SurveyButton = observer(() => {
  const waitingStateStore = useWaitingStateStore();
  const { baseLineSurveyLink } = waitingStateStore;

  return (
    <Button
      w="fit-content"
      onClick={() => (window.location.href = baseLineSurveyLink!)}
    >
      Fragebogen ausfüllen
    </Button>
  );
});
