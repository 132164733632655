import { z } from 'zod';
import { anonymousQuestionnaireEvaluationRecordTypeZodEnum } from '../../enum/anonymous-questionnaire-evaluation-record-type.zod-enum';

export const recordAnonymousQuestionnaireResultResDto = z.object({
  id: z.number(),
  date: z.coerce.date(),
  type: anonymousQuestionnaireEvaluationRecordTypeZodEnum(),
  cuditEvaluation: z.boolean().nullable(),
  sf12PhysicalEvaluation: z.boolean().nullable(),
  sf12MentalEvaluation: z.boolean().nullable(),
  reoccurrence: z.number(),
});
export type RecordAnonymousQuestionnaireResultResDto = z.infer<
  typeof recordAnonymousQuestionnaireResultResDto
>;
