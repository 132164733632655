import { UserMeStore, UserMeStoreProvider } from '../../store';

const userMeStore = new UserMeStore();

export const AuthenticatedGlobalStores = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <UserMeStoreProvider store={userMeStore}>{children}</UserMeStoreProvider>
  );
};
