import { useOnboardingChecklistStore } from '../../../../../store';
import { Dispatch, SetStateAction, useEffect } from 'react';

type CheckListId = string;

const useAutoTaskOpener = (
  setOpenItem: Dispatch<SetStateAction<string | null>>,
  hiddenChecklistItems: CheckListId[],
) => {
  const checklistStore = useOnboardingChecklistStore();

  useEffect(() => {
    if (!checklistStore.checklist) return;

    const openTask = Object.entries(checklistStore.checklist)
      .filter(([id]) => !hiddenChecklistItems.includes(id as CheckListId))
      .find(([, item]) => !item.done);
    if (openTask) {
      setOpenItem(openTask[0]);
    } else {
      setOpenItem(null); // so all are collapsed in the end
    }
  }, [checklistStore.checklist, hiddenChecklistItems, setOpenItem]);
};

export default useAutoTaskOpener;
