import { Flex } from '@mantine/core';
import { useWindowSize } from '../../hooks';
import { observer } from 'mobx-react';
const smallScreenWidth = 760;

type BannerProps = {
  maxWidth?: string;
  padding?: string;
  margin?: string;
  children: JSX.Element;
};

export const BannerContainer = observer(
  ({
    maxWidth = '1100px',
    padding = '0px 20px 10px 20px',
    margin = 'auto',
    children,
  }: BannerProps) => {
    const isSmallScreen = useWindowSize(smallScreenWidth);

    return (
      <Flex
        justify={isSmallScreen ? 'flex-start' : 'center'}
        p={padding}
        gap="30px"
        wrap={isSmallScreen ? 'wrap' : 'nowrap'}
        maw={maxWidth}
        m={margin}
      >
        {children}
      </Flex>
    );
  },
);
