/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Flex, Text } from '@mantine/core';
import { Header } from './navigation';
import { gaId } from '../../store';
import { showSuccessNotification } from '../../component/notification';

const browsers = [
  {
    name: 'Google Chrome',
    link: 'https://support.google.com/chrome/answer/95647?hl=de',
  },
  {
    name: 'Internet Explorer',
    link: 'https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies',
  },
  {
    name: 'Firefox',
    link: 'https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen',
  },
  {
    name: 'Safari',
    link: 'https://support.apple.com/de-de/HT201265',
  },
];

export function Datenschutz() {
  function disableGATracking() {
    window.localStorage.setItem('ga_tracking_active', 'false');

    const disableStr = 'ga-disable-' + gaId;
    document.cookie =
      disableStr + '=true;expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/';
    (window as any)[disableStr] = true;

    showSuccessNotification('Opt-Out wurde erfolgreich gesetzt!');
  }

  const baseUrl = window.location.origin;
  const datenSchutzUrl = `${baseUrl}/datenschutz`;

  return (
    <>
      <Header canLogout={false} />
      <Container fluid maw={1000}>
        <Flex
          direction="column"
          gap={20}
          py={80}
          style={{ overflowWrap: 'anywhere' }}
        >
          <Text size="50px" fw="500">
            Datenschutz&shy;erklärung
          </Text>
          <Text fw="600">Version vom 15.12.2023</Text>
          <Text size="21px" fw="500" p="30px 0px 0px 0px">
            Deine Privatsphäre liegt uns am Herzen.
          </Text>
          <Text>
            Mit dieser Datenschutzerklärung informieren wir Dich über die
            Verarbeitung personenbezogener Daten im Rahmen der Nutzung unserer
            Cannabis Research APP / Website. Die Abkürzung DSG steht für
            Schweizer Datenschutzgesetz
          </Text>
          <Text size="21px" fw="500">
            Name und Anschrift des für die Verarbeitung Verantwortlichen
          </Text>
          <Text>
            Verantwortliche im Sinne der Datenschutz-Gesetzgebung und anderer
            Bestimmungen mit datenschutzrechtlichem Charakter ist: <br /> <br />
            Rivamo AG <br />
            Neue Winterthurerstrasse 99 <br />
            8304 Wallisellen <br />
            E-Mail: hello@rivamo.com
          </Text>
          <Text size="21px" fw="500">
            Grundsätze
          </Text>
          <Text>
            Für die Durchführung des Pilotversuchs und der Studie benötigen wir
            verschiedene Personendaten von Dir.
            <br />
            <br />
            Diese Personendaten benötigen wir, damit wir die Studie (Forschung)
            und den Pilotversuch (Verkauf von Cannabisprodukten) ordnungsgemäss
            durchführen können, u.a. für die Erstellung des Studienausweises,
            für die Abklärung, ob Du bereits in einem anderen Pilotversuch
            angemeldet bist, für die Generierung Deiner Studien-ID, für das
            Mahnwesen / Inkasso, für das Zustellung von Erinnerungen zur
            Beantwortung von Fragebögen, für die Kommunikation mit Dir über News
            zur Studie und allgemein zu Cannabis-Themen und für Marketingzwecke
            (insb. Information über Produkte für einen gesünderen Konsum von
            Cannabis, bspw. Vaporisatoren) und für die Kommunikation mit Dir
            über Chat-Programme (Whatsapp, Telegram, etc.).
            <br />
            <br />
            Deine Daten werden streng vertraulich behandelt. Nicht mehr
            benötigte Daten werden umgehend gelöscht.
            <br />
            <br />
            Für das Forschungsteam und das Verkaufspersonal bist du in erster
            Linie eine Identifikationsnummer, um Deine Privatsphäre zu wahren.
            <br />
            <br />
            Der Zugriff auf Deine Personendaten ist streng reglementiert. Nur
            die Studienleitung hat Zugriff; für alle anderen beteiligten
            Personen sind Deine Daten pseudonymisiert.
            <br />
            <br />
            Bei Fragen stehen wir dir jederzeit zur Verfügung und erläutern
            gerne unsere Datenschutzpraktiken.
          </Text>
          <Text size="21px" fw="500">
            Deine Rechte
          </Text>

          <Text>
            Recht auf Auskunft: Du hast das Recht, von uns eine Bestätigung
            darüber zu verlangen, ob durch uns dich betreffende personenbezogene
            Daten verarbeitet werden. Wenn dies der Fall ist, so hast Du ein
            Recht auf Auskunft über diese personenbezogenen Daten.
            <br />
            <br />
            Recht auf Berichtigung: Du hast das Recht, von uns unverzüglich die
            Berichtigung unrichtiger personenbezogener Daten zu verlangen, die
            dich betreffen. Unter Berücksichtigung der Zwecke der Verarbeitung
            hast du ferner das Recht, die Vervollständigung unvollständiger
            personenbezogener Daten zu verlangen.
            <br />
            <br />
            Recht auf Löschung: Du hast in denen vom Gesetz vorgesehenen Fällen
            die Möglichkeit, die Löschung von Daten zu verlangen, bspw. wenn
            Daten nicht mehr erforderlich / notwendig sind oder die Einwilligung
            zur Bearbeitung widerrufen wurde.
            <br />
            <br />
            Rechte anrufen: Wenn Du von einem Dir zustehenden Recht Gebrauch
            machen möchten, wende dich bitte an uns als Verantwortlichen unter
            den oben angegebenen Kontaktdaten.
          </Text>
          <Text size="21px" fw="500">
            Server Log Files
          </Text>
          <Text>
            Wenn Du unsere Cannabis Research APP aufrufst, verarbeitet und
            speichert das von uns mit dem Betrieb der APP beauftragte
            Unternehmen neben technischen Informationen zu dem von Dir
            verwendeten Endgerät (Betriebssystem, Bildschirmauflösung und
            andere, nicht personenbezogene Merkmale) sowie dem Browser (Version,
            Spracheinstellungen), insbesondere die öffentliche IP-Adresse des
            Rechners, mit dem Du unsere Webseite besuchst, samt Datum und
            Uhrzeit des Zugriffs. Bei der IP-Adresse handelt es sich um eine
            eindeutige numerische Adresse, unter der dein Endgerät Daten in das
            Internet sendet bzw. abruft. Uns oder unserem Dienstleister ist im
            Regelfall nicht bekannt, wer sich hinter einer IP-Adresse verbirgt,
            es sei denn, Du teilst uns während der Nutzung unserer Webseite
            Daten mit, welche uns deine Identifizierung ermöglichen. Ferner kann
            es zur Identifizierung eines Nutzers kommen, wenn gegen diesen
            rechtliche Schritte eingeleitet werden (z.B. bei Angriffen gegen
            unsere Webseite) und wir im Rahmen des Ermittlungsverfahrens
            Kenntnis von seiner Identität bekommen. Du musst also im Regelfall
            keine Sorge haben, dass wir deine IP-Adresse dir zuordnen können.
            <br />
            <br />
            Unser Dienstleister verwendet die verarbeiteten Daten nicht
            personenbezogen für statistische Zwecke, damit wir nachvollziehen
            können, welche Endgeräte mit welchen Einstellungen für den Besuch
            unserer Webseite eingesetzt werden, um sie für diese ggf. zu
            optimieren. Diese Statistiken enthalten keine personenbezogenen
            Daten. Grundlage für die Erstellung der Statistiken ist unser
            Interesse an der Verbesserung und der effizienten Durchführung der
            Studie / des Pilotversuchs.
            <br />
            <br />
            Die IP-Adresse wird weiterverwendet, damit du unsere Webseite
            technisch abrufen und nutzen kannst sowie zur Erkennung und Abwehr
            von Angriffen gegen unseren Dienstleister oder unsere Webseite.
            Leider gibt es immer wieder Angriffe, um den Betreibern von
            Webseiten oder deren Nutzern Schaden zuzufügen (z.B. Verhindern des
            Zugriffs, Ausspionieren von Daten, Verbreiten von Schadsoftware
            (z.B. Viren) oder andere unrechtmässige Zwecke). Durch solche
            Angriffe würde die bestimmungsgemässe Funktionsfähigkeit des
            Rechenzentrums des von uns beauftragten Unternehmens, die Nutzung
            unserer Webseite bzw. deren Funktionsfähigkeit sowie die Sicherheit
            der Besucher unserer Webseite beeinträchtigt werden. Die
            Verarbeitung der IP-Adresse samt dem Zeitpunkt des Zugriffes erfolgt
            zur Abwehr solcher Angriffe. Die gespeicherten IP-Daten werden
            (durch Anonymisierung) gelöscht, wenn sie nicht mehr für die
            Erkennung oder Abwehr eines Angriffs benötigt werden.
          </Text>
          <Text size="21px" fw="500">
            Cookies
          </Text>
          <Text>
            Wir nutzen für den Betrieb unserer Webseite Cookies und
            vergleichbare Technologien (Local Storage), um die technische
            Funktionsfähigkeit unserer Webseite zu gewährleisten, um zu
            verstehen, wie Besucher unsere Website nutzen und um
            Voreinstellungen, die ein Nutzer vorgenommen hat, in seinem Browser
            zu speichern. <br />
            <br />
            Bei einem Cookie handelt es sich um kleine Textdateien, die bei
            Aufruf unserer Webseite durch deinen Browser auf deinem Endgerät
            gespeichert werden. Wenn du unsere Website später erneut aufrufst,
            können wir diese Cookies wieder auslesen. Cookies werden
            unterschiedlich lange gespeichert. Du hast jederzeit die Möglichkeit
            in deinem Browser einzustellen, welche Cookies er annehmen soll, was
            aber zur Folge haben kann, dass unsere Webseite nicht mehr richtig
            funktioniert. Ferner kannst du Cookies selbständig jederzeit
            löschen. Wenn du das nicht machst, können wir beim Speichern
            angeben, wie lange eine Cookie auf deinem Rechner gespeichert werden
            soll. Hier ist zu unterscheiden zwischen sog. Sitzungs-Cookies und
            dauerhaften Cookies. Sitzungs-Cookies werden von deinem Browser
            gelöscht, wenn du unsere Webseite verlässt oder du den Browser
            beendest. Dauerhafte Cookies werden für die Dauer gespeichert,
            welche wir bei der Speicherung angeben. <br />
            <br />
            Wir nutzen Cookies zu folgenden Zwecken:
            <ul>
              <li>
                Technisch erforderliche Cookies, die für die Nutzung der
                Funktionen unserer Webseite zwingend erforderlich sind (z.B.
                Erkennung, ob Sie sich angemeldet haben). Ohne diese Cookies
                könnten bestimmte Funktionen nicht bereitgestellt werden.
              </li>
              <li>
                Funktionale Cookies, die eingesetzt werden, um bestimmte
                Funktionen, welche du nutzen willst, technisch durchführen zu
                können.
              </li>
              <li>
                Analyse-Cookies, die dazu dienen, dein Nutzerverhalten zu
                analysieren.
              </li>
            </ul>
            Nutzer verwenden, erlauben es, einzustellen, welche Cookies
            gespeichert werden sollen und ermöglichen es, (bestimmte) Cookies
            wieder zu löschen. Wenn du das Speichern von Cookies auf bestimmte
            Webseiten einschränken oder keine Cookies von Drittanbietern
            zulassen willst, kann das unter Umständen dazu führen, dass unsere
            Webseite nicht mehr in vollem Umfang genutzt werden kann. Hier
            finden Sie Informationen, wie du für die üblichsten Browser die
            <ul>
              {browsers.map((browser, index) => (
                <li key={index}>
                  <a href={browser.link} key={index}>
                    {browser.name}
                  </a>
                </li>
              ))}
            </ul>
          </Text>
          <Text size="21px" fw="500">
            Analysetool "Google Analytics"
          </Text>
          <Text>
            Wir nutzen im Rahmen einer Auftragsverarbeitung ggf. über den Google
            Tag Manager, Google Analytics, einen Dienst der Google LLC
            („Google“), Amphitheatre Parkway, Mountain View, CA 94043, USA.
            Google nutzt als Auftragsverarbeiter hierfür einen sog. „Cookie“.
            Hierbei handelt es sich um eine kleine Textdatei, die durch Ihren
            Browser auf Ihrem Computer gespeichert wird. Mittels dieses Cookies
            erhält Google Informationen darüber, welche Webseite von Ihnen
            aufgerufen wurde und zusätzlich insbesondere die folgenden
            Informationen Browser-Typ/-Version, verwendetes Betriebssystem,
            technische Informationen über das Betriebssystem und den Browser
            sowie die öffentliche IP-Adresse des von Ihnen verwendeten Rechners.
            Wir verwenden Google Analytics so, dass Ihre IP-Adresse nur in
            anonymisierter Form verwendet wird. Diese Anonymisierung erfolgt
            nach Mitteilung von Google in der Europäischen Union oder einem
            Mitgliedstaat des EWR. Nur in Ausnahmefällen soll die volle
            IP-Adresse an einen Server von Google in den USA übertragen und erst
            dort gekürzt werden. Nach Auskunft von Google erfolgt die
            Anonymisierung bevor die IP-Adresse erstmals auf einen dauerhaften
            Datenträger gespeichert wird. Wegen der Einzelheiten verweisen wir
            auf die Datenschutzerklärung von Google, abrufbar unter{' '}
            <a href="https://support.google.com/analytics/answer/6004245?hl=de">
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
            . <br />
            <br />
            Google Analytics erlaubt uns in nicht personenbezogener Form das
            Erstellen von Nutzungsstatistiken für unsere Webseite sowie von
            demografischen Daten über die Besucher und deren Benutzerverhalten.
            Ferner werden Statistiken erstellt, die es uns helfen besser zu
            verstehen, wie unsere Website gefunden wird, um unsere
            Suchmaschinenoptimierung und unsere Werbemassnahmen zu verbessern.
            Wir verfolgen mit dieser Verarbeitung das berechtigte Interesse,
            unsere Webseite sowie unsere Werbemassnahmen verbessern zu können
            (Rechtsgrundlage DSGVO: Art. 6 Abs. 1 lit. f.)
            <br />
            <br />
            Sie finden unter{' '}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>{' '}
            Informationen, wie Sie der Verwendung von Google Analytics
            widersprechen können.
            <br /> <br />
            Alternativ zum Browser-Add-On oder innerhalb von Browsern auf
            mobilen Geräten klicken Sie bitte diesen Link, um die Erfassung
            durch Google Analytics innerhalb dieser Website zukünftig zu
            verhindern (das Opt Out funktioniert nur in diesem Browser und nur
            für diese Domain). Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät
            abgelegt. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie
            diesen Link erneut klicken. <br />
            <br />
            Klicken Sie hier für ein Opt Out von Google Analytics //
            <a
              // eslint-disable-next-line no-script-url
              href="javascript:void(0);"
              onClick={disableGATracking}
            >
              Opt-Out Cookie für diesen Browser und diese Website setzen
            </a>
            <br /> <br />
            Google ist Mitglied des PrivacyShield Übereinkommens und hat mit uns
            einen Auftragsverarbeitungsvertrag für Google Analytics
            abgeschlossen. Die pseudonymen Daten werden nach 14 Monaten
            gelöscht.
          </Text>
          <Text size="21px" fw="500">
            Weitergabe Daten an Dritte
          </Text>
          <Text>
            Wir geben die uns mitgeteilten personenbezogenen Daten nicht an
            Dritte weiter.
          </Text>
          <Text size="21px" fw="500">
            Anpassung Datenschutzhinweise
          </Text>
          <Text>
            Wir halten diese Datenschutzhinweise immer auf dem neuesten Stand.
            Deshalb behalten wir uns vor, sie von Zeit zu Zeit zu ändern und
            Änderungen bei der Erhebung, Verarbeitung oder Nutzung Ihrer Daten
            nachzupflegen. Die aktuelle Fassung der Datenschutzerklärung ist
            stets unter folgender Adresse abrufbar:
            <br />
            <a href={datenSchutzUrl}>{datenSchutzUrl}</a>
          </Text>
        </Flex>
      </Container>
    </>
  );
}
