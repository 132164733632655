import { action, makeObservable, observable, runInAction } from 'mobx';
import { type UserRepResDto } from '@web/common';
import { participantResponsibleUser } from '../../../../request/authenticated-requests/participant';
import { setupMobX } from '../../../../util/setupMobX';
import { handleRequestError } from '../../../../request';

export class ResponsibleUserStore {
  @observable public isLoading = false;
  @observable public responsibleUser: null | UserRepResDto = null;
  @observable public noResponsibleUserFound = false;

  constructor() {
    makeObservable(this);
  }

  @action public getResponsibleUser(participantId: string) {
    this.isLoading = true;

    participantResponsibleUser(participantId)
      .then((responsibleUser) => {
        console.log(responsibleUser);
        runInAction(() => {
          this.responsibleUser = responsibleUser?.responsibleUser;
          this.noResponsibleUserFound =
            responsibleUser?.responsibleUser === null;
        });
      })
      .catch((error) => {
        handleRequestError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }
}

const { provider, useStore } = setupMobX<ResponsibleUserStore>();
export const useResponsibleUserStore = useStore;
export const ResponsibleUserStoreProvider = provider;
