import { Box, Image, Space, Text } from '@mantine/core';
import doctor from '../../../../assets/doctor.jpeg';
import classes from './StudienArztin.module.css';
import { Link } from 'react-router-dom';
import { InfoItem, MyIcon } from '../../../../component';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const aerztin = {
  name: 'Dr. Thilo Beck',
  email: 'arud@arud.ch',
  phone: '058 360 50 00',
  location: 'Schützengasse 31, 8001 Zürich',
  linkLocation: 'https://maps.app.goo.gl/ie1RzdW5ZyE7pbbN9',
};

export const StudienArztin = observer(() => {
  const { t } = useTranslation('studienaerztin');

  return (
    <Box className={classes.box}>
      <Image radius="sm" src={doctor} h={155} />
      <div>
        <Text size="14px" lh="24px" fw="400">
          {t('title')}
        </Text>
        <Text size="16px" lh="24px" fw="700">
          {aerztin.name}
        </Text>
        <Text size="14px" lh="24px">
          {t('stichwort')} «Cannabis Pilotversuch»
        </Text>
        <Space h="sm" />
        <div>
          <InfoItem
            icon={<MyIcon name="mail" />}
            customText={
              <Text size="14px" lh="18px">
                {aerztin.email}
              </Text>
            }
          />
          <InfoItem
            icon={<MyIcon name="phone" />}
            customText={
              <Text size="14px" lh="18px">
                {aerztin.phone}
              </Text>
            }
          />
          <InfoItem
            icon={<MyIcon name="location" />}
            customText={
              <Link to={aerztin.linkLocation}>
                <Text size="14px" lh="18px">
                  {aerztin.location}
                </Text>
              </Link>
            }
          />
        </div>
      </div>
    </Box>
  );
});
