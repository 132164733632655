import { useEffect, useState } from 'react';

export const useAudioPlayer = (onComplete: () => void) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [paused, setPaused] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  function toggleAudio() {
    if (audio?.paused) {
      void audio?.play();
      setPaused(false);
    } else {
      audio?.pause();
      setPaused(true);
    }
  }

  function setAudioRef(audio: HTMLAudioElement | null) {
    setAudio(audio);
  }

  function forward() {
    if (audio) audio.currentTime += 10;
  }

  function replay() {
    if (audio) audio.currentTime -= 10;
  }

  function updateCurrentTime(time: number) {
    if (audio) audio.currentTime = time;
  }

  function setPlaybackRate(speed: number) {
    if (audio) audio.playbackRate = speed;
  }

  useEffect(() => {
    if (!audio) return;

    const updateTime = () => {
      if (audio) {
        setCurrentTime(audio.currentTime);
        setDuration(audio.duration);
      }
    };

    const updateDuration = () => {
      if (audio) setDuration(audio.duration);
    };

    const canPlayListener = () => {
      console.log('can play audio');
      setFinishedLoading(true);
    };

    function completedAudio() {
      setPaused(true);
      onComplete();
    }

    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('loadeddata', updateDuration);
    audio.addEventListener('canplay', canPlayListener);
    audio.addEventListener('ended', completedAudio);
    audio.addEventListener('loadedmetadata', canPlayListener);

    return () => {
      if (!audio) return;
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('loadeddata', updateDuration);
      audio.removeEventListener('canplay', canPlayListener);
      audio.removeEventListener('ended', completedAudio);
      audio.addEventListener('loadedmetadata', canPlayListener);
    };
  }, [audio, onComplete]);

  return {
    paused,
    toggleAudio,
    forward,
    replay,
    currentTime,
    updateCurrentTime,
    duration,
    finishedLoading,
    setPlaybackRate,
    setAudioRef,
  };
};
