import { Search } from './Search';
import { List } from './List';

export const UserSearchList = () => {
  return (
    <>
      <Search />
      <List />
    </>
  );
};
