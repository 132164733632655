import { Radio, Group } from '@mantine/core';
import { observer } from 'mobx-react';
import { SetStateAction, useState } from 'react';
import { useUserStateStore } from '../../../store';
import {
  useViewParticipantOnboardingStore,
  CheckOffId,
} from '../../../store/ViewParticipantOnboardingStore';

export const NotSmokingRadioGroup = observer(
  ({
    setOpenItem,
    isDone,
    isPaused,
  }: {
    setOpenItem: (isOpen: SetStateAction<string | null>) => void;
    isDone: boolean;
    isPaused: boolean;
  }) => {
    const userStateStore = useUserStateStore();
    const stateStore = useViewParticipantOnboardingStore();

    const [value, setValue] = useState<string | undefined>(
      isDone ? 'noContraIndications' : undefined,
    );

    const handleOptionChange = (value: string) => {
      setValue(value);
      switch (value) {
        case 'dataCorrect':
          stateStore.setCurrentCheckpointState(
            CheckOffId.CannabisConsumptionQuestionnaire,
            true,
          );
          setOpenItem(CheckOffId.SF12Questionnaire);
          break;
        case 'notSmoking':
          userStateStore.setNotSmokingModalOpen(true);
          break;
        default:
          break;
      }
    };

    return (
      <Radio.Group onChange={handleOptionChange} value={value} maw="700px">
        <Group>
          <Radio value="dataCorrect" label="Die Daten stimmen überein" />
          <Radio
            disabled={isPaused}
            value="notSmoking"
            label="Ich habe das Gefühl, der Teilnehmer kifft nicht in seinem Alltag"
          />
        </Group>
      </Radio.Group>
    );
  },
);
