import { OpenOrderStore, OpenOrderStoreProvider } from './OpenOrdersStore';
import { OpenShopOrders } from './OpenShopOrders';

export const OpenShopOrderWrapper = () => {
  return (
    <OpenOrderStoreProvider store={new OpenOrderStore()}>
      <OpenShopOrders />
    </OpenOrderStoreProvider>
  );
};
