/**
 * zod wants a non-empty array as an input for its array declaration
 * @param array
 */
export function arrayToNonEmptyArray<T extends string>(
  array: T[],
): [T, ...T[]] {
  if (array.length === 0) throw Error();
  return [array[0], ...array.slice(1)];
}
