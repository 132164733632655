import { z } from 'zod';

const featuredImage = z
  .object({
    url: z.string().url(),
  })
  .nullable();
const variants = z.object({
  edges: z.array(
    z.object({
      node: z.object({
        id: z.string(),
        price: z.string(),
        barcode: z.string(),
        sku: z.string(),
        inventoryQuantity: z.number(),
        inventoryItem: z.object({
          id: z.string(),
          measurement: z.object({
            weight: z.object({
              value: z.number(),
              unit: z.string(),
            }),
          }),
        }),
      }),
    }),
  ),
});
const product = z.object({
  node: z.object({
    id: z.string(),
    title: z.string(),
    handle: z.string(),
    status: z.string(),
    priceRangeV2: z.object({
      maxVariantPrice: z.object({
        currencyCode: z.string(),
      }),
    }),
    featuredImage,
    variants,
  }),
});
export const shopifyProductsResDto = z.object({
  products: z.object({
    edges: z.array(product),
  }),
});
export type ShopifyProductsResDto = z.infer<typeof shopifyProductsResDto>;
