import {
  EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer,
  EntryQuestionnaireHowOldAreYouAnswer,
  EntryQuestionnaireQuestionKey,
  whereDoYouLiveChoices,
  requiredAnswer,
} from '@web/common';
import { renderToString } from 'react-dom/server';
import classes from './IntroBox.module.css';

type TranslationOptions = { returnObjects: boolean };

type TranslationFunction = {
  (key: string): string;
  (key: string, options: TranslationOptions): string[];
};

const introComponent = (t: TranslationFunction) => {
  return (
    <div className={classes.box}>
      <span style={{ fontWeight: 600 }}>{t('Header.Title')}</span>
      <p>{t('Header.Description')}</p>
    </div>
  );
};

const getIntro = (t: TranslationFunction) => {
  return {
    name: 'intro',
    type: 'html',
    html: renderToString(introComponent(t)),
  };
};

const areYouMemberOfZurican = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_MEMBER_OF_ZURICAN,
    title: t('AreYouMemberOfZurican.Title'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getHowOldAreYouQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.HOW_OLD_ARE_YOU,
    title: t('HowOldAreYou.Title'),
    type: 'radiogroup',
    choices: [
      {
        value: EntryQuestionnaireHowOldAreYouAnswer.UNDER_18,
        text: t('HowOldAreYou.Choices.Under18'),
      },
      {
        value: EntryQuestionnaireHowOldAreYouAnswer.BETWEEN_18_AND_65,
        text: t('HowOldAreYou.Choices.Between18And65'),
      },
      {
        value: EntryQuestionnaireHowOldAreYouAnswer.OVER_65,
        text: t('HowOldAreYou.Choices.Over65'),
      },
    ],
    ...requiredAnswer(t),
  };
};

const getWhereDoYouLiveQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.WHERE_DO_YOU_LIVE,
    title: t('WhereDoYouLive.Title'),
    type: 'dropdown',
    choices: whereDoYouLiveChoices,
    ...requiredAnswer(t),
  };
};

const getHowOftenDoYouConsumeCannabisQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS,
    title: t('HowOftenConsumption.Title'),
    type: 'radiogroup',
    ...requiredAnswer(t),
    choices: [
      {
        value: EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.NEVER,
        text: t('HowOftenConsumption.Choices.Never'),
      },
      {
        value:
          EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.EVERY_FEW_MONTHS,
        text: t('HowOftenConsumption.Choices.EveryFewMonths'),
      },
      {
        value: EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.MONTHLY,
        text: t('HowOftenConsumption.Choices.Monthly'),
      },
      {
        value: EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.WEEKLY,
        text: t('HowOftenConsumption.Choices.Weekly'),
      },
      {
        value: EntryQuestionnaireHowOftenDoYouConsumeCannabisAnswer.DAILY,
        text: t('HowOftenConsumption.Choices.Daily'),
      },
    ],
  };
};

const getAreYouPregnantQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_PREGNANT,
    title: t('AreYouPregnant.Title'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getAreYouUnderGuardianShip = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_UNDER_GUARDIANSHIP,
    title: t('AreYouUnderGuardianShip.Title'),
    description: t('AreYouUnderGuardianShip.Description'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getAreYouProfessionalDriver = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_PROFESSIONAL_DRIVER,
    title: t('AreYouProfessionalDriver.Title'),
    description: t('AreYouProfessionalDriver.Description'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getSufferingFromMedicalConditionQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.SUFFERING_FROM_MEDICAL_CONDITION,
    title: t('SufferingFromMedicalCondition.Title'),
    description: t('SufferingFromMedicalCondition.Description'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getAreYouPlanningRelocationQuestion = (t: TranslationFunction) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_PLANNING_RELOCATION,
    title: t('AreYouPlanningRelocation.Title'),
    description: t('AreYouPlanningRelocation.Description'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

const getAreYouReadyToFillOutQuestionnairesQuestion = (
  t: TranslationFunction,
) => {
  return {
    name: EntryQuestionnaireQuestionKey.ARE_YOU_READY_TO_FILL_OUT_QUESTIONNAIRES,
    title: t('AreYouReadyToFillOutQuestionnaires.Title'),
    type: 'boolean',
    ...requiredAnswer(t),
  };
};

export const getEntryQuestionnaireSurveyJSON = (t: TranslationFunction) => {
  return {
    pages: [
      {
        elements: [
          getIntro(t),
          areYouMemberOfZurican(t),
          getHowOldAreYouQuestion(t),
          getWhereDoYouLiveQuestion(t),
          getHowOftenDoYouConsumeCannabisQuestion(t),
          getAreYouPregnantQuestion(t),
          getAreYouUnderGuardianShip(t),
          getAreYouProfessionalDriver(t),
          getSufferingFromMedicalConditionQuestion(t),
          getAreYouPlanningRelocationQuestion(t),
          getAreYouReadyToFillOutQuestionnairesQuestion(t),
        ],
      },
    ],
  };
};
