import { z } from 'zod';

export const tokenDataSchema = z.object({
  exp: z.number(),
  iat: z.number(),
  iss: z.string(),
  sub: z.string().uuid(),
  typ: z.string(),
  preferred_username: z.string(),
  realm_access: z.object({
    roles: z.array(z.string()),
  }),
});

export type TokenDataSchema = z.infer<typeof tokenDataSchema>;
