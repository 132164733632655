import { Flex, Text } from '@mantine/core';
import classes from '../LandingPage.module.css';
import { ContactDetails } from './ContactDetails';
import { useTranslation } from 'react-i18next';

export const Contact = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');
  return (
    <Flex
      id={id}
      className={classes.baseSection}
      style={{ background: '#fff' }}
      direction="column"
      gap="20px"
    >
      <Text size="50px" fw="500" lh="64px">
        {t('Contact.Title')}
      </Text>
      <Text size="21px" fw="700" lh="24px">
        {t('Contact.SubTitle')}
      </Text>
      <Text size="16px" lh="24px" maw="530px">
        {t('Contact.Description')}
        <br />
        {t('Contact.Description2')}
      </Text>
      <ContactDetails />
    </Flex>
  );
};
