import { isLocalOrDev } from '../../../config/environment';

export function isPasswordStrongEnough(password: string): boolean {
  if (isLocalOrDev) return true;

  // NEEDS TO BE THE SAME AS IN realm.tf password_policy
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_. ]).{10,}$/;
  const match = password.match(regex);
  return match != null;
}
