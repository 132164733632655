import { useMemo } from 'react';
import { getNavItemsForRole, NavTitles } from './utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DynamicNavItem } from './DynamicNavItem';
import { useMyCohortStore } from '../../../../store/MyCohortStore';
import { useKeycloakStore } from '../../../../store';

const useNavItems = (isMobile: boolean) => {
  const kcStore = useKeycloakStore();
  const store = useMyCohortStore();
  const navigate = useNavigate();
  const { t } = useTranslation('navbar');
  const currentRole = kcStore.currentRealmRole;

  const navItemElements = useMemo(() => {
    if (!currentRole) return [];

    let navItems = getNavItemsForRole(currentRole, store.cohortType);

    // check if cohortStartDate of user is 4+ months ago, only then he can access this page
    if (!store.eligibleForHRPlusContent) {
      navItems = navItems.filter((item) => item.text !== NavTitles.Consumption);
    }

    return navItems.map((item) => (
      <DynamicNavItem
        key={item.route}
        iconName={item.iconName}
        text={t('NavItems.' + item.text)}
        route={item.route}
        onClick={() => navigate(item.route)}
        isMobile={isMobile}
      />
    ));
  }, [
    currentRole,
    store.eligibleForHRPlusContent,
    store.cohortType,
    t,
    isMobile,
    navigate,
  ]);

  return navItemElements;
};

export default useNavItems;
