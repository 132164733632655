import { Button, Flex, Stepper, Text } from '@mantine/core';
import classes from '../LandingPage.module.css';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import { useTranslation } from 'react-i18next';

const getLinkTitle = (title: string, navigateId?: string) => (
  <Link
    to="/faq"
    state={{ navigateId: navigateId ?? title }}
    style={{ textDecoration: 'none', color: '#202020' }}
  >
    {title} &gt;
  </Link>
);

type StepProps = {
  Key: string;
  Title: string;
  Description: string;
};

export const HowItWorks = ({ id }: { id: string }) => {
  const { t } = useTranslation('landingpage');

  const steps = t('HowItWorks.Steps', { returnObjects: true }) as StepProps[];

  return (
    <Flex
      id={id}
      className={classes.baseSection}
      style={{ background: '#F1F0E899' }}
      justify="space-between"
      gap="40px"
      wrap="wrap"
    >
      <Flex className={classes.howItWorksContainer}>
        <Text className={classes.howItWorksTitle} c="#202020">
          {t('HowItWorks.Title')}
        </Text>
        <Text>{t('HowItWorks.Description1')} </Text>
        <Text> {t('HowItWorks.Description2')}</Text>
      </Flex>
      <Stepper active={-1} orientation="vertical" classNames={classes}>
        {steps.map((step, index) => (
          <Stepper.Step
            key={index}
            label={getLinkTitle(step.Title, step.Key)}
            description={<span>{step.Description}</span>}
          ></Stepper.Step>
        ))}
      </Stepper>
      <Link
        children={<Button>{t('Buttons.RegisterNow')}</Button>}
        to={Routes.EntryQuestionnaire}
      />
    </Flex>
  );
};
