import { Flex, Box, Text } from '@mantine/core';
import { Title } from '../../../../../component';
import { useWindowSize } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';

const smallScreenWidth = 760;

export const SimpleChecklistHeader = () => {
  const isSmallScreen = useWindowSize(smallScreenWidth);
  const { t } = useTranslation('onboarding-checklist');

  return (
    <Flex
      justify={isSmallScreen ? 'flex-start' : 'center'}
      p="40px 20px 20px 20px"
    >
      <Box maw="850px">
        <Title
          text={t('SimpleChecklist.Header.Title')}
          boxPadding="0px 0px 10px 0px"
          textPadding="0px 0px 10px 0px"
          width={800}
          margin="0px"
        />
        <Text miw="200px">{t('SimpleChecklist.Header.Description')}</Text>
      </Box>
    </Flex>
  );
};
