import { Divider, Flex, Text } from '@mantine/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { GetPodcastSchema } from '@web/common';
import { PlayerCard } from './player';
import {
  PodcastFeedbackStore,
  PodcastFeedbackStoreProvider,
} from '../../../../store/podcast';
import { useMemo } from 'react';

export const PodcastDetails = observer(
  ({ podcast }: { podcast: GetPodcastSchema }) => {
    const { t } = useTranslation('podcast');

    const store = useMemo(() => new PodcastFeedbackStore(), []);

    return (
      <Flex direction="column" w="100%" gap="20px">
        <PodcastFeedbackStoreProvider store={store}>
          <PlayerCard
            title={t(`Podcast.${podcast.id}.Title`)}
            description={t(`Podcast.${podcast.id}.Subtitle`)}
          />
        </PodcastFeedbackStoreProvider>

        <Divider />
        <Text fw="600" size="21px" lh="24px">
          {t(`Description`)}
        </Text>
        <Text c="#4A4A48">{t(`Podcast.${podcast.id}.Description`)}</Text>
      </Flex>
    );
  },
);
