import fillOut_img from '../../../../assets/fillOutQuestionnaire.svg';
import waiting2_img from '../../../../assets/waiting2.svg';
import waiting4_img from '../../../../assets/waiting4.svg';
import { Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { CohortType } from '@prisma/client';

export type StepDataType = {
  id: number;
  title: JSX.Element | string;
  title_long: string;
  img: string;
  description: JSX.Element | string;
  description_long?: JSX.Element | string;
};

export const stepsData: (
  dateTime: DateTime,
  cohortType: CohortType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, options?: any) => string,
) => StepDataType[] = (
  dateTime: DateTime,
  cohortType: CohortType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, options?: any) => string,
) => {
  const stepData: StepDataType[] = [
    {
      id: 0,
      title: (
        <div>
          {t('Steps.0.Title1')}&shy;{t('Steps.0.Title2')}
        </div>
      ),
      title_long: t('Steps.0.TitleLong'),
      img: fillOut_img,
      description: t('Steps.0.DescriptionShort'),
      description_long: (
        <Text size="16px" lh="24px" maw="650px">
          {t('Steps.0.DescriptionLong1')}
          <br />
          <br />
          {t('Steps.0.DescriptionLong2')}
        </Text>
      ),
    },
    {
      id: 1,
      title: t('Steps.1.Title'),
      title_long: t('Steps.1.TitleLong'),
      img: waiting2_img,
      description: t('Steps.1.DescriptionShort'),
      description_long: (
        <Text size="16px" lh="24px" maw="650px">
          {t('Steps.1.DescriptionLong1')}
          <br />
          <br />
          {t('Steps.1.DescriptionLong2')}
          <br />
          <br />
          {t('Steps.1.DescriptionLong3')}
          <br />
          <br /> {t('Steps.1.DescriptionLong4')}
        </Text>
      ),
    },
  ];

  if (
    cohortType === CohortType.ACCESS ||
    cohortType === CohortType.LIMITED_ACCESS
  ) {
    stepData.push({
      id: 2,
      title: t('Steps.2.1.Title'),
      title_long: t('Steps.2.1.TitleLong'),
      img: waiting4_img,
      description: t('Steps.2.1.DescriptionShort'),
      description_long: (
        <Text size="16px" lh="24px" maw="650px">
          {t('Steps.2.1.DescriptionLong1', {
            date: dateTime.toFormat('dd.MM.'),
          })}
          <br />
          <br />
          {t('Steps.2.1.DescriptionLong2')}
        </Text>
      ),
    });
  } else if (cohortType === CohortType.NONE) {
    stepData.push({
      id: 2,
      title: t('Steps.2.2.Title'),
      title_long: t('Steps.2.2.TitleLong'),
      img: waiting4_img,
      description: t('Steps.2.2.DescriptionShort'),
    });
  } else {
    stepData.push({
      id: 2,
      title: t('Steps.2.3.Title'),
      title_long: t('Steps.2.3.TitleLong'),
      img: waiting4_img,
      description: t('Steps.2.3.DescriptionShort'),
      description_long: (
        <Text size="16px" lh="24px" maw="650px">
          {t('Steps.2.3.DescriptionLong')}
        </Text>
      ),
    });
  }
  return stepData;
};
