import { CheckListId, CheckListItem } from '@web/common';
import { Accordion, Checkbox, Flex, Text } from '@mantine/core';
import { BaseItem } from '../BaseItem';
import { simpleChecklistStaticData } from '../CheckListStaticData';
import { WithTranslation, withTranslation } from 'react-i18next';

export const allowedItems = [
  CheckListId.AHVNummerNachweis,
  CheckListId.IdentitaetsNachweis,
  CheckListId.Wohnsitznachweis,
];

interface Props {
  id: CheckListId;
  item: CheckListItem;
  handleTick: () => void;
  index: number;
  t: WithTranslation['t'];
}
const SimpleChecklistItemView = ({ id, item, handleTick, index, t }: Props) => {
  const content = simpleChecklistStaticData(t);
  // hide everything except the document
  if (!allowedItems.includes(id)) return;

  return <div id={id}>{getContent(id)}</div>;

  function getContent(id: CheckListId) {
    switch (id) {
      case CheckListId.AHVNummerNachweis:
      case CheckListId.IdentitaetsNachweis:
      case CheckListId.Wohnsitznachweis: {
        return (
          <Accordion.Item key={id} value={id}>
            <BaseItem
              index={index}
              item={item}
              title={content[id].title}
              handleTick={handleTick}
            />
            <Accordion.Panel>
              <Flex
                direction="column"
                align="flex-start"
                m="0px 34px"
                gap="15px"
              >
                <Text>{content[id].description}</Text>
                <Checkbox
                  label={content[id].checkboxLabel}
                  checked={item.done}
                  onChange={handleTick}
                />
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        );
      }
      default: {
        return <div>Unbekannter Eintrag</div>;
      }
    }
  }
};

export default withTranslation('onboarding-checklist')(SimpleChecklistItemView);
