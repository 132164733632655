import { Flex, Stepper } from '@mantine/core';
import { Header } from '../navigation';
import classes from './Deactivation.module.css';
import { useWindowSize } from '../../../hooks';
import { useState } from 'react';
import { EnterReason, Warning } from './steps';
import { deactivateUser } from '../../../request';
import { useSearchParams } from 'react-router-dom';
import { WaitingForConfirmation } from './steps/WaitingForConfirmation';
import { showErrorNotification } from '../../../component/notification';
import stepperClasses from '../../../component/stepper/Stepper.module.css';

export const Deactivation = () => {
  const isSmallScreen = useWindowSize(550);
  const [active, setActive] = useState(0);
  const [searchParams] = useSearchParams();
  const mail = searchParams.get('mail');
  const decodedMail = decodeURIComponent(mail || '');

  function makeStep() {
    const nextStep = active + 1;
    if (nextStep >= 0 && nextStep <= 3) setActive(nextStep);
  }

  function redirect() {
    window.location.href = '/';
  }

  function sendDeactivationMail() {
    deactivateUser({ email: decodedMail })
      .then(() => makeStep())
      .catch(() => showErrorNotification());
  }

  return (
    <Flex direction="column" mih="calc(100vh - 3.5em)">
      <Header canLogout />

      <Flex style={{ flex: 1 }}>
        <Flex
          className={isSmallScreen ? classes.smallContainer : classes.container}
        >
          <Stepper
            active={active}
            onStepClick={makeStep}
            className={stepperClasses.hiddenProgress}
          >
            <Stepper.Step>
              <EnterReason
                makeStep={makeStep}
                cancel={redirect}
                mail={decodedMail}
              />
            </Stepper.Step>
            <Stepper.Step>
              <Warning makeStep={sendDeactivationMail} cancel={redirect} />
            </Stepper.Step>
            <Stepper.Step>
              <WaitingForConfirmation close={redirect} />
            </Stepper.Step>
          </Stepper>
        </Flex>
      </Flex>
    </Flex>
  );
};
