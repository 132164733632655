import { Flex } from '@mantine/core';
import classes from './Footer.module.css';
import { Routes } from '../../../../route/Routes';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Flex className={classes.footerContainer}>
      <Flex className={classes.labelWrapper}>
        {[
          { label: t('Footer.PrivacyPolicy'), route: Routes.Datenschutz },
          { label: t('Footer.Impressum'), route: Routes.Impressum },
          {
            label: t('Footer.TermsOfUse'),
            route: Routes.Nutzungsbedingungen,
          },
        ].map((link) => (
          <a key={link.label} href={link.route} className={classes.label}>
            {link.label}
          </a>
        ))}
      </Flex>
    </Flex>
  );
};
