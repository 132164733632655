import { z } from 'zod';
import { cohortTypeZodEnum, userStatusZodEnum } from '../../enum';

export const UF_WITHOUT_TERMIN_VALUE = 'WITHOUT_TERMIN';

export const COHORT_NONE = 'COHORT_NONE';

const termin = z.union([
  z.literal(UF_WITHOUT_TERMIN_VALUE),
  z.object({
    qualifier: z.enum(['lt', 'lte', 'gt', 'gte']),
    value: z.coerce.date(),
  }),
]);

export enum BagQuestionnaireFilledOut {
  TRUE = 'true',
  FALSE = 'false',
  NOT_ASSIGNED_YET = 'not_assigned_yet',
}

export enum ChecklistComplete {
  TRUE = 'true',
  FALSE = 'false',
}

export const userFilterQueryReqDto = z.object({
  termin: termin.optional(),
  status: userStatusZodEnum().optional(),
  cohort: z.string().optional(),
  email: z.union([z.literal(COHORT_NONE), z.string()]).optional(),
  phoneNumber: z.string().optional(),
  groupAssignment: cohortTypeZodEnum().optional(),
  bagQuestionnaireFilledOut: z.nativeEnum(BagQuestionnaireFilledOut).optional(),
  checklistComplete: z.nativeEnum(ChecklistComplete).optional(),
});

export type UserFilterQueryReqDto = z.infer<typeof userFilterQueryReqDto>;
