import React from 'react';
import classes from './DevContent.module.css';
import { Text } from '@mantine/core';
import { isLocalOrDev } from '../config/environment';

export function DevContent({ children }: { children: React.ReactNode }) {
  if (!isLocalOrDev) return null;

  return (
    <div className={classes.container}>
      <Text size="20px" fw="bold" my="5px">
        Developer Options
      </Text>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
}
