import { UseFormReturnType } from '@mantine/form';
import { devRandomCharString } from '@web/common';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const onRandomize = (Form: UseFormReturnType<any>) => {
  const { email, phoneNumber, password } = randomizeRegisterData();
  Form.setFieldValue('email', email);
  Form.setFieldValue('phoneNumber', phoneNumber);
  Form.setFieldValue('password', password);
  Form.setFieldValue('confirmPassword', password);
  if (Math.ceil(Math.random() * 2) % 2 === 0) {
    Form.setFieldValue('affiliateCode', devRandomCharString(5));
  } else {
    Form.setFieldValue('affiliateCode', '');
  }

  navigator.permissions
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    .query({ name: 'clipboard-write' })
    .then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        void navigator.clipboard.writeText(email).catch(console.error);
      }
    })
    .catch(console.error);
};

const randomizeRegisterData = () => {
  const randomNumber = Math.floor(Math.random() * 1000);
  const randomChars = devRandomCharString(3);
  const min = 100_000_000;
  const max = 10_000_000_000;
  const randomPhoneNumber = Math.floor(Math.random() * (max - min) + min);

  return {
    email: `scc+${randomNumber}${randomChars}@quickbird.io`,
    phoneNumber: `${randomPhoneNumber}`,
    password: `1234`,
  };
};
