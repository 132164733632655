import { z } from 'zod';
import { AnonymousQuestionnaireEvaluationRecordType } from '@prisma/client';

export const recordAnonymousQuestionnaireResultReqDto = z.object({
  date: z.coerce.date(),
  evaluation: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(AnonymousQuestionnaireEvaluationRecordType.CUDIT),
      cuditEvaluation: z.boolean(),
    }),
    z.object({
      type: z.literal(AnonymousQuestionnaireEvaluationRecordType.SF12),
      sf12PhysicalEvaluation: z.boolean(),
      sf12MentalEvaluation: z.boolean(),
    }),
  ]),
  reoccurrence: z.number(),
});
export type RecordAnonymousQuestionnaireResultReqDto = z.infer<
  typeof recordAnonymousQuestionnaireResultReqDto
>;
