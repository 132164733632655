import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container, Flex, Text, Box } from '@mantine/core';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useEntryQuestionnaireStore,
  useGoogleAnalyticsStore,
  useKeycloakStore,
  useSnapPixelStore,
} from '../../../store';
import style from './Register.module.css';
import { Loading } from '../../../component';
import { useWindowSize } from '../../../hooks';
import {
  CongratulationsMessageDesktop,
  CongratulationsMessageMobile,
} from './CongratulationsMessage';
import { RegisterForm } from './RegisterForm';
import { Header } from '../navigation';
import { Routes } from '../../../route/Routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Register = observer(() => {
  const kcStore = useKeycloakStore();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const entryQuestionnaireStore = useEntryQuestionnaireStore();
  const isSmallScreen = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation('register');

  useEffect(() => {
    const surveyData = sessionStorage.getItem('entryQuestionnaire');
    if (!surveyData) navigate(Routes.EntryQuestionnaire);
    // if we have survey data, we load it into the store
    entryQuestionnaireStore.loadQuestionnaireResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryQuestionnaireStore]);

  useEffect(() => {
    snapPixelStore.track(SnapEvent.Register_Page);
  }, [snapPixelStore]);

  useEffect(() => {
    googleAnalyticsStore.track(GoogleAnalyticsEvent.Register_Page);
  }, [googleAnalyticsStore]);

  if (!kcStore.initialized && !kcStore.initializingInProgress) {
    kcStore.initKeycloak();
    return <Loading />;
  }

  const registerForm = (
    <Container
      fluid
      className={style.baseContainer}
      p="0px"
      bg={isSmallScreen ? '#fff' : 'transparent'}
    >
      <Flex mih="calc(100vh - 56px)" direction="column" justify="center">
        <Header canLogout={false} />

        <Flex
          style={{ flex: 1 }}
          align={isSmallScreen ? 'flex-start' : 'center'}
        >
          {!isSmallScreen && <CongratulationsMessageDesktop />}

          <Flex
            w={isSmallScreen ? '100%' : '50%'}
            direction="column"
            align={isSmallScreen ? 'center' : 'start'}
            h={isSmallScreen ? '100%' : 'auto'}
            maw={isSmallScreen ? '100%' : '670px'}
          >
            <Box className={isSmallScreen ? style.smallWrapper : style.wrapper}>
              {isSmallScreen && <CongratulationsMessageMobile />}
              {!isSmallScreen && (
                <Text className={style.title}>{t('Header')}</Text>
              )}

              <RegisterForm />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );

  return registerForm;
});
