import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  GoogleAnalyticsEvent,
  SnapEvent,
  useGoogleAnalyticsStore,
  useOnboardingChecklistStore,
  useSnapPixelStore,
  useUserMeStore,
} from '../../../../../store';
import { Routes } from '../../../../../route/Routes';
import {
  Accordion,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Text,
} from '@mantine/core';
import classes from '../OnboardingChecklist.module.css';
import { useNavigate } from 'react-router-dom';
import { CheckListId, ErrorCode } from '@web/common';
import { BookingSoonPossible } from '../BookingSoonPossible';
import { Header } from '../../../../public/navigation';
import { QuestionnaireEvaluationModal } from '../../questionnaire-evaluation/QuestionnaireEvaluationModal';
import SimpleChecklistItemView, {
  allowedItems,
} from './SimpleChecklistItemView';
import { SimpleChecklistHeader } from './SimpleChecklistHeader';
import useScrollToCheckItem from '../hooks/useScrollToCheckItem';
import { useInitChecks } from '../hooks/useInitChecks';
import useAutoTaskOpener from '../hooks/useAutoTaskOpener';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../../../component';
import { NoData } from '../../deactivated';
import * as Sentry from '@sentry/react';
import { UserStatus } from '@prisma/client';
import { NoAccess } from '../../../../../view/no-access';

const hiddenChecklistItems = [
  CheckListId.EmailVerified,
  CheckListId.StammdatenQuestionnaire,
  CheckListId.CannabisConsumptionQuestionnaire,
  CheckListId.SF12Questionnaire,
  CheckListId.Einwilligungserklaerung,
];

export const SimpleOnboardingChecklist = observer(() => {
  const checklistStore = useOnboardingChecklistStore();
  const userStore = useUserMeStore();
  const isLoading = checklistStore.loadingChecklist;
  const navigate = useNavigate();
  const snapPixelStore = useSnapPixelStore();
  const googleAnalyticsStore = useGoogleAnalyticsStore();
  const { t } = useTranslation('onboarding-checklist');
  const [openItem, setOpenItem] = useState<string | null>(null);
  sessionStorage.removeItem('redirectLocation');

  useEffect(() => {
    checklistStore.loadOnboardingChecklist();
    userStore.loadMyData();
  }, [checklistStore, userStore]);

  const bookingCurrentlyNotPossible =
    process.env['NX_BOOKING_ALLOWED'] === 'false';

  useInitChecks(hiddenChecklistItems);
  useScrollToCheckItem(openItem);
  useAutoTaskOpener(setOpenItem, hiddenChecklistItems);

  if (isLoading) return <Loading />;
  if (!checklistStore.checklist) {
    Sentry.captureEvent({
      message: `Error: ${ErrorCode.CannotLoadChecklist} - The Onboarding Checklist could not be loaded for the user: ${userStore.myInfo?.id}`,
      level: 'warning',
    });

    return <NoData errorCode={ErrorCode.CannotLoadChecklist} />;
  }
  const checklist = Object.entries(checklistStore.checklist);

  // this page is only accessible for users with status OPEN
  if (userStore.myInfo?.status !== UserStatus.OPEN) return <NoAccess />;

  return (
    <>
      <QuestionnaireEvaluationModal />
      <Header canLogout />

      <SimpleChecklistHeader />
      <Container fluid className={classes.container} p={0}>
        <Flex direction="column">
          <Center className={classes.centerContainer}>
            <Flex direction="column" w="870px">
              <Flex
                className={classes.checklistHeader}
                style={{ borderRadius: 5 }}
              >
                <Text fw="700" size="21px" lh="24px">
                  {t('SimpleChecklist.ChecklistTitle')}
                </Text>
              </Flex>
              <Flex className={classes.checklistSection}>
                <Divider />
                <Accordion
                  radius="xs"
                  value={openItem}
                  onChange={setOpenItem}
                  classNames={{ item: classes.accordionItem }}
                >
                  {checklist
                    .filter(([id]) => allowedItems.includes(id as CheckListId))
                    .map(([id, item], index) => (
                      <SimpleChecklistItemView
                        key={id}
                        id={id as CheckListId}
                        item={item}
                        handleTick={() =>
                          checklistStore.checkItem(
                            id as CheckListId,
                            !item.done,
                          )
                        }
                        index={index}
                      />
                    ))}
                </Accordion>
                <div className={classes.appointmentContainer}>
                  {checklistStore.allChecked && bookingCurrentlyNotPossible && (
                    <BookingSoonPossible />
                  )}

                  <Button
                    className={classes.appointmentItem}
                    disabled={bookingCurrentlyNotPossible}
                    onClick={() => {
                      snapPixelStore.track(SnapEvent.Wants_to_book_appointment);
                      googleAnalyticsStore.track(
                        GoogleAnalyticsEvent.Wants_to_book_appointment,
                      );
                      navigate(Routes.BookInPerson);
                    }}
                    maw="300px"
                  >
                    {t('Buttons.MakeAppointment')}
                  </Button>
                </div>
              </Flex>
            </Flex>
          </Center>
        </Flex>
      </Container>
    </>
  );
});
