import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StudyDashboardDetailPage } from '../../../../component/page';
import { PodcastList, PodcastListVersion } from './PodcastList';

export const PodcastsView = observer(() => {
  const { t } = useTranslation('consumption');

  return (
    <StudyDashboardDetailPage breadcrumbText={`/ ${t('BreadcrumbText')}`}>
      <PodcastList version={PodcastListVersion.Long} />
    </StudyDashboardDetailPage>
  );
});
