import { z } from 'zod';

export const orderCancelReasonSchema = z.union([
  z.literal('CUSTOMER'),
  z.literal('DECLINED'),
  z.literal('FRAUD'),
  z.literal('INVENTORY'),
  z.literal('OTHER'),
  z.literal('STAFF'),
]);
export type OrderCancelReasonSchema = z.infer<typeof orderCancelReasonSchema>;

export const cancelOrderReqDto = z.object({
  orderId: z.string(),
  reason: orderCancelReasonSchema,
  staffNote: z.string().max(2048),
});
export type CancelOrderReqDto = z.infer<typeof cancelOrderReqDto>;
