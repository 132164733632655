import { userSearchSchema } from './users-search-result.res.dto';
import { z } from 'zod';
import { cohortTypeZodEnum } from '../../enum';

const usersFilterSchema = userSearchSchema.extend({
  email: z.string().email().nullable(),
  groupAssignment: cohortTypeZodEnum().nullable(),
  BAGQuestionnaireFilledOut: z.boolean(),
});
export const usersFilterResDto = z.array(usersFilterSchema);
export type UsersFilterResDto = z.infer<typeof usersFilterResDto>;
