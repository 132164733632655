import structuredClone from '@ungap/structured-clone';

(function setupPolyfills() {
  console.debug(`setting up polyfills...`);
  if (!('structuredClone' in globalThis)) {
    console.debug(`[polyfilling]: structuredClone`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.structuredClone = structuredClone;
  }
})();
