import { ActionIcon, Flex } from '@mantine/core';
import { MyIcon } from '../../icon';
import {
  SnakeBubbleProgressIndicator,
  SnakeBubbleProgressIndicatorProps,
} from '../../progress';

export const SimpleProgressModalHeader = ({
  active,
  max,
  onBackClick,
}: {
  onBackClick: () => void;
} & SnakeBubbleProgressIndicatorProps) => {
  return (
    <Flex w="100%" justify="space-between" align="center">
      {active > 0 && (
        <ActionIcon
          variant="transparent"
          aria-label="Settings"
          onClick={onBackClick}
          pos="absolute"
        >
          <MyIcon name="arrow_back" style={{ fill: 'grey' }} />
        </ActionIcon>
      )}
      <SnakeBubbleProgressIndicator active={active} max={max} />
    </Flex>
  );
};
