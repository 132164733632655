import { z } from 'zod';

export const productSchemaDto = z.object({
  id: z.string(),
  variantId: z.string(),
  title: z.string(),
  handle: z.string(),
  inventory: z.number(),
  price: z.coerce.number(),
  currencyCode: z.string(),
  imageUrl: z.string().url().nullish(),
  weight: z.object({
    value: z.number(),
    unit: z.string(),
  }),
  thcAmount: z.number(),
  GTIN: z.string(),
});
export type ProductDto = z.infer<typeof productSchemaDto>;

export const productsResDto = z.array(productSchemaDto);
export type ProductsResDto = z.infer<typeof productsResDto>;

export const productResDtoWithoutThc = productSchemaDto.omit({
  thcAmount: true,
});
export type ProductResDtoWithoutThc = z.infer<typeof productResDtoWithoutThc>;

export const productsResDtoWithoutThc = z.array(productResDtoWithoutThc);
export type ProductsResDtoWithoutThc = z.infer<typeof productsResDtoWithoutThc>;
