import { Flex, Text } from '@mantine/core';
import { MyIcon } from './icon';

export enum ObtainablePointsStatus {
  Unavailable,
  Available,
}

export function getRewardsColor(
  status: ObtainablePointsStatus,
  darkMode: boolean,
) {
  return (() => {
    switch (status) {
      case ObtainablePointsStatus.Available:
        return darkMode ? '#6FB0F1' : '#1570CB';
      case ObtainablePointsStatus.Unavailable:
        return '#7F898F';
    }
  })();
}

export const ObtainablePoints = ({
  points,
  status = ObtainablePointsStatus.Available,
  darkMode = false,
  direction = 'row',
  showPlus = true,
}: {
  points: string | number;
  status?: ObtainablePointsStatus;
  darkMode?: boolean;
  direction?: 'row' | 'row-reverse';
  showPlus?: boolean;
}) => {
  const color = getRewardsColor(status, darkMode);

  return (
    <Flex gap="5px" align="center" direction={direction}>
      <Text c={color} size="14px" lh="14px">
        {showPlus && '+'}
        {points}
      </Text>
      <MyIcon name="local_activity" style={{ fill: color, width: '20px' }} />
    </Flex>
  );
};
