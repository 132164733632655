import { TextInput, Button } from '@mantine/core';
import { useUserStateStore } from '../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from './BaseModal';

export const ContraIndicationModal = observer(
  ({
    userId,
    onUserStateUpdated,
  }: {
    userId: string;
    onUserStateUpdated: () => void;
  }) => {
    const stateStore = useUserStateStore();

    const onClose = () => {
      stateStore.setContraIndicationModalOpen(false);
      stateStore.setContraIndication(null);
    };

    const onPositiveAction = () => {
      stateStore.deactivateUser(
        userId,
        stateStore.contraIndication || '',
        () => {
          stateStore.setContraIndicationModalOpen(false);
          onUserStateUpdated();
        },
      );
    };

    return (
      <BaseModal
        opened={stateStore.contraIndicationModalOpen}
        onClose={onClose}
        title="Kontra Indikation die zum Ausschluss führt"
        description="Wenn der Teilnehmer eine Kontra Indikation hat, die zum Ausschluss führt, kann er nicht an der Studie zum Cannabis Konsum teilnehmen."
        iconName="cancel"
        iconColor="#E4032E"
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        buttons={[
          <Button key="cancel" variant="outline" onClick={onClose}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            disabled={!stateStore.contraIndication || stateStore.loading}
            onClick={onPositiveAction}
          >
            Ja, Teilnehmer ausschliessen
          </Button>,
        ]}
        children={
          <TextInput
            key="contraIndication"
            label="Kontra Indikation"
            placeholder="Kontra Indikation"
            type="text"
            onChange={(event) =>
              stateStore.setContraIndication(event.currentTarget?.value)
            }
          />
        }
      />
    );
  },
);
