import { z } from 'zod';
import { storeChecklistReqDto } from '../checklist';
import { entryQuestionnaireResDto } from '../entry-questionnaire';
import { userDataResDto } from './user-data.res.dto';
import { appointmentResDto } from '../appointment';

export const onboardingDataResDto = userDataResDto.extend({
  entryQuestionnaire: entryQuestionnaireResDto,
  checkListData: storeChecklistReqDto.nullable(),
  email: z.string().nullable(),
  emailVerified: z.boolean(),
  phoneNumber: z.string().nullable(),
  phoneNumberVerified: z.boolean(),
  pauseReason: z.string().nullable(),
  contraIndication: z.string().nullable(),
  contraIndicationDate: z.coerce.date().nullable(),
  onboardingAppointment: appointmentResDto.nullable(),
});
export type OnboardingDataResDto = z.infer<typeof onboardingDataResDto>;
