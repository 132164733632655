import { DialogContentType } from '../../../../store';
import { TFunction } from 'i18next';

const cuditDialogText = (
  t: TFunction<'questionnaire-evaluation', undefined>,
) => (
  <div>
    {t('content.cudit.1')} <br />
    {t('content.cudit.2')} <strong>{t('content.cudit.3')}</strong>{' '}
    {t('content.cudit.4')} <br />
    <br />
    {t('content.cudit.5')}{' '}
    <a href="https://arud.ch/substanzen-und-abhangigkeiten/abhangigkeiten/cannabis">
      {t('content.cudit.6')}
    </a>{' '}
    <br />
    <br />
    {t('content.cudit.7')}
  </div>
);
const sf12PhysicalDialogText = (
  t: TFunction<'questionnaire-evaluation', undefined>,
) => (
  <div>
    {t('content.sf12physical.1')} <br />
    {t('content.sf12physical.2')} <strong>{t('content.sf12physical.3')}</strong>{' '}
    {t('content.sf12physical.4')} <br />
    <br />
    {t('content.sf12physical.5')}
  </div>
);
const sf12MentalDialogText = (
  t: TFunction<'questionnaire-evaluation', undefined>,
) => (
  <div>
    {t('content.sf12mental.1')}
    <br />
    {t('content.sf12mental.2')} <strong>{t('content.sf12mental.3')}</strong>{' '}
    {t('content.sf12mental.4')}
    <br />
    <br />
    {t('content.sf12mental.5')}
  </div>
);

export const getDialogContent = (
  t: TFunction<'questionnaire-evaluation', undefined>,
  dialogType: DialogContentType | null,
) => {
  if (dialogType === null) return t('content.base');

  switch (dialogType) {
    case DialogContentType.CUDIT:
      return cuditDialogText(t);
    case DialogContentType.SF12Physical:
      return sf12PhysicalDialogText(t);
    case DialogContentType.SF12Mental:
      return sf12MentalDialogText(t);
    default:
      return t('content.base');
  }
};
