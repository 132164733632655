import { authenticatedAPI } from '../AuthenticatedAPI';
import {
  getPodcastsResDto,
  GivePodcastFeedbackReqDto,
  loyalityPointsRewardArrayResDto,
} from '@web/common';
import { z } from 'zod';

export const getPodcasts = async () => {
  return authenticatedAPI.get(`api/potcast`, getPodcastsResDto);
};

export const markPodcastAsFinished = async (podcastId: number) => {
  return authenticatedAPI.post(
    `api/potcast/${podcastId}/finished`,
    undefined,
    loyalityPointsRewardArrayResDto,
  );
};

export const giveFeedback = async (
  podcastId: number,
  data: GivePodcastFeedbackReqDto,
) => {
  return authenticatedAPI.put(
    `api/potcast/${podcastId}/feedback`,
    data,
    z.string().max(0),
  );
};
