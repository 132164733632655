import { createBrowserRouter, Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';
import { FeatureFlag, RealmRole } from '@web/common';
import { AuthenticatedRoot } from '../view/authenticated/AuthenticatedRoot';
import { Routes } from './Routes';
import { ParticipantDetail } from '../component';
import { FAQPage } from '../view/public/faq/FAQPage';
import { getFeature } from '../util/ff/getFeature';
import {
  Root,
  Datenschutz,
  Impressum,
  NotEligible,
  LandingPage,
  Nutzungsbedingungen,
  Register,
  VerifyEmail,
  EntryQuestionnaire,
  EnrollLandingPage,
  Deactivation,
  VerifyDeactivation,
} from '../view/public';
import {
  CannabisConsumptionQuestionnaire,
  Dashboard as ParticipantDashboard,
  SF12Questionnaire,
  BookInPerson,
  Deactivated,
  ShopScreen,
  ProfileScreen,
  StammdatenQuestionnaire,
  OrderDetail,
  PointsScreen,
  SimpleOnboardingChecklist,
  RescheduleAppointment,
  ConsumptionScreen,
  PodcastDetailsScreen,
} from '../view/authenticated/participant';
import {
  ViewSF12Questionnaire,
  ViewCannabisConsumptionQuestionnaire,
  ViewStammdatenQuestionnaire,
  ViewEntryQuestionnaire,
} from '../component/participantDataView';
import {
  OpenShopOrderWrapper,
  OrderDetailsWrapper,
  OrganizerDashboard,
  UserDetail,
  UserFilterList,
} from '../view/authenticated/organizer';
import {
  InventoryView,
  PausedUsersView,
  StatisticsView,
} from '../view/authenticated/study-owner';
import { QuickEnrollPage } from '../view/public/quick-enroll/QuickEnrollPage';
import { PodcastsView } from '../view/authenticated/participant/podcast/PodcastsView';
import { CohortType } from '@prisma/client';
import { AuthenticatedGlobalStores } from '../view/authenticated/AuthenticatedGlobalStores';
import { UpdateTHCLimitWrapper } from '../view/authenticated/participant/hrplus';

export const createRoutes = (
  realmRole: null | RealmRole,
  eligibleForHRPlusContent: boolean,
  cohortType: null | CohortType,
) => {
  return createBrowserRouter([
    {
      path: Routes.Root,
      Component: Root,
      children: [
        {
          index: true,
          Component: LandingPage,
        },
        { path: Routes.Enroll, Component: EnrollLandingPage },
        { path: Routes.QuickEnroll, Component: QuickEnrollPage },
        { path: Routes.Faq, Component: FAQPage },
        {
          path: Routes.EntryQuestionnaire,
          Component: EntryQuestionnaire,
        },
        {
          path: Routes.Register,
          Component: Register,
        },
        {
          path: Routes.VerifyEmail,
          Component: VerifyEmail,
        },
        {
          path: Routes.VerifyDeactivation,
          Component: VerifyDeactivation,
        },
        {
          path: Routes.SelfDeactivation,
          Component: Deactivation,
        },
        {
          path: Routes.NotEligible,
          Component: NotEligible,
        },
        {
          path: Routes.Nutzungsbedingungen,
          Component: Nutzungsbedingungen,
        },
        {
          path: Routes.Datenschutz,
          Component: Datenschutz,
        },
        {
          path: Routes.Impressum,
          Component: Impressum,
        },
        {
          path: Routes.Study,
          Component: () => (
            <AuthenticatedGlobalStores>
              <AuthenticatedRoot />
              <UpdateTHCLimitWrapper />
            </AuthenticatedGlobalStores>
          ),
          children: authenticatedRoutes(
            realmRole,
            eligibleForHRPlusContent,
            cohortType,
          ),
        },
        catchAllRoute,
      ],
    },
  ]);
};

const authenticatedRoutes = (
  realmRole: null | RealmRole,
  eligibleForHRPlusContent: boolean,
  cohortType: null | CohortType,
): RouteObject[] => {
  const employeeAndOrganizerShopRoutes =
    getFeature(FeatureFlag.Shop, [
      {
        path: Routes.OpenShopOrders,
        Component: OpenShopOrderWrapper,
      },
      {
        path: `${Routes.OpenShopOrders}/:orderId`,
        Component: OrderDetailsWrapper,
      },
    ]) ?? [];

  switch (realmRole) {
    case RealmRole.Deactivated: {
      return [
        {
          index: true,
          Component: Deactivated,
        },
      ];
    }
    case RealmRole.Participant: {
      const shop =
        getFeature(FeatureFlag.Shop, [
          {
            path: `${Routes.ShopOrder}/:orderId`,
            Component: OrderDetail,
          },
          {
            path: Routes.Shop,
            Component: ShopScreen,
          },
        ]) ?? [];

      return [
        {
          index: true,
          Component: ParticipantDashboard,
        },
        {
          path: Routes.SimpleOnboardingChecklist,
          Component: SimpleOnboardingChecklist,
        },
        ...(cohortType &&
        ([CohortType.ACCESS, CohortType.LIMITED_ACCESS] as string[]).includes(
          cohortType,
        )
          ? [
              {
                path: Routes.Profile,
                Component: ProfileScreen,
              },
            ]
          : []),
        {
          path: Routes.Points,
          Component: PointsScreen,
        },
        ...(eligibleForHRPlusContent
          ? [
              {
                path: Routes.Konsum,
                Component: ConsumptionScreen,
              },
              {
                path: Routes.Podcasts,
                Component: PodcastsView,
              },
              {
                path: `${Routes.PodcastDetails}/:podcastId`,
                Component: PodcastDetailsScreen,
              },
            ]
          : []),
        ...shop,
        {
          path: Routes.StammdatenQuestionnaire,
          Component: StammdatenQuestionnaire,
        },
        {
          path: Routes.CannabisConsumptionQuestionnaire,
          Component: CannabisConsumptionQuestionnaire,
        },
        {
          path: Routes.SF12Questionnaire,
          Component: SF12Questionnaire,
        },
        {
          path: Routes.BookInPerson,
          Component: BookInPerson,
        },
        {
          path: Routes.RescheduleAppointment,
          Component: RescheduleAppointment,
        },
        {
          path: '*',
          Component: () => <Navigate to={Routes.Study} />,
        },
      ];
    }
    case RealmRole.ShopEmployee: {
      return [
        {
          index: true,
          Component: OrganizerDashboard,
        },
        {
          path: `${Routes.User}/:userId`,
          Component: UserDetail,
          children: [
            {
              index: true,
              element: <ParticipantDetail />,
            },
            {
              path: Routes.UserDetailEntryQuestionnaire,
              element: <ViewEntryQuestionnaire />,
            },
            {
              path: Routes.UserDetailStammdatenQuestionnaire,
              element: <ViewStammdatenQuestionnaire />,
            },
            {
              path: Routes.UserDetailCannabisConsumptionQuestionnaire,
              element: <ViewCannabisConsumptionQuestionnaire />,
            },
            {
              path: Routes.UserDetailSF12Questionnaire,
              element: <ViewSF12Questionnaire />,
            },
          ],
        },
        ...employeeAndOrganizerShopRoutes,
        {
          path: '*',
          Component: () => <Navigate to={Routes.Study} />,
        },
      ];
    }
    case RealmRole.StudyOrganizer: {
      return [
        {
          index: true,
          Component: OrganizerDashboard,
        },
        {
          path: `${Routes.User}/:userId`,
          Component: UserDetail,
          children: [
            {
              index: true,
              element: <ParticipantDetail />,
            },
            {
              path: Routes.UserDetailEntryQuestionnaire,
              element: <ViewEntryQuestionnaire />,
            },
            {
              path: Routes.UserDetailStammdatenQuestionnaire,
              element: <ViewStammdatenQuestionnaire />,
            },
            {
              path: Routes.UserDetailCannabisConsumptionQuestionnaire,
              element: <ViewCannabisConsumptionQuestionnaire />,
            },
            {
              path: Routes.UserDetailSF12Questionnaire,
              element: <ViewSF12Questionnaire />,
            },
          ],
        },
        ...employeeAndOrganizerShopRoutes,
        {
          path: Routes.PausedUsers,
          Component: PausedUsersView,
        },
        {
          path: Routes.Statistics,
          Component: StatisticsView,
        },
        {
          path: Routes.FilterUsers,
          Component: UserFilterList,
        },
        {
          path: Routes.Inventory,
          Component: InventoryView,
        },
        {
          path: '*',
          Component: () => <Navigate to={Routes.Study} />,
        },
      ];
    }
    case RealmRole.Admin: {
      return [
        {
          path: '',
          element: <div>not implemented yet</div>,
        },
        {
          path: '*',
          Component: () => <Navigate to={Routes.Study} />,
        },
      ];
    }

    default: {
      const currentPath = window.location.pathname;
      if (currentPath.length > 1) {
        // since we dont want to save paths like that one: '/'
        sessionStorage.setItem('redirectLocation', window.location.pathname);
      }

      console.log(
        `Could not determine role. Not appending any protected routes`,
      );
      return [];
    }
  }
};

const catchAllRoute: RouteObject = {
  path: '*',
  Component: () => <Navigate to={Routes.Root} />,
};
