import { Flex, Text, Image, Space } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import classes from '../LandingPage.module.css';
import zurichZeitung from '../../../../assets/testimonial/zurichZeitung.png';
import blick from '../../../../assets/testimonial/blick.png';
import tagesanzeiger from '../../../../assets/testimonial/tagesanzeiger.png';
import forbes from '../../../../assets/testimonial/forbes.png';
import { useTranslation } from 'react-i18next';

type TranslationFunction = {
  (key: string): string;
};

const testimonialContent = (t: TranslationFunction) => [
  {
    cite: (
      <div>
        <Text>{t('Testimonials.Forbes.Cite1')}</Text>
        <br />
        <Text>{t('Testimonials.Forbes.Cite2')}</Text>
      </div>
    ),
    icon: forbes,
    author: 'Forbes.com',
  },
  {
    cite: t('Testimonials.NZZ.Cite'),
    icon: zurichZeitung,
    author: 'Neue Züricher Zeitung',
  },
  {
    cite: (
      <div>
        <Text>{t('Testimonials.Tagesanzeiger.Cite1')}</Text>
        <br />
        <Text>{t('Testimonials.Tagesanzeiger.Cite2')}</Text>
      </div>
    ),

    icon: tagesanzeiger,
    author: 'Tagesanzeiger.ch',
  },
  {
    cite: t('Testimonials.Blick.Cite'),
    icon: blick,
    author: 'Blick.ch',
  },
];

export const Testimonials = () => {
  const { t } = useTranslation('landingpage');
  const testimonialLoop = testimonialContent(t);

  return (
    <Flex
      className={classes.baseSection}
      gap="40px"
      direction="column"
      h="100%"
      p="0px"
    >
      <Text className={classes.faqTitle} p="20px">
        {t('Testimonials.Title')}
      </Text>

      <Carousel
        slideSize={{ base: '70%', md: '45%' }}
        slideGap={{ base: 'md', md: 'lg' }}
        loop
        align="center"
        maw="100%"
      >
        {testimonialLoop.map((content, index) => {
          return (
            <Carousel.Slide key={index}>
              <Flex className={classes.slide}>
                <Text ta="start" ff="Poppins" component="div">
                  {content.cite}
                </Text>
                <Flex align="center" gap="15px">
                  <Image alt="icon" src={content.icon} h="32px" />
                  <Text fw="500" lh="32px">
                    {content.author}
                  </Text>
                </Flex>
              </Flex>
            </Carousel.Slide>
          );
        })}
      </Carousel>
      <Space h="md" />
    </Flex>
  );
};
