import { z } from 'zod';

const TWILIO_CB_MESSAGE_STATUS_DELIVERED = 'delivered';
const TWILIO_CB_MESSAGE_STATUS_UNDELIVERED = 'undelivered';
const TWILIO_CB_MESSAGE_STATUS_FAILED = 'failed';

export const twilioIncomingMsgReqDto = z
  .object({
    SmsMessageSid: z.string(),
    NumMedia: z.string(),
    ProfileName: z.string(),
    MessageType: z.string(),
    SmsSid: z.string(),
    WaId: z.string(),
    SmsStatus: z.union([
      z.literal('queued'),
      z.literal('sent'),
      z.literal(TWILIO_CB_MESSAGE_STATUS_DELIVERED),
      z.literal(TWILIO_CB_MESSAGE_STATUS_UNDELIVERED),
      z.literal(TWILIO_CB_MESSAGE_STATUS_FAILED),
      z.string(),
    ]),
    Body: z.string(),
    To: z.string(),
    MessagingServiceSid: z.string(),
    NumSegments: z.string(),
    ReferralNumMedia: z.string(),
    MessageSid: z.string(),
    AccountSid: z.string(),
    From: z.string(),
    ApiVersion: z.string(),
  })
  // we want to keep unknown additional fields, because of the header validation
  .passthrough();
export type TwilioIncomingMsgReqDto = z.infer<typeof twilioIncomingMsgReqDto>;
