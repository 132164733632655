import { UserStatus } from '@prisma/client';
import { RealmRole } from '../../enum';

/**
 * On state changes:
 * OPEN -> DEACTIVATED => ✅ user is deactivated
 * OPEN -> ACTIVE => ✅ we connect the user with the participant
 * OPEN -> PAUSED => ✅ we allow it, since that means the user needs to be paused
 * OPEN -> OPEN => ❌ disallowed, since it is useless
 *
 * PAUSED -> OPEN => ❌ we disallow it, since then we'd have to consider deleting the participant and all its data
 * PAUSED -> ACTIVE => ✅ we allow it, since that means the user is active again
 * PAUSED -> DEACTIVATED => ✅ user is deactivated
 * PAUSED -> PAUSED => ❌ disallowed, since it is useless
 *
 * ACTIVE -> OPEN => ❌ we disallow it, since then we'd have to consider deleting the participant and all its data
 * ACTIVE -> ACTIVE => ❌ disallowed, since it is useless
 * ACTIVE -> DEACTIVATED => ✅ user is deactivated
 * ACTIVE -> PAUSED => ❌ we allow it, since that means the user needs to be paused
 *
 * DEACTIVATED => ❌ final state
 * DEACTIVATED -> OPEN => ❌ we disallow it, since then we'd have to consider deleting the participant and all its data
 * DEACTIVATED -> ACTIVE => ❌ if a mistake happened, you can reactivate the user
 * DEACTIVATED -> DEACTIVATED => ❌ disallowed, since it is useless
 */
export const ALLOWED_STATUS_CHANGES: Record<UserStatus, UserStatus[]> = {
  [UserStatus.OPEN]: [
    UserStatus.ACTIVE,
    UserStatus.PAUSED,
    UserStatus.DEACTIVATED,
  ],
  [UserStatus.PAUSED]: [UserStatus.ACTIVE, UserStatus.DEACTIVATED],
  [UserStatus.ACTIVE]: [UserStatus.DEACTIVATED],
  [UserStatus.DEACTIVATED]: [],
};

export const allowedStatusChangesByRole = (
  roles: RealmRole[],
  currentStatus: UserStatus,
) => {
  if (
    currentStatus === UserStatus.PAUSED &&
    !roles.includes(RealmRole.StudyOrganizer)
  ) {
    return [];
  }
  return ALLOWED_STATUS_CHANGES[currentStatus];
};
