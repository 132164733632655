import { CSVLink } from 'react-csv';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Data } from 'react-csv/lib/core';

export const CSVExport = ({
  csvData,
  disabled = false,
}: {
  csvData: string | Data | (() => string | Data);
  disabled?: boolean;
}) => {
  const { t } = useTranslation('common');

  return (
    <CSVLink data={csvData} filename={'list-export.csv'}>
      <Button disabled={disabled} type="submit" data-cy="export-button">
        {t('Buttons.ExportCSV')}
      </Button>
    </CSVLink>
  );
};
