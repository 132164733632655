import React from 'react';
import { getFeature } from './getFeature';
import { FeatureFlag } from '@web/common';

export function FeatureAccess({
  flag,
  children,
}: {
  flag: FeatureFlag;
  children: React.ReactNode;
}) {
  return getFeature(flag, children);
}
