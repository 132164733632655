import { z } from 'zod';

const thcConsumptionPerMonth = z.array(
  z.object({
    year: z.number(), // this will be DateTime.year
    month: z.number(), // this will be DateTime.month https://moment.github.io/luxon/api-docs/index.html#datetimemonth
    thcConsumptionInGrams: z.number(),
  }),
);

export type ThcConsumptionPerMonth = z.infer<typeof thcConsumptionPerMonth>;

export const thcConsumptionStatisticsResDto = z.object({
  // the array will be ordered correctly already
  thcPerMonth: thcConsumptionPerMonth,
  averageThcConsumptionPreviousTimeframe: z.number(), // this will be the sum of the previous thcPerMonth timeframe (e.g. 7-12 months)
});

export type ThcConsumptionStatisticsResDto = z.infer<
  typeof thcConsumptionStatisticsResDto
>;
