import { z } from 'zod';

export enum CannabisConsumptionQuestionnaireQuestionKey {
  HOW_OFTEN_DO_YOU_CONSUME_CANNABIS = 'how-often-do-you-consume-cannabis',
  HOW_MANY_HOURS_WERE_YOU_HIGH = 'how-many-hours-were-you-high',
  HOW_OFTEN_COULD_YOU_NOT_STOP_SMOKING = 'how-often-could-you-not-stop-smoking',
  FAILED_TO_MEET_EXPECTATIONS = 'failed-to-meet-expectations',
  ORGANIZING_CANNABIS_CONSUMPTION = 'organizing-cannabis-consumption',
  MEMORY_OR_CONCENTRATION_PROBLEMS = 'memory-or-concentration-problems',
  CONSUMPTION_IN_DANGEROUS_SITUATIONS = 'consumption-in-dangerous-situations',
  THOUGHT_ABOUT_STOPPING_CONSUMPTION = 'thought-about-stopping-consumption',

  WHEN_DID_YOU_START_CONSUMING_CANNABIS = 'when-did-you-start-consuming-cannabis',
  HOW_MANY_TIMES_DID_YOU_CONSUME_WHAT = 'how-many-times-did-you-consume-what',
  WHAT_CANNABIS_PRODUCTS_DID_YOU_CONSUME = 'what-cannabis-products-did-you-consume',
  HOW_DO_YOU_CONSUME_CANNABIS = 'how-do-you-consume-cannabis',
  OTHER_DRUGS = 'other-drugs',
}

export const cannabisConsumptionQuestionnaireReqDto = z.object({
  [CannabisConsumptionQuestionnaireQuestionKey.OTHER_DRUGS]: z
    .string()
    .optional(),
  [CannabisConsumptionQuestionnaireQuestionKey.FAILED_TO_MEET_EXPECTATIONS]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.HOW_DO_YOU_CONSUME_CANNABIS]: z
    .string()
    .optional(),
  [CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_HOURS_WERE_YOU_HIGH]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.ORGANIZING_CANNABIS_CONSUMPTION]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.MEMORY_OR_CONCENTRATION_PROBLEMS]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_DO_YOU_CONSUME_CANNABIS]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.THOUGHT_ABOUT_STOPPING_CONSUMPTION]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.CONSUMPTION_IN_DANGEROUS_SITUATIONS]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.HOW_MANY_TIMES_DID_YOU_CONSUME_WHAT]:
    z.string().optional(),
  [CannabisConsumptionQuestionnaireQuestionKey.HOW_OFTEN_COULD_YOU_NOT_STOP_SMOKING]:
    z.string(),
  [CannabisConsumptionQuestionnaireQuestionKey.WHEN_DID_YOU_START_CONSUMING_CANNABIS]:
    z.string().optional(),
  [CannabisConsumptionQuestionnaireQuestionKey.WHAT_CANNABIS_PRODUCTS_DID_YOU_CONSUME]:
    z.string().optional(),
});
export type CannabisConsumptionQuestionnaireResult = z.infer<
  typeof cannabisConsumptionQuestionnaireReqDto
>;
