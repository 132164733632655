import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePodcastStore } from '../../../../store/podcast';
import { useMyCohortStore } from '../../../../store/MyCohortStore';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../route/Routes';
import classes from './podcast.module.css';
import { Card, MyIcon } from '../../../../component';
import { Button, Flex, Text } from '@mantine/core';
import { ObtainablePoints } from '../../../../component/ObtainablePoints';

export const OpenPodcastPrompt = observer(() => {
  const { t } = useTranslation('podcast');
  const navigate = useNavigate();
  const podcastListStore = usePodcastStore();
  const myCohortStore = useMyCohortStore();

  const podcast = podcastListStore.currentOpenPodcast;

  useEffect(() => {
    podcastListStore.getPodcasts();
  }, [podcastListStore]);

  /**
   * not showing the prompt, if the user is not eligible for HR+ content yet
   */
  if (!podcast || !myCohortStore.eligibleForHRPlusContent) {
    return null;
  }

  return (
    <Card
      className={classes.card}
      flexProps={{ align: 'start', direction: 'column' }}
    >
      <Flex direction={'column'} w={'100%'} gap={'1em'}>
        <Flex direction={'row'} justify={'space-between'} w={'100%'}>
          <Text
            size={'16px'}
            lh={'24px'}
            style={{ fontWeight: 700, color: '#202020' }}
          >
            {t('Prompt.Title')}: {t(`Podcast.${podcast.id}.Title`)}
          </Text>
          <ObtainablePoints points={podcast.loyalityPoints} />
        </Flex>
        <Text
          size={'16px'}
          lh={'24px'}
          style={{ fontWeight: '400', color: '#202020', textAlign: 'start' }}
        >
          {t(`Podcast.${podcast.id}.Subtitle`)}
        </Text>
        <Button
          onClick={() =>
            navigate(`${Routes.Study}/${Routes.PodcastDetails}/${podcast.id}`)
          }
        >
          <Flex
            direction={'row'}
            justify={'center'}
            align={'center'}
            gap={'1em'}
          >
            <MyIcon
              name="play_filled"
              style={{ fill: 'white', width: '20px' }}
            />
            {t('Prompt.Button')}
          </Flex>
        </Button>
      </Flex>
    </Card>
  );
});
