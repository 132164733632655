import { z } from 'zod';
import { participantStatusZodEnum } from '../../enum';

export const createParticipantResDto = z.object({
  message: z.string(),
  qr_string: z.string(),
  data: z.object({
    participantID: z.string(),
    status: participantStatusZodEnum(),
    dispensaries: z.array(z.string()),
  }),
});
export type CreateParticipantResDto = z.infer<typeof createParticipantResDto>;
