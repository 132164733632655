import { Flex, Space, Text } from '@mantine/core';
import { OrderDto } from '@web/common';
import { useTranslation } from 'react-i18next';
import PickUp from '../PickUp/PickUp';

export const OrderOverview = ({ order }: { order: OrderDto }) => {
  const { t } = useTranslation('shop');
  return (
    <Flex direction="column">
      <Text fw="600" size="21px" lh="24px">
        {t('OrderOverview.Overview')}
      </Text>
      <Space h="20px" />
      {order.userId ? <PickUp userId={order.userId} /> : null}
      <Space h="20px" />
      <Text size="16px" lh="24px" c="#202020">
        {t('OrderOverview.Customer')}
      </Text>
      <Space h="10px" />
      <Text size="14px" lh="18px" c="#3D7F53">
        {order.billingAddress.firstName}, {order.billingAddress.lastName}
      </Text>
      <Text size="14px" lh="18px" c="#202020">
        {order.billingAddress.address1} {order.billingAddress.address2},{' '}
        {order.billingAddress.zip} {order.billingAddress.city}
      </Text>
    </Flex>
  );
};
